import React from "react";
import {TreeSelectionGroupDialog} from "../../TreeSelectionDialog";
import { useConfigurationGroupObjectSelectionArea }from "./hooks";
import EntityTypeEnum from "../../../models/EntityTypeEnum";
import {ITreeNode} from "../../SelectionTree/TreeNode/types";
import {TranslateText} from "../../../utils/Translations";
import GroupMemberSelectionUtil from "../../../utils/TreeSelection/GroupMemberSelectionUtil";

export interface ConfigurationGroupObjectSelectionAreaProps {
	configurationGroupId: string;
	templateEntityType: EntityTypeEnum;
	showDialog: boolean;
	selectedEntities: ITreeNode[];
	includeInactive: boolean;
	includeInactiveCallback: (includeInactive: boolean) => void;
	filterPlaceholder?: string;
	saveCallback: (treeData: ITreeNode[]) => void;
	closeDialogCallback: () => void;
}

const ObjectSelectionDialog = (props: ConfigurationGroupObjectSelectionAreaProps) => {
	const {
		showInactive,
		retrieveDataAsyncCallback,
		saveCallback,
		closeCallback,
		filterButtons,
	} =  useConfigurationGroupObjectSelectionArea(props);

	return (
		<TreeSelectionGroupDialog
			visible={props.showDialog}
			initialSelection={props.selectedEntities}
			title={TranslateText('common.addEntities')}
			filterPlaceholder={TranslateText('fleetSelection.searchEntitiesPlaceholder')}
			retrieveData={retrieveDataAsyncCallback}
			closeCallback={closeCallback}
			saveCallback={saveCallback}
			selectionUtil={GroupMemberSelectionUtil}
			showInactive={showInactive}
			clearOnCheckInactive={false}
			filterButtons={filterButtons}
		/>
	);
}

export default ObjectSelectionDialog;
