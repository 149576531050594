import React, { useEffect, useState } from "react";

import { HistoryTripTemplateDto, ProcessedTripDto } from "../../store/HistoryStore";
import { ApplicationState } from "../../store";
import { useSelector } from "react-redux";
import { Polyline } from "@react-google-maps/api";
import { privateDataMask } from "../../utils/TripUtils";
import { LatLng } from "../../store/LiveMap";

interface Props {
	tripConfig: ProcessedTripDto[];
	templates: HistoryTripTemplateDto[];
	isLiveMap: boolean;
	map: google.maps.Map;
	refresh: boolean;
}

interface TemplatePathDto {
	parentId: string;
	templateId: string;
	highlighted: boolean;
	color: string;
	path: LatLng[];
}

const RietveldHistoryTemplates = ({
		tripConfig,
		templates,
		isLiveMap,
		map,
		refresh
	}: Props) => {

	const highlightedTripIds = useSelector((state: ApplicationState) => state.historyStore.highlightedTripIds);
	const selectedTemplates = useSelector((state: ApplicationState) => state.historyStore.selectedTemplatesOnMap);

	const [paths, setPaths] = useState<TemplatePathDto[]>([]);
	const [numberOfPaths, setNumberOfPaths] = useState<Record<string, number>>(null);
	const [strokeWeights, setStrokeWeights] = useState<Record<string, number>>(null);
	const [currentPathsOnMap, setCurrentPathsOnMap] = useState<TemplatePathDto[]>([]);

	useEffect(() => {
		setPathsWithColors();
	}, [tripConfig, templates, refresh, highlightedTripIds]);

	useEffect(() => {
		const newPaths = paths?.filter(x => selectedTemplates?.some(s => s.templateId === x.templateId));
		setCurrentPathsOnMap(newPaths);
	},[selectedTemplates, paths]);
	const setPathsWithColors = () => {
		if (map && templates?.length && tripConfig?.length && !isLiveMap) {
			const bounds = new google.maps.LatLngBounds();
			let newPaths = [] as TemplatePathDto[];
			let count = 0;
			let lastParentId = null;
			let newStrokeWeights = {} as Record<string, number>;
			let newNumberOfPaths = {} as Record<string, number>
			for (let i = 0; i < tripConfig.length; i++) {
				const item = tripConfig[i];
				lastParentId = item.parentId;
				if(i > 0 && tripConfig[i].parentId !== tripConfig[i-1].parentId) {
					newNumberOfPaths[tripConfig[i-1].parentId] = count;
					count = 0;
				}
				if (
					!item.visible ||
					item.isParked ||
					item?.startAddress === privateDataMask ||
					item?.endAddress === privateDataMask
				) {
					continue;
				}
				const tripTemplates = templates.find((x) => x.tripId === item.id)?.templates;
				if(tripTemplates) {
					for (let j = 0; j < tripTemplates.length; j++) {
						const templatePath = tripTemplates[j].templatePath;
						const templateId = tripTemplates[j].templateId;
						let newPath = [] as LatLng[];
						let color: string = null;
						for (let p = 0; p < templatePath.length; p++) {

							if ((p === 0 && templatePath[p].break) || (p > 0 && templatePath[p - 1].break) || (p>0 && templatePath[p-1].color !== templatePath[p].color)) {
								if (newPath.length > 0) {

									if (!newStrokeWeights.hasOwnProperty(`${tripConfig[i].parentId}-${templateId}`)) {
										count = count + 5;
										newStrokeWeights[`${tripConfig[i].parentId}-${templateId}`] = count;
									}
									if(p > 0 && templatePath[p-1].color !== templatePath[p].color)
										newPath.push({lat: templatePath[p].latitude, lng: templatePath[p].longitude});
									newPaths.push({
										parentId: tripConfig[i].parentId,
										templateId: templateId,
										highlighted: highlightedTripIds.some((historyTrip) => historyTrip === item.idWithParent) ,
										color: color ?? '#4d6d92',
										path: [...newPath]});
									newPath = [];
								}
								if (templatePath[p].break === false) {
									color = templatePath[p].color;
									newPath.push({lat: templatePath[p].latitude, lng: templatePath[p].longitude});
								}
							} else {
								if (color === null)
									color = templatePath[p].color;
								newPath.push({lat: templatePath[p].latitude, lng: templatePath[p].longitude});
							}
						}
						if (newPath.length > 0) {
							newPaths.push({
								parentId: tripConfig[i].parentId,
								templateId: templateId,
								highlighted: highlightedTripIds.some((historyTrip) => historyTrip === item.idWithParent),
								color: color ?? '#4d6d92',
								path: [...newPath]});
							if (!newStrokeWeights.hasOwnProperty(`${tripConfig[i].parentId}-${templateId}`)) {
								count = count + 5;
								newStrokeWeights[`${tripConfig[i].parentId}-${templateId}`] = count;
							}
						}
					}
				}
				if(newPaths.length > 0)
					newPaths.forEach((x) => x.path.forEach((p) => bounds.extend(p)))
			}
			if(lastParentId !== null && !newNumberOfPaths.hasOwnProperty(lastParentId))
				newNumberOfPaths[lastParentId] = count;

			setPaths(newPaths);
			setStrokeWeights(newStrokeWeights);
			setNumberOfPaths(newNumberOfPaths);
		}
		else {
			setPaths([]);
			setStrokeWeights(null);
			setNumberOfPaths(null);
		}
	};
	const calculateStrokeWeight = (value: number, numberOfPaths: number, highlighted: boolean) => {
		if(highlighted)
			return 12 + numberOfPaths - value;
		return 8 + numberOfPaths - value;
	};
	return (
		<>
			{currentPathsOnMap?.length > 0 && strokeWeights && currentPathsOnMap?.map((x, index) => (
				<Polyline
					key={`poly-${index}`}
					options={{
						path: x.path,
						strokeWeight: calculateStrokeWeight(strokeWeights[`${x.parentId}-${x.templateId}`], numberOfPaths[x.parentId], x.highlighted),
						strokeColor: x.color,
						strokeOpacity: strokeWeights[`${x.parentId}-${x.templateId}`],
						zIndex: strokeWeights[`${x.parentId}-${x.templateId}`],
						icons: null,
					}}
				/>
			))}
		</>
	);
}

export default RietveldHistoryTemplates;
