enum EntityTypeEnum {
	Person = 'Person',
	Customer = 'Customer',
	Device = 'Device',
	Object = 'Object',
	Asset = 'Asset',
	SimCard = 'SimCard',
	Key = 'Key',
	Template = 'Template',
	Group = 'Group',
	Driver = 'Driver',
	User = 'User',
	Alert = 'Alert',
	Integration = 'Integration',
	ConfigurationGroup = 'ConfigurationGroup',
	Translation = 'Translation',
	GroupMember = 'GroupMember',
	Role = 'Role',
	Report = 'Report',
	Right = 'Right',
	ReportCategory = 'ReportCategory',
	Location = 'Location',
	InformationNotification = 'InformationNotification',
	PersonReport = 'PersonReport',
}

export default EntityTypeEnum;
