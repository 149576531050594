import './historyTabHeader.scss';

import {Checkbox} from '@material-ui/core';
import React, {useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

import {ApplicationState} from '../../../../store';
import {TrackedEntityModel} from '../../HistoryTabs/types';
import EntityTypeEnum from "../../../../models/EntityTypeEnum";
import {ClaimUtil} from "../../../../authorization/ClaimUtil";
import ClaimType, {ClaimValue} from "../../../../authorization/ClaimType";

interface Props {
	details: TrackedEntityModel;
	isSelected: boolean;
	removeTab: () => void;
	selectCallback: () => void;
	checkCallback: (id: string, checked: boolean) => void;
	checked: boolean;
	firstSelectedTabId?: string;
	editCallback: () => void;
	disabled: boolean;
	forceSelect: boolean;
	disabledEditButton: boolean;
	entityType: EntityTypeEnum;
}

const HistoryTabHeader = (props: Props) => {
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const tabRef = useRef<HTMLDivElement>(null);
	const isDriverWithNoAuthorization = useSelector(
		(state: ApplicationState) => state.currentSession.isDriverWithNoAuthorization
	);
	const driverIdentification = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.driverIdentification
			: s.currentSession.customer.featuresSettings.driverIdentification
	);

	const trackTypeSpecification = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.trackTypeSpecification
			: s.currentSession.customer.featuresSettings.trackTypeSpecification
	);

	useEffect(() => {
		if (props.details.entityId === props.firstSelectedTabId && tabRef.current) {
			if ((tabRef.current as any).scrollIntoViewIfNeeded) {
				(tabRef.current as any).scrollIntoViewIfNeeded();
			} else if (tabRef.current.scrollIntoView) {
				tabRef.current.scrollIntoView();
			}
		}
	}, [props.firstSelectedTabId]);

	return (
		<div
			ref={tabRef}
			className={`tab-header-container${props.isSelected ? ' selected' : ''}${props.disabled ? ' disabled' : ''}`}
			onClick={() => {
				if (!props.disabled) {
					props.selectCallback();
				}
			}}
		>
			<div
				className={'tab-header pop-in-animation'}
				style={isDriverWithNoAuthorization ? { cursor: 'default' } : null}
			>
				<div
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					{!isDriverWithNoAuthorization && (
						<Checkbox
							color={'primary'}
							checked={props.checked}
							onChange={() => {
								props.checkCallback(props.details.entityId, !props.checked);
							}}
						/>
					)}
				</div>
				<div className={`e-list-icon inline-block ${props.details.entityType}`} />
				<span className="normal-text">{props.details.entityName}</span>
				{!isDriverWithNoAuthorization &&
					((props.entityType === EntityTypeEnum.Object && (driverIdentification || trackTypeSpecification)) ||
						(props.entityType === EntityTypeEnum.Person && trackTypeSpecification)) &&
					ClaimUtil.validateClaim(user, {
					claim: ClaimType.TripsEditor,
					values: [ClaimValue.edit],
					}) && (
					<i
						onClick={(e) => {
							e.stopPropagation();
							if (props.disabledEditButton) {
								return;
							}
							if (props.forceSelect) {
								props.selectCallback();
							}
							props.editCallback();
						}}
						className={`material-icons with-hover edit-tab-icon-tab ${props.disabledEditButton ? 'disabled' : ''}`}
					>
						<EditOutlinedIcon className="edit-icon-tab" />
					</i>
				)}
				{!isDriverWithNoAuthorization && (
					<i
						onClick={(e) => {
							e.stopPropagation();
							props.removeTab();
						}}
						className="material-icons with-hover remove-tab-icon"
					>
						close
					</i>
				)}
			</div>
		</div>
	);
};

export default HistoryTabHeader;
