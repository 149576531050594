import './ConfigurationGroupStyle.scss';

import { AddWizardComponentProps } from 'components/NewAddWizard/NewAddWizard';
import ConfigurationGroupDto, {
	ConfigurationGroupFactors,
	ConfigurationGroupGeneralCamera,
	ConfigurationGroupGeneralInformation,
	ConfigurationGroupIncidents,
	ConfigurationGroupLevels,
	ConfigurationGroupMedia,
	ConfigurationGroupPeriodicals,
	ConfigurationGroupSettings,
	ConfigurationGroupThresholds,
	ConfigurationGroupTrips,
	ConfigurationGroupWeights,
} from 'models/ConfigurationGroupDto';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ValidationResult } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ApplicationState } from 'store';
import { TranslateText } from 'utils/Translations';

import GeneralInformation from './GeneralInformation/GeneralInformation';
import { handleConfigurationGroupChange } from './types';
import { ConfigurationGroupGeneralInformationRules } from './Utils';

type Props = AddWizardComponentProps & {
	id: string;
	customerId: string;
	aspNetUserId: string;
	objects: string[];
	active: boolean;
	startDateUtc: string;
	endDateUtc?: string;
	configurationGroupGeneralInformation: ConfigurationGroupGeneralInformation;
	configurationGroupWeights: ConfigurationGroupWeights;
	configurationGroupFactors: ConfigurationGroupFactors;
	configurationGroupSettings: ConfigurationGroupSettings;
	configurationGroupThresholds: ConfigurationGroupThresholds;
	configurationGroupTrips: ConfigurationGroupTrips;
	configurationGroupPeriodicals: ConfigurationGroupPeriodicals;
	configurationGroupIncidents: ConfigurationGroupIncidents;
	configurationGroupMedia: ConfigurationGroupMedia;
	configurationGroupGeneralCamera: ConfigurationGroupGeneralCamera;
	configurationGroupLevels: ConfigurationGroupLevels;
};

const ConfigGroupGenInfoView = (props: Props) => {
	const currentSession = useSelector((s: ApplicationState) => s.currentSession);
	const defaultCustomerId = useSelector((s: ApplicationState) =>
		s.globalCustomer.filteredCustomer ? s.globalCustomer.filteredCustomer.id : s.currentSession.customerId
	);

	const [unsavedData, setUnsavedData] = useState<boolean>(false);
	const [validationResult, setValidationResult] = useState<ValidationResult | null>(null);

	const [configurationGroupDto, setConfigurationGroupDto] = useState<ConfigurationGroupDto>(
		props.customerId
			? {
					id: props.id,
					customerId: props.customerId,
					aspNetUserId: props.aspNetUserId,
					objects: props.objects,
					active: props.active,
					startDateUtc: props.startDateUtc,
					endDateUtc: props.endDateUtc,
					configurationGroupGeneralInformation: props.configurationGroupGeneralInformation,
					configurationGroupWeights: props.configurationGroupWeights,
					configurationGroupFactors: props.configurationGroupFactors,
					configurationGroupSettings: props.configurationGroupSettings,
					configurationGroupThresholds: props.configurationGroupThresholds,
					configurationGroupTrips: props.configurationGroupTrips,
					configurationGroupPeriodicals: props.configurationGroupPeriodicals,
					configurationGroupIncidents: props.configurationGroupIncidents,
					configurationGroupMedia: props.configurationGroupMedia,
					configurationGroupGeneralCamera: props.configurationGroupGeneralCamera,
					configurationGroupLevels: props.configurationGroupLevels,
			  }
			: new ConfigurationGroupDto(defaultCustomerId)
	);

	const [validator] = useState<Validator>(() => {
		return new Validator(
			{
				fieldRules: ConfigurationGroupGeneralInformationRules(),
			},
			true
		);
	});

	useEffect(() => {
		validator.validate(configurationGroupDto).then((result) => {
			setValidationResult(result.validationResult);
			props.setValidationCallback(result.formResult);
			if (unsavedData) {
				props.onChangeCallback(configurationGroupDto);
			}
		});
	}, [configurationGroupDto]);

	const handleValueChangeMaterial = (value: any, statePropName: any, childObjName?: string) => {
		const newConfigurationGroup = handleConfigurationGroupChange<ConfigurationGroupDto>(
			configurationGroupDto,
			statePropName,
			value,
			childObjName
		);

		setUnsavedData(true);
		setConfigurationGroupDto(newConfigurationGroup);
	};

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-md-12">
					<div className="column-title">{TranslateText('common.generalInformation')}</div>
					<form id="configurationGroupGeneralInformationForm" noValidate={true}>
						<GeneralInformation
							currentSession={currentSession}
							data={configurationGroupDto}
							customerId={defaultCustomerId}
							validationResult={validationResult}
							handleChange={handleValueChangeMaterial}
						/>
					</form>
				</div>
			</div>
		</div>
	);
};

export default ConfigGroupGenInfoView;
