import React from 'react';
import MaterialTextField from 'shared/components/MaterialTextField/MaterialTextField';
import { TranslateText } from 'utils/Translations';

import { Radio, RadioGroup } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';

import { CONFIGURATIONGROUPTRIPS } from '../constants';
import { ConfigurationGroupProps } from '../types';
import {handleKeyDown} from "../../../../Common/RemoveSpinnerOptionInput";
import {getNumberValue} from "../../../../Widgets/Views/ConfigurationGroupSettingsView/Utils";

const ConfigurationGroupTripsView = ({
	data,
	validationResult,
	handleChange,
	isAddNew = true,
	editMode = true,
	isForNewAddWizard = true,
}: ConfigurationGroupProps) => {
	const handleChangeChild = (value: unknown, statePropName: string) => {
		handleChange(value, statePropName, CONFIGURATIONGROUPTRIPS);
	};
	return (
		<>
			<div className="form-group">
				<MaterialTextField
					autoFocus={true}
					isForNewAddWizard={isForNewAddWizard}
					id="ignitionTimeout"
					className="resize-font noSpinner"
					label={TranslateText('fields.ignitionTimeout')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="ignitionTimeout"
					value={data.configurationGroupTrips?.ignitionTimeout}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'ignitionTimeout')}
					validationResult={validationResult?.ignitionTimeoutConfigurationGroupTrips}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="radio-container">
				<RadioGroup row={false}>
					<FormControlLabel
						value={true}
						disabled={!isAddNew || !editMode}
						control={<Radio size="small" color="primary" />}
						label={
							<Typography
								style={{
									fontSize: 10,
									marginRight: 5,
									color: true ? 'black' : 'grey',
								}}
							>
								{TranslateText('fields.ignition')}
							</Typography>
						}
						labelPlacement="end"
						checked={data.configurationGroupTrips?.ignition ?? true}
						className={'radio-option no-grouping'}
						onChange={(_, checked) => {
							handleChangeChild(checked, 'ignition');
							handleChangeChild(!checked, 'engine');
						}}
					/>
					<FormControlLabel
						value={false}
						disabled={!isAddNew || !editMode}
						control={<Radio size="small" color="primary" />}
						label={
							<Typography
								style={{
									fontSize: 10,
									marginRight: 5,
									color: true ? 'black' : 'grey',
								}}
							>
								{TranslateText('fields.engine')}
							</Typography>
						}
						labelPlacement="end"
						checked={data.configurationGroupTrips?.engine ?? false}
						className={'radio-option'}
						onChange={(_, checked) => {
							handleChangeChild(checked, 'engine');
							handleChangeChild(!checked, 'ignition');
						}}
					/>
				</RadioGroup>
			</div>
		</>
	);
};

export default ConfigurationGroupTripsView;
