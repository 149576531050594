import CustomerLevelEnum from 'models/CustomerLevelEnum';
import moment from 'moment';
import { Column } from 'primereact/column';
import { DataTable, DataTablePFSEvent } from 'primereact/datatable';
import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { Button, Icon } from '@material-ui/core';

import ClaimType, { ClaimValue } from '../../authorization/ClaimType';
import { ClaimUtil } from '../../authorization/ClaimUtil';
import GlobalSettings from '../../GlobalSettings.json';
import { EditPlannedReportDto } from '../../models/EditPlannedReportDto';
import ReportDaysEnum from '../../models/ReportDaysEnum';
import ReportFrequencyEnum from '../../models/ReportFrequencyEnum';
import ReportGenerateTypeEnum from '../../models/ReportGenerateTypeEnum';
import ReportPeriodsEnum from '../../models/ReportPeriodsEnum';
import ReportRenderFormatEnum from '../../models/ReportRenderFormatEnum';
import TripTypesEnum from '../../models/TripTypesEnum';
import DateTimeUtil from '../../shared/datetime/DateTimeUtil';
import { ApplicationState } from '../../store';
import { reportStoreActionCreators } from '../../store/ReportStore';
import ajaxUtil from '../../utils/Ajax';
import { FormatDate } from '../../utils/DateUtils';
import { TranslateText, TranslateTextInterpolated } from '../../utils/Translations';
import { DialogUtil } from '../Common/NotificationDialog/NotificationDialog';
import { PageResult } from '../Common/Pagination';
import { PlannedReportDto } from './PlannedReportDto';

const mapStateToProps = (state: ApplicationState) => {
	return {
		user: state.oidc.user,
		customerTripTypes: state.currentSession.customer.featuresSettings.tripTypes,
	};
};

function mapDispatchToProps(dispatch: Dispatch) {
	return {
		reportStoreActionCreators: bindActionCreators(reportStoreActionCreators, dispatch),
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & {
	focus: boolean;
	take: number;
	customerId: string;
	customerLevel: CustomerLevelEnum;
	redirectOnEditCallback: (index: number) => void;
	driverIdentification: boolean;
};

type State = {
	reports: PlannedReportDto[];
	total: number;
	skip: number;
	currentReportId: string;
	disableEdit: string[];
};

class PlannedReportsOverview extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			reports: [],
			total: 0,
			skip: 0,
			currentReportId: '',
			disableEdit: [],
		};
	}

	private getUrl: string = GlobalSettings.reportingApi + '/plannedReports';

	componentDidUpdate(prevProps: Props, prevState: State) {
		if (
			(prevProps.focus !== this.props.focus ||
				prevProps.customerId !== this.props.customerId ||
				prevState.skip !== this.state.skip) &&
			this.props.focus
		) {
			this.fetchData();
		}
	}

	translatedSchedule(
		scheduleFrequency: ReportFrequencyEnum,
		scheduleDay: ReportDaysEnum,
		scheduleTime: string
	): string {
		switch (scheduleFrequency.toLowerCase()) {
			case ReportFrequencyEnum.Daily:
				return TranslateTextInterpolated('reports.overview.everyDay', [scheduleTime]); //Every day at scheduleTime
			case ReportFrequencyEnum.DailyRunning:
				return TranslateTextInterpolated('reports.overview.everyDay', [scheduleTime]); //Every day at scheduleTime
			case ReportFrequencyEnum.Weekly:
				return TranslateTextInterpolated('reports.overview.everyWeek', [
					TranslateText('reports.scheduleDay.' + scheduleDay.toLowerCase()),
					scheduleTime,
				]); //Every scheduleDay at scheduleTime
			case ReportFrequencyEnum.Monthly:
				return TranslateTextInterpolated('reports.overview.everyMonth', [scheduleTime]); // Every first day of the month at scheduleTime
			case ReportFrequencyEnum.Quarterly:
				return TranslateTextInterpolated('reports.overview.everyQuarter', [scheduleTime]); // Every first day of a quarter of the year at scheduleTime
		}
	}

	formatReportPeriod(periodType: string, customPeriodInterval: Date[]) {
		if(periodType !== null) {
			if (periodType.toLowerCase() === ReportPeriodsEnum.CustomPeriod.value) {
				return (
					TranslateText('reports.periods.' + periodType.toLowerCase()) +
					': ' +
					FormatDate(customPeriodInterval[0]) +
					' - ' +
					FormatDate(customPeriodInterval[1])
				);
			} else {
				return TranslateText('reports.periods.' + periodType.toLowerCase());
			}
		}
		return null;
	}

	fetchData() {
		const body = {
			take: this.props.take,
			skip: this.state.skip,
			getAllPlannedReports: ClaimUtil.validateClaim(this.props.user, {
				claim: ClaimType.Reporting,
				values: [ClaimValue.editPlannedReports],
			}),
			customerId: this.props.customerId,
			loggedInCustomerLevel: this.props.customerLevel,
		};
		ajaxUtil.post<PageResult<PlannedReportDto>>(this.getUrl, body).then((result) => {
			this.setState({
				reports: result.data,
				total: result.total,
			});
		});
	}

	deleteReport() {
		const url = GlobalSettings.reportingApi + '/deletePlannedReport/' + this.state.currentReportId;
		ajaxUtil.delete(url).then((data) => {
			this.fetchData();
		});
	}

	private handleUserResponse(response: boolean) {
		if (response) {
			this.deleteReport();
		}
	}

	openDeleteReportDialog(reportId: string) {
		this.setState({
			currentReportId: reportId,
		});
		DialogUtil.confirm({
			title: TranslateText('common.titleConfirmAction'),
			content: TranslateText('notificationMessages.deletePlannedReport'),
		}).then(this.handleUserResponse.bind(this));
	}

	getConvertedReportScheduleTimeFallback(data: EditPlannedReportDto) {
		let fallbackScheduleTimeConverted = new Date(`01-01-2021 ${data.reportParameters.scheduleTime}`);
		if (!moment(fallbackScheduleTimeConverted).isValid()) {
			const timeParsed = moment(data.reportParameters.scheduleTime, ['h:mm:ss A']).format('HH:mm:ss');

			fallbackScheduleTimeConverted = new Date(`01/01/2021 ${timeParsed}`);
		}
		return fallbackScheduleTimeConverted;
	}

	getTripTypeParameters(data: TripTypesEnum): TripTypesEnum {
		let newTripTypeParameter = data;
		for (let x in TripTypesEnum) {
			if (!isNaN(Number(x)) && !(this.props.customerTripTypes & Number(x)) && data & Number(x)) {
				newTripTypeParameter = newTripTypeParameter - Number(x);
			}
		}
		return newTripTypeParameter;
	}

	verifyDisabledButton(reportId: string): boolean {
		return this.state.disableEdit.some((s) => s == reportId);
	}

	openEditPlannedReport(reportId: string) {
		this.setState({ disableEdit: [...this.state.disableEdit, reportId] });
		const url = `${GlobalSettings.reportingApi}/getPlannedReport/${reportId}`;
		ajaxUtil
			.get<EditPlannedReportDto>(url)
			.then((data) => {
				let periodInThePast = false;
				const periodType = ReportPeriodsEnum.GetReportPeriodTypeByValue(data.reportParameters.reportPeriodType);
				if (
					periodType === ReportPeriodsEnum.CustomPeriod &&
					data.reportParameters.reportCustomPeriodInterval &&
					data.reportParameters.reportCustomPeriodInterval.length > 1
				) {
					if (data.reportParameters.reportCustomPeriodInterval[1] < new Date()) {
						periodInThePast = true;
					}
				}

				this.props.reportStoreActionCreators.setReportData({
					reportId: data.id,
					isPlanned: true,
					selectedTemplateId: data.templateId,
					reportName: data.reportName,
					reportNotes: data.reportNotes,
					reportTemplateName: data.reportTemplateName,
					templateEntityType: data.templateEntityType,
					templateDataType: data.dataType,
					reportCategory: TranslateText(data.templateCategoryType),
					reportCategoryId: data.selectedCategoryId,
					reportPeriodType: periodType,
					reportHeaderTitle: data.reportName,
					reportCustomPeriodInterval: data.reportParameters.reportCustomPeriodInterval,
					customerId: data.reportParameters.customerId,
					personId: data.reportParameters.personId,
					periodInThePast: periodInThePast,
					includeInactive: data.reportParameters.includeInactive,
					includePrivateData: data.reportParameters.includePrivateData,
					calculateVisitLastTripOfDayInReport: data.reportParameters.calculateVisitLastTripOfDayInReport,
					templates: data.reportParameters.templates,
					selectedSummaries: data.reportParameters.selectedSummaries,
					detailed: data.reportParameters.detailed,
					locationType: data.reportParameters.locationType
				});
				this.props.reportStoreActionCreators.setTripData({
					tripDistanceParameter: data.reportParameters.tripDistanceParameter,
					tripTypeParameter: this.getTripTypeParameters(data.reportParameters.tripTypeParameter),
				});
				this.props.reportStoreActionCreators.setReportingEntitiesData({
					reportSelectedEntities: data.reportParameters.reportPersonIds
						.concat(data.reportParameters.reportObjectIds)
						.concat(data.reportParameters.reportGroupIds),
				});

				this.props.reportStoreActionCreators.setOutsideBusinessHours(
					data.reportParameters.outsideBusinessHours
				);

				this.props.reportStoreActionCreators.setScheduleData({
					sendAsMail: data.reportParameters.sendAsMail,
					scheduleDay: ReportDaysEnum[data.reportParameters.scheduleDay as keyof typeof ReportDaysEnum],
					scheduleFrequency:
						ReportFrequencyEnum[
							data.reportParameters.scheduleFrequency as keyof typeof ReportFrequencyEnum
						],
					scheduleTime: moment(
						moment(new Date()).format('L') + ' ' + data.reportParameters.scheduleTime
					).isValid()
						? moment(moment(new Date()).format('L') + ' ' + data.reportParameters.scheduleTime).toDate()
						: this.getConvertedReportScheduleTimeFallback(data),
					scheduleTimezoneId: data.reportParameters.scheduleTimezoneId,
					receivers: data.mailReceivers,
					generateType: ReportGenerateTypeEnum.Schedule,
					separateReports: data.reportParameters.separateReports,
					formatType:
						ReportRenderFormatEnum[data.reportParameters.formatType as keyof typeof ReportRenderFormatEnum],
				});
				this.props.reportStoreActionCreators.setCurrentStep(6);

				setTimeout(() => {
					this.props.redirectOnEditCallback(0);
					this.setState({ disableEdit: [...this.state.disableEdit.map((s) => s != reportId && s)] });
				}, 500);
			})
			.catch((err) => {
				this.setState({ disableEdit: [...this.state.disableEdit.map((s) => s != reportId && s)] });
				console.log(err);
			});
	}

	render() {
		return (
			<div className="report-content-container">
				<div className="flex-grid-container">
					<DataTable
						emptyMessage={TranslateText('common.noData')}
						className={'overview'}
						value={this.state.reports}
						totalRecords={this.state.total}
						rows={this.props.take}
						paginator={true}
						lazy={true}
						first={this.state.skip}
						dataKey={'id'}
						paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
						currentPageReportTemplate={TranslateText('historyOverview.pageReportTemplate')}
						onPage={(e: DataTablePFSEvent) => {
							this.setState({
								skip: e.first,
							});
						}}
					>
						<Column
							field="requestedByUserName"
							header={TranslateText('maintenanceOverview.grid.colUser')}
							style={{ maxWidth: '150px', textAlign: 'left' }}
							body={(rowData: PlannedReportDto) => (
								<span title={rowData.requestedByUserName}>{rowData.requestedByUserName}</span>
							)}
						/>
						<Column
							field="reportName"
							header={TranslateText('maintenanceOverview.grid.colReportName')}
							style={{ maxWidth: '200px', textAlign: 'left' }}
							body={(rowData: PlannedReportDto) => (
								<span title={rowData.reportName}>{rowData.reportName}</span>
							)}
						/>
						<Column
							field="templateCategoryType"
							header={TranslateText('maintenanceOverview.grid.colReportType')}
							style={{ width: '100px', textAlign: 'left' }}
							body={(rowData: PlannedReportDto) => {
								return TranslateText(rowData.templateCategoryType);
							}}
						/>
						<Column
							field="outputType"
							header={TranslateText('maintenanceOverview.grid.colOutputType')}
							style={{ width: '70px', textAlign: 'left' }}
						/>
						<Column
							field="selectedGroups"
							header={TranslateText('maintenanceOverview.grid.colSelectedGroups')}
							style={{ width: '70px', textAlign: 'left' }}
						/>
						<Column
							field="selectedEntities"
							header={TranslateText('maintenanceOverview.grid.colSelectedEntities')}
							style={{ width: '70px', textAlign: 'left' }}
						/>
						<Column
							field="customPeriodInterval"
							header={TranslateText('maintenanceOverview.grid.colRequestedPeriod')}
							style={{ maxWidth: '100px', textAlign: 'left' }}
							body={(rowData: PlannedReportDto) => {
								const period = this.formatReportPeriod(
									rowData.periodType,
									rowData.customPeriodInterval
								);
								return <span title={period}>{period}</span>;
							}}
						/>
						<Column
							field="receivers"
							header={TranslateText('maintenanceOverview.grid.colReceivers')}
							style={{ width: '85px', textAlign: 'left' }}
						/>
						<Column
							field="lastRun"
							header={TranslateText('maintenanceOverview.grid.colLastRun')}
							style={{ maxWidth: '190px', textAlign: 'left' }}
							body={(rowData: PlannedReportDto) => {
								if (!rowData.lastRun) {
									return null;
								}
								const lastRun = FormatDate(new Date(rowData.lastRun));
								return <span title={lastRun}>{lastRun}</span>;
							}}
						/>
						<Column
							field="nextRun"
							header={TranslateText('maintenanceOverview.grid.colNextRun')}
							style={{ maxWidth: '190px', textAlign: 'left' }}
							body={(rowData: PlannedReportDto) => {
								const nextRun = FormatDate(new Date(rowData.nextRun));
								return <span title={nextRun}>{nextRun}</span>;
							}}
						/>
						<Column
							field="scheduleFrequency.scheduleDay.scheduleTime"
							header={TranslateText('maintenanceOverview.grid.colSchedule')}
							style={{ maxWidth: '220px', textAlign: 'left' }}
							body={(rowData: PlannedReportDto) => {
								const schedule = this.translatedSchedule(
									rowData.scheduleFrequency,
									rowData.scheduleDay,
									moment(rowData.scheduleTime).format(DateTimeUtil.momentTimeFormat())
								);
								return <span title={schedule}>{schedule}</span>;
							}}
						/>
						<Column
							field="deliverType"
							header={TranslateText('maintenanceOverview.grid.colGenerateType')}
							style={{ width: '45px', textAlign: 'left' }}
							body={(rowData: PlannedReportDto) => {
								return <div className={'generate-type-' + rowData.deliverType} />;
							}}
						/>
						{ClaimUtil.validateClaim(this.props.user, {
							claim: ClaimType.Reporting,
							values: [ClaimValue.edit],
						}) && (
							<Column
								field="remove"
								header={TranslateText('maintenanceOverview.grid.colRemove')}
								style={{ width: '70px', textAlign: 'center' }}
								body={(rowData: PlannedReportDto) => (
									<Button
										className="report-grid-button"
										disableElevation
										variant="contained"
										disabled={rowData.status === 'InProgress'}
										onClick={() => this.openDeleteReportDialog(rowData.id)}
									>
										<Icon>close</Icon>
									</Button>
								)}
							/>
						)}
						{ClaimUtil.validateClaim(this.props.user, {
							claim: ClaimType.Reporting,
							values: [ClaimValue.edit],
						}) && (
							<Column
								field="edit"
								header={TranslateText('maintenanceOverview.grid.colEdit')}
								style={{ width: '70px', textAlign: 'center' }}
								body={(rowData: PlannedReportDto) => (
									<Button
										className="report-grid-button"
										disableElevation
										variant="contained"
										disabled={this.verifyDisabledButton(rowData.id)}
										onClick={() => this.openEditPlannedReport(rowData.id)}
									>
										<Icon>edit</Icon>
									</Button>
								)}
							/>
						)}
					</DataTable>
				</div>
			</div>
		);
	}
}

export default connector(PlannedReportsOverview);
