import EntityTypeEnum from 'models/EntityTypeEnum';
import { WorkingDay } from 'models/WorkingDay';

import {
	LocationPositionType,
	SummaryType
} from '../components/ReportsOverview/NewReport/ReportParameters';
import EntityData from '../models/EntityData';
import ReportDaysEnum from '../models/ReportDaysEnum';
import ReportFrequencyEnum from '../models/ReportFrequencyEnum';
import ReportGenerateTypeEnum from '../models/ReportGenerateTypeEnum';
import ReportPeriodsEnum, { ReportPeriodType } from '../models/ReportPeriodsEnum';
import ReportRenderFormatEnum from '../models/ReportRenderFormatEnum';
import TemplateData from '../models/TemplateData';
import TripTypesEnum from '../models/TripTypesEnum';

export const SET_DATA = 'SET_DATA';
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
export const SET_REPORT_DATA = 'SET_REPORT_DATA';
export const SET_VALID_PERIOD = 'SET_VALID_PERIOD';
export const SET_REPORTING_ENTITIES_DATA = 'SET_REPORTING_ENTITIES_DATA';
export const SET_TRIP_DATA = 'SET_TRIP_DATA';
export const SET_SCHEDULE_DATA = 'SET_SCHEDULE_DATA';
export const SET_OUTSIDE_BUSINESS_HOURS = 'SET_OUTSIDE_BUSINESS_HOURS';
export const RESET_REPORT = 'RESET_EPORT';
export const SET_INCLUDE_INACTIVE = 'SET_INCLUDE_INACTIVE';
export const SET_INCLUDE_PRIVATE_DATA = 'SET_INCLUDE_PRIVATE_DATA';

export interface ReportStoreState {
	reportId: string;
	isFavorite: boolean;
	isPlanned: boolean;
	currentStep: number;
	selectedTemplateId: string;
	templateEntityType: EntityTypeEnum;
	templateDataType: TemplateData;
	reportHeaderTitle: string;
	reportTemplateName: string;
	reportName: string;
	reportCategory: string;
	reportCategoryId: string;
	reportNotes: string;
	unsavedData: boolean;
	data: ReportTemplate[][];
	reportPeriodType: ReportPeriodType;
	reportCustomPeriodInterval?: string[];
	reportSelectedEntities: EntityData[];
	tripTypeParameter: TripTypesEnum;
	tripDistanceParameter: number;
	formatType: ReportRenderFormatEnum;
	separateReports: boolean;
	generateType: ReportGenerateTypeEnum;
	scheduleFrequency: ReportFrequencyEnum;
	scheduleDay: ReportDaysEnum;
	scheduleTime: Date;
	scheduleTimezoneId: string;
	timeZone: string;
	sendAsMail: boolean;
	receivers: EntityData[];
	isValidPeriod: boolean;
	periodInThePast: boolean;
	customerId: string;
	personId: string;
	includeInactive: boolean;
	includePrivateData: boolean;
	includeMaxSpeed: boolean;
	calculateVisitLastTripOfDayInReport: boolean;
	includeDevicesWithoutObjectConnection: boolean;
	templates: string[];
	locations: string[];
	objectTypes: string[];
	fuelTypes: string[];
	selectedSummaries: SummaryType[];
	outsideBusinessHours: OutsideBusinessHours | null;
	detailed: boolean;
	locationType: LocationPositionType;
}

export type OutsideBusinessHours = {
	includeTripsStartedBefore: boolean;
	includeTripsEndedAfter: boolean;
	workingDays: WorkingDay[];
};

export interface ReportTemplate {
	id: string;
	key: string;
	name: string;
	description: string;
	category: string;
	categoryId: string;
	entityType: EntityTypeEnum;
	dataType: TemplateData;
}

const initialState: ReportStoreState = {
	currentStep: 1,
	reportId: null,
	isFavorite: false,
	isPlanned: false,
	selectedTemplateId: null,
	templateEntityType: null,
	templateDataType: null,
	reportHeaderTitle: null,
	reportName: null,
	reportTemplateName: null,
	reportCategory: null,
	reportCategoryId: null,
	reportNotes: null,
	data: null,
	unsavedData: false,
	reportPeriodType: ReportPeriodsEnum.LastWeek,
	reportCustomPeriodInterval: null,
	reportSelectedEntities: [],
	tripTypeParameter: TripTypesEnum.Business | TripTypesEnum.Private | TripTypesEnum.Commuting,
	tripDistanceParameter: 0.1,
	formatType: ReportRenderFormatEnum.pdf,
	separateReports: false,
	generateType: ReportGenerateTypeEnum.GenerateNow,
	scheduleFrequency: ReportFrequencyEnum.Monthly,
	scheduleDay: ReportDaysEnum.Sunday,
	scheduleTime: null,
	scheduleTimezoneId: null,
	timeZone: null,
	receivers: [],
	sendAsMail: false,
	isValidPeriod: true,
	periodInThePast: false,
	customerId: null,
	personId: null,
	includeInactive: false,
	includePrivateData: false,
	includeMaxSpeed: false,
	calculateVisitLastTripOfDayInReport: false,
	templates: ['All'],
	locations: ['All'],
	objectTypes: ['All'],
	fuelTypes: ['All'],
	selectedSummaries: [SummaryType.no],
	outsideBusinessHours: null,
	includeDevicesWithoutObjectConnection: false,
	detailed: false,
	locationType: LocationPositionType.In
};

export const reportStoreActionCreators = {
	setData: (data: any) => (dispatch: any) => {
		dispatch({
			type: SET_DATA,
			data: data,
		});
	},
	setCurrentStep: (currentStep: number) => (dispatch: any) => {
		dispatch({
			type: SET_CURRENT_STEP,
			currentStep: currentStep,
		});
	},
	setReportData: (options: {
		reportId?: string;
		isFavorite?: boolean;
		isPlanned?: boolean;
		selectedTemplateId?: string;
		templateEntityType?: EntityTypeEnum;
		templateDataType?: TemplateData;
		reportName?: string;
		reportTemplateName?: string;
		reportHeaderTitle?: string;
		reportCategory?: string;
		reportCategoryId?: string;
		reportNotes?: string;
		reportPeriodType?: ReportPeriodType;
		reportCustomPeriodInterval?: Date[];
		periodInThePast?: boolean;
		unsavedData?: boolean;
		timeZone?: string;
		customerId?: string;
		personId?: string;
		includeInactive?: boolean;
		includePrivateData?: boolean;
		includeMaxSpeed?: boolean;
		calculateVisitLastTripOfDayInReport?: boolean;
		selectedSummaries?: SummaryType[];
		templates?: string[];
		locations?: string[];
		objectTypes?: string[];
		fuelTypes?: string[];
		includeDevicesWithoutObjectConnection?: boolean;
		detailed?: boolean;
		locationType?: LocationPositionType;
	}) => (dispatch: any) => {
		dispatch({
			type: SET_REPORT_DATA,
			reportId: options.reportId,
			isFavorite: options.isFavorite,
			isPlanned: options.isPlanned,
			selectedTemplateId: options.selectedTemplateId,
			templateEntityType: options.templateEntityType,
			templateDataType: options.templateDataType,
			reportName: options.reportName,
			reportTemplateName: options.reportTemplateName,
			reportHeaderTitle: options.reportHeaderTitle,
			reportCategory: options.reportCategory,
			reportCategoryId: options.reportCategoryId,
			reportNotes: options.reportNotes,
			reportPeriodType: options.reportPeriodType,
			reportCustomPeriodInterval: options.reportCustomPeriodInterval,
			periodInThePast: options.periodInThePast,
			unsavedData: options.unsavedData,
			timeZone: options.timeZone,
			customerId: options.customerId,
			personId: options.personId,
			includeInactive: options.includeInactive,
			includePrivateData: options.includePrivateData,
			includeMaxSpeed: options.includeMaxSpeed,
			calculateVisitLastTripOfDayInReport: options.calculateVisitLastTripOfDayInReport,
			selectedSummaries: options.selectedSummaries,
			templates: options.templates,
			locations: options.locations,
			objectTypes: options.objectTypes,
			fuelTypes: options.fuelTypes,
			includeDevicesWithoutObjectConnection: options.includeDevicesWithoutObjectConnection,
			detailed: options.detailed,
			locationType: options.locationType,
		});
	},
	setValidPeriod: (isValidPeriod: boolean) => (dispatch: any) => {
		dispatch({
			type: SET_VALID_PERIOD,
			isValidPeriod: isValidPeriod,
		});
	},
	setReportingEntitiesData: (options: { reportSelectedEntities?: EntityData[]; unsavedData?: boolean }) => (
		dispatch: any
	) => {
		dispatch({
			type: SET_REPORTING_ENTITIES_DATA,
			reportSelectedEntities: options.reportSelectedEntities,
			unsavedData: options.unsavedData,
		});
	},
	setTripData: (options: {
		tripDistanceParameter?: number;
		tripTypeParameter?: TripTypesEnum;
		includePrivateData?: boolean;
		includeMaxSpeed?: boolean;
		calculateVisitLastTripOfDayInReport?: boolean;
		templates?: string[];
		locations?: string[];
		selectedSummaries?: SummaryType[];
		unsavedData?: boolean;
		objectTypes?: string[];
		fuelTypes?: string[];
		includeDevicesWithoutObjectConnection?: boolean;
		detailed?: boolean,
		locationType?: LocationPositionType
	}) => (dispatch: any) => {
		dispatch({
			type: SET_TRIP_DATA,
			tripDistanceParameter: options.tripDistanceParameter,
			tripTypeParameter: options.tripTypeParameter,
			includePrivateData: options.includePrivateData,
			includeMaxSpeed: options.includeMaxSpeed,
			calculateVisitLastTripOfDayInReport: options.calculateVisitLastTripOfDayInReport,
			templates: options.templates,
			locations: options.locations,
			selectedSummaries: options.selectedSummaries,
			unsavedData: options.unsavedData,
			objectTypes: options.objectTypes,
			fuelTypes: options.fuelTypes,
			includeDevicesWithoutObjectConnection: options.includeDevicesWithoutObjectConnection,
			detailed: options.detailed,
			locationType: options.locationType
		});
	},
	setScheduleData: (options: {
		formatType?: ReportRenderFormatEnum;
		separateReports?: boolean;
		generateType?: ReportGenerateTypeEnum;
		scheduleFrequency?: ReportFrequencyEnum;
		scheduleDay?: ReportDaysEnum;
		scheduleTime?: Date;
		scheduleTimezoneId?: string;
		sendAsMail?: boolean;
		receivers?: EntityData[];
		unsavedData?: boolean;
	}) => (dispatch: any) => {
		dispatch({
			type: SET_SCHEDULE_DATA,
			formatType: options.formatType,
			separateReports: options.separateReports,
			generateType: options.generateType,
			scheduleFrequency: options.scheduleFrequency,
			scheduleDay: options.scheduleDay,
			scheduleTime: options.scheduleTime,
			scheduleTimezoneId: options.scheduleTimezoneId,
			sendAsMail: options.sendAsMail,
			receivers: options.receivers,
			unsavedData: options.unsavedData,
		});
	},
	setOutsideBusinessHours: (outsideBusinessHours: OutsideBusinessHours, unsavedData?: boolean) => (dispatch: any) => {
		dispatch({
			type: SET_OUTSIDE_BUSINESS_HOURS,
			outsideBusinessHours: outsideBusinessHours,
			unsavedData: unsavedData,
		});
	},
	setIncludeInactive: (includeInactive: boolean) => (dispatch: any) => {
		dispatch({
			type: SET_INCLUDE_INACTIVE,
			includeInactive: includeInactive,
		});
	},
	setIncludePrivateData: (includePrivateData: boolean) => (dispatch: any) => {
		dispatch({
			type: SET_INCLUDE_PRIVATE_DATA,
			includePrivateData: includePrivateData,
		});
	},
	resetReport: () => (dispatch: any) => {
		dispatch({
			type: RESET_REPORT,
		});
	},
};

export const reportStoreReducer = (state: ReportStoreState, action: any) => {
	state = state || initialState;
	switch (action.type) {
		case SET_DATA:
			return {
				...state,
				data: action.data,
			};
		case SET_CURRENT_STEP:
			return {
				...state,
				currentStep: action.currentStep,
			};
		case SET_REPORT_DATA:
			return {
				...state,
				reportId: action.reportId ?? state.reportId,
				isFavorite: action.isFavorite ?? state.isFavorite,
				isPlanned: action.isPlanned ?? state.isPlanned,
				selectedTemplateId: action.selectedTemplateId ?? state.selectedTemplateId,
				templateEntityType: action.templateEntityType ?? state.templateEntityType,
				templateDataType: action.templateDataType ?? state.templateDataType,
				reportName: action.reportName ?? state.reportName,
				reportTemplateName: action.reportTemplateName ?? state.reportTemplateName,
				reportHeaderTitle: action.reportHeaderTitle ?? state.reportHeaderTitle,
				reportCategory: action.reportCategory ?? state.reportCategory,
				reportCategoryId: action.reportCategoryId ?? state.reportCategoryId,
				reportNotes: action.reportNotes ?? state.reportNotes,
				reportPeriodType: action.reportPeriodType ?? state.reportPeriodType,
				reportCustomPeriodInterval: action.reportCustomPeriodInterval ?? state.reportCustomPeriodInterval,
				periodInThePast: action.periodInThePast ?? state.periodInThePast,
				unsavedData: action.unsavedData ?? state.unsavedData,
				timeZone: action.timeZone ?? state.timeZone,
				customerId: action.customerId ?? state.customerId,
				personId: action.personId ?? state.personId,
				includeInactive: action.includeInactive ?? state.includeInactive,
				includePrivateData: action.includePrivateData ?? state.includePrivateData,
				includeMaxSpeed: action.includeMaxSpeed ?? state.includeMaxSpeed,
				calculateVisitLastTripOfDayInReport:
					action.calculateVisitLastTripOfDayInReport ?? state.calculateVisitLastTripOfDayInReport,
				selectedSummaries: action.selectedSummaries ?? state.selectedSummaries,
				templates: action.templates ?? state.templates,
				locations: action.locations ?? state.locations,
				objectTypes: action.objectTypes ?? state.objectTypes,
				fuelTypes: action.fuelTypes ?? state.fuelTypes,
				includeDevicesWithoutObjectConnection: action.includeDevicesWithoutObjectConnection ?? state.includeDevicesWithoutObjectConnection,
				detailed: action.detailed ?? state.detailed,
				locationType: action.locationType ?? state.locationType
			};
		case SET_VALID_PERIOD:
			return {
				...state,
				isValidPeriod: action.isValidPeriod,
			};
		case SET_REPORTING_ENTITIES_DATA:
			return {
				...state,
				reportSelectedEntities: action.reportSelectedEntities ?? state.reportSelectedEntities,
				unsavedData: action.unsavedData ?? state.unsavedData,
			};
		case SET_TRIP_DATA:
			return {
				...state,
				tripDistanceParameter: action.tripDistanceParameter ?? state.tripDistanceParameter,
				tripTypeParameter: action.tripTypeParameter ?? state.tripTypeParameter,
				includePrivateData: action.includePrivateData ?? state.includePrivateData,
				includeMaxSpeed: action.includeMaxSpeed ?? state.includeMaxSpeed,
				calculateVisitLastTripOfDayInReport:
					action.calculateVisitLastTripOfDayInReport ?? state.calculateVisitLastTripOfDayInReport,
				templates: action.templates ?? state.templates,
				locations: action.locations ?? state.locations,
				selectedSummaries: action.selectedSummaries ?? state.selectedSummaries,
				unsavedData: action.unsavedData ?? state.unsavedData,
				objectTypes: action.objectTypes ?? state.objectTypes,
				fuelTypes: action.fuelTypes ?? state.fuelTypes,
				includeDevicesWithoutObjectConnection: action.includeDevicesWithoutObjectConnection ?? state.includeDevicesWithoutObjectConnection,
				detailed: action.detailed ?? state.detailed,
				locationType: action.locationType ?? state.locationType
			};
		case SET_SCHEDULE_DATA:
			return {
				...state,
				formatType: action.formatType ?? state.formatType,
				separateReports: action.separateReports ?? state.separateReports,
				generateType: action.generateType ?? state.generateType,
				scheduleFrequency: action.scheduleFrequency ?? state.scheduleFrequency,
				scheduleDay: action.scheduleDay ?? state.scheduleDay,
				scheduleTime: action.scheduleTime ?? state.scheduleTime,
				scheduleTimezoneId: action.scheduleTimezoneId ?? state.scheduleTimezoneId,
				sendAsMail: action.sendAsMail ?? state.sendAsMail,
				receivers: action.receivers ?? state.receivers,
				unsavedData: action.unsavedData ?? state.unsavedData,
			};
		case SET_OUTSIDE_BUSINESS_HOURS:
			return {
				...state,
				outsideBusinessHours: action.outsideBusinessHours,
				unsavedData: action.unsavedData ?? state.unsavedData,
			};
		case SET_INCLUDE_INACTIVE:
			return {
				...state,
				includeInactive: action.includeInactive,
			};
		case SET_INCLUDE_PRIVATE_DATA:
			return {
				...state,
				includePrivateData: action.includePrivateData,
			};
		case RESET_REPORT:
			return { ...initialState, data: state.data };
		default:
			return state;
	}
};
