import ClaimType from 'authorization/ClaimType';
import { ClaimUtil } from 'authorization/ClaimUtil';
import * as GlobalSettings from 'GlobalSettings.json';
import { Person } from 'models/Person';
import { User } from 'oidc-client';
import { ValidationRegex } from 'shared/validation/ValidationRegex';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import ajaxUtil from 'utils/Ajax';
import { TranslateText } from 'utils/Translations';

export function getCommonValidationRules(user: User): Validator {
	const validator = new Validator({
		fieldRules: {
			//personViewSystemInformationForm
			code: {
				rules: {
					required: ValidatorFunctions.required(),
					maxLength: ValidatorFunctions.maxLength(24),
					noEmptySpace: ValidatorFunctions.noEmptySpace(),
					unique: {
						message: TranslateText('fieldsValidations.uniqueValue'),
						validationFunction: (data, fieldName) => {
							const personData = data as Person;
							return ajaxUtil.post<boolean>(`${GlobalSettings.validateApi}/PersonFields`, {
								customerId: personData.customerId,
								editedEntityId: personData.id ? personData.id : null,
								value: data[fieldName],
								Field: 'code',
							});
						},
					},
				},
			},
			externalCode: {
				rules: {
					required: ValidatorFunctions.required(),
					maxLength: ValidatorFunctions.maxLength(24),
					noEmptySpace: ValidatorFunctions.noEmptySpace(),
					unique: {
						message: TranslateText('fieldsValidations.uniqueValue'),
						validationFunction: (data, fieldName) => {
							const personData = data as Person;
							return ajaxUtil.post<boolean>(`${GlobalSettings.validateApi}/PersonFields`, {
								customerId: personData.customerId,
								editedEntityId: personData.id ? personData.id : null,
								value: data[fieldName],
								Field: 'externalCode',
							});
						},
					},
				},
			},
			customerId: {
				rules: {
					required: {
						...ValidatorFunctions.required(),
						disabled: !ClaimUtil.validateHasClaim(user, ClaimType.Customers),
					},
				},
			},
			//personalDataForm
			firstName: {
				rules: {
					required: ValidatorFunctions.required(),
					maxLength: ValidatorFunctions.maxLength(200),
					noEmptySpace: ValidatorFunctions.noEmptySpace(),
				},
			},
			lastName: {
				rules: {
					required: ValidatorFunctions.required(),
					maxLength: ValidatorFunctions.maxLength(200),
					noEmptySpace: ValidatorFunctions.noEmptySpace(),
				},
			},
			emailAddress: {
				rules: {
					email: ValidatorFunctions.regexIfNotNull(
						ValidationRegex.email(),
						TranslateText('fieldsValidations.invalidEmailFormat')
					),
					maxLength: ValidatorFunctions.maxLength(150),
				},
			},
			phoneNumber: {
				rules: {
					number: ValidatorFunctions.regexIfNotNull(
						ValidationRegex.onlyDigits(),
						TranslateText('fieldsValidations.onlyDigits')
					),
					maxLength: ValidatorFunctions.maxLength(20),
				},
			},
			languageId: {
				rules: {
					required: ValidatorFunctions.required(),
				},
			},
			birthDate: {
				rules: {
					validDate: ValidatorFunctions.validDate(),
				},
			},
			birthName: {
				rules: {
					maxLength: ValidatorFunctions.maxLength(200),
				},
			},
			initials: {
				rules: {
					maxLength: ValidatorFunctions.maxLength(20),
				},
			},
			socialSecurityNumber: {
				rules: {
					maxLength: ValidatorFunctions.maxLength(20),
				},
			},
			'homeAddress.street': {
				rules: {
					maxLength: ValidatorFunctions.maxLength(200),
				},
			},
			'homeAddress.number': {
				rules: {
					maxLength: ValidatorFunctions.maxLength(10),
				},
			},
			'homeAddress.postalCode': {
				rules: {
					maxLength: ValidatorFunctions.maxLength(10),
				},
			},
			'homeAddress.city': {
				rules: {
					maxLength: ValidatorFunctions.maxLength(200),
				},
			},
			//loginDataForm
			username: {
				rules: {
					required: ValidatorFunctions.required(),
					maxLength: ValidatorFunctions.maxLength(50),
					alphaNumeric: ValidatorFunctions.regexIfNotNull(
						ValidationRegex.onlyAlphaNumeric(),
						TranslateText('fieldsValidations.onlyalphanumeric')
					),
					unique: {
						message: TranslateText('fieldsValidations.uniqueValue'),
						validationFunction: (data, fieldName) => {
							const personData = data as Person;
							return ajaxUtil.post<boolean>(`${GlobalSettings.validateApi}/PersonFields`, {
								customerId: personData.customerId,
								editedEntityId: personData.id ? personData.id : null,
								value: data[fieldName],
								Field: 'username',
							});
						},
					},
				},
			},
			loginCode: {
				rules: {
					maxLength: ValidatorFunctions.maxLength(50),
				},
			},
			keypadCode: {
				rules: {
					number: ValidatorFunctions.regexIfNotNull(
						ValidationRegex.onlyDigits(),
						TranslateText('fieldsValidations.onlyDigits')
					),
					noZeroAsFirstDigit: ValidatorFunctions.regexIfNotNull(
						ValidationRegex.noZeroAsFirstDigit(),
						TranslateText('fieldsValidations.noZeroAsFirstDigit')
					),
					maxLength: ValidatorFunctions.maxLength(10),
					unique: {
						message: TranslateText('fieldsValidations.uniqueValue'),
						validationFunction: (data, fieldName) => {
							if (!data[fieldName]) {
								return ValidatorFunctions.wrapInPromise(true);
							}

							const personData = data as Person;
							return ajaxUtil.post<boolean>(`${GlobalSettings.validateApi}/PersonFields`, {
								customerId: personData.customerId,
								editedEntityId: personData.id ? personData.id : null,
								value: data[fieldName] ? data[fieldName].toString() : null,
								Field: 'keypadCode',
							});
						},
					},
				},
			},
			tachoId: {
				rules: {
					maxLength: ValidatorFunctions.maxLength(15),
				},
			},
		},
	});

	return validator;
}
