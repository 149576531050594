import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { Button, Icon } from '@material-ui/core';

import GlobalSettings from '../../GlobalSettings.json';
import { FavoriteReportDto } from '../../models/FavoriteReportDto';
import ReportPeriodsEnum from '../../models/ReportPeriodsEnum';
import ReportRenderFormatEnum from '../../models/ReportRenderFormatEnum';
import TripTypesEnum from '../../models/TripTypesEnum';
import { ApplicationState } from '../../store';
import { reportStoreActionCreators } from '../../store/ReportStore';
import ajaxUtil from '../../utils/Ajax';
import { FormatDate } from '../../utils/DateUtils';
import { TranslateText } from '../../utils/Translations';
import { DialogUtil } from '../Common/NotificationDialog';
import { PageResult } from '../Common/Pagination';
import { GeneratedReportDto } from './GeneratedReportDto';

const mapStateToProps = (state: ApplicationState) => {
	return {
		reportData: state.reportStore,
		customerTripTypes: state.currentSession.customer.featuresSettings.tripTypes,
	};
};

function mapDispatchToProps(dispatch: Dispatch) {
	return {
		reportStoreActionCreators: bindActionCreators(reportStoreActionCreators, dispatch),
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & {
	focus: boolean;
	take: number;
	customerId: string;
	redirectOnEditCallback: (index: number, resetGeneratedReportsFilters?: boolean) => void;
	driverIdentification: boolean;
};

type State = {
	reports: GeneratedReportDto[];
	total: number;
	skip: number;
	currentReportId: string;
	disableEdit: string[];
	disableRun: string[];
};

class FavoriteReportsOverview extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			reports: [],
			total: 0,
			skip: 0,
			currentReportId: '',
			disableEdit: [],
			disableRun: [],
		};
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		if (
			(prevProps.focus !== this.props.focus ||
				prevProps.customerId !== this.props.customerId ||
				prevState.skip !== this.state.skip) &&
			this.props.focus
		) {
			this.fetchData();
		}
	}

	private getUrl: string = GlobalSettings.reportingApi + '/favoriteGeneratedReports';

	private notificationDialogCallback(response: boolean) {
		if (response) {
			this.removeReport(this.state.currentReportId);
		}
	}

	openRemoveFavoriteReport(reportId: string) {
		this.setState({
			currentReportId: reportId,
		});
		DialogUtil.confirm({
			title: TranslateText('common.titleConfirmAction'),
			content: TranslateText('notificationMessages.removeFavoriteReport'),
		}).then(this.notificationDialogCallback.bind(this));
	}

	getTripTypeParameters(data: TripTypesEnum): TripTypesEnum {
		let newTripTypeParameter = data;
		for (let x in TripTypesEnum) {
			if (!isNaN(Number(x)) && !(this.props.customerTripTypes & Number(x)) && data & Number(x)) {
				newTripTypeParameter = newTripTypeParameter - Number(x);
			}
		}
		return newTripTypeParameter;
	}

	verifyDisabledButton(id: string, array: string[]): boolean {
		return array.some((s) => s == id);
	}

	openEditFavoriteReport(reportId: string) {
		this.setState({ disableEdit: [...this.state.disableEdit, reportId] });
		const url = `${GlobalSettings.reportingApi}/getFavoriteReport/${reportId}`;
		ajaxUtil
			.get<FavoriteReportDto>(url)
			.then((data) => {
				let periodInThePast = false;
				const periodType = ReportPeriodsEnum.GetReportPeriodTypeByValue(data.reportParameters.reportPeriodType);
				if (
					periodType === ReportPeriodsEnum.CustomPeriod &&
					data.reportParameters.reportCustomPeriodInterval &&
					data.reportParameters.reportCustomPeriodInterval.length > 1
				) {
					if (data.reportParameters.reportCustomPeriodInterval[1] < new Date()) {
						periodInThePast = true;
					}
				}
				this.props.reportStoreActionCreators.setReportData({
					reportId: data.id,
					isFavorite: true,
					selectedTemplateId: data.templateId,
					reportName: data.reportName,
					reportNotes: data.reportNotes,
					reportTemplateName: data.reportTemplateName,
					templateEntityType: data.templateEntityType,
					templateDataType: data.dataType,
					reportCategory: TranslateText(data.templateCategoryType),
					reportCategoryId: data.selectedCategoryId,
					reportPeriodType: periodType,
					reportHeaderTitle: data.reportName,
					reportCustomPeriodInterval: data.reportParameters.reportCustomPeriodInterval,
					periodInThePast: periodInThePast,
					includeInactive: data.reportParameters.includeInactive,
					includePrivateData: data.reportParameters.includePrivateData,
					includeMaxSpeed: data.reportParameters.includeMaxSpeed,
					calculateVisitLastTripOfDayInReport: data.reportParameters.calculateVisitLastTripOfDayInReport,
					selectedSummaries: data.reportParameters.selectedSummaries,
					templates: data.reportParameters.templates,
					locations: data.reportParameters.locations,
					objectTypes: data.reportParameters.objectTypes,
					fuelTypes: data.reportParameters.fuelTypes,
					includeDevicesWithoutObjectConnection: data.reportParameters.includeDevicesWithoutObjectConnection,
					detailed: data.reportParameters.detailed,
					locationType: data.reportParameters.locationType,
				});
				this.props.reportStoreActionCreators.setTripData({
					tripDistanceParameter: data.reportParameters.tripDistanceParameter,
					tripTypeParameter: this.getTripTypeParameters(data.reportParameters.tripTypeParameter),
				});
				this.props.reportStoreActionCreators.setReportingEntitiesData({
					reportSelectedEntities: data.reportParameters.reportPersonIds
						.concat(data.reportParameters.reportObjectIds)
						.concat(data.reportParameters.reportGroupIds),
				});
				this.props.reportStoreActionCreators.setOutsideBusinessHours(
					data.reportParameters.outsideBusinessHours
				);
				this.props.reportStoreActionCreators.setScheduleData({
					formatType:
						ReportRenderFormatEnum[data.reportParameters.formatType as keyof typeof ReportRenderFormatEnum],
					separateReports: data.reportParameters.separateReports,
				});
				this.props.reportStoreActionCreators.setCurrentStep(6);
				setTimeout(() => {
					this.props.redirectOnEditCallback(0);
					this.setState({ disableEdit: [...this.state.disableEdit.map((s) => s != reportId && s)] });
				}, 500);
			})
			.catch((err) => {
				this.setState({ disableEdit: [...this.state.disableEdit.map((s) => s != reportId && s)] });
				console.log(err);
			});
	}

	base64ToArrayBuffer(base64: any) {
		const binaryString = window.atob(base64);
		const binaryLen = binaryString.length;
		const bytes = new Uint8Array(binaryLen);
		for (let i = 0; i < binaryLen; i++) {
			const ascii = binaryString.charCodeAt(i);
			bytes[i] = ascii;
		}
		return bytes;
	}

	runReport(reportId: string) {
		this.setState({ disableRun: [...this.state.disableRun, reportId] });
		const url = GlobalSettings.reportingApi + '/runFavoriteReport/' + reportId;
		ajaxUtil
			.post(url, { reportId })
			.then((data) => {
				this.props.redirectOnEditCallback(4, true);
				this.setState({ disableRun: [...this.state.disableRun.map((s) => s != reportId && s)] });
			})
			.catch((err) => {
				this.setState({ disableRun: [...this.state.disableRun.map((s) => s != reportId && s)] });
				console.log(err);
			});
	}

	removeReport(reportId: string) {
		const url = GlobalSettings.reportingApi + '/deleteFavoriteReport/' + reportId;
		ajaxUtil.delete(url).then((data) => {
			this.fetchData();
		});
	}

	formatReportPeriod(periodType: string, customPeriodInterval: Date[]) {
		if(periodType !== null) {
			if (periodType.toLowerCase() === ReportPeriodsEnum.CustomPeriod.value) {
				return (
					TranslateText('reports.periods.' + periodType.toLowerCase()) +
					': ' +
					FormatDate(customPeriodInterval[0]) +
					' - ' +
					FormatDate(customPeriodInterval[1])
				);
			} else {
				return TranslateText('reports.periods.' + periodType.toLowerCase());
			}
		}
		return null;
	}

	fetchData() {
		const body = {
			take: this.props.take,
			skip: this.state.skip,
			customerId: this.props.customerId,
		};
		ajaxUtil.post<PageResult<GeneratedReportDto>>(this.getUrl, body).then((result) => {
			this.setState({
				reports: result.data,
				total: result.total,
			});
		});
	}

	render() {
		return (
			<div className="report-content-container">
				<div className="flex-grid-container">
					<DataTable
						emptyMessage={TranslateText('common.noData')}
						className={'overview'}
						value={this.state.reports}
						totalRecords={this.state.total}
						rows={this.props.take}
						paginator={true}
						lazy={true}
						first={this.state.skip}
						dataKey={'id'}
						paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
						currentPageReportTemplate={TranslateText('historyOverview.pageReportTemplate')}
						onPage={(e: any) => {
							this.setState({
								skip: e.first,
							});
						}}
					>
						<Column
							field="reportName"
							header={TranslateText('maintenanceOverview.grid.colReportName')}
							style={{ maxWidth: '80px', textAlign: 'left' }}
							body={(rowData: GeneratedReportDto) => (
								<span title={rowData.reportName}>{rowData.reportName}</span>
							)}
						/>
						<Column
							field="templateCategoryType"
							header={TranslateText('maintenanceOverview.grid.colReportType')}
							style={{ width: '130px', textAlign: 'left' }}
							body={(rowData: GeneratedReportDto) => {
								return TranslateText(rowData.templateCategoryType);
							}}
						/>
						<Column
							field="outputType"
							header={TranslateText('maintenanceOverview.grid.colOutputType')}
							style={{ width: '80px', textAlign: 'left' }}
						/>
						<Column
							field="selectedGroups"
							header={TranslateText('maintenanceOverview.grid.colSelectedGroups')}
							style={{ width: '70px', textAlign: 'left' }}
						/>
						<Column
							field="selectedEntities"
							header={TranslateText('maintenanceOverview.grid.colSelectedEntities')}
							style={{ width: '70px', textAlign: 'left' }}
						/>
						<Column
							field="customPeriodInterval"
							header={TranslateText('maintenanceOverview.grid.colRequestedPeriod')}
							style={{ maxWidth: '100px', textAlign: 'left' }}
							body={(rowData: GeneratedReportDto) => {
								const formatedData = this.formatReportPeriod(
									rowData.periodType,
									rowData.customPeriodInterval
								);
								return <span title={formatedData}>{formatedData}</span>;
							}}
						/>
						<Column
							field="generatedDateTime"
							header={TranslateText('maintenanceOverview.grid.colDateTime')}
							style={{ width: '200px', textAlign: 'left' }}
							body={(rowData: GeneratedReportDto) => {
								return FormatDate(new Date(rowData.generatedDateTime));
							}}
						/>
						<Column
							field="run"
							header={TranslateText('maintenanceOverview.grid.colRun')}
							style={{ width: '50px', textAlign: 'center' }}
							body={(rowData: GeneratedReportDto) => (
								<Button
									className="report-grid-button"
									disableElevation
									variant="contained"
									disabled={this.verifyDisabledButton(rowData.id, this.state.disableRun)}
									onClick={() => this.runReport(rowData.id)}
								>
									<Icon>play_arrow</Icon>
								</Button>
							)}
						/>
						<Column
							field="remove"
							header={TranslateText('maintenanceOverview.grid.colRemove')}
							style={{ width: '50px', textAlign: 'center' }}
							body={(rowData: GeneratedReportDto) => (
								<Button
									className="report-grid-button"
									disableElevation
									variant="contained"
									disabled={rowData.status === 'InProgress'}
									onClick={() => this.openRemoveFavoriteReport(rowData.id)}
								>
									<Icon>close</Icon>
								</Button>
							)}
						/>
						<Column
							field="edit"
							header={TranslateText('maintenanceOverview.grid.colEdit')}
							style={{ width: '50px', textAlign: 'center' }}
							body={(rowData: GeneratedReportDto) => (
								<Button
									className="report-grid-button"
									disableElevation
									variant="contained"
									disabled={this.verifyDisabledButton(rowData.id, this.state.disableEdit)}
									onClick={() => this.openEditFavoriteReport(rowData.id)}
								>
									<Icon>edit</Icon>
								</Button>
							)}
						/>
					</DataTable>
				</div>
			</div>
		);
	}
}

export default connector(FavoriteReportsOverview);
