const ClaimType = {
	Live: 'easytrack.live',
	History: 'easytrack.history',
	Reporting: 'easytrack.reporting',
	Dashboard: 'easytrack.dashboard',
	Alerts: 'easytrack.alerts',
	Persons: 'easytrack.persons',
	Keys: 'easytrack.keys',
	Groups: 'easytrack.groups',
	Objects: 'easytrack.objects',
	Devices: 'easytrack.devices',
	SimCards: 'easytrack.simCards',
	Templates: 'easytrack.templates',
	Customers: 'easytrack.customers',
	Locations: 'easytrack.locations',
	Subscriptions: 'easytrack.subscriptions',
	GeoFence: 'easytrack.geoFence',
	ReportingGroup: 'easytrack.reporting.group',
	ReportingItem: 'easytrack.reporting.item',
	Roles: 'easytrack.roles',
	Translations: 'easytrack.translations',
	Configs: 'easytrack.configs',
	KeyPersonConnection: 'easytrack.keyPersonConnection',
	ObjectPersonConnection: 'easytrack.objectPersonConnection',
	SimDeviceConnection: 'easytrack.simDeviceConnection',
	ObjectDeviceConnection: 'easytrack.objectDeviceConnection',
	DeviceSensorTemplateConnection: 'easytrack.deviceSensorTemplateConnection',
	AllEntities: 'easytrack.allEntities',
	ShowPrivateTrips: 'easytrack.showPrivateTrips',
	Drivers: 'easytrack.drivers',
	Import: 'easytrack.import',
	TripsEditor: 'easytrack.tripsEditor',
	MileageCorrection: 'easytrack.mileageCorrection',
	DurationCorrection: 'easytrack.durationCorrection',
	Profile: 'easytrack.profile',
	Impersonation: 'impersonation',
	InformationNotificationManage: 'easytrack.informationNotificationManage',
	InformationNotificationReceive: 'easytrack.informationNotificationReceive',
	Integration: 'easytrack.integration',
	ConfigurationGroup: 'easytrack.configurationGroup',
	OemTools: 'easytrack.oemTools',
	EasyDrive: 'easytrack.easyDrive',
	EasyDrive2Integrator: 'easytrack.easyDrive2Integrator',
	Export: 'easytrack.export',
	ResendActivationMail: 'easytrack.resendActivationMail'
};

export type ClaimValueTypes =
	| 'editDesigner'
	| 'whatsAppAlert'
	| 'smsAlert'
	| 'mailAlert'
	| 'notificationAlert'
	| 'editPlannedReports'
	| 'editGeneratedReports'
	| 'edit'
	| 'editRestricted'
	| 'view'
	| 'editProfile'
	| 'roleAssign'
	| 'editApplicationSettings'
	| 'editCustomerSettings'
	| 'receive'
	| 'easyDrive2Oem'
	| 'easyDrive2Reseller'
	| 'easyDrive2CustomerAdmin'
	| 'easyDrive2User'
	| 'easyDrive2Driver'
	| 'easyDrive2Integrator'
	| 'keys'
	| 'locations'
	| 'objects'
	| 'drivers';

export type ClaimValueAlertTypes = {
	[claimValue in Exclude<
		ClaimValueTypes,
		| 'editDesigner'
		| 'editRestricted'
		| 'edit'
		| 'view'
		| 'editProfile'
		| 'editApplicationSettings'
		| 'editCustomerSettings'
		| 'editPlannedReports'
		| 'editGeneratedReports'
		| 'roleAssign'
		| 'receive'
		| 'easyDrive2Oem'
		| 'easyDrive2Reseller'
		| 'easyDrive2Integrator'
		| 'easyDrive2CustomerAdmin'
		| 'easyDrive2User'
		| 'easyDrive2Driver'
		| 'keys'
		| 'locations'
		| 'objects'
		| 'drivers'
	>]: boolean;
};

const ClaimValue: { [claimValue in ClaimValueTypes]: string } = {
	view: 'View',
	editRestricted: 'EditRestricted',
	edit: 'Edit',
	roleAssign: 'RoleAssign',
	editDesigner: 'EditDesigner',
	editPlannedReports: 'EditPlannedReports',
	editGeneratedReports: 'EditGeneratedReports',
	whatsAppAlert: 'WhatsAppAlert',
	smsAlert: 'SmsAlert',
	mailAlert: 'MailAlert',
	notificationAlert: 'NotificationAlert',
	editProfile: 'EditProfile',
	editApplicationSettings: 'EditApplicationSettings',
	editCustomerSettings: 'EditCustomerSettings',
	receive: 'Receive',
	easyDrive2Oem: 'EasyDrive2Oem',
	easyDrive2Reseller: 'EasyDrive2Reseller',
	easyDrive2Integrator: 'EasyDrive2Integrator',
	easyDrive2CustomerAdmin: 'EasyDrive2CustomerAdmin',
	easyDrive2User: 'EasyDrive2User',
	easyDrive2Driver: 'EasyDrive2Driver',
	keys: 'Keys',
	locations: 'Locations',
	objects: 'Objects',
	drivers: 'Drivers'
};

export { ClaimValue };

export default ClaimType;
