import React from 'react';
import MaterialTextField from 'shared/components/MaterialTextField/MaterialTextField';
import { TranslateText } from 'utils/Translations';

import { CONFIGURATIONGROUPLEVELS } from '../constants';
import { ConfigurationGroupProps } from '../types';
import {handleKeyDown} from "../../../../Common/RemoveSpinnerOptionInput";
import {getNumberValue} from "../../../../Widgets/Views/ConfigurationGroupSettingsView/Utils";

const ConfigurationGroupLevelsView = ({
	data,
	validationResult,
	handleChange,
	isAddNew = true,
	editMode = true,
	isForNewAddWizard = true,
}: ConfigurationGroupProps) => {
	const handleChangeChild = (value: unknown, statePropName: string) => {
		handleChange(value, statePropName, CONFIGURATIONGROUPLEVELS);
	};
	return (
		<>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="warningLevel"
					className="resize-font noSpinner"
					type="number"
					inputProps={{
						min: 0,
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					label={TranslateText('fields.warningLevel')}
					name="warningLevel"
					value={data.configurationGroupLevels?.warningLevel}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'warningLevel')}
					validationResult={validationResult?.warningConfigurationGroupLevels}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="criticalLevel"
					className="resize-font noSpinner"
					label={TranslateText('fields.criticalLevel')}
					type="number"
					inputProps={{
						min: 0,
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="criticalLevel"
					value={data.configurationGroupLevels?.criticalLevel}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'criticalLevel')}
					validationResult={validationResult?.criticalConfigurationGroupLevels}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
		</>
	);
};

export default ConfigurationGroupLevelsView;
