import AuthenticationTypeEnum from '../../models/AuthenticationTypeEnum';
import {TranslateText} from '../../utils/Translations';

const AuthenticationTypeDataSource = {
	dataSource: () => [
		{
			id: AuthenticationTypeEnum.None,
			display: TranslateText('devices.authenticationType.None'),
		},
		{
			id: AuthenticationTypeEnum.Fixed,
			display: TranslateText('devices.authenticationType.Fixed'),
		},
		{
			id: AuthenticationTypeEnum.IButton,
			display: TranslateText('devices.authenticationType.IButton'),
		},
		{
			id: AuthenticationTypeEnum.MiFare,
			display: TranslateText('devices.authenticationType.MiFare'),
		},
		{
			id: AuthenticationTypeEnum.Keypad,
			display: TranslateText('devices.authenticationType.Keypad'),
		},
		{
			id: AuthenticationTypeEnum.TachoId,
			display: TranslateText('devices.authenticationType.TachoId'),
		},
		{
			id: AuthenticationTypeEnum.NFC,
			display: TranslateText('devices.authenticationType.NFC')
		}
	],
};

export default AuthenticationTypeDataSource;
