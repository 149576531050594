import { ConfigurationGroupCameraBitMask } from './ConfigurationGroupCameraBitMask';
import { ConfigurationGroupMediaBitMask } from './ConfigurationGroupMediaBitMask';
import FuelTypeEnum from './FuelTypeEnum';
import IdIdentifiable from './IdIdentifiable';
import ObjectTypeEnum from './ObjectTypeEnum';

//Be carefull when changing classnames here
//The classnames are part of constant strings to support the recursive handleChange function used in the newAddWizard->ConfigurationGroup
//If changed, don't forget to change the constant strings: NewAddWizard/Views/ConfigurationGroup/constants.ts
class ConfigurationGroupDto extends IdIdentifiable {
	constructor(customerId?: string) {
		super();
		this.customerId = customerId;
		this.aspNetUserId = null;
		this.active = true;
		this.configurationGroupGeneralInformation = new ConfigurationGroupGeneralInformation();
		this.configurationGroupWeights = new ConfigurationGroupWeights();
		this.configurationGroupFactors = new ConfigurationGroupFactors();
		this.configurationGroupSettings = new ConfigurationGroupSettings();
		this.configurationGroupThresholds = new ConfigurationGroupThresholds();
		this.configurationGroupTrips = new ConfigurationGroupTrips();
		this.configurationGroupPeriodicals = new ConfigurationGroupPeriodicals();
		this.configurationGroupIncidents = new ConfigurationGroupIncidents();
		this.configurationGroupMedia = new ConfigurationGroupMedia();
		this.configurationGroupGeneralCamera = new ConfigurationGroupGeneralCamera();
		this.configurationGroupLevels = new ConfigurationGroupLevels();
	}

	id: string;
	customerId: string;
	aspNetUserId: string;
	active: boolean;
	startDateUtc: string;
	endDateUtc?: string;
	configurationGroupGeneralInformation: ConfigurationGroupGeneralInformation;
	configurationGroupWeights: ConfigurationGroupWeights;
	configurationGroupFactors: ConfigurationGroupFactors;
	configurationGroupSettings: ConfigurationGroupSettings;
	configurationGroupThresholds: ConfigurationGroupThresholds;
	configurationGroupTrips: ConfigurationGroupTrips;
	configurationGroupPeriodicals: ConfigurationGroupPeriodicals;
	configurationGroupIncidents: ConfigurationGroupIncidents;
	configurationGroupMedia: ConfigurationGroupMedia;
	configurationGroupGeneralCamera: ConfigurationGroupGeneralCamera;
	configurationGroupLevels: ConfigurationGroupLevels;
}

export class ConfigurationGroupGeneralInformation {
	constructor() {
		this.code = '';
		this.externalCode = '';
		this.name = '';
		this.description = '';
		this.objectType = null;
		this.fuelType = null;
	}
	code: string;
	externalCode: string;
	name: string;
	description: string;
	objectType: ObjectTypeEnum;
	fuelType: FuelTypeEnum;
}

export class ConfigurationGroupWeights {
	constructor() {
		this.coast = null;
		this.cruise = null;
		this.drivingBehaviour = null;
		this.harshAcceleration = null;
		this.harshBrake = null;
		this.harshPedalAcceleration = null;
		this.idle = null;
		this.overRpm = null;
		this.overSpeed = null;
	}

	coast?: number;
	cruise?: number;
	drivingBehaviour?: number;
	harshAcceleration?: number;
	harshBrake?: number;
	harshPedalAcceleration?: number;
	idle?: number;
	overRpm?: number;
	overSpeed?: number;
}

export class ConfigurationGroupFactors {
	constructor() {
		this.coast = null;
		this.cruise = null;
		this.drivingBehaviour = null;
		this.harshAcceleration = null;
		this.harshPedalAcceleration = null;
		this.harshBrake = null;
		this.idle = null;
		this.overRpm = null;
		this.overSpeed = null;
	}

	coast?: number;
	cruise?: number;
	drivingBehaviour?: number;
	harshAcceleration?: number;
	harshBrake?: number;
	harshPedalAcceleration?: number;
	idle?: number;
	overRpm?: number;
	overSpeed?: number;
}

export class ConfigurationGroupSettings {
	constructor() {
		this.harshAcceleration = null;
		this.harshBrake = null;
		this.rpm = null;
		this.speed = null;
		this.torque = null;
		this.pedal = null;
		this.acceleration = null;
		this.deceleration = null;
	}

	harshAcceleration?: number;
	harshBrake?: number;
	rpm?: number;
	speed?: number;
	torque?: number;
	pedal?: number;
	acceleration?: number;
	deceleration?: number;
}

export class ConfigurationGroupThresholds {
	constructor() {
		this.coastableDistance = null;
		this.cruiseTime = null;
		this.idleTime = null;
		this.overRpm = null;
		this.overSpeed = null;
		this.harshAccelerationSpeed = null;
		this.stopSpeed = null;
		this.brakeSpeed = null;
		this.fuelLevelDelay = null;
	}
	coastableDistance?: number;
	cruiseTime?: number;
	idleTime?: number;
	overRpm?: number;
	overSpeed?: number;
	harshAccelerationSpeed?: number;
	stopSpeed?: number;
	brakeSpeed?: number;
	fuelLevelDelay?: number;
}

export class ConfigurationGroupTrips {
	constructor() {
		this.ignition = true;
		this.ignitionTimeout = null;
		this.engine = false;
	}
	ignition: boolean;
	ignitionTimeout?: number;
	engine: boolean;
}

export class ConfigurationGroupPeriodicals {
	constructor() {
		this.frequencyWhileMoving = null;
		this.frequencyWhileStandingStill = null;
	}

	frequencyWhileMoving?: number;
	frequencyWhileStandingStill?: number;
}

export class ConfigurationGroupIncidents {
	constructor() {
		this.recordTime = null;
		this.minimumSpeed = null;
		this.acceleration = null;
		this.brake = null;
	}

	recordTime?: number;
	minimumSpeed?: number;
	acceleration?: number;
	brake?: number;
}

export class ConfigurationGroupMedia {
	constructor() {
		this.sendMediaOverWifi = false;
		this.alarm = [];
		this.emergencyBrake = [];
		this.harshBrake = [];
		this.harshAcceleration = [];
		this.overSpeedStart = [];
		this.overRpmStart = [];
		this.idleStart = [];
		this.videoDurationBeforeAlarm = null;
		this.videoDurationAfterAlarm = null;
		this.videoDurationAfterEvent = null;
		this.videoDurationBeforeEvent = null;
	}
	sendMediaOverWifi: boolean;
	alarm: ConfigurationGroupMediaBitMask[];
	videoDurationBeforeAlarm?: number;
	videoDurationAfterAlarm?: number;
	emergencyBrake: ConfigurationGroupMediaBitMask[];
	harshBrake: ConfigurationGroupMediaBitMask[];
	harshAcceleration: ConfigurationGroupMediaBitMask[];
	overSpeedStart: ConfigurationGroupMediaBitMask[];
	overRpmStart: ConfigurationGroupMediaBitMask[];
	idleStart: ConfigurationGroupMediaBitMask[];
	videoDurationBeforeEvent?: number;
	videoDurationAfterEvent?: number;
}

export class ConfigurationGroupGeneralCamera {
	constructor() {
		this.alwaysRecord = false;
		this.camerasToRecord = [];
		this.camerasToDisplay = [];
		this.enableOsd = false;
		this.useRankingForOverlay = false;
		this.overlayFrontCameraAfterIgnitionOn = false;
		this.configurationGroupCamera1 = new ConfigurationGroupCamera();
		this.configurationGroupCamera2 = new ConfigurationGroupCamera();
		this.configurationGroupCamera3 = new ConfigurationGroupCamera();
		this.configurationGroupCamera4 = new ConfigurationGroupCamera();
	}
	alwaysRecord: boolean;
	camerasToRecord: ConfigurationGroupCameraBitMask[];
	camerasToDisplay: ConfigurationGroupCameraBitMask[];
	enableOsd: boolean;
	useRankingForOverlay: boolean;
	overlayFrontCameraAfterIgnitionOn: boolean;
	configurationGroupCamera1: ConfigurationGroupCamera;
	configurationGroupCamera2: ConfigurationGroupCamera;
	configurationGroupCamera3: ConfigurationGroupCamera;
	configurationGroupCamera4: ConfigurationGroupCamera;
}

export class ConfigurationGroupCamera {
	constructor() {
		this.resolution = null;
		this.mirrored = false;
		this.io = false;
		this.rank = 0;
		this.hideDelay = null;
		this.overlayActiveCameras = [];
	}
	resolution: string;
	mirrored: boolean;
	io: boolean;
	rank?: number;
	hideDelay?: number;
	overlayActiveCameras: ConfigurationGroupCameraBitMask[];
}

export class ConfigurationGroupLevels {
	constructor() {
		this.warningLevel = null;
		this.criticalLevel = null;
	}
	warningLevel?: number;
	criticalLevel?: number;
}

export default ConfigurationGroupDto;
