import './HistoryTemplatesSelectionOnMap.scss';
import React, { useCallback } from "react";
import {useEffect, useState } from "react";

import {TranslateText} from "../../utils/Translations";
import RemoveDoneIcon from "../Common/Icons/RemoveDoneIcon";
import AutoTextField from "../AutoTextfield/AutoTextField";
import { Button, Tooltip } from '@material-ui/core';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import SelectedItem from "./SelectedItem/SelectedItem";
import {historyStoreActionCreators} from "../../store/HistoryStore";
import { useDispatch, useSelector } from 'react-redux';
import {ApplicationState} from "../../store";

interface HistoryTemplatesSelectionProps {
	setRef: (ref: HTMLDivElement | null) => void;
}
export interface TemplateOnMap {
	templateId: string;
	templateName: string;
}

const HistoryTemplatesSelectionOnMap = (props: HistoryTemplatesSelectionProps) => {

	const dispatch = useDispatch();

	const templatesOnMap = useSelector((state: ApplicationState) => state.historyStore.templatesOnMap);
	const selectedTemplates = useSelector((state: ApplicationState) => state.historyStore.selectedTemplatesOnMap);

	const [text, setText] = useState<string>('');
	const [currentTemplates, setCurrentTemplates] = useState(templatesOnMap);


	useEffect(() => {
		text.length
			? setCurrentTemplates(templatesOnMap.filter((mp: TemplateOnMap) => mp.templateName.toLowerCase().includes(text.toLowerCase())))
			: setCurrentTemplates(templatesOnMap);
	}, [text]);

	const selectTemplate = useCallback((selectedTemplate: TemplateOnMap, selectedTemplates:TemplateOnMap[]) => {
		dispatch(
				historyStoreActionCreators.setSelectedTemplatesOnHistory([...selectedTemplates, selectedTemplate])
			);
	}, []);

	const deselectTemplate = useCallback(
		(deselectedTemplate: TemplateOnMap, deselectedTemplates: TemplateOnMap[]) => {
			const newSelectedTemplates = [...deselectedTemplates.filter((sc) => sc.templateId !== deselectedTemplate.templateId)];
			dispatch(historyStoreActionCreators.setSelectedTemplatesOnHistory(newSelectedTemplates));
		},
		[]
	);

	const deselectAllTemplates = useCallback(() => {
		dispatch(historyStoreActionCreators.setSelectedTemplatesOnHistory([]));
	}, []);

	const selectAllTemplates = useCallback((selectedTemplates: TemplateOnMap[]) => {
		dispatch(historyStoreActionCreators.setSelectedTemplatesOnHistory(selectedTemplates));
	}, []);

	return (
		currentTemplates && (
			<div className={'selected-templates-map'} ref={(r) => props.setRef(r)}>
				<div className={'search-template-area'}>
					<span className={'deselect-button'}>
						{selectedTemplates?.length === templatesOnMap?.length ? (
							<Tooltip title={TranslateText('fleetSelection.deselectAll')}>
								<Button onClick={deselectAllTemplates}>
									<RemoveDoneIcon />
								</Button>
							</Tooltip>
						) : (
							<Tooltip title={TranslateText('fleetSelection.selectAll')}>
								<Button onClick={() => selectAllTemplates(templatesOnMap)}>
									<SelectAllIcon />
								</Button>
							</Tooltip>
						)}
					</span>
					<span className={'search-field'}>
						<AutoTextField
							placeHolder={TranslateText('fields.searchTemplatesOnMap')}
							onChange={(newValue) => setText(newValue)}
							style={{ fontSize: 12, fontFamily: 'Ubuntu' }}
							debounceDuration={100}
							value={text}
							showClearButton
						/>
					</span>
				</div>
				<div className={`search-items-area`}>
					{currentTemplates?.length ? (
						currentTemplates.map((se) => (
							<SelectedItem
								key={se.templateId}
								item={se}
								selected={selectedTemplates?.some((sc) => sc.templateId === se.templateId)}
								selectCallback={(item, select) => {
									select
										? selectTemplate(item, selectedTemplates)
										: deselectTemplate(item, selectedTemplates)
								}}
							/>
						))
					) : (
						<span className={'no-record-found-text'}>{TranslateText('common.noRecordFound')}</span>
					)}
				</div>
			</div>
		)
	);
};

export default HistoryTemplatesSelectionOnMap;
