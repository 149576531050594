import '../configurationGroupView.scss'

import React, { useEffect } from "react";

import {WidgetViewDefault} from "../../../Widget";
import {ConfigurationGroupSettingsEditViewProps} from "../types";
import {ConfigurationGroupSettingsEditDto} from "../ConfigurationGroupSettingsView";
import {TranslateText} from "../../../../../utils/Translations";
import {
	CONFIGURATIONGROUPINCIDENTS, CONFIGURATIONGROUPSETTINGS,
} from "../../../../NewAddWizard/Views/ConfigurationGroup/constants";
import {handleKeyDown} from "../../../../Common/RemoveSpinnerOptionInput";
import {getCurrentTabValidation, getNumberValue} from "../Utils";
import TextFieldTooltip from "../../../../../shared/components/TextFieldToolTip/TextFieldTooltip";
import {verifyDifferences} from "../../ConfigurationGroupHistoryView/Utils";

type Props = WidgetViewDefault & ConfigurationGroupSettingsEditViewProps &{
	data: ConfigurationGroupSettingsEditDto;
};
const TripsIncidentsPeriodicalSettingsView = (props: Props) => {

	useEffect(() => {
		if(props.editMode) {
			if(!props.isForModal) {
				const resultValidations = getCurrentTabValidation(props.validationResult, CONFIGURATIONGROUPINCIDENTS);

				let newTabValidations = [...props.tabValidations];
				newTabValidations[6] = resultValidations;
				props.setTabValidation(newTabValidations);
			}
		}
	},[props.validationResult, props.editMode]);

	return (
		<div className="container-configuration-group-edit">
			<div className={!props.isSingleColumn ? 'container-row' : 'container-column'}
			>
				<div className={!props.isSingleColumn ? 'col-md-6' : 'col-12'}>
					<TextFieldTooltip
						id="recordTime"
						type="number"
						className="resize-font noSpinner"
						fullWidth
						label={TranslateText('fields.recordTime')}
						toolTipText={TranslateText('fields.recordTimeDetails')}
						fontSizeLabel={12}
						labelStyle={props.isForModal &&
						verifyDifferences(
							props.data,
							'recordTime',
							CONFIGURATIONGROUPINCIDENTS,
							props.differences) ? {color: props.colorLabel } : {}
						}
						disableTooltipButton={!props.editMode && !props.isForModal}
						inputProps={{
							readOnly: !props.editMode,
							style: { fontSize: 12 },
							onKeyPress: (event: any) => {
								if (event.key === '-' || event.key === 'e') {
									event.preventDefault();
								}
							}}}
						name="recordTime"
						value={props.data.configurationGroupIncidents?.recordTime}
						onChange={(e) => {
							props.handleChange(getNumberValue(e.target.value), 'recordTime', CONFIGURATIONGROUPINCIDENTS);
						}}
						disabled={!props.editMode}
						onKeyDown={handleKeyDown}
						onWheel={(e: any) => e.target.blur()}
						validationResult={props.validationResult?.recordTimeConfigurationGroupIncidents}
					/>
					<TextFieldTooltip
						id="minimumSpeed"
						type="number"
						className="resize-font noSpinner"
						fullWidth
						label={TranslateText('fields.minimumSpeed')}
						toolTipText={TranslateText('fields.minimumSpeedDetails')}
						fontSizeLabel={12}
						labelStyle={props.isForModal &&
						verifyDifferences(
							props.data,
							'minimumSpeed',
							CONFIGURATIONGROUPINCIDENTS,
							props.differences) ? {color: props.colorLabel } : {}
						}
						disableTooltipButton={!props.editMode && !props.isForModal}
						inputProps={{
							readOnly: !props.editMode,
							style: { fontSize: 12 },
							onKeyPress: (event: any) => {
								if (event.key === '-' || event.key === 'e') {
									event.preventDefault();
								}
							}}}
						name="minimumSpeed"
						value={props.data.configurationGroupIncidents?.minimumSpeed}
						onChange={(e) => {
							props.handleChange(getNumberValue(e.target.value), 'minimumSpeed', CONFIGURATIONGROUPINCIDENTS);
						}}
						disabled={!props.editMode}
						onKeyDown={handleKeyDown}
						onWheel={(e: any) => e.target.blur()}
						validationResult={props.validationResult?.minimumSpeedConfigurationGroupIncidents}
					/>
				</div>
				<div className={!props.isSingleColumn ? 'col-md-6' : 'col-12'}>
					<TextFieldTooltip
						id="acceleration"
						type="number"
						className="resize-font noSpinner"
						fullWidth
						label={TranslateText('fields.incidentAcceleration')}
						toolTipText={TranslateText('fields.incidentAccelerationDetails')}
						fontSizeLabel={12}
						labelStyle={props.isForModal &&
						verifyDifferences(
							props.data,
							'acceleration',
							CONFIGURATIONGROUPINCIDENTS,
							props.differences) ? {color: props.colorLabel } : {}
						}
						disableTooltipButton={!props.editMode && !props.isForModal}
						inputProps={{
							readOnly: !props.editMode,
							style: { fontSize: 12 },
							onKeyPress: (event: any) => {
								if (event.key === '-' || event.key === 'e') {
									event.preventDefault();
								}
							}}}
						name="acceleration"
						value={props.data.configurationGroupIncidents?.acceleration}
						onChange={(e) => {
							props.handleChange(getNumberValue(e.target.value), 'acceleration', CONFIGURATIONGROUPINCIDENTS);
						}}
						disabled={!props.editMode}
						onKeyDown={handleKeyDown}
						onWheel={(e: any) => e.target.blur()}
						validationResult={props.validationResult?.accelerationConfigurationGroupIncidents}
					/>
					<TextFieldTooltip
						id="brake"
						type="number"
						className="resize-font noSpinner"
						fullWidth
						label={TranslateText('fields.brake')}
						toolTipText={TranslateText('fields.brakeDetails')}
						labelStyle={props.isForModal &&
						verifyDifferences(
							props.data,
							'brake',
							CONFIGURATIONGROUPINCIDENTS,
							props.differences) ? {color: props.colorLabel } : {}
						}
						disableTooltipButton={!props.editMode && !props.isForModal}
						inputProps={{
							readOnly: !props.editMode,
							style: { fontSize: 12 },
							onKeyPress: (event: any) => {
								if (event.key === 'e') {
									event.preventDefault();
								}
							}}}
						fontSizeLabel={12}
						name="brake"
						value={props.data.configurationGroupIncidents?.brake}
						onChange={(e) => {
							props.handleChange(getNumberValue(e.target.value), 'brake', CONFIGURATIONGROUPINCIDENTS);
						}}
						disabled={!props.editMode}
						onKeyDown={handleKeyDown}
						onWheel={(e: any) => e.target.blur()}
						validationResult={props.validationResult?.brakeConfigurationGroupIncidents}
					/>
				</div>
			</div>
		</div>
	);
}
export default TripsIncidentsPeriodicalSettingsView;
