import { Check, Close } from '@material-ui/icons';
import { Column, ColumnBodyType } from 'primereact/column';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import React from 'react';
import { TranslateText } from 'utils/Translations';

type Props = {
	fieldName: string;
	header: string;
	body?: ColumnBodyType;
	sortable?: boolean;
	filterable?: boolean;
	shown?: boolean;
	showHeaderTooltip?: boolean;
	showContentTooltip?: boolean;
	showFilterTooltip?: boolean;
	filterDisabled?: boolean;
	defaultWidth?: number;
	defaultMinWidth?: number;
};

const BooleanColumn = ({
	fieldName,
	sortable,
	header,
	filterable,
	body,
	shown = true,
	showHeaderTooltip = true,
	showFilterTooltip = true,
	showContentTooltip = true,
	filterDisabled,
	defaultWidth,
	defaultMinWidth
}: Props) =>
	shown ? (
		<Column
			className={`booleanColumn sortIcon ${filterDisabled ? 'disabled' : ''}`}
			key={fieldName}
			field={fieldName}
			style={{
				...(defaultWidth !== undefined ? {
					flexGrow: '1',
					flexShrink: '1',
					flexBasis: `${defaultWidth}px`,
				} : {}),
				...(defaultMinWidth !== undefined ? {
				minWidth: `${defaultMinWidth}px`,
			} : {})
			}}
			header={() => (
				<span
					className={`p-column-title ${filterDisabled ? 'disabled' : ''}`}
					title={showHeaderTooltip ? header : ''}
				>
					{header}
				</span>
			)}
			body={
				body !== undefined
					? body
					: (rowData) =>
							rowData[fieldName] ? (
								<span
									title={showFilterTooltip ? TranslateText('maintenanceOverview.grid.colActive') : ''}
								>
									<Check fontSize="small" />
								</span>
							) : (
								<span
									title={
										showFilterTooltip ? TranslateText('maintenanceOverview.grid.colInactive') : ''
									}
								>
									<Close fontSize="small" />
								</span>
							)
			}
			dataType="boolean"
			sortable={sortable}
			showClearButton={false}
			showFilterMenu={false}
			filter={filterable}
			filterElement={(options) => {
				return (
					<span
						title={
							showContentTooltip
								? options.value === true
									? TranslateText('maintenanceOverview.grid.colActive')
									: options.value === false
									? TranslateText('maintenanceOverview.grid.colInactive')
									: ''
								: ''
						}
					>
						<TriStateCheckbox
							style={{ alignSelf: 'center' }}
							value={options.value}
							onChange={(e) => options.filterApplyCallback(e.value)}
							disabled={filterDisabled}
						/>
					</span>
				);
			}}
		/>
	) : null;

export default BooleanColumn;
