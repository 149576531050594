import './configurationGroupHistoryModalView.scss'

import React, {useEffect, useRef, useState } from "react";

import {TranslateText} from "../../../../../../utils/Translations";
import WeightsView from "../../../ConfigurationGroupSettingsView/WeightsView/WeightsView";
import FactorsView from "../../../ConfigurationGroupSettingsView/FactorsView/FactorsView";
import SettingsView from "../../../ConfigurationGroupSettingsView/SettingsView/SettingsView";
import ThresholdsView from "../../../ConfigurationGroupSettingsView/ThresholdsView/ThresholdsView";
import TripsView from "../../../ConfigurationGroupSettingsView/TripsView/TripsView";
import PeriodicalSettingsView from "../../../ConfigurationGroupSettingsView/PeriodicalView/PeriodicalSettingsView";
import IncidentsView from "../../../ConfigurationGroupSettingsView/IncidentsView/IncidentsView";
import MediaView from "../../../ConfigurationGroupSettingsView/MediaView/MediaView";
import CameraView from "../../../ConfigurationGroupSettingsView/CameraView/CameraView";
import LevelsView from "../../../ConfigurationGroupSettingsView/LevelsView/LevelsView";
import { Box, Button } from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";
import {ApplicationState} from "../../../../../../store";
import { useSelector } from "react-redux";
import {WidgetViewDefault} from "../../../../Widget";
import {
	ConfigurationGroupSettingsEditDto
} from "../../../ConfigurationGroupSettingsView/ConfigurationGroupSettingsView";
import {
	CONFIGURATIONGROUPFACTORS,
	CONFIGURATIONGROUPGENERALCAMERA,
	CONFIGURATIONGROUPINCIDENTS,
	CONFIGURATIONGROUPLEVELS,
	CONFIGURATIONGROUPMEDIA,
	CONFIGURATIONGROUPPERIODICALS,
	CONFIGURATIONGROUPSETTINGS,
	CONFIGURATIONGROUPTHRESHOLDS,
	CONFIGURATIONGROUPTRIPS,
	CONFIGURATIONGROUPWEIGHTS
} from "../../../../../NewAddWizard/Views/ConfigurationGroup/constants";

type Props = WidgetViewDefault & {
	version: ConfigurationGroupSettingsEditDto;
	previousVersion: ConfigurationGroupSettingsEditDto;
	differences: string[];
	versionTitle: number;
	previousVersionTitle: number;
};
const ConfigurationGroupHistoryModalView = (props: Props) => {

	const mediaFootage = useSelector((state: ApplicationState) =>
		state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.mediaFootage
			: state.currentSession.customer?.featuresSettings.mediaFootage
	);
	const [value, setValue] = useState<string>("0");
	const containerRef = useRef(null);

	useEffect(() => {
		if (containerRef.current) {
			containerRef.current.scrollTop = 0;
		}
	}, [value]);
	useEffect(() => {
		const value =  getValueTab();
		setValue(value);
	},[props.differences]);
	const handleChangeTab = (newValue: string) => {
		setValue(newValue);
	};

	const getValueTab = () => {
		const configurations = [
			{ key: CONFIGURATIONGROUPWEIGHTS, value: '0' },
			{ key: CONFIGURATIONGROUPFACTORS, value: '1' },
			{ key: CONFIGURATIONGROUPSETTINGS, value: '2' },
			{ key: CONFIGURATIONGROUPTHRESHOLDS, value: '3' },
			{ key: CONFIGURATIONGROUPTRIPS, value: '4' },
			{ key: CONFIGURATIONGROUPPERIODICALS, value: '5' },
			{ key: CONFIGURATIONGROUPINCIDENTS, value: '6' },
			{ key: CONFIGURATIONGROUPMEDIA, value: '7', conditional: () => mediaFootage },
			{ key: CONFIGURATIONGROUPGENERALCAMERA, value: '8', conditional: () => mediaFootage },
			{ key: CONFIGURATIONGROUPLEVELS, value: '9' },
		];
		for (const { key, value, conditional } of configurations) {
			if ((!conditional || conditional()) && props.differences.some(x => x.toLowerCase().includes(key.toLowerCase()))) {
				return value;
			}
		}
		return '0';
	};

	return (
	<div>
		<Box sx={{ width: '100%', overflow: 'auto'}}>
			<TabContext value={value}>
				<Box className="chip-button-list-modal">
					<div role="group" aria-label="Settings chip buttons" className="radio-button-container-modal">
						<Button
							variant={value === '0' ? 'contained' : 'outlined'}
							onClick={() => handleChangeTab('0')}
							style={
								props.differences.some(x => x.toLowerCase().includes(CONFIGURATIONGROUPWEIGHTS.toLowerCase()))
									? { color: '#037C17' }
									: {}
							}
						>
							{TranslateText('configurationGroups.settingsTab.weights')}
						</Button>
						<Button
							variant={value === '1' ? 'contained' : 'outlined'}
							onClick={() => handleChangeTab('1')}
							style={
								props.differences.some(x => x.toLowerCase().includes(CONFIGURATIONGROUPFACTORS.toLowerCase()))
									? { color: '#037C17' }
									: {}
							}
						>
							{TranslateText('configurationGroups.settingsTab.factors')}
						</Button>
						<Button
							variant={value === '2' ? 'contained' : 'outlined'}
							onClick={() => handleChangeTab('2')}
							style={
								props.differences.some(x => x.toLowerCase().includes(CONFIGURATIONGROUPSETTINGS.toLowerCase()))
									? { color: '#037C17' }
									: {}
							}
						>
							{TranslateText('configurationGroups.settingsTab.settings')}
						</Button>
						<Button
							variant={value === '3' ? 'contained' : 'outlined'}
							onClick={() => handleChangeTab('3')}
							style={
								props.differences.some(x => x.toLowerCase().includes(CONFIGURATIONGROUPTHRESHOLDS.toLowerCase()))
									? { color: '#037C17' }
									: {}
							}
						>
							{TranslateText('configurationGroups.settingsTab.thresholds')}
						</Button>
						<Button
							variant={value === '4' ? 'contained' : 'outlined'}
							onClick={() => handleChangeTab('4')}
							style={
								props.differences.some(x => x.toLowerCase().includes(CONFIGURATIONGROUPTRIPS.toLowerCase()))
									? { color: '#037C17' }
									: {}
							}
						>
							{TranslateText('configurationGroups.settingsTab.trips')}
						</Button>
						<Button
							variant={value === '5' ? 'contained' : 'outlined'}
							onClick={() => handleChangeTab('5')}
							style={
								props.differences.some(x => x.toLowerCase().includes(CONFIGURATIONGROUPPERIODICALS.toLowerCase()))
									? { color: '#037C17' }
									: {}
							}
						>
							{TranslateText('configurationGroups.settingsTab.periodical')}
						</Button>
						<Button
							variant={value === '6' ? 'contained' : 'outlined'}
							onClick={() => handleChangeTab('6')}
							style={
								props.differences.some(x => x.toLowerCase().includes(CONFIGURATIONGROUPINCIDENTS.toLowerCase()))
									? { color: '#037C17' }
									: {}
							}
						>
							{TranslateText('configurationGroups.settingsTab.incidents')}
						</Button>
						{mediaFootage && (
							<Button
								variant={value === '7' ? 'contained' : 'outlined'}
								onClick={() => handleChangeTab('7')}
								style={
									props.differences.some(x => x.toLowerCase().includes(CONFIGURATIONGROUPMEDIA.toLowerCase()))
										? { color: '#037C17' }
										: {}
								}
							>
								{TranslateText('configurationGroups.settingsTab.media')}
							</Button>
						)}
						{mediaFootage && (
							<Button
								variant={value === '8' ? 'contained' : 'outlined'}
								onClick={() => handleChangeTab('8')}
								style={
									props.differences.some(x => x.toLowerCase().includes(CONFIGURATIONGROUPGENERALCAMERA.toLowerCase()))
										? { color: '#037C17' }
										: {}
								}
							>
								{TranslateText('configurationGroups.settingsTab.camera')}
							</Button>
						)}
						<Button
							variant={value === '9' ? 'contained' : 'outlined'}
							onClick={() => handleChangeTab('9')}
							style={
								props.differences.some(x => x.toLowerCase().includes(CONFIGURATIONGROUPLEVELS.toLowerCase()))
									? { color: '#037C17' }
									: {}
							}
						>
							{TranslateText('configurationGroups.settingsTab.levels')}
						</Button>
					</div>
				</Box>
				<div>
				{props.versionTitle && props.previousVersionTitle
					? <div className="row-version">
						{props.version.id !== props.previousVersion.id ?
						<div  className="version-title">{`${TranslateText('common.version')} ${props?.previousVersionTitle}`}</div>
						: null}
						<div  className="version-title">{`${TranslateText('common.version')} ${props?.versionTitle}`}</div>
					  </div>
				: null}
				<div className={`scrollable-container`} ref={containerRef}>
					<TabPanel value="0" className="tab-panel">
						<div className="row-container">
							{props.version.id !== props.previousVersion.id
								? <WeightsView
									{...props}
									editMode={false}
									data={props.previousVersion}
									isSingleColumn={props.version.id !== props.previousVersion.id}
									isForModal={true}
									differences={props.differences}
									colorLabel={'#f44336'}
								/>
							: null}
							<WeightsView
								{...props}
								editMode={false}
								data={props.version}
								isSingleColumn={props.version.id !== props.previousVersion.id}
								isForModal={true}
								differences={props.differences}
								colorLabel={'#037C17'}
							/>
						</div>
					</TabPanel>
					<TabPanel value="1" className="tab-panel">
						<div className="row-container">
							{props.version.id !== props.previousVersion.id
								? <FactorsView
									{...props}
									editMode={false}
									data={props.previousVersion}
									isSingleColumn={props.version.id !== props.previousVersion.id}
									isForModal={true}
									differences={props.differences}
									colorLabel={'#f44336'}
								/>
							: null}
							<FactorsView
								{...props}
								editMode={false}
								data={props.version}
								isSingleColumn={props.version.id !== props.previousVersion.id}
								isForModal={true}
								differences={props.differences}
								colorLabel={'#037C17'}
							/>
						</div>
					</TabPanel>
					<TabPanel value="2" className="tab-panel">
						<div className="row-container">
							{props.version.id !== props.previousVersion.id
								? <SettingsView
									{...props}
									editMode={false}
									data={props.previousVersion}
									isSingleColumn={props.version.id !== props.previousVersion.id}
									isForModal={true}
									differences={props.differences}
									colorLabel={'#f44336'}
									/>
								: null}
							<SettingsView
								{...props}
								editMode={false}
								data={props.version}
								isSingleColumn={props.version.id !== props.previousVersion.id}
								isForModal={true}
								differences={props.differences}
								colorLabel={'#037C17'}
							/>
						</div>
					</TabPanel>
					<TabPanel value="3" className="tab-panel">
						<div className="row-container">
							{props.version.id !== props.previousVersion.id
								? <ThresholdsView
									{...props}
									editMode={false}
									data={props.previousVersion}
									isSingleColumn={props.version.id !== props.previousVersion.id}
									isForModal={true}
									differences={props.differences}
									colorLabel={'#f44336'}
									/>
							: null}
							<ThresholdsView
								{...props}
								editMode={false}
								data={props.version}
								isSingleColumn={props.version.id !== props.previousVersion.id}
								isForModal={true}
								differences={props.differences}
								colorLabel={'#037C17'}
							/>
						</div>
					</TabPanel>
					<TabPanel value="4" className="tab-panel">
						<div className="row-container">
							{props.version.id !== props.previousVersion.id
								? <TripsView
									{...props}
									editMode={false}
									data={props.previousVersion}
									isSingleColumn={props.version.id !== props.previousVersion.id}
									isForModal={true}
									differences={props.differences}
									colorLabel={'#f44336'}
								/>
							: null}
							<TripsView
								{...props}
								editMode={false}
								data={props.version}
								isSingleColumn={props.version.id !== props.previousVersion.id}
								isForModal={true}
								differences={props.differences}
								colorLabel={'#037C17'}
							/>
						</div>
					</TabPanel>
					<TabPanel value="5" className="tab-panel">
						<div className="row-container">
							{props.version.id !== props.previousVersion.id
								? <PeriodicalSettingsView
									{...props}
									editMode={false}
									resetValidationsCallback={null}
									reloadDataCallback={null}
									data={props.previousVersion}
									isSingleColumn={props.version.id !== props.previousVersion.id}
									isForModal={true}
									differences={props.differences}
									colorLabel={'#f44336'}
								/>
							: null}
							<PeriodicalSettingsView
								{...props}
								editMode={false}
								resetValidationsCallback={null}
								reloadDataCallback={null}
								data={props.version}
								isSingleColumn={props.version.id !== props.previousVersion.id}
								isForModal={true}
								differences={props.differences}
								colorLabel={'#037C17'}
							/>
						</div>
					</TabPanel>
					<TabPanel value="6" className="tab-panel">
						<div className="row-container">
							{props.version.id !== props.previousVersion.id
								? <IncidentsView
									{...props}
									data={props.previousVersion}
									editMode={false}
									isSingleColumn={props.version.id !== props.previousVersion.id}
									isForModal={true}
									differences={props.differences}
									colorLabel={'#f44336'}
								/>
							: null}
							<IncidentsView
								{...props}
								data={props.version}
								editMode={false}
								isSingleColumn={props.version.id !== props.previousVersion.id}
								isForModal={true}
								differences={props.differences}
								colorLabel={'#037C17'}
							/>
						</div>
					</TabPanel>
					{mediaFootage ?
						<TabPanel value="7" className="tab-panel">
							<div className="row-container">
								{props.version.id !== props.previousVersion.id
									? <MediaView
										{...props}
										editMode={false}
										data={props.previousVersion}
										isSingleColumn={props.version.id !== props.previousVersion.id}
										isForModal={true}
										differences={props.differences}
										colorLabel={'#f44336'}
									/>
								: null}
								<MediaView
									{...props}
									editMode={false}
									data={props.version}
									isSingleColumn={props.version.id !== props.previousVersion.id}
									isForModal={true}
									differences={props.differences}
									colorLabel={'#037C17'}
								/>
							</div>
						</TabPanel>
						: null}
					{mediaFootage ?
						<TabPanel value="8" className="tab-panel">
							<div className="row-container">
								{props.version.id !== props.previousVersion.id
									? <CameraView
										{...props}
										editMode={false}
										data={props.previousVersion}
										isSingleColumn={props.version.id !== props.previousVersion.id}
										isForModal={true}
										differences={props.differences}
										colorLabel={'#f44336'}
									/>
								: null}
								<CameraView
									{...props}
									editMode={false}
									data={props.version}
									isSingleColumn={props.version.id !== props.previousVersion.id}
									isForModal={true}
									differences={props.differences}
									colorLabel={'#037C17'}
								/>
							</div>
						</TabPanel>
						: null}
					<TabPanel value="9" className="tab-panel">
						<div className="row-container">
							{props.version.id !== props.previousVersion.id
								? <LevelsView
									{...props}
									editMode={false}
									data={props.previousVersion}
									isSingleColumn={props.version.id !== props.previousVersion.id}
									isForModal={true}
									differences={props.differences}
									colorLabel={'#f44336'}
							 	 />
							: null}
							<LevelsView
								{...props}
								editMode={false}
								data={props.version}
								isSingleColumn={props.version.id !== props.previousVersion.id}
								isForModal={true}
								differences={props.differences}
								colorLabel={'#037C17'}
							/>
						</div>
					</TabPanel>
				</div>
				</div>
			</TabContext>
		</Box>
	</div>
	);
}

export default ConfigurationGroupHistoryModalView;
