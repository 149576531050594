import '../configurationGroupView.scss'

import React, { useEffect } from "react";

import {WidgetViewDefault} from "../../../Widget";
import {ConfigurationGroupSettingsEditViewProps} from "../types";
import {ConfigurationGroupSettingsEditDto} from "../ConfigurationGroupSettingsView";
import {TranslateText} from "../../../../../utils/Translations";
import {
	CONFIGURATIONGROUPTRIPS
} from "../../../../NewAddWizard/Views/ConfigurationGroup/constants";
import {handleKeyDown} from "../../../../Common/RemoveSpinnerOptionInput";
import {FormControlLabel, IconButton, Radio, RadioGroup, Tooltip, Typography } from '@material-ui/core';
import _ from 'lodash';
import {getCurrentTabValidation, getNumberValue} from "../Utils";
import TextFieldTooltip from "../../../../../shared/components/TextFieldToolTip/TextFieldTooltip";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {verifyDifferences} from "../../ConfigurationGroupHistoryView/Utils";

type Props = WidgetViewDefault & ConfigurationGroupSettingsEditViewProps &{
	data: ConfigurationGroupSettingsEditDto;
};
const TripsView = (props: Props) => {

	const handleChangeOption = (ignition: boolean, engine: boolean) => {
		const clonedData = _.cloneDeep(props.data);
		clonedData.configurationGroupTrips.ignition = ignition;
		clonedData.configurationGroupTrips.engine = engine;
		props.changeDataCallback(clonedData);
	}

	useEffect(() => {
		if(props.editMode) {
			if(!props.isForModal) {
				const resultValidations = getCurrentTabValidation(props.validationResult, CONFIGURATIONGROUPTRIPS);

				let newTabValidations = [...props.tabValidations];
				newTabValidations[4] = resultValidations;
				props.setTabValidation(newTabValidations);
			}
		}
	},[props.validationResult, props.editMode]);

	return (
		<div className="container-configuration-group-edit">
			<div className={!props.isSingleColumn ? 'container-row' : 'container-column'}
			>
				<div className={!props.isSingleColumn ? 'col-md-4' : 'col-12'}>
					<TextFieldTooltip
						id="ignitionTimeout"
						type="number"
						className="resize-font noSpinner"
						fullWidth
						label={TranslateText('fields.ignitionTimeout')}
						toolTipText={TranslateText('fields.ignitionTimeoutDetails')}
						fontSizeLabel={12}
						labelStyle={props.isForModal &&
						verifyDifferences(
							props.data,
							'ignitionTimeout',
							CONFIGURATIONGROUPTRIPS,
							props.differences) ? { color: props.colorLabel } : {}
						}
						inputProps={{
							readOnly: !props.editMode,
							style: { fontSize: 12 },
							onKeyPress: (event: any) => {
								if (event.key === 'e') {
									event.preventDefault();
								}
							}}}
						name="ignitionTimeout"
						disableTooltipButton={!props.editMode && !props.isForModal}
						value={props.data.configurationGroupTrips?.ignitionTimeout}
						onChange={(e) => {
							props.handleChange(getNumberValue(e.target.value), 'ignitionTimeout', CONFIGURATIONGROUPTRIPS);
						}}
						disabled={!props.editMode}
						onKeyDown={handleKeyDown}
						onWheel={(e: any) => e.target.blur()}
						validationResult={props.validationResult?.ignitionTimeoutConfigurationGroupTrips}
					/>
					<div className="radio-container">
						<RadioGroup row={false}>
							<FormControlLabel
								value={true}
								disabled={!props.editMode}
								control={<Radio size="small" color="primary" />}
								label={
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<Typography
											style={{
												fontSize: 12,
												marginRight: 5,
												color: props.isForModal &&
														verifyDifferences(
															props.data,
															'ignition',
															CONFIGURATIONGROUPTRIPS,
															props.differences) ? props.colorLabel
													                           : props.editMode ? '#0000008A' : '#00000061',
											}}
										>
											{TranslateText('fields.ignition')}
										</Typography>
										<Tooltip title={TranslateText('fields.ignitionDetails')} arrow placement="top">
											<IconButton
												size="small"
												style={{
													padding: 2,
													marginLeft: 4,
													color: props.editMode || props.isForModal ? '#0000008A' : '#00000061',
												}}
												onClick={(e) => e.preventDefault()}
												disabled={!props.editMode && !props.isForModal}
											>
												<InfoOutlinedIcon style={{ fontSize: 16 }} />
											</IconButton>
										</Tooltip>
									</div>
								}
								labelPlacement="end"
								checked={props.data.configurationGroupTrips?.ignition}
								className={'radio-option no-grouping'}
								onChange={(_, checked) => {
									handleChangeOption(checked, !checked);
								}}
							/>
							<FormControlLabel
								value={false}
								disabled={!props.editMode}
								control={<Radio size="small" color="primary" />}
								label={
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<Typography
											style={{
												fontSize: 12,
												marginRight: 5,
												color: props.isForModal &&
															verifyDifferences(
																props.data,
																'engine',
																CONFIGURATIONGROUPTRIPS,
																props.differences) ? props.colorLabel
													                               : props.editMode ? '#0000008A' : '#00000061',
											}}
										>
											{TranslateText('fields.engine')}
										</Typography>
										<Tooltip title={TranslateText('fields.engineDetails')} arrow placement="top">
											<IconButton
												size="small"
												style={{
													padding: 2,
													marginLeft: 4,
													color: props.editMode || props.isForModal ? '#0000008A' : '#00000061',
												}}
												onClick={(e) => e.preventDefault()}
												disabled={!props.editMode && !props.isForModal}
											>
												<InfoOutlinedIcon style={{ fontSize: 16 }} />
											</IconButton>
										</Tooltip>
									</div>
								}
								labelPlacement="end"
								checked={props.data.configurationGroupTrips?.engine}
								className={'radio-option'}
								onChange={(_, checked) => {
									handleChangeOption(!checked, checked);
								}}
							/>
						</RadioGroup>
					</div>
				</div>
			</div>
		</div>
	);
}
export default TripsView;
