import './historyTripTypeSelector.scss'

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NotificationPrompt from 'shared/components/UserPrompt/NotificationPrompt';
import { SET_TRIP_UNSAVED_DATA } from 'store/HistoryStore';

import { Checkbox, Input, ListItemText, MenuItem, Select } from '@material-ui/core';

import ClaimType from '../../authorization/ClaimType';
import PrivacySettingsEnum from '../../models/PrivacySettingsEnum';
import TripTypesEnum from '../../models/TripTypesEnum';
import { ApplicationState } from '../../store';
import { historyFilterActionCreators } from '../../store/HistoryFilter';
import { TranslateText } from '../../utils/Translations';

interface SelectionValue {
	id: TripTypesEnum;
	description: string;
}

const HistoryTripTypeSelector = () => {
	const dispatch = useDispatch();
	const globalCustomer = useSelector((state: ApplicationState) => state.globalCustomer);
	const customer = useSelector((state: ApplicationState) => state.currentSession.customer);
	const trackType = useSelector((state: ApplicationState) => state.historyFilter.trackType);
	const user = useSelector((state: ApplicationState) => state.oidc.user);
	const isDriver = useSelector((state: ApplicationState) => state.currentSession.isDriver);
	const tripUnsavedData = useSelector((state: ApplicationState) => state.historyStore.tripUnsavedData);
	const selectedTripEditBatch = useSelector((state: ApplicationState) => state.historyStore.tripEditBatch);

	const [defaultTripType, setDefaultTripType] = useState<TripTypesEnum>(TripTypesEnum.None);
	const [dataSource, setDataSource] = useState<SelectionValue[]>([]);
	const [showNotificationPrompt, setShowNotificationPrompt] = useState<boolean>(false);
	const [promptCallback, setPromptCallback] = useState<() => void>();

	useEffect(() => {
		if (trackType === TripTypesEnum.None) {
			dispatch(
				historyFilterActionCreators.setTrackTypeFilter(
					globalCustomer?.filteredCustomer?.featuresSettings.tripTypes || customer.featuresSettings.tripTypes
				)
			);
		}
	}, [trackType]);

	useEffect(() => {
		let tripType: TripTypesEnum = TripTypesEnum.Business | TripTypesEnum.Commuting | TripTypesEnum.Private;
		const tempCustomerFeatures = globalCustomer?.filteredCustomer?.featuresSettings || customer.featuresSettings;

		if (tempCustomerFeatures?.trackTypeSpecification) {
			tripType = tempCustomerFeatures.tripTypes;
		}

		if (user?.profile[ClaimType.ShowPrivateTrips] || isDriver) {
			tripType |= TripTypesEnum.Private;
		} else if (
			tempCustomerFeatures?.privacySettings === PrivacySettingsEnum.ShowPrivateData &&
			!(tempCustomerFeatures.tripTypes & TripTypesEnum.Private)
		) {
			tripType &= ~TripTypesEnum.Private;
		}

		setDefaultTripType(tripType);
	}, [globalCustomer, customer]);

	useEffect(() => {
		const newDatasource: SelectionValue[] = [];
		if (defaultTripType & TripTypesEnum.Business) {
			newDatasource.push({
				description: TranslateText('historyOverview.business'),
				id: TripTypesEnum.Business,
			});
		}
		if (
			defaultTripType & TripTypesEnum.Private &&
			(customer.featuresSettings.privacySettings === PrivacySettingsEnum.ShowPrivateData ||
				user?.profile[ClaimType.ShowPrivateTrips] ||
				isDriver)
		) {
			newDatasource.push({
				description: TranslateText('historyOverview.private'),
				id: TripTypesEnum.Private,
			});
		}
		if (defaultTripType & TripTypesEnum.Commuting) {
			newDatasource.push({
				description: TranslateText('historyOverview.commuting'),
				id: TripTypesEnum.Commuting,
			});
		}
		setDataSource(newDatasource);
	}, [defaultTripType]);

	const trackTypeChangedCallback = useCallback(
		(e) => {
			dispatch(historyFilterActionCreators.setTrackTypeFilter(trackType ^ (e.target.value as number)));
		},
		[trackType]
	);

	const visible: boolean =
		!!defaultTripType &&
		(!!globalCustomer?.filteredCustomer
			? !!globalCustomer.filteredCustomer?.featuresSettings.trackTypeSpecification
			: customer.featuresSettings.trackTypeSpecification);

	const validateCurrentTripData = (callbackFn: () => void) => {
		if (!tripUnsavedData) {
			callbackFn();
		} else {
			setShowNotificationPrompt(true);
			setPromptCallback(() => () => callbackFn());
		}
	};
	return (
		visible && (
			<div className={`trip-type-selector${selectedTripEditBatch?.activeEdit ? ' disabled' : ''}`}>
				<NotificationPrompt
					title={TranslateText('common.titleUnsavedData')}
					message={TranslateText('notificationMessages.unsavedData')}
					handleUserResponse={(response) => {
						setShowNotificationPrompt(false);
						if (response) {
							dispatch({
								type: SET_TRIP_UNSAVED_DATA,
								payload: false,
							});
							promptCallback();
							setPromptCallback(null);
						}
					}}
					displayDialog={showNotificationPrompt}
				/>
				<span>{TranslateText('historyOverview.show')}</span>
				<Select
					value={trackType}
					input={<Input />}
					style={{ width: '250px' }}
					onChange={(e) => validateCurrentTripData(() => trackTypeChangedCallback(e))}
					renderValue={(value) => {
						return dataSource
							.filter((v) => v.id & (value as TripTypesEnum))
							.map((v) => v.description)
							.join(', ');
					}}
				>
					{dataSource.map((o) => (
						<MenuItem key={o.id} value={o.id}>
							<Checkbox checked={!!(trackType & o.id)} />
							<ListItemText primary={o.description} />
						</MenuItem>
					))}
				</Select>
			</div>
		)
	);
};
export default HistoryTripTypeSelector;
