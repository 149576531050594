import './summaryView.scss'

import React from "react";

import {TripEditWizardDto} from "../../HistoryTripEditWizard";
import {FormatDate} from "../../../../../utils/DateUtils";
import TripTypesEnum from "../../../../../models/TripTypesEnum";
import {TranslateText, TranslateTextInterpolated} from "../../../../../utils/Translations";
interface SummaryProps {
	data: TripEditWizardDto;
	setIndexCallback: (value: number) => void;
	hideDriver: boolean;
	cancelCallback: () => void;
	driverIdentification: boolean;
	trackTypeSpecification: boolean;
}
const SummaryView = (props: SummaryProps) => {

	const getTripType = (id: TripTypesEnum): string => {
		if (id === TripTypesEnum.Business) {
			return TranslateText('historyOverview.business');
		}
		if (id === TripTypesEnum.Private) {
			return TranslateText('historyOverview.private');
		}
		if (id === TripTypesEnum.Commuting) {
			return TranslateText('historyOverview.commuting');
		}
		return null;
	}

	return (
		<div className={'summary-view'}>
			<div className={'summary-container'}>
				<div className={'field-row row-padding'}>
					<span className={'summary-title'}>{TranslateText('historyBatchEdit.summary')}</span>
				</div>
				<div className={'field-row row-padding'} style={{width: '100%'}}>
					<span>{TranslateText('historyBatchEdit.allTripsFrom')} <span className={'field-strong'}>{FormatDate(props.data?.from, false, false, false)}</span> {TranslateText('historyBatchEdit.tripConflictTo')} <span className={'field-strong'}>{FormatDate(props.data?.to, false, false, false)}</span> <span className={'field-strong'}>{TranslateText('historyBatchEdit.willBeSetTo')}</span></span>
				</div>
				{!props.hideDriver && props.driverIdentification && props.data?.driverSelected ?
				<div className={'field-row'}>
					<span className={'first-col'}>{`${TranslateText('fleetSelection.Driver')}:`}</span>
					<span className={'second-col'}>{props.data?.selectedDriverName}</span>
				</div>
					: null}
				{props.trackTypeSpecification && props.data?.tripTypeSelected?
				<div className={'field-row row-padding'}>
					<span className={'first-col'}>{`${TranslateText('reports.parameter.tripType')}:`}</span>
					<span className={'second-col'}>{getTripType(props.data?.selectedTripType)}</span>
				</div>
					: null}
			</div>
		</div>
	);
};
export default SummaryView;
