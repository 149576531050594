import ClaimType, { ClaimValue } from 'authorization/ClaimType';
import { ClaimUtil } from 'authorization/ClaimUtil';
import BooleanColumn from 'components/GridOverview/Columns/BooleanColumn';
import DateColumn from 'components/GridOverview/Columns/DateColumn';
import MultiSelectionColumn, {
	ColumnFlagOrdering,
	computeFlagOrderingBasedOnTranslation,
	MultiSelectionColumnOption,
} from 'components/GridOverview/Columns/MultiSelectionColumn';
import TextColumn from 'components/GridOverview/Columns/TextColumn';
import NewGridOverview from 'components/GridOverview/NewGridOverview';
import CustomerLevelEnum from 'models/CustomerLevelEnum';
import EntityTypeEnum from 'models/EntityTypeEnum';
import FuelTypeEnum from 'models/FuelTypeEnum';
import { GridFilters, MatchMode, SortOrder } from 'models/GridOverview';
import ObjectTypeEnum from 'models/ObjectTypeEnum';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ApplicationState } from 'store';
import { FormatDate } from 'utils/DateUtils';
import { useToggleWithoutInit } from 'utils/hooks';
import { getMultiSelectionEnumColumn, TranslateText, TranslateWithFailText } from 'utils/Translations';

import GlobalSettings from '../../GlobalSettings.json';
import { ConfigurationGroupOverviewDto } from './types';

const fieldNames = {
	customerName: 'customerName',
	code: 'code',
	name: 'name',
	description: 'description',
	objectType: 'objectType',
	fuelType: 'fuelType',
	memberObjectCount: 'memberObjectCount',
	lastChangeDate: 'lastChangeDate',
	lastChangeUserName: 'lastChangeUserName',
	active: 'active',
};

const initialFilters: GridFilters = {
	sortField: fieldNames.name,
	sortOrder: SortOrder.Ascendent,
	filters: {
		[fieldNames.customerName]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.code]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.name]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.description]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.objectType]: {
			value: [],
			matchMode: MatchMode.In,
		},
		[fieldNames.fuelType]: {
			value: [],
			matchMode: MatchMode.In,
		},
		[fieldNames.memberObjectCount]: {
			value: '',
			matchMode: MatchMode.GreaterThanOrEqual,
		},
		[fieldNames.lastChangeDate]: {
			value: '',
			matchMode: MatchMode.DateIs,
		},
		[fieldNames.lastChangeUserName]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.active]: {
			value: null,
			matchMode: MatchMode.Equals,
		},
	},
};

export const ConfigurationGroupsOverview = () => {
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const customerLevel = useSelector((s: ApplicationState) =>
		s.globalCustomer.filteredCustomer ? s.globalCustomer.filteredCustomer.level : s.currentSession.customerLevel
	);

	const configurationGroupFeature = useSelector((s: ApplicationState) =>
		s.globalCustomer.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.mediaFootage ||
			  s.globalCustomer.filteredCustomer.featuresSettings.driverStyleScores
			: s.currentSession.customer.featuresSettings.mediaFootage ||
			  s.currentSession.customer.featuresSettings.driverStyleScores
	);
	const objectTypeSelectOptions = useMemo<MultiSelectionColumnOption[]>(
		() => getMultiSelectionEnumColumn(ObjectTypeEnum, 'configurationGroups.objectTypeEnum.', true),
		[]
	);

	const fuelTypeSelectOptions = useMemo<MultiSelectionColumnOption[]>(
		() => getMultiSelectionEnumColumn(FuelTypeEnum, 'configurationGroups.fuelTypeEnum.', true),
		[]
	);

	const columnFlagOrdering = useMemo<ColumnFlagOrdering>(
		() => ({
			[fieldNames.objectType]: computeFlagOrderingBasedOnTranslation(objectTypeSelectOptions),
			[fieldNames.fuelType]: computeFlagOrderingBasedOnTranslation(fuelTypeSelectOptions),
		}),
		[]
	);

	const [fetchDataTrigger, toggleFetchDataTrigger] = useToggleWithoutInit();

	const columns = [
		TextColumn({
			fieldName: fieldNames.customerName,
			header: TranslateText('maintenanceOverview.grid.colCustomer'),
			body: (rowData: ConfigurationGroupOverviewDto) => (
				<Link title={rowData.customerName} to={`${GlobalSettings.route.customers}/${rowData.customerId}`}>
					{rowData.customerName}
				</Link>
			),
			sortable: true,
			filterable: true,
			shown: ClaimUtil.validateClaim(user, {
				claim: ClaimType.Customers,
				values: [ClaimValue.view],
			}),
		}),
		TextColumn({
			fieldName: fieldNames.code,
			header: TranslateText('maintenanceOverview.grid.colCode'),
			sortable: true,
			filterable: true,
		}),
		TextColumn({
			fieldName: fieldNames.name,
			header: TranslateText('maintenanceOverview.grid.colName'),
			sortable: true,
			filterable: true,
		}),
		TextColumn({
			fieldName: fieldNames.description,
			header: TranslateText('maintenanceOverview.grid.colDescription'),
			sortable: true,
			filterable: true,
		}),
		MultiSelectionColumn({
			fieldName: fieldNames.objectType,
			header: TranslateText('maintenanceOverview.grid.colObjectType'),
			sortable: true,
			filterable: true,
			body: (rowData: ConfigurationGroupOverviewDto) => {
				const enumValue = ObjectTypeEnum[rowData.objectType]?.toString();
				const objectType = TranslateWithFailText(`configurationGroups.objectTypeEnum.${enumValue}`, enumValue);
				return <span title={objectType}>{objectType}</span>;
			},
			selectOptions: objectTypeSelectOptions,
		}),
		MultiSelectionColumn({
			fieldName: fieldNames.fuelType,
			header: TranslateText('maintenanceOverview.grid.colFuelType'),
			sortable: true,
			filterable: true,
			body: (rowData: ConfigurationGroupOverviewDto) => {
				const enumValue = FuelTypeEnum[rowData.fuelType]?.toString();
				const fuelType = TranslateWithFailText(`configurationGroups.fuelTypeEnum.${enumValue}`, enumValue);
				return <span title={fuelType}>{fuelType}</span>;
			},
			selectOptions: fuelTypeSelectOptions,
		}),
		TextColumn({
			fieldName: fieldNames.memberObjectCount,
			header: TranslateText('maintenanceOverview.grid.colMemberObjectCount'),
			sortable: true,
			filterable: true,
		}),
		DateColumn({
			fieldName: fieldNames.lastChangeDate,
			header: TranslateText('maintenanceOverview.grid.colLastChangeDate'),
			sortable: true,
			filterable: true,
			formatDateFunction: FormatDate,
		}),
		TextColumn({
			fieldName: fieldNames.lastChangeUserName,
			header: TranslateText('maintenanceOverview.grid.colLastChangeUser'),
			body: (rowData: ConfigurationGroupOverviewDto) =>
				rowData.canViewPerson ? (
					<Link
						title={rowData.lastChangeUserName}
						to={`${GlobalSettings.route.persons}/${rowData.lastChangeUserId}`}
					>
						{rowData.lastChangeUserName}
					</Link>
				) : (
					<span title={rowData.lastChangeUserName}>{rowData.lastChangeUserName}</span>
				),
			sortable: true,
			filterable: true,
			shown: ClaimUtil.validateClaim(user, {
				claim: ClaimType.Persons,
				values: [ClaimValue.view],
			}),
		}),
		BooleanColumn({
			fieldName: fieldNames.active,
			header: TranslateText('maintenanceOverview.grid.colActive'),
			sortable: true,
			filterable: true,
		}),
	];

	return (
		<NewGridOverview
			columns={columns}
			initialFilters={initialFilters}
			getGridDataUrl={GlobalSettings.configurationGroupsMaintenanceApi}
			headerText={TranslateText('maintenanceOverview.configurationGroups')}
			disableAddButton={customerLevel !== CustomerLevelEnum.Default || !configurationGroupFeature}
			entityType={EntityTypeEnum.ConfigurationGroup}
			columnFlagOrder={columnFlagOrdering}
			useNewAddWizard
			showAddConfigClaim={[
				{
					claim: ClaimType.ConfigurationGroup,
					values: [ClaimValue.edit],
				},
			]}
			addEntityUrl={GlobalSettings.configurationGroupsMaintenanceApi + '/addConfigurationGroup'}
			fetchDataTrigger={fetchDataTrigger}
			disableImportButton={customerLevel !== CustomerLevelEnum.Default}
			disableExportButton={customerLevel !== CustomerLevelEnum.Default}
		/>
	);
};
