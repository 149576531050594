import './ConfigurationGroupStyle.scss';

import { AddWizardComponentProps } from 'components/NewAddWizard/NewAddWizard';
import ConfigurationGroupDto, {
	ConfigurationGroupFactors,
	ConfigurationGroupGeneralCamera,
	ConfigurationGroupGeneralInformation,
	ConfigurationGroupIncidents,
	ConfigurationGroupLevels,
	ConfigurationGroupMedia,
	ConfigurationGroupPeriodicals,
	ConfigurationGroupSettings,
	ConfigurationGroupThresholds,
	ConfigurationGroupTrips,
	ConfigurationGroupWeights,
} from 'models/ConfigurationGroupDto';
import React, { useEffect, useState } from 'react';
import { ValidationResult } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { TranslateText } from 'utils/Translations';

import ConfigurationGroupFactorsView from './Factors/ConfigurationGroupFactorsView';
import { handleConfigurationGroupChange } from './types';
import { ConfigurationGroupWeightAndFactorRules } from './Utils';
import ConfigurationGroupWeightsView from './Weights/ConfigurationGroupWeightsView';

type Props = AddWizardComponentProps & {
	id: string;
	customerId: string;
	aspNetUserId: string;
	objects: string[];
	active: boolean;
	startDateUtc: string;
	endDateUtc?: string;
	configurationGroupGeneralInformation: ConfigurationGroupGeneralInformation;
	configurationGroupWeights: ConfigurationGroupWeights;
	configurationGroupFactors: ConfigurationGroupFactors;
	configurationGroupSettings: ConfigurationGroupSettings;
	configurationGroupThresholds: ConfigurationGroupThresholds;
	configurationGroupTrips: ConfigurationGroupTrips;
	configurationGroupPeriodicals: ConfigurationGroupPeriodicals;
	configurationGroupIncidents: ConfigurationGroupIncidents;
	configurationGroupMedia: ConfigurationGroupMedia;
	configurationGroupGeneralCamera: ConfigurationGroupGeneralCamera;
	configurationGroupLevels: ConfigurationGroupLevels;
};
export const checkTotalWeight = (configurationGroupWeights: ConfigurationGroupWeights) => {
	let total = 0;
	let prop: keyof ConfigurationGroupWeights;

	for (prop in configurationGroupWeights) {
		total = total + Number(configurationGroupWeights[prop] ?? 0);
	}

	if (total > 100 || total < 100) {
		return false;
	}

	return true;
};
const ConfigGroupWeightFactorView = (props: Props) => {
	const [unsavedData, setUnsavedData] = useState<boolean>(false);
	const [validationResult, setValidationResult] = useState<ValidationResult | null>(null);
	const [validTotalWeight, setValidTotalWeight] = useState<boolean>(false);

	const [configurationGroupDto, setConfigurationGroupDto] = useState<ConfigurationGroupDto>({
		id: props.id,
		customerId: props.customerId,
		aspNetUserId: props.aspNetUserId,
		objects: props.objects,
		active: props.active,
		startDateUtc: props.startDateUtc,
		endDateUtc: props.endDateUtc,
		configurationGroupGeneralInformation: props.configurationGroupGeneralInformation,
		configurationGroupWeights: props.configurationGroupWeights,
		configurationGroupFactors: props.configurationGroupFactors,
		configurationGroupSettings: props.configurationGroupSettings,
		configurationGroupThresholds: props.configurationGroupThresholds,
		configurationGroupTrips: props.configurationGroupTrips,
		configurationGroupPeriodicals: props.configurationGroupPeriodicals,
		configurationGroupIncidents: props.configurationGroupIncidents,
		configurationGroupMedia: props.configurationGroupMedia,
		configurationGroupGeneralCamera: props.configurationGroupGeneralCamera,
		configurationGroupLevels: props.configurationGroupLevels,
	});

	const [validator] = useState<Validator>(() => {
		return new Validator(
			{
				fieldRules: ConfigurationGroupWeightAndFactorRules(),
			},
			true
		);
	});

	useEffect(() => {
		if (!props.visible) return;

		setConfigurationGroupDto({
			id: props.id,
			customerId: props.customerId,
			aspNetUserId: props.aspNetUserId,
			objects: props.objects,
			active: props.active,
			startDateUtc: props.startDateUtc,
			endDateUtc: props.endDateUtc,
			configurationGroupGeneralInformation: props.configurationGroupGeneralInformation,
			configurationGroupWeights: props.configurationGroupWeights,
			configurationGroupFactors: props.configurationGroupFactors,
			configurationGroupSettings: props.configurationGroupSettings,
			configurationGroupThresholds: props.configurationGroupThresholds,
			configurationGroupTrips: props.configurationGroupTrips,
			configurationGroupPeriodicals: props.configurationGroupPeriodicals,
			configurationGroupIncidents: props.configurationGroupIncidents,
			configurationGroupMedia: props.configurationGroupMedia,
			configurationGroupGeneralCamera: props.configurationGroupGeneralCamera,
			configurationGroupLevels: props.configurationGroupLevels,
		});
	}, [props.visible]);

	useEffect(() => {
		const validTotalWeight = checkTotalWeight(configurationGroupDto.configurationGroupWeights);

		validator.validate(configurationGroupDto).then((result) => {
			setValidationResult(result.validationResult);
			props.setValidationCallback(result.formResult && validTotalWeight);
			if (unsavedData) {
				setValidTotalWeight(validTotalWeight);
				props.onChangeCallback(configurationGroupDto);
			}
		});
	}, [configurationGroupDto]);
	const handleValueChangeMaterial = (value: any, statePropName: any, childObjName?: string) => {
		const newConfigurationGroup = handleConfigurationGroupChange<ConfigurationGroupDto>(
			configurationGroupDto,
			statePropName,
			value,
			childObjName
		);

		setUnsavedData(true);
		setConfigurationGroupDto(newConfigurationGroup);
	};

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-md-6">
					<div className="column-title">{TranslateText('common.configurationGroupWeights')}</div>
					<form id="configurationGroupWeightsForm" noValidate={true}>
						<ConfigurationGroupWeightsView
							data={configurationGroupDto}
							validationResult={validationResult}
							handleChange={handleValueChangeMaterial}
							validTotalWeight={validTotalWeight}
							isAddNew={true}
							editMode={true}
							isForNewAddWizard={true}
						/>
					</form>
				</div>
				<div className="col-md-6">
					<div className="column-title">{TranslateText('common.configurationGroupFactors')}</div>
					<form id="configurationGroupFactorsForm" noValidate={true}>
						<ConfigurationGroupFactorsView
							data={configurationGroupDto}
							validationResult={validationResult}
							handleChange={handleValueChangeMaterial}
						/>
					</form>
				</div>
			</div>
		</div>
	);
};

export default ConfigGroupWeightFactorView;
