import './styles.scss'

import React from "react";

import {IconButton, TextField, Tooltip } from "@material-ui/core";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {ValidationMessage} from "../../../components/ValidationMessage/ValidationMessage";
import {TextFieldToolTipProps} from "./types";
const TextFieldTooltip = (props: TextFieldToolTipProps) => {
	return (
		<div className="form-group">
			<div className="flex-container">
				<label
					className={`label-container ${props.disabled ? 'disabled' : 'enabled'}`}
					style={
					!props.labelStyle
						? { fontSize: props.fontSizeLabel }
						: { fontSize: props.fontSizeLabel, ...props.labelStyle}
				}
				>
					{props.label}
					<Tooltip title={props.toolTipText} arrow placement="right-start">
						<IconButton
							size="small"
							className={`small-icon-button ${props.disableTooltipButton? 'disabled' : 'enabled'}`}
							onClick={(e) => e.preventDefault()}
							disabled={props.disableTooltipButton}
							tabIndex={-1}
						>
							<InfoOutlinedIcon />
						</IconButton>
					</Tooltip>
				</label>
			</div>
			<TextField
				id={props.id}
				type={props.type}
				className={props.className}
				fullWidth={props.fullWidth}
				inputProps={props.inputProps}
				name={props.name}
				value={props.value}
				onChange={props.onChange}
				disabled={props.disabled}
				onKeyDown={props.onKeyDown}
				onWheel={props.onWheel}
			/>
			{!!props.validationResult && (
				<ValidationMessage result={props.validationResult}/>
			)}
		</div>
	);
};
export default TextFieldTooltip;
