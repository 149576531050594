import "./objectConfigurationGroupWidget.scss"

import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector } from "react-redux";

import {ClaimConfig, ClaimUtil} from '../../../authorization/ClaimUtil';
import {TranslateText} from '../../../utils/Translations';
import WidgetHeader from '../../BaseWidget/WidgetHeader';
import {DialogUtil} from '../../Common/NotificationDialog/NotificationDialog';
import {Button} from "@material-ui/core";
import ajaxUtil from "../../../utils/Ajax";
import GlobalSettings from "../../../GlobalSettings.json";
import {ApplicationState} from "../../../store";
import {FormatDate} from "../../../utils/DateUtils";
import {GridWidgetOverview} from "../../GridOverview/GridWidgetOverview";
import { Column } from "primereact/column";
import { Link } from "react-router-dom";
import ConfigurationGroupObjectConflict, {ConfigurationGroupObjectConflictDto} from "../ConfigurationGroupObjectWidget/ConfigurationGroupObjectConflict";
import ClaimType from "../../../authorization/ClaimType";
import ConnectionDialog from "./ConnectionDialog";
import {ListItem} from "../DeviceTemplateWidget/DeviceTemplateWidget";
import {unsavedDataStoreActionCreators} from "../../../store/UnsavedDataStore";

type Props = {
	position: {
		row: number;
		col: number;
		sizeX: number;
		sizeY: number;
	};
	entityId?: string;
	url?: string;
	exitEditModeOnUrlChange: boolean;
	widgetTitle: string;
	allowEditMode?: boolean;
	editModeCallback?: (editMode: boolean) => void;
	editClaimConfig?: ClaimConfig[];
	optionalEditClaimConfig?: ClaimConfig[];
	showEditButton: boolean;
	className?: string;
	hidden?: boolean;
};

interface ConfigurationGroupObjectDto {
	id: string;
	configurationGroupId: string;
	name: string;
	code: string;
	personName: string;
	userId: string;
	startDateTime: string;
	endDateTime?: string;
}
interface ConfigurationGroupObjectResult {
	success: boolean,
	hasConflicts: boolean,
	conflicts: ConfigurationGroupObjectConflictDto[]
}
const ObjectConfigurationGroupWidget = (props: Props) => {

	const dispatch = useDispatch();
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const defaultCustomerId = useSelector((s: ApplicationState) =>
		s.globalCustomer.filteredCustomer ? s.globalCustomer.filteredCustomer.id : s.currentSession.customerId
	);
	const unsavedData = useSelector((s: ApplicationState) => s.unsavedDataStore.unsavedData);

	const [data, setData] = useState<ConfigurationGroupObjectDto[]>([]);
	const [flags, setFlags] = useState({
		editMode: false,
		hasChanges: false,
		visible: true,
		viewEditMode: false,
	});
	const [selectedRow, setSelectedRow] = useState<ConfigurationGroupObjectDto>(null);
	const [disableDisconnect, setDisableDisconnect] = useState<boolean>(false);

	const [selectedItemId, setSelectedItemId] = useState<string>(null);
	const [connectionDataList, setConnectionDataList] = useState<ListItem[]>([]);
	const [showConnectionDialog, setShowConnectionDialog] = useState<boolean>(false);
	const [hasConflict, setHasConflict] = useState<boolean>(false);

	const [conflictsData, setConflictsData] = useState<ConfigurationGroupObjectConflictDto[]>([]);
	const [showConflictDialog, setShowConflictDialog] = useState<boolean>(false);

	useEffect(() => {
		props.editModeCallback(flags.editMode);
	}, [flags.editMode]);

	useEffect(() => {
		operations.getData();
	}, [props.entityId]);

	useEffect(() => {
		if(showConnectionDialog)
			operations.getConfigurationGroupsForConnection();
		else
			util.removeData();
	}, [showConnectionDialog])

	const util = {
		setEditMode: (editMode: boolean) => {
			setFlags({ ...flags, editMode: editMode });
		},
		confirm: async () => {
			return await DialogUtil.confirm({
				title: TranslateText('common.titleUnsavedData'),
				content: TranslateText('notificationMessages.cancel'),
			});
		},
		removeData: () => {
			setHasConflict(false);
			setSelectedItemId(null);
			dispatch(unsavedDataStoreActionCreators.setUnsavedData(false));
		}
	};

	const events = {
		removePanel: async () => {
			if (!flags.hasChanges || (flags.hasChanges && (await util.confirm()))) {
				setFlags({ ...flags, visible: false });
			}
		},
		toggleEdit: async () => {
			const editMode = !flags.editMode;
			if (!editMode && flags.hasChanges) {
				if (await util.confirm()) {
					util.setEditMode(editMode);
				}
			} else {
				util.setEditMode(editMode);
			}
		},
		editModeCallback: (editMode: boolean) => {
			setFlags({
				...flags,
				viewEditMode: editMode,
			});
		},
	};

	const operations = {
		getData: () => {
			ajaxUtil
				.get<ConfigurationGroupObjectDto[]>(`${GlobalSettings.objectsMaintenanceApi}/getObjectConfigurationGroups/${props.entityId}`)
				.then((data) => {
					setData(data);
				});
		},
		getConfigurationGroupsForConnection: () => {
			ajaxUtil
				.get<ListItem[]>(`${GlobalSettings.configurationGroupsMaintenanceApi}/getConfigurationGroups/${props.entityId}`)
				.then((data) => {
					setConnectionDataList(data);
				});
		},
		addObjects: (selectedId: string ,bypassConflicts: boolean) => {
			ajaxUtil
				.post<ConfigurationGroupObjectResult>(`${GlobalSettings.configurationGroupsMaintenanceApi}/addConfigurationGroupObjects`,
					{
						configurationGroupId: selectedId,
						bypassConflicts: bypassConflicts,
						customerId: defaultCustomerId,
						entities: [props.entityId]
					})
				.then((data) => {
					if (!data.success) {
						if (data.hasConflicts) {
							setConflictsData(data.conflicts);
							setShowConflictDialog(true);
							setHasConflict(true);
						}
					} else {
						operations.getData();
						setShowConnectionDialog(false);
					}
				});
		},
		terminateSelectedConnection: () => {
			setDisableDisconnect(true);
			if (selectedRow) {
				const url =
					GlobalSettings.connectionsApi +
					'/TerminateObjectConfigurationGroupConnection/' + selectedRow?.id;

				ajaxUtil.post(url, null)
					.then((data) => {
						operations.getData();
						setDisableDisconnect(false);
						setSelectedRow(null);
					});
			}
		}
	};

	const actionsConflictDialog = {
		onClickConflict: useCallback(
			(value: boolean) => {
				setShowConflictDialog(false);
				if(value)
				{
					operations.addObjects(selectedItemId,true);
					setShowConnectionDialog(false);
					setSelectedItemId(null);
				}
			},
			[conflictsData]),
	};

	const actionsConnectionDialog = {
		onCloseCallback: useCallback(
			() => {
				util.removeData();
			},
			[]),
		onCancelCallback: async () => {
			if (unsavedData) {
				if(await util.confirm()) {
					setShowConnectionDialog(false);
					util.removeData();
				}
			}
			else
			{
				setShowConnectionDialog(false);
				util.removeData();
			}
		},
		setItemCallback: useCallback(
			(value: string) => {
				setHasConflict(false);
				setSelectedItemId(value);
				dispatch(unsavedDataStoreActionCreators.setUnsavedData(true));
			},
			[selectedItemId]),
		onConnectCallback: useCallback(
			() => {
				operations.addObjects(selectedItemId,false);
			},
			[selectedItemId]),
	};

	return (
		<div className={`e-panel widget object-configuration-group-widget ${flags.visible ? '' : 'hidden'} ${props.className ? props.className : ''}`}
			 data-row={props.position.row}
			 data-col={props.position.col}
			 data-sizex={props.position.sizeX}
			 data-sizey={props.position.sizeY}
			 data-minsizex={props.position.sizeX}
			 data-minsizey={props.position.sizeY}
			 hidden={props.hidden}
		>
			<div className="e-panel-container">
				<WidgetHeader
					caption={props.widgetTitle}
					showEditButton={props.showEditButton}
					allowEditMode={props.allowEditMode || flags.editMode}
					editClaimConfig={props.editClaimConfig}
					optionalEditClaimConfig={props.optionalEditClaimConfig}
					editMode={flags.editMode}
					removePanelCallback={events.removePanel}
					editCallback={events.toggleEdit}
				/>

				<div className={'widget-view'}>
					<GridWidgetOverview
						columns={[
							<Column
								key={'code'}
								field={'code'}
								className="widget-grid-column"
								header={TranslateText('widgets.grid.colCode')}
								style={{ width: 120 }}
								body={(rowData: any) =>
										 <Link to={`${GlobalSettings.route.configurationGroups}/${rowData.configurationGroupId}`}>
											{rowData.code}
										</Link>
								}
							/>,
							<Column
								key={'name'}
								field={'name'}
								className="widget-grid-column"
								header={TranslateText('widgets.grid.colName')}
								style={{ width: 170 }}
							/>,
							ClaimUtil.validateHasClaim(user, ClaimType.Persons) ?
								(<Column
								key="personName"
								field="personName"
								className="widget-grid-column"
								header={TranslateText('widgets.grid.colPerson')}
								style={{ width: 120 }}
							/>) : null,
							<Column
								key="startDateTime"
								field="startDateTime"
								className="widget-grid-column"
								header={TranslateText('widgets.grid.colStartDate')}
								style={{ width: 240 }}
								body={(rowData: any) =>
									FormatDate(new Date(rowData.startDateTime))
							}
							/>,
							<Column
								key="endDateTime"
								field="endDateTime"
								className="widget-grid-column"
								header={TranslateText('widgets.grid.colEndDate')}
								style={{ width: 240 }}
								body={(rowData: any) =>
									rowData.endDateTime ? (
										FormatDate(new Date(rowData.endDateTime))
									) : null
								}
							/>,
						]}
						data={data}
						total={data?.length ?? 0}
						paginator={true}
						selectedRowCallback={(rowData) => {
							setSelectedRow(rowData);
						}}
					/>
				</div>
				{flags.editMode ? (
					<div className={`buttons-host`}>
						<div className="left-side-buttons">
							<Button
								className="widget-button disconnect"
								disabled={(selectedRow === null) || (selectedRow.endDateTime !== null) || disableDisconnect}
								onClick={() => operations.terminateSelectedConnection()}
							>
								{TranslateText('common.disconnect')}
							</Button>
						</div>
						<div className="right-side-buttons">
							<Button
								className="widget-button link"
								disabled={false}
								onClick={() => setShowConnectionDialog(true)}
							>
								{TranslateText('common.link')}
							</Button>
						</div>
					</div>
				) : null}
			</div>
			<ConnectionDialog
				open={showConnectionDialog}
				selectedItemId={selectedItemId}
				data={connectionDataList}
				onCloseCallback={actionsConnectionDialog.onCloseCallback}
				setItemCallback={actionsConnectionDialog.setItemCallback}
				onCancelCallback={actionsConnectionDialog.onCancelCallback}
				onConnectCallback={actionsConnectionDialog.onConnectCallback}
				hasConflicts={hasConflict}
			/>
			<ConfigurationGroupObjectConflict
				showDialog={showConflictDialog}
				data={conflictsData}
				onClickCallback={actionsConflictDialog.onClickConflict}
			/>
		</div>
	);
}
ObjectConfigurationGroupWidget.defaultProps = {
	showEditButton: true,
};

export default ObjectConfigurationGroupWidget;
