import Accordion from 'components/Accordion/Accordion';
import AccordionSection from 'components/Accordion/AccordionSection';
import MaterialAutocomplete, { AutocompleteItem } from 'components/Common/Autocomplete/MaterialAutocomplete';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import GlobalSettings from 'GlobalSettings.json';
import AuthenticationTypeEnum from 'models/AuthenticationTypeEnum';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import MaterialTextField from 'shared/components/MaterialTextField/MaterialTextField';
import AuthenticationTypeDataSource from 'shared/staticDataSources/AuthenticationTypeDataSource';
import { FieldRules } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { ApplicationState, CustomerFeaturesState } from 'store';
import ajaxUtil from 'utils/Ajax';
import { TranslateText } from 'utils/Translations';

import { TextField } from '@material-ui/core';

import ClaimType, { ClaimValue } from '../../../authorization/ClaimType';
import { ClaimUtil } from '../../../authorization/ClaimUtil';
import CO2CalculationTypeEnum from '../../../models/CO2CalculationTypeEnum';
import CustomerLevelEnum from '../../../models/CustomerLevelEnum';
import TripTypesEnum from '../../../models/TripTypesEnum';
import CO2CalculationTypeDataSource from '../../../shared/staticDataSources/CO2CalculationTypeDataSource';
import { WidgetViewDefault } from '../Widget';
import followupItem from "../../SearchComponent/FollowupItem";

//extend with custom props
type Props = WidgetViewDefault & {
	data: EntityDefaultsState;
	//Any other custom props
};

const constants = {
	defaultValues: {
		externalCodeSameCode: true,
		toleranceTimeOutDistance: 50,
		timeOutEndOfTrip: 180,
		co2CalculationType: CO2CalculationTypeEnum.DistanceWLTP,
		autoGeneratePersonCode: true,
		autoGenerateAlertCode: true,
		defaultDeviceAuthenticationType: AuthenticationTypeEnum.None,
		customerUsesDriverIdentification: false,
		customerUsesTrackTypeSpecification: false,
		customerUsesTemplates: false,
		trackTypesContainPrivate: false,
		disconnectDriverAfterIgnitionOff: false,
		disconnectDriverAfterIgnitionOffTimeOutSeconds: 300,
		defaultDeviceNeedToBeSyncedWithEd2: true,
		defaultDeviceShouldHaveEd2MediaSupport: true,
		useDriverStyleScores: true,
		useMediaFootage: true,
	} as EntityDefaultsState,
	customerCodeUnique: true,
	fieldsConfig: { text: 'display', value: 'id' },
};

interface EntityDefaultsState {
	externalCodeSameCode: boolean;
	toleranceTimeOutDistance: number;
	timeOutEndOfTrip: number;
	co2CalculationType: CO2CalculationTypeEnum;
	autoGeneratePersonCode: boolean;
	autoGenerateAlertCode: boolean;
	defaultDeviceAuthenticationType: AuthenticationTypeEnum;
	customerUsesDriverIdentification: boolean;
	customerUsesTrackTypeSpecification: boolean;
	customerUsesTemplates: boolean;
	trackTypesContainPrivate: boolean;
	disconnectDriverAfterIgnitionOff: boolean;
	disconnectDriverAfterIgnitionOffTimeOutSeconds: number;
	defaultDriverRoleId: string;
	autoConnectPrivateSwitchTemplate: boolean;
	defaultPrivateSwitchTemplateId: string;
	defaultDeviceNeedToBeSyncedWithEd2: boolean;
	defaultDeviceShouldHaveEd2MediaSupport: boolean;
	useDriverStyleScores: boolean;
	useMediaFootage: boolean;
}

const CustomerEntityDefaultsValidationConfig = (config: {
	useDriverAuth: boolean;
	useTrackTypeSpecification: boolean;
	autoConnectPrivateSwitchTemplate: boolean;
	customerTypeDefault: boolean;
	trackTypesContainPrivate: boolean;
	useTemplates: boolean;
	claimCustomerEdit: boolean;
	useDriverStyleScores: boolean;
	useMediaFootage: boolean;
}): FieldRules => {
	const fieldRules: FieldRules = {
		externalCodeSameCode: {
			rules: { required: ValidatorFunctions.required() },
		},
		autoGeneratePersonCode: {
			rules: { required: ValidatorFunctions.required() },
		},
		autoGenerateAlertCode: {
			rules: { required: ValidatorFunctions.required() },
		},
		defaultDriverRoleId: {
			rules: config.useDriverAuth &&
				config.customerTypeDefault &&
				config.claimCustomerEdit && { required: ValidatorFunctions.required() },
		},
		autoConnectPrivateSwitchTemplate: {
			rules: { required: ValidatorFunctions.required() },
		},
		defaultPrivateSwitchTemplateId: {
			rules: config.useTrackTypeSpecification &&
				config.trackTypesContainPrivate &&
				config.useTemplates &&
				config.customerTypeDefault &&
				config.claimCustomerEdit && {
					required: {
						...ValidatorFunctions.required(),
						disabled: !config.useDriverAuth || !config.autoConnectPrivateSwitchTemplate,
					},
				},
		},
		toleranceTimeOutDistance: {
			rules: {
				required: ValidatorFunctions.required(),
				minLimit: {
					message: TranslateText('fieldsValidations.minValue') + '50',
					validationFunction: (data, fieldName) => {
						if (data[fieldName] == -1) {
							return ValidatorFunctions.wrapInPromise(true);
						} else {
							return ValidatorFunctions.wrapInPromise(
								(!data[fieldName] && data[fieldName] !== 0) || data[fieldName] >= 50
							);
						}
					},
				},
				maxNumber: ValidatorFunctions.maxNumber(250),
			},
		},
		timeOutEndOfTrip: {
			rules: {
				required: ValidatorFunctions.required(),
				minLimit: {
					message: TranslateText('fieldsValidations.minValue') + '15',
					validationFunction: (data, fieldName) => {
						if (data[fieldName] == -1) {
							return ValidatorFunctions.wrapInPromise(true);
						} else {
							return ValidatorFunctions.wrapInPromise(
								(!data[fieldName] && data[fieldName] !== 0) || data[fieldName] >= 15
							);
						}
					},
				},
				maxNumber: ValidatorFunctions.maxNumber(300),
			},
		},
		co2CalculationType: {
			rules: {
				required: ValidatorFunctions.required(),
			},
		},
		defaultDeviceAuthenticationType: {
			rules: {
				required: { ...ValidatorFunctions.required(), disabled: !config.useDriverAuth },
			},
		},
		disconnectDriverAfterIgnitionOff: {
			rules: {
				required: ValidatorFunctions.required(),
			},
		},
		disconnectDriverAfterIgnitionOffTimeOutSeconds: {
			rules: {
				required: ValidatorFunctions.required(),
				minNumber: ValidatorFunctions.minNumber(0),
				maxNumber: ValidatorFunctions.maxNumber(31557600), // 1 year in seconds
			},
		},
		defaultDeviceNeedToBeSyncedWithEd2: {
			rules: (config.useDriverStyleScores || config.useMediaFootage) && {
				required: ValidatorFunctions.required(),
			},
		},
		defaultDeviceShouldHaveEd2MediaSupport: {
			rules: (config.useDriverStyleScores || config.useMediaFootage) && {
				required: ValidatorFunctions.required(),
			},
		},
	};

	return fieldRules;
};

const CustomerEntityDefaultsView = (props: Props) => {
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const [defaultDataSource] = useState([
		{ id: true, display: TranslateText('common.yes') },
		{ id: false, display: TranslateText('common.no') },
	]);

	const [useDriverAuth, setUseDriverAuth] = useState<boolean>(
		constants.defaultValues.customerUsesDriverIdentification
	);
	const [useTrackTypeSpecification, setUseTrackTypeSpecification] = useState<boolean>(
		constants.defaultValues.customerUsesTrackTypeSpecification
	);
	const [useTemplates, setUseTemplates] = useState<boolean>(constants.defaultValues.customerUsesTemplates);
	const [trackTypesContainPrivate, setTrackTypesContainPrivate] = useState<boolean>(
		constants.defaultValues.trackTypesContainPrivate
	);

	const [useDriverStyleScores, setUseDriverStyleScores] = useState<boolean>(constants.defaultValues.useDriverStyleScores);
	const [useMediaFootage, setUseMediaFootage] = useState<boolean>(constants.defaultValues.useMediaFootage);

	const { data: rolesDataSource, refetch: refetchRoles } = useQuery(
		['entity-defaults', 'roles-data-source'],
		async () =>
			await ajaxUtil.get<AutocompleteItem[]>(
				`${GlobalSettings.rolesMaintenanceApi}/GetDriverRoleDefaults/${props.entityId}`
			)
	);

	const { data: templatesEventDataSource, refetch: refetchTemplates } = useQuery(
		['entity-defaults', 'templates-event-data-source'],
		async () =>
			await ajaxUtil.get<AutocompleteItem[]>(
				`${GlobalSettings.listsApi}/eventTemplatesForCustomer?customerId=${props.entityId}`
			)
	);

	const { data: customerType } = useQuery(
		['entity-defaults', 'customer-type'],
		async () =>
			await ajaxUtil.get<CustomerLevelEnum>(
				`${GlobalSettings.customersMaintenanceApi}/GetCustomerType/${props.entityId}`
			)
	);

	useEffect(() => {
		if (!props.data.defaultDriverRoleId) {
			const newData = { ...props.data };
			newData.defaultDriverRoleId = '';
			props.changeDataCallback(newData);
		}
	}, [props.data.defaultDriverRoleId]);

	useEffect(() => {
		if (!props.data.defaultPrivateSwitchTemplateId) {
			const newData = { ...props.data };
			newData.defaultPrivateSwitchTemplateId = null;
			props.changeDataCallback(newData);
		}
	}, [props.data.defaultPrivateSwitchTemplateId]);

	useEffect(() => {
		props.setValidatorCallback(
			new Validator({
				fieldRules: CustomerEntityDefaultsValidationConfig({
					useDriverAuth,
					useTrackTypeSpecification,
					autoConnectPrivateSwitchTemplate: props.data.autoConnectPrivateSwitchTemplate,
					customerTypeDefault: customerType === CustomerLevelEnum.Default,
					trackTypesContainPrivate,
					useTemplates,
					claimCustomerEdit: ClaimUtil.validateClaim(user, {
						claim: ClaimType.Customers,
						values: [ClaimValue.edit],
					}),
					useDriverStyleScores,
					useMediaFootage
				}),
			})
		);
	}, [props.data.autoConnectPrivateSwitchTemplate,
		props.data.defaultDeviceShouldHaveEd2MediaSupport,
		props.data.defaultDeviceNeedToBeSyncedWithEd2]);

	useEffect(() => {
		const url = `${GlobalSettings.customersMaintenanceApi}/features/${props.entityId}`;
		refetchTemplates();
		refetchRoles();
		ajaxUtil.get<CustomerFeaturesState>(url).then((data) => {
			setUseDriverAuth(data.driverIdentification);
			setUseTrackTypeSpecification(data.trackTypeSpecification);
			setUseTemplates(data.templates);
			setTrackTypesContainPrivate(!!(data.tripTypes & TripTypesEnum.Private));
			setUseDriverStyleScores(data.driverStyleScores);
			setUseMediaFootage(data.mediaFootage);
			if(props?.setValidatorCallback) {
				props?.setValidatorCallback(
					new Validator({
						fieldRules: CustomerEntityDefaultsValidationConfig({
							useDriverAuth: data.driverIdentification,
							useTrackTypeSpecification: data.trackTypeSpecification,
							autoConnectPrivateSwitchTemplate: props.data.autoConnectPrivateSwitchTemplate,
							customerTypeDefault: customerType === CustomerLevelEnum.Default,
							trackTypesContainPrivate: !!(data.tripTypes & TripTypesEnum.Private),
							useTemplates: data.templates,
							claimCustomerEdit: ClaimUtil.validateClaim(user, {
								claim: ClaimType.Customers,
								values: [ClaimValue.edit],
							}),
							useDriverStyleScores,
							useMediaFootage
						}),
					})
				);
			}
		});
	}, [props.entityId, props.dataHasChanged]);

	useEffect(() => {
		const authType = props.data.defaultDeviceAuthenticationType;
		if (authType === AuthenticationTypeEnum.None || authType === AuthenticationTypeEnum.Fixed) {
			const newData = { ...props.data };
			newData.disconnectDriverAfterIgnitionOff = constants.defaultValues.disconnectDriverAfterIgnitionOff;
			newData.disconnectDriverAfterIgnitionOffTimeOutSeconds =
				constants.defaultValues.disconnectDriverAfterIgnitionOffTimeOutSeconds;
			props.changeDataCallback(newData);
		}
	}, [props.data.defaultDeviceAuthenticationType]);

	useEffect(() => {
		if (!props.data.disconnectDriverAfterIgnitionOff) {
			const newData = { ...props.data };
			newData.disconnectDriverAfterIgnitionOffTimeOutSeconds =
				constants.defaultValues.disconnectDriverAfterIgnitionOffTimeOutSeconds;
			props.changeDataCallback(newData);
		}
	}, [props.data.disconnectDriverAfterIgnitionOff]);

	const events = {
		handleValueChange: async (
			value: boolean | number | AuthenticationTypeEnum | string,
			key: keyof EntityDefaultsState
		) => {
			const newData = { ...props.data };
			(newData[key] as any) = value;
			if (!newData.autoConnectPrivateSwitchTemplate) {
				newData.defaultPrivateSwitchTemplateId = null;
			}
			props.changeDataCallback(newData);
		},
	};

	const [expandedSection, setExpandedSection] = useState<number>(0);

	return (
		<>
			<Accordion
				scrollToTop={props.scrollToTop}
				expandedCallback={(index) => {
					setExpandedSection(index);
				}}
			>
				<AccordionSection expanded={expandedSection === 0} header={TranslateText('fields.generalDefaults')}>
					<div className="view-section-wrapper">
						<div className="form-group">
							<MaterialAutocomplete
								valueId={props.data.externalCodeSameCode}
								dataSource={defaultDataSource}
								name="externalCodeSameCode"
								disabled={!props.editMode}
								label={TranslateText('fields.externalCodeSameCode')}
								onChange={({ value }) =>
									events.handleValueChange(value as boolean, 'externalCodeSameCode')
								}
							/>
							<ValidationMessage result={props.validationResult?.externalCodeSameCode} />
						</div>
					</div>
				</AccordionSection>
				<AccordionSection expanded={expandedSection === 1} header={TranslateText('fields.objectDefaults')}>
					<div className="view-section-wrapper">
						<div className="form-group">
							<TextField
								id="toleranceTimeOutDistance"
								type="number"
								className="resize-font"
								fullWidth
								label={TranslateText('fields.toleranceTimeOutDistance')}
								inputProps={{ style: { fontSize: 12 } }}
								name="toleranceTimeOutDistance"
								value={props.data.toleranceTimeOutDistance}
								onChange={(args: any) =>
									events.handleValueChange(args.target.value, 'toleranceTimeOutDistance')
								}
								disabled={!props.editMode}
							/>
							<ValidationMessage result={props.validationResult?.toleranceTimeOutDistance} />
						</div>
						<div className="form-group">
							<TextField
								id="timeOutEndOfTrip"
								type="number"
								className="resize-font"
								fullWidth
								label={TranslateText('fields.timeOutEndOfTrip')}
								inputProps={{ style: { fontSize: 12 } }}
								name="timeOutEndOfTrip"
								value={props.data.timeOutEndOfTrip}
								onChange={(args: any) =>
									events.handleValueChange(args.target.value, 'timeOutEndOfTrip')
								}
								disabled={!props.editMode}
							/>
							<ValidationMessage result={props.validationResult?.timeOutEndOfTrip} />
						</div>
						<div className="form-group">
							<MaterialAutocomplete
								isForNewAddWizard={false}
								valueId={props.data.co2CalculationType}
								dataSource={CO2CalculationTypeDataSource.dataSource().filter((x) => !x.disabled)}
								name="co2CalculationType"
								disabled={!props.editMode}
								label={TranslateText('fields.co2CalculationType')}
								onChange={({ value }) => events.handleValueChange(value, 'co2CalculationType')}
							/>
							<ValidationMessage result={props.validationResult?.co2CalculationType} />
						</div>
					</div>
				</AccordionSection>
				<AccordionSection expanded={expandedSection === 2} header={TranslateText('fields.personDefaults')}>
					<div className="view-section-wrapper">
						<div className="form-group">
							<MaterialAutocomplete
								valueId={props.data.autoGeneratePersonCode}
								dataSource={defaultDataSource}
								name="autoGeneratePersonCode"
								disabled={!props.editMode}
								label={TranslateText('fields.autoGeneratePersonCode')}
								onChange={({ value }) =>
									events.handleValueChange(value as boolean, 'autoGeneratePersonCode')
								}
							/>
							<ValidationMessage result={props.validationResult?.autoGeneratePersonCode} />
						</div>
					</div>
				</AccordionSection>
				<AccordionSection expanded={expandedSection === 3} header={TranslateText('fields.alertDefaults')}>
					<div className="view-section-wrapper">
						<div className="form-group">
							<MaterialAutocomplete
								valueId={props.data.autoGenerateAlertCode}
								dataSource={defaultDataSource}
								name="autoGenerateAlertCode"
								disabled={!props.editMode}
								label={TranslateText('fields.autoGenerateAlertCode')}
								onChange={({ value }) =>
									events.handleValueChange(value as boolean, 'autoGenerateAlertCode')
								}
							/>
							<ValidationMessage result={props.validationResult?.autoGenerateAlertCode} />
						</div>
					</div>
				</AccordionSection>
				{(useMediaFootage || useDriverStyleScores) ?
					<AccordionSection
					expanded={expandedSection === 4}
					header={TranslateText('fields.deviceDefaults')}
				>
					<div className="view-section-wrapper">
						<div className="form-group">
							<MaterialAutocomplete
								valueId={props.data.defaultDeviceNeedToBeSyncedWithEd2}
								dataSource={defaultDataSource}
								name="defaultDeviceNeedToBeSyncedWithEd2"
								disabled={!props.editMode}
								label={TranslateText('fields.deviceNeedToBeSyncedWithEd2')}
								onChange={({ value }) =>
									events.handleValueChange(value as boolean, 'defaultDeviceNeedToBeSyncedWithEd2')
								}
							/>
							<ValidationMessage result={props.validationResult?.defaultDeviceNeedToBeSyncedWithEd2} />
							<MaterialAutocomplete
								valueId={props.data.defaultDeviceShouldHaveEd2MediaSupport}
								dataSource={defaultDataSource}
								name="defaultDeviceShouldHaveEd2MediaSupport"
								disabled={!props.editMode}
								label={TranslateText('fields.deviceShouldHaveEd2MediaSupport')}
								onChange={({ value }) =>
									events.handleValueChange(value as boolean, 'defaultDeviceShouldHaveEd2MediaSupport')
								}
							/>
							<ValidationMessage result={props.validationResult?.defaultDeviceShouldHaveEd2MediaSupport} />
						</div>
					</div>
				</AccordionSection> : null}
				{useDriverAuth ? (
					<AccordionSection
						expanded={expandedSection === 5}
						header={TranslateText('fields.driverIdentificationDefaults')}
					>
						<div className="view-section-wrapper">
							<div className="form-group">
								<MaterialAutocomplete
									valueId={props.data.defaultDeviceAuthenticationType}
									dataSource={AuthenticationTypeDataSource.dataSource()}
									name="defaultDeviceAuthenticationType"
									disabled={!props.editMode}
									label={TranslateText('fields.authenticationType')}
									onChange={({ value }) =>
										events.handleValueChange(
											value as AuthenticationTypeEnum,
											'defaultDeviceAuthenticationType'
										)
									}
								/>
								<ValidationMessage result={props.validationResult?.defaultDeviceAuthenticationType} />
							</div>
						</div>
						<div className="view-section-wrapper">
							<div className="form-group">
								<MaterialAutocomplete
									valueId={props.data.disconnectDriverAfterIgnitionOff}
									dataSource={defaultDataSource}
									name="disconnectDriverAfterIgnitionOff"
									disabled={
										!props.editMode ||
										props.data.defaultDeviceAuthenticationType === AuthenticationTypeEnum.None ||
										props.data.defaultDeviceAuthenticationType === AuthenticationTypeEnum.Fixed
									}
									label={TranslateText('fields.disconnectDriverAfterIgnitionOff')}
									onChange={({ value }) =>
										events.handleValueChange(value as boolean, 'disconnectDriverAfterIgnitionOff')
									}
								/>
								<ValidationMessage result={props.validationResult?.disconnectDriverAfterIgnitionOff} />
							</div>
						</div>
						<div className="form-group">
							<MaterialTextField
								id="disconnectDriverAfterIgnitionOffTimeOutSeconds"
								type="number"
								className="resize-font"
								fullWidth
								label={TranslateText('fields.disconnectDriverAfterIgnitionOffTimeOutSeconds')}
								inputProps={{ style: { fontSize: 12 } }}
								name="disconnectDriverAfterIgnitionOffTimeOutSeconds"
								value={props.data.disconnectDriverAfterIgnitionOffTimeOutSeconds}
								handleValueChange={(args: any) =>
									events.handleValueChange(
										args as number,
										'disconnectDriverAfterIgnitionOffTimeOutSeconds'
									)
								}
								disabled={
									!props.editMode ||
									props.data.defaultDeviceAuthenticationType === AuthenticationTypeEnum.None ||
									props.data.defaultDeviceAuthenticationType === AuthenticationTypeEnum.Fixed ||
									!props.data.disconnectDriverAfterIgnitionOff
								}
							/>
							<ValidationMessage
								result={props.validationResult?.disconnectDriverAfterIgnitionOffTimeOutSeconds}
							/>
						</div>
					</AccordionSection>
				) : null}
				{useDriverAuth &&
				customerType === CustomerLevelEnum.Default &&
				ClaimUtil.validateClaim(user, {
					claim: ClaimType.Customers,
					values: [ClaimValue.edit],
				}) ? (
					<AccordionSection
						expanded={expandedSection === 6}
						header={TranslateText('fields.driverRoleDefaults')}
					>
						<div className="view-section-wrapper">
							<div className="form-group">
								<MaterialAutocomplete
									valueId={props.data.defaultDriverRoleId}
									dataSource={rolesDataSource}
									name="defaultDriverRole"
									disabled={!props.editMode}
									label={TranslateText('fields.driverRole')}
									onChange={({ value }) =>
										events.handleValueChange(value as string, 'defaultDriverRoleId')
									}
								/>
								<ValidationMessage result={props.validationResult?.defaultDriverRoleId} />
							</div>
						</div>
					</AccordionSection>
				) : null}
				{useTrackTypeSpecification &&
				trackTypesContainPrivate &&
				useTemplates &&
				customerType === CustomerLevelEnum.Default &&
				ClaimUtil.validateClaim(user, {
					claim: ClaimType.Customers,
					values: [ClaimValue.edit],
				}) ? (
					<AccordionSection
						expanded={expandedSection === 7}
						header={TranslateText('fields.trackTypeDefaults')}
					>
						<div className="view-section-wrapper">
							<div className="form-group">
								<MaterialAutocomplete
									valueId={props.data.autoConnectPrivateSwitchTemplate}
									dataSource={defaultDataSource}
									name="autoConnectPrivateSwitchTemplate"
									disabled={!props.editMode}
									label={TranslateText('fields.autoConnectPrivateSwitchTemplate')}
									onChange={({ value }) =>
										events.handleValueChange(value as boolean, 'autoConnectPrivateSwitchTemplate')
									}
								/>
								<ValidationMessage result={props.validationResult?.autoConnectPrivateSwitchTemplate} />
								<MaterialAutocomplete
									valueId={props.data.defaultPrivateSwitchTemplateId}
									dataSource={templatesEventDataSource}
									name="defaultPrivateSwitchTemplateId"
									disabled={!props.editMode || !props.data.autoConnectPrivateSwitchTemplate}
									label={TranslateText('fields.privateSwitchTemplate')}
									onChange={({ value }) =>
										events.handleValueChange(value as string, 'defaultPrivateSwitchTemplateId')
									}
								/>
								<ValidationMessage result={props.validationResult?.defaultPrivateSwitchTemplateId} />
							</div>
						</div>
					</AccordionSection>
				) : null}
			</Accordion>
		</>
	);
};

export default CustomerEntityDefaultsView;
