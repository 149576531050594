import {FleetEntityTypeFilter} from "../../../store/FleetSelection";
import {ITreeNode} from "../../SelectionTree/TreeNode/types";
import ajaxUtil from "../../../utils/Ajax";
import GlobalSettings from "../../../GlobalSettings.json";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import {ApplicationState} from "../../../store";
import {ButtonConfig} from "../../RadioButtonContainer";
import TreeConfigurationUtil from "../../../utils/TreeSelection/TreeConfigurationUtil";
import {SelectionFilter} from "../../TreeSelectionDialog/TreeSelectionDialog";
import {ObjectFunctionFilter} from "../../../models/ObjectFunctionFilter";
import {ConfigurationGroupObjectSelectionAreaProps} from "./ObjectSelectionDialog";

export const useConfigurationGroupObjectSelectionArea = (props: ConfigurationGroupObjectSelectionAreaProps) => {
	const showInactiveRef = useRef<boolean>(props.includeInactive);
	showInactiveRef.current = props.includeInactive;

	const assetTracking = useSelector((state: ApplicationState) =>
		state.globalCustomer?.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.assetTracking
			: state.currentSession.customer?.featuresSettings.assetTracking
	);


	const [filterButtons, setFilterButtons] = useState<ButtonConfig[]>([]);
	useEffect(() => {
		if(assetTracking)
			setFilterButtons(
				TreeConfigurationUtil.getButtonConfig([
					FleetEntityTypeFilter.all,
					FleetEntityTypeFilter.object,
					FleetEntityTypeFilter.asset,
				]));
		else
			setFilterButtons(
				TreeConfigurationUtil.getButtonConfig([
					FleetEntityTypeFilter.object,
				]));
	}, [props.templateEntityType, assetTracking]);

	const retrieveDataAsyncCallback = async (filter: SelectionFilter): Promise<ITreeNode[]> => {
		showInactiveRef.current = filter.showInactive;

		return await ajaxUtil.post<ITreeNode[]>(`${GlobalSettings.listsApi}/GetConfigurationGroupObjectsSelectionData`,
			{
			groupConfigurationId: props.configurationGroupId,
			customerId: filter.customerId,
			objectFunction:
				filter.selectedFilter in FleetEntityTypeFilter
					? TreeConfigurationUtil.getObjectFunctionFilter(filter.selectedFilter, assetTracking)
					: ObjectFunctionFilter.Full,
			showInactive: filter.showInactive,
			filterText: filter.filterText.trim(),
		});
	};

	const saveCallback = (tracked: ITreeNode[]): void => {
		props.includeInactiveCallback(showInactiveRef.current);

		props.saveCallback(tracked);
	};

	const closeCallback = () => {
		props.includeInactiveCallback(showInactiveRef.current);

		props.closeDialogCallback();
	};

	return {
		showInactive: showInactiveRef.current,
		retrieveDataAsyncCallback,
		saveCallback,
		closeCallback,
		filterButtons,
	};
};

