import '../configurationGroupView.scss'

import React, {useMemo} from 'react';

import {getAutocompleteEnumOptions, TranslateText} from "../../../../../utils/Translations";
import {Checkbox, Chip, FormControlLabel, InputLabel, TextField, Typography} from '@material-ui/core';
import {WidgetViewDefault} from "../../../Widget";
import {ConfigurationGroupSettingsEditViewProps} from "../types";
import {
	ConfigurationGroupSettingsEditDto,
	inputLabelAutocompleteEditStyle
} from "../ConfigurationGroupSettingsView";
import {
	CONFIGURATIONGROUPGENERALCAMERA, CONFIGURATIONGROUPMEDIA
} from "../../../../NewAddWizard/Views/ConfigurationGroup/constants";
import {AutocompleteItem} from "../../../../Common/Autocomplete/MaterialAutocomplete";
import {ValidationMessage} from "../../../../ValidationMessage/ValidationMessage";
import {Autocomplete} from '@material-ui/lab';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import {handleKeyDown} from "../../../../Common/RemoveSpinnerOptionInput";
import {ConfigurationGroupCameraBitMask} from "../../../../../models/ConfigurationGroupCameraBitMask";
import {ConfigurationGroupCameraResolution} from "../../../../../models/ConfigurationGroupCameraResolution";
import _ from 'lodash';
import { getNumberValue } from '../Utils';
import {verifyDifferences} from "../../ConfigurationGroupHistoryView/Utils";

type Props = WidgetViewDefault & ConfigurationGroupSettingsEditViewProps &{
	data: ConfigurationGroupSettingsEditDto;
};
const CameraView = (props: Props) => {
	const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
	const checkedIcon = <CheckBoxIcon fontSize="small" />;

	const CameraOptions = useMemo<AutocompleteItem[]>(
		() =>
			getAutocompleteEnumOptions(ConfigurationGroupCameraBitMask, 'configurationGroups.cameraBitMaskEnum.', true),
		[]
	);

	const ResolutionOptions = useMemo<AutocompleteItem[]>(
		() =>
			getAutocompleteEnumOptions(
				ConfigurationGroupCameraResolution,
				'configurationGroups.cameraResolutionEnum.',
				true
			),
		[]
	);

	return (
		<div className="container-configuration-group-edit">
			<div className={!props.isSingleColumn ? 'container-row' : 'container-column'}
			>
				<div className={!props.isSingleColumn ? 'col-md-6' : 'col-12'}>
					<div className="form-group">
						<FormControlLabel
							control={
								<Checkbox
									name="alwaysRecord"
									checked={props.data.configurationGroupGeneralCamera?.alwaysRecord ?? false}
									onChange={(event) => props.handleChange(event.target.checked, 'alwaysRecord', CONFIGURATIONGROUPGENERALCAMERA)}
								/>
							}
							label={
								<Typography
									style={{
										fontSize: 12,
										marginRight: 5,
										color: props.isForModal &&
										verifyDifferences(
											props.data,
											'alwaysRecord',
											CONFIGURATIONGROUPGENERALCAMERA,
											props.differences) ? props.colorLabel
											                   : props.editMode ? '#0000008A' : '#00000061',
									}}
								>
									{TranslateText('fields.alwaysRecord')}
								</Typography>
							}
							disabled={!props.editMode}
							className="m-0"
							labelPlacement="start"
						/>
					</div>
					<div className="form-group">
						<Autocomplete
							filterSelectedOptions={false}
							fullWidth={true}
							className="material-autocomplete"
							options={CameraOptions}
							autoHighlight
							getOptionLabel={(option) => option.display}
							noOptionsText={TranslateText('common.noRecordFound')}
							multiple
							id="checkboxes-tags-demo"
							disableCloseOnSelect
							disabled={!props.editMode}
							value={CameraOptions.filter((option) =>
								props.data?.configurationGroupGeneralCamera.camerasToRecord?.includes(
									ConfigurationGroupCameraBitMask[option.id as keyof typeof ConfigurationGroupCameraBitMask]
								)
							)}
							renderOption={(option, { selected }) => (
								<React.Fragment>
									<Checkbox
										icon={icon}
										checkedIcon={checkedIcon}
										style={{ marginRight: 8 }}
										checked={selected}
									/>
									{option.display}
								</React.Fragment>
							)}
							onChange={(event, newValue) => {
								const val: ConfigurationGroupCameraBitMask[] = Object.values(
									newValue as AutocompleteItem[]
								).map(
									(x) => ConfigurationGroupCameraBitMask[x.id as keyof typeof ConfigurationGroupCameraBitMask]
								);
								props.handleChange(val, 'camerasToRecord', CONFIGURATIONGROUPGENERALCAMERA);
							}}
							renderTags={(tagValue, getTagProps) =>
								tagValue.map((option, index) => (
									<Chip
										key={index}
										label={option.display}
										{...getTagProps({ index })}
										className="chip-selection"
									/>
								))
							}
							renderInput={(params) => (
								<>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
										<InputLabel
											style={{
												...inputLabelAutocompleteEditStyle(props.data?.configurationGroupGeneralCamera?.camerasToRecord, props.editMode),
												...(props.isForModal && verifyDifferences(props.data, 'camerasToRecord', CONFIGURATIONGROUPGENERALCAMERA, props.differences)
														? { color: props.colorLabel }
														: {}
												),
											}}
										>
											{TranslateText('fields.camerasToRecord')}
										</InputLabel>
										{!!props.validationResult?.camerasToRecord && (
											<ValidationMessage
												result={props.validationResult?.camerasToRecord}
												isForNewAddWizard={true}
											/>
										)}
									</div>

									<TextField name={'camerasToRecord'} margin={'none'} {...params} label={null} />
								</>
							)}
						/>
					</div>
					<div className="form-group">
						<Autocomplete
							filterSelectedOptions={false}
							fullWidth={true}
							className="material-autocomplete"
							options={CameraOptions}
							autoHighlight
							getOptionLabel={(option) => option.display}
							noOptionsText={TranslateText('common.noRecordFound')}
							multiple
							id="checkboxes-tags-demo"
							disableCloseOnSelect
							disabled={!props.editMode}
							value={CameraOptions.filter((option) =>
								props.data?.configurationGroupGeneralCamera.camerasToDisplay?.includes(
									ConfigurationGroupCameraBitMask[option.id as keyof typeof ConfigurationGroupCameraBitMask]
								)
							)}
							renderOption={(option, { selected }) => (
								<React.Fragment>
									<Checkbox
										icon={icon}
										checkedIcon={checkedIcon}
										style={{ marginRight: 8 }}
										checked={selected}
									/>
									{option.display}
								</React.Fragment>
							)}
							onChange={(event, newValue) => {
								const val: ConfigurationGroupCameraBitMask[] = Object.values(
									newValue as AutocompleteItem[]
								).map(
									(x) => ConfigurationGroupCameraBitMask[x.id as keyof typeof ConfigurationGroupCameraBitMask]
								);
								props.handleChange(val, 'camerasToDisplay', CONFIGURATIONGROUPGENERALCAMERA);
							}}
							renderTags={(tagValue, getTagProps) =>
								tagValue.map((option, index) => (
									<Chip
										key={index}
										label={option.display}
										{...getTagProps({ index })}
										className="chip-selection"
									/>
								))
							}
							renderInput={(params) => (
								<>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
										<InputLabel
											style={{
												...inputLabelAutocompleteEditStyle(props.data?.configurationGroupGeneralCamera?.camerasToDisplay, props.editMode),
												...(props.isForModal && verifyDifferences(props.data, 'camerasToDisplay', CONFIGURATIONGROUPGENERALCAMERA, props.differences)
														? { color: props.colorLabel }
														: {}
												),
											}}
										>
											{TranslateText('fields.camerasToDisplay')}
										</InputLabel>
										{!!props.validationResult?.camerasToDisplay && (
											<ValidationMessage
												result={props.validationResult?.camerasToDisplay}
												isForNewAddWizard={true}
											/>
										)}
									</div>

									<TextField name={'camerasToDisplay'} margin={'none'} {...params} label={null} />
								</>
							)}
						/>
					</div>
					<div className="form-group">
						<FormControlLabel
							control={
								<Checkbox
									name="enableOsd"
									checked={props.data.configurationGroupGeneralCamera?.enableOsd ?? false}
									onChange={(event) => props.handleChange(event.target.checked, 'enableOsd', CONFIGURATIONGROUPGENERALCAMERA)}
								/>
							}
							label={
								<Typography
									style={{
										fontSize: 12,
										marginRight: 5,
										color: props.isForModal &&
										verifyDifferences(
											props.data,
											'enableOsd',
											CONFIGURATIONGROUPGENERALCAMERA,
											props.differences) ? props.colorLabel
											: props.editMode ? '#0000008A' : '#00000061',
									}}
								>
									{TranslateText('fields.enableOsd')}
								</Typography>
							}
							disabled={!props.editMode}
							className="m-0"
							labelPlacement="start"
						/>
					</div>
					<div className="form-group">
						<FormControlLabel
							control={
								<Checkbox
									name="useRankingForOverlay"
									checked={props.data.configurationGroupGeneralCamera?.useRankingForOverlay ?? false}
									onChange={(event) => props.handleChange(event.target.checked, 'useRankingForOverlay', CONFIGURATIONGROUPGENERALCAMERA)}
								/>
							}
							label={
								<Typography
									style={{
										fontSize: 12,
										marginRight: 5,
										color: props.isForModal &&
										verifyDifferences(
											props.data,
											'useRankingForOverlay',
											CONFIGURATIONGROUPGENERALCAMERA,
											props.differences) ? props.colorLabel
											: props.editMode ? '#0000008A' : '#00000061',
									}}
								>
									{TranslateText('fields.useRankingForOverlay')}
								</Typography>
							}
							disabled={!props.editMode}
							className="m-0"
							labelPlacement="start"
						/>
					</div>
					<div className="form-group">
						<FormControlLabel
							control={
								<Checkbox
									name="overlayFrontCameraAfterIgnitionOn"
									checked={props.data.configurationGroupGeneralCamera?.overlayFrontCameraAfterIgnitionOn ?? false}
									onChange={(event) => props.handleChange(event.target.checked, 'overlayFrontCameraAfterIgnitionOn', CONFIGURATIONGROUPGENERALCAMERA)
									}
								/>
							}
							label={
								<Typography
									style={{
										fontSize: 12,
										marginRight: 5,
										color: props.isForModal &&
										verifyDifferences(
											props.data,
											'overlayFrontCameraAfterIgnitionOn',
											CONFIGURATIONGROUPGENERALCAMERA,
											props.differences) ? props.colorLabel
											                   : props.editMode ? '#0000008A' : '#00000061',
									}}
								>
									{TranslateText('fields.overlayFrontCameraAfterIgnitionOn')}
								</Typography>
							}
							disabled={!props.editMode}
							className="m-0"
							labelPlacement="start"
						/>
					</div>
					<div className="form-group">
						<Autocomplete
							filterSelectedOptions={false}
							fullWidth={true}
							className="material-autocomplete"
							options={ResolutionOptions}
							autoHighlight
							getOptionLabel={(option) => option.display}
							noOptionsText={TranslateText('common.noRecordFound')}
							id="checkboxes-tags-demo"
							disableCloseOnSelect
							disabled={!props.editMode}
							value={
								props.data?.configurationGroupGeneralCamera?.configurationGroupCamera1?.resolution === null
									? null
									: ResolutionOptions.find(
										(option) =>
											props.data?.configurationGroupGeneralCamera?.configurationGroupCamera1?.resolution === option.display
									)
							}
							renderOption={(option, { selected }) => (
								<React.Fragment>
									<Checkbox
										icon={icon}
										checkedIcon={checkedIcon}
										style={{ marginRight: 8 }}
										checked={selected}
									/>
									{option.display}
								</React.Fragment>
							)}
							onChange={(event, newValue, reason) => {
								const clonedData = _.cloneDeep(props.data);
								if (reason === 'clear') {
									clonedData.configurationGroupGeneralCamera.configurationGroupCamera1.resolution = null;
								} else {
									const val = newValue as AutocompleteItem;
									clonedData.configurationGroupGeneralCamera.configurationGroupCamera1.resolution = val?.display;
								}
								props.changeDataCallback(clonedData);
							}}
							renderTags={(tagValue, getTagProps) =>
								tagValue.map((option, index) => (
									<Chip
										key={index}
										label={option.display}
										{...getTagProps({ index })}
										className="chip-selection"
									/>
								))
							}
							renderInput={(params) => (
								<>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
										<InputLabel
											style={{
												...inputLabelAutocompleteEditStyle(props.data?.configurationGroupGeneralCamera?.configurationGroupCamera1?.resolution, props.editMode),
												...(props.isForModal && verifyDifferences(props.data, 'resolution', 'configurationGroupCamera1', props.differences)
														? { color: props.colorLabel }
														: {}
												),
											}}
										>
											{TranslateText('fields.resolution')}
										</InputLabel>
										{!!props.validationResult?.resolution && (
											<ValidationMessage result={props.validationResult?.resolution} isForNewAddWizard={true} />
										)}
									</div>

									<TextField name={'resolution'} margin={'none'} {...params} label={null} />
								</>
							)}
						/>
					</div>
					<div className="form-group">
						<FormControlLabel
							control={
								<Checkbox
									name="mirrored"
									checked={props.data.configurationGroupGeneralCamera?.configurationGroupCamera1?.mirrored ?? false}
									onChange={(event) =>
										props.handleChange(event.target.checked, 'mirrored', 'configurationGroupCamera1')
									}
								/>
							}
							label={
								<Typography
									style={{
										fontSize: 12,
										marginRight: 5,
										color: props.isForModal &&
										verifyDifferences(
											props.data,
											'mirrored',
											'configurationGroupCamera1',
											props.differences) ? props.colorLabel
											: props.editMode ? '#0000008A' : '#00000061',
									}}
								>
									{TranslateText('fields.mirrored')}
								</Typography>
							}
							disabled={!props.editMode}
							className="m-0"
							labelPlacement="start"
						/>
					</div>
					<div className="form-group">
						<FormControlLabel
							control={
								<Checkbox
									name="io"
									checked={props.data.configurationGroupGeneralCamera?.configurationGroupCamera1?.io ?? false}
									onChange={(event) => props.handleChange(event.target.checked, 'io', 'configurationGroupCamera1')}
								/>
							}
							label={
								<Typography
									style={{
										fontSize: 12,
										marginRight: 5,
										color: props.isForModal &&
										verifyDifferences(
											props.data,
											'io',
											'configurationGroupCamera1',
											props.differences) ? props.colorLabel
											: props.editMode ? '#0000008A' : '#00000061',
									}}
								>
									{TranslateText('fields.io')}
								</Typography>
							}
							disabled={!props.editMode}
							className="m-0"
							labelPlacement="start"
						/>
					</div>
					<div className="form-group">
						<TextField
							id="rank"
							type="number"
							className="resize-font noSpinner"
							fullWidth
							label={TranslateText('fields.rank')}
							InputLabelProps={{
								style:
									props.isForModal &&
									verifyDifferences(
										props.data,
										'rank',
										'configurationGroupCamera1',
										props.differences) ? { color: props.colorLabel } : {},
							}}
							inputProps={{
								readOnly: !props.editMode,
								style: { fontSize: 12 },
								onKeyPress: (event: any) => {
									if (event.key === 'e') {
										event.preventDefault();
									}
								}}}
							name="rank"
							value={props.data.configurationGroupGeneralCamera?.configurationGroupCamera1?.rank}
							onChange={(e) => {
								props.handleChange(getNumberValue(e.target.value), 'rank', 'configurationGroupCamera1');
							}}
							disabled={!props.editMode}
							onKeyDown={handleKeyDown}
							onWheel={(e: any) => e.target.blur()}
						/>
						<ValidationMessage result={props.validationResult?.rank} />
					</div>
					<div className="form-group">
						<TextField
							id="hideDelay"
							type="number"
							className="resize-font noSpinner"
							fullWidth
							label={TranslateText('fields.hideDelay')}
							InputLabelProps={{
								style:
									props.isForModal &&
									verifyDifferences(
										props.data,
										'hideDelay',
										'configurationGroupCamera1',
										props.differences) ? { color: props.colorLabel } : {},
							}}
							inputProps={{
								readOnly: !props.editMode,
								style: { fontSize: 12 },
								onKeyPress: (event: any) => {
									if (event.key === 'e') {
										event.preventDefault();
									}
								}}}
							name="hideDelay"
							value={props.data.configurationGroupGeneralCamera?.configurationGroupCamera1?.hideDelay}
							onChange={(e) => {
								props.handleChange(getNumberValue(e.target.value), 'hideDelay', 'configurationGroupCamera1');
							}}
							disabled={!props.editMode}
							onKeyDown={handleKeyDown}
							onWheel={(e: any) => e.target.blur()}
						/>
						<ValidationMessage result={props.validationResult?.hideDelay} />
					</div>
					<div className="form-group">
						<Autocomplete
							filterSelectedOptions={false}
							fullWidth={true}
							className="material-autocomplete"
							options={CameraOptions}
							autoHighlight
							getOptionLabel={(option) => option.display}
							noOptionsText={TranslateText('common.noRecordFound')}
							multiple
							id="checkboxes-tags-demo"
							disableCloseOnSelect
							disabled={!props.editMode}
							value={CameraOptions.filter((option) =>
								props.data?.configurationGroupGeneralCamera.configurationGroupCamera1.overlayActiveCameras?.includes(
									ConfigurationGroupCameraBitMask[option.id as keyof typeof ConfigurationGroupCameraBitMask]
								)
							)}
							renderOption={(option, { selected }) => (
								<React.Fragment>
									<Checkbox
										icon={icon}
										checkedIcon={checkedIcon}
										style={{ marginRight: 8 }}
										checked={selected}
									/>
									{option.display}
								</React.Fragment>
							)}
							onChange={(event, newValue) => {
								const val: ConfigurationGroupCameraBitMask[] = Object.values(
									newValue as AutocompleteItem[]
								).map(
									(x) => ConfigurationGroupCameraBitMask[x.id as keyof typeof ConfigurationGroupCameraBitMask]
								);
								props.handleChange(val, 'overlayActiveCameras', 'configurationGroupCamera1');
							}}
							renderTags={(tagValue, getTagProps) =>
								tagValue.map((option, index) => (
									<Chip
										key={index}
										label={option.display}
										{...getTagProps({ index })}
										className="chip-selection"
									/>
								))
							}
							renderInput={(params) => (
								<>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
										<InputLabel
											style={{
												...inputLabelAutocompleteEditStyle(props.data?.configurationGroupGeneralCamera?.configurationGroupCamera1?.overlayActiveCameras, props.editMode),
												...(props.isForModal && verifyDifferences(props.data, 'overlayActiveCameras', 'configurationGroupCamera1', props.differences)
														? { color: props.colorLabel }
														: {}
												),
											}}
										>
											{TranslateText('fields.overlayActiveCameras')}
										</InputLabel>
										{!!props.validationResult?.overlayActiveCameras && (
											<ValidationMessage
												result={props.validationResult?.overlayActiveCameras}
												isForNewAddWizard={true}
											/>
										)}
									</div>

									<TextField name={'overlayActiveCameras'} margin={'none'} {...params} label={null} />
								</>
							)}
						/>
					</div>
					<div className="form-group">
						<Autocomplete
							filterSelectedOptions={false}
							fullWidth={true}
							className="material-autocomplete"
							options={ResolutionOptions}
							autoHighlight
							getOptionLabel={(option) => option.display}
							noOptionsText={TranslateText('common.noRecordFound')}
							id="checkboxes-tags-demo"
							disableCloseOnSelect
							disabled={!props.editMode}
							value={props.data?.configurationGroupGeneralCamera?.configurationGroupCamera2?.resolution === null
								? null
								: ResolutionOptions.find(
									(option) =>
										props.data?.configurationGroupGeneralCamera?.configurationGroupCamera2?.resolution === option.display
								)
							}
							renderOption={(option, { selected }) => (
								<React.Fragment>
									<Checkbox
										icon={icon}
										checkedIcon={checkedIcon}
										style={{ marginRight: 8 }}
										checked={selected}
									/>
									{option.display}
								</React.Fragment>
							)}
							onChange={(event, newValue, reason) => {
								if (reason === 'clear') {
									props.handleChange(null, 'resolution', 'configurationGroupCamera2');
								} else {
									const val = newValue as AutocompleteItem;
									props.handleChange(val?.display, 'resolution', 'configurationGroupCamera2');
								}
							}}
							renderTags={(tagValue, getTagProps) =>
								tagValue.map((option, index) => (
									<Chip
										key={index}
										label={option.display}
										{...getTagProps({ index })}
										className="chip-selection"
									/>
								))
							}
							renderInput={(params) => (
								<>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
										<InputLabel
											style={{
												...inputLabelAutocompleteEditStyle(props.data?.configurationGroupGeneralCamera?.configurationGroupCamera2?.resolution, props.editMode),
												...(props.isForModal && verifyDifferences(props.data, 'resolution', 'configurationGroupCamera2', props.differences)
														? { color: props.colorLabel }
														: {}
												),
											}}
										>
											{TranslateText('fields.resolution')}
										</InputLabel>
										{!!props.validationResult?.resolution && (
											<ValidationMessage result={props.validationResult?.resolution} isForNewAddWizard={true} />
										)}
									</div>

									<TextField name={'resolution'} margin={'none'} {...params} label={null} />
								</>
							)}
						/>
					</div>
					<div className="form-group">
						<FormControlLabel
							control={
								<Checkbox
									name="mirrored"
									checked={props.data.configurationGroupGeneralCamera?.configurationGroupCamera2?.mirrored ?? false}
									onChange={(event) =>
										props.handleChange(event.target.checked, 'mirrored', 'configurationGroupCamera2')
									}
								/>
							}
							label={
								<Typography
									style={{
										fontSize: 12,
										marginRight: 5,
										color: props.isForModal &&
										verifyDifferences(
											props.data,
											'mirrored',
											'configurationGroupCamera2',
											props.differences) ? props.colorLabel
											: props.editMode ? '#0000008A' : '#00000061',
									}}
								>
									{TranslateText('fields.mirrored')}
								</Typography>
							}
							disabled={!props.editMode}
							className="m-0"
							labelPlacement="start"
						/>
					</div>
					<div className="form-group">
						<FormControlLabel
							control={
								<Checkbox
									name="io"
									checked={props.data.configurationGroupGeneralCamera?.configurationGroupCamera2?.io ?? false}
									onChange={(event) => props.handleChange(event.target.checked, 'io', 'configurationGroupCamera2')}
								/>
							}
							label={
								<Typography
									style={{
										fontSize: 12,
										marginRight: 5,
										color: props.isForModal &&
										verifyDifferences(
											props.data,
											'io',
											'configurationGroupCamera2',
											props.differences) ? props.colorLabel
											: props.editMode ? '#0000008A' : '#00000061',
									}}
								>
									{TranslateText('fields.io')}
								</Typography>
							}
							disabled={!props.editMode}
							className="m-0"
							labelPlacement="start"
						/>
					</div>
				</div>
				<div className={!props.isSingleColumn ? 'col-md-6' : 'col-12'}>
					<div className="form-group">
						<TextField
							id="rank"
							type="number"
							className="resize-font noSpinner"
							fullWidth
							label={TranslateText('fields.rank')}
							InputLabelProps={{
								style:
									props.isForModal &&
									verifyDifferences(
										props.data,
										'rank',
										'configurationGroupCamera2',
										props.differences) ? { color: props.colorLabel } : {},
							}}
							inputProps={{
								readOnly: !props.editMode,
								style: { fontSize: 12 },
								onKeyPress: (event: any) => {
									if (event.key === 'e') {
										event.preventDefault();
									}
								}}}
							name="rank"
							value={props.data.configurationGroupGeneralCamera?.configurationGroupCamera2?.rank}
							onChange={(e) => {
								props.handleChange(getNumberValue(e.target.value), 'rank', 'configurationGroupCamera2');
							}}
							disabled={!props.editMode}
							onKeyDown={handleKeyDown}
							onWheel={(e: any) => e.target.blur()}
						/>
						<ValidationMessage result={props.validationResult?.rank} />
					</div>
					<div className="form-group">
						<TextField
							id="hideDelay"
							type="number"
							className="resize-font noSpinner"
							fullWidth
							label={TranslateText('fields.hideDelay')}
							InputLabelProps={{
								style:
									props.isForModal &&
									verifyDifferences(
										props.data,
										'hideDelay',
										'configurationGroupCamera2',
										props.differences) ? { color: props.colorLabel } : {},
							}}
							inputProps={{
								readOnly: !props.editMode,
								style: { fontSize: 12 },
								onKeyPress: (event: any) => {
									if (event.key === 'e') {
										event.preventDefault();
									}
								}}}
							name="hideDelay"
							value={props.data.configurationGroupGeneralCamera?.configurationGroupCamera2?.hideDelay}
							onChange={(e) => {
								props.handleChange(getNumberValue(e.target.value), 'hideDelay', 'configurationGroupCamera2');
							}}
							disabled={!props.editMode}
							onKeyDown={handleKeyDown}
							onWheel={(e: any) => e.target.blur()}
						/>
						<ValidationMessage result={props.validationResult?.hideDelay} />
					</div>
					<div className="form-group">
						<Autocomplete
							filterSelectedOptions={false}
							fullWidth={true}
							className="material-autocomplete"
							options={CameraOptions}
							autoHighlight
							getOptionLabel={(option) => option.display}
							noOptionsText={TranslateText('common.noRecordFound')}
							multiple
							id="checkboxes-tags-demo"
							disableCloseOnSelect
							disabled={!props.editMode}
							value={CameraOptions.filter((option) =>
								props.data?.configurationGroupGeneralCamera.configurationGroupCamera2.overlayActiveCameras?.includes(
									ConfigurationGroupCameraBitMask[option.id as keyof typeof ConfigurationGroupCameraBitMask]
								)
							)}
							renderOption={(option, { selected }) => (
								<React.Fragment>
									<Checkbox
										icon={icon}
										checkedIcon={checkedIcon}
										style={{ marginRight: 8 }}
										checked={selected}
									/>
									{option.display}
								</React.Fragment>
							)}
							onChange={(event, newValue) => {
								const val: ConfigurationGroupCameraBitMask[] = Object.values(
									newValue as AutocompleteItem[]
								).map(
									(x) => ConfigurationGroupCameraBitMask[x.id as keyof typeof ConfigurationGroupCameraBitMask]
								);
								props.handleChange(val, 'overlayActiveCameras', 'configurationGroupCamera2');
							}}
							renderTags={(tagValue, getTagProps) =>
								tagValue.map((option, index) => (
									<Chip
										key={index}
										label={option.display}
										{...getTagProps({ index })}
										className="chip-selection"
									/>
								))
							}
							renderInput={(params) => (
								<>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
										<InputLabel
											style={{
												...inputLabelAutocompleteEditStyle(props.data?.configurationGroupGeneralCamera?.configurationGroupCamera2?.overlayActiveCameras, props.editMode),
												...(props.isForModal && verifyDifferences(props.data, 'overlayActiveCameras', 'configurationGroupCamera2', props.differences)
														? { color: props.colorLabel }
														: {}
												),
											}}
										>
											{TranslateText('fields.overlayActiveCameras')}
										</InputLabel>
										{!!props.validationResult?.overlayActiveCameras && (
											<ValidationMessage
												result={props.validationResult?.overlayActiveCameras}
												isForNewAddWizard={true}
											/>
										)}
									</div>

									<TextField name={'overlayActiveCameras'} margin={'none'} {...params} label={null} />
								</>
							)}
						/>
					</div>
					<div className="form-group">
						<Autocomplete
							filterSelectedOptions={false}
							fullWidth={true}
							className="material-autocomplete"
							options={ResolutionOptions}
							autoHighlight
							getOptionLabel={(option) => option.display}
							noOptionsText={TranslateText('common.noRecordFound')}
							id="checkboxes-tags-demo"
							disableCloseOnSelect
							disabled={!props.editMode}
							value={props.data?.configurationGroupGeneralCamera?.configurationGroupCamera3?.resolution == null
								? null
								: ResolutionOptions.find(
									(option) =>
										props.data?.configurationGroupGeneralCamera?.configurationGroupCamera3?.resolution === option.display
								)}
							renderOption={(option, { selected }) => (
								<React.Fragment>
									<Checkbox
										icon={icon}
										checkedIcon={checkedIcon}
										style={{ marginRight: 8 }}
										checked={selected}
									/>
									{option.display}
								</React.Fragment>
							)}
							onChange={(event, newValue, reason) => {
								if (reason === 'clear') {
									props.handleChange(null, 'resolution', 'configurationGroupCamera3');
								} else {
									const val = newValue as AutocompleteItem;
									props.handleChange(val?.display, 'resolution', 'configurationGroupCamera3');
								}
							}}
							renderTags={(tagValue, getTagProps) =>
								tagValue.map((option, index) => (
									<Chip
										key={index}
										label={option.display}
										{...getTagProps({ index })}
										className="chip-selection"
									/>
								))
							}
							renderInput={(params) => (
								<>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
										<InputLabel
											style={{
												...inputLabelAutocompleteEditStyle(props.data?.configurationGroupGeneralCamera?.configurationGroupCamera3?.resolution, props.editMode),
												...(props.isForModal && verifyDifferences(props.data, 'resolution', 'configurationGroupCamera3', props.differences)
														? { color: props.colorLabel }
														: {}
												),
											}}
										>
											{TranslateText('fields.resolution')}
										</InputLabel>
										{!!props.validationResult?.resolution && (
											<ValidationMessage result={props.validationResult?.resolution} isForNewAddWizard={true} />
										)}
									</div>

									<TextField name={'resolution'} margin={'none'} {...params} label={null} />
								</>
							)}
						/>
					</div>
					<div className="form-group">
						<FormControlLabel
							control={
								<Checkbox
									name="mirrored"
									checked={props.data.configurationGroupGeneralCamera?.configurationGroupCamera3?.mirrored ?? false}
									onChange={(event) =>
										props.handleChange(event.target.checked, 'mirrored', 'configurationGroupCamera3')
									}
								/>
							}
							label={
								<Typography
									style={{
										fontSize: 12,
										marginRight: 5,
										color: props.isForModal &&
										verifyDifferences(
											props.data,
											'mirrored',
											'configurationGroupCamera3',
											props.differences) ? props.colorLabel
											: props.editMode ? '#0000008A' : '#00000061',
									}}
								>
									{TranslateText('fields.mirrored')}
								</Typography>
							}
							disabled={!props.editMode}
							className="m-0"
							labelPlacement="start"
						/>
					</div>
					<div className="form-group">
						<FormControlLabel
							control={
								<Checkbox
									name="io"
									checked={props.data.configurationGroupGeneralCamera?.configurationGroupCamera3?.io ?? false}
									onChange={(event) => props.handleChange(event.target.checked, 'io', 'configurationGroupCamera3')}
								/>
							}
							label={
								<Typography
									style={{
										fontSize: 12,
										marginRight: 5,
										color: props.isForModal &&
										verifyDifferences(
											props.data,
											'io',
											'configurationGroupCamera3',
											props.differences) ? props.colorLabel
											: props.editMode ? '#0000008A' : '#00000061',
									}}
								>
									{TranslateText('fields.io')}
								</Typography>
							}
							disabled={!props.editMode}
							className="m-0"
							labelPlacement="start"
						/>
					</div>
					<div className="form-group">
						<TextField
							id="rank"
							type="number"
							className="resize-font noSpinner"
							fullWidth
							label={TranslateText('fields.rank')}
							InputLabelProps={{
								style:
									props.isForModal &&
									verifyDifferences(
										props.data,
										'rank',
										'configurationGroupCamera3',
										props.differences) ? { color: props.colorLabel } : {},
							}}
							inputProps={{
								readOnly: !props.editMode,
								style: { fontSize: 12 },
								onKeyPress: (event: any) => {
									if (event.key === 'e') {
										event.preventDefault();
									}
								}}}
							name="rank"
							value={props.data.configurationGroupGeneralCamera?.configurationGroupCamera3?.rank}
							onChange={(e) => {
								props.handleChange(getNumberValue(e.target.value), 'rank', 'configurationGroupCamera3');
							}}
							disabled={!props.editMode}
							onKeyDown={handleKeyDown}
							onWheel={(e: any) => e.target.blur()}
						/>
						<ValidationMessage result={props.validationResult?.rank} />
					</div>
					<div className="form-group">
						<TextField
							id="hideDelay"
							type="number"
							className="resize-font noSpinner"
							fullWidth
							label={TranslateText('fields.hideDelay')}
							InputLabelProps={{
								style:
									props.isForModal &&
									verifyDifferences(
										props.data,
										'hideDelay',
										'configurationGroupCamera3',
										props.differences) ? { color: props.colorLabel } : {},
							}}
							inputProps={{
								readOnly: !props.editMode,
								style: { fontSize: 12 },
								onKeyPress: (event: any) => {
									if (event.key === 'e') {
										event.preventDefault();
									}
								}}}
							name="hideDelay"
							value={props.data.configurationGroupGeneralCamera?.configurationGroupCamera3?.hideDelay}
							onChange={(e) => {
								props.handleChange(getNumberValue(e.target.value), 'hideDelay', 'configurationGroupCamera3');
							}}
							disabled={!props.editMode}
							onKeyDown={handleKeyDown}
							onWheel={(e: any) => e.target.blur()}
						/>
						<ValidationMessage result={props.validationResult?.hideDelay} />
					</div>
					<div className="form-group">
						<Autocomplete
							filterSelectedOptions={false}
							fullWidth={true}
							className="material-autocomplete"
							options={CameraOptions}
							autoHighlight
							getOptionLabel={(option) => option.display}
							noOptionsText={TranslateText('common.noRecordFound')}
							multiple
							id="checkboxes-tags-demo"
							disableCloseOnSelect
							disabled={!props.editMode}
							value={CameraOptions.filter((option) =>
								props.data?.configurationGroupGeneralCamera.configurationGroupCamera3.overlayActiveCameras?.includes(
									ConfigurationGroupCameraBitMask[option.id as keyof typeof ConfigurationGroupCameraBitMask]
								)
							)}
							renderOption={(option, { selected }) => (
								<React.Fragment>
									<Checkbox
										icon={icon}
										checkedIcon={checkedIcon}
										style={{ marginRight: 8 }}
										checked={selected}
									/>
									{option.display}
								</React.Fragment>
							)}
							onChange={(event, newValue) => {
								const val: ConfigurationGroupCameraBitMask[] = Object.values(
									newValue as AutocompleteItem[]
								).map(
									(x) => ConfigurationGroupCameraBitMask[x.id as keyof typeof ConfigurationGroupCameraBitMask]
								);
								props.handleChange(val, 'overlayActiveCameras', 'configurationGroupCamera3');
							}}
							renderTags={(tagValue, getTagProps) =>
								tagValue.map((option, index) => (
									<Chip
										key={index}
										label={option.display}
										{...getTagProps({ index })}
										className="chip-selection"
									/>
								))
							}
							renderInput={(params) => (
								<>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
										<InputLabel
											style={{
												...inputLabelAutocompleteEditStyle(props.data?.configurationGroupGeneralCamera?.configurationGroupCamera3?.overlayActiveCameras, props.editMode),
												...(props.isForModal && verifyDifferences(props.data, 'overlayActiveCameras', 'configurationGroupCamera3', props.differences)
														? { color: props.colorLabel }
														: {}
												),
											}}
										>
											{TranslateText('fields.overlayActiveCameras')}
										</InputLabel>
										{!!props.validationResult?.overlayActiveCameras && (
											<ValidationMessage
												result={props.validationResult?.overlayActiveCameras}
												isForNewAddWizard={true}
											/>
										)}
									</div>

									<TextField name={'overlayActiveCameras'} margin={'none'} {...params} label={null} />
								</>
							)}
						/>
					</div>
					<div className="form-group">
						<Autocomplete
							filterSelectedOptions={false}
							fullWidth={true}
							className="material-autocomplete"
							options={ResolutionOptions}
							autoHighlight
							getOptionLabel={(option) => option.display}
							noOptionsText={TranslateText('common.noRecordFound')}
							id="checkboxes-tags-demo"
							disableCloseOnSelect
							disabled={!props.editMode}
							value={props.data?.configurationGroupGeneralCamera?.configurationGroupCamera4?.resolution === null
								? null
								: ResolutionOptions.find(
									(option) =>
										props.data?.configurationGroupGeneralCamera?.configurationGroupCamera4?.resolution === option.display
								)}
							renderOption={(option, { selected }) => (
								<React.Fragment>
									<Checkbox
										icon={icon}
										checkedIcon={checkedIcon}
										style={{ marginRight: 8 }}
										checked={selected}
									/>
									{option.display}
								</React.Fragment>
							)}
							onChange={(event, newValue, reason) => {
								if (reason === 'clear') {
									props.handleChange('', 'resolution', 'configurationGroupCamera4');
								} else {
									const val = newValue as AutocompleteItem;
									props.handleChange(val?.display, 'resolution', 'configurationGroupCamera4');
								}
							}}
							renderTags={(tagValue, getTagProps) =>
								tagValue.map((option, index) => (
									<Chip
										key={index}
										label={option.display}
										{...getTagProps({ index })}
										className="chip-selection"
									/>
								))
							}
							renderInput={(params) => (
								<>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
										<InputLabel
											style={{
												...inputLabelAutocompleteEditStyle(props.data?.configurationGroupGeneralCamera?.configurationGroupCamera4?.resolution, props.editMode),
												...(props.isForModal && verifyDifferences(props.data, 'resolution', 'configurationGroupCamera4', props.differences)
														? { color: props.colorLabel }
														: {}
												),
											}}
										>
											{TranslateText('fields.resolution')}
										</InputLabel>
										{!!props.validationResult?.resolution && (
											<ValidationMessage result={props.validationResult?.resolution} isForNewAddWizard={true} />
										)}
									</div>

									<TextField name={'resolution'} margin={'none'} {...params} label={null} />
								</>
							)}
						/>
					</div>
					<div className="form-group">
						<FormControlLabel
							control={
								<Checkbox
									name="mirrored"
									checked={props.data.configurationGroupGeneralCamera?.configurationGroupCamera4?.mirrored}
									onChange={(event) =>
										props.handleChange(event.target.checked, 'mirrored', 'configurationGroupCamera4')
									}
								/>
							}
							label={
								<Typography
									style={{
										fontSize: 12,
										marginRight: 5,
										color: props.isForModal &&
										verifyDifferences(
											props.data,
											'mirrored',
											'configurationGroupCamera4',
											props.differences) ? props.colorLabel
											: props.editMode ? '#0000008A' : '#00000061',
									}}
								>
									{TranslateText('fields.mirrored')}
								</Typography>
							}
							disabled={!props.editMode}
							className="m-0"
							labelPlacement="start"
						/>
					</div>
					<div className="form-group">
						<FormControlLabel
							control={
								<Checkbox
									name="io"
									checked={props.data.configurationGroupGeneralCamera?.configurationGroupCamera4?.io ?? false}
									onChange={(event) => props.handleChange(event.target.checked, 'io', 'configurationGroupCamera4')}
								/>
							}
							label={
								<Typography
									style={{
										fontSize: 12,
										marginRight: 5,
										color: props.isForModal &&
										verifyDifferences(
											props.data,
											'io',
											'configurationGroupCamera4',
											props.differences) ? props.colorLabel
											: props.editMode ? '#0000008A' : '#00000061',
									}}
								>
									{TranslateText('fields.io')}
								</Typography>
							}
							disabled={!props.editMode}
							className="m-0"
							labelPlacement="start"
						/>
					</div>
					<div className="form-group">
						<TextField
							id="rank"
							type="number"
							className="resize-font noSpinner"
							fullWidth
							label={TranslateText('fields.rank')}
							InputLabelProps={{
								style:
									props.isForModal &&
									verifyDifferences(
										props.data,
										'rank',
										'configurationGroupCamera4',
										props.differences) ? { color: props.colorLabel } : {},
							}}
							inputProps={{
								readOnly: !props.editMode,
								style: { fontSize: 12 },
								onKeyPress: (event: any) => {
									if (event.key === 'e') {
										event.preventDefault();
									}
								}}}
							name="rank"
							value={props.data.configurationGroupGeneralCamera?.configurationGroupCamera4?.rank}
							onChange={(e) => {
								props.handleChange(getNumberValue(e.target.value), 'rank', 'configurationGroupCamera4');
							}}
							disabled={!props.editMode}
							onKeyDown={handleKeyDown}
							onWheel={(e: any) => e.target.blur()}
						/>
						<ValidationMessage result={props.validationResult?.rank} />
					</div>
					<div className="form-group">
						<TextField
							id="hideDelay"
							type="number"
							className="resize-font noSpinner"
							fullWidth
							label={TranslateText('fields.hideDelay')}
							InputLabelProps={{
								style:
									props.isForModal &&
									verifyDifferences(
										props.data,
										'hideDelay',
										'configurationGroupCamera4',
										props.differences) ? { color: props.colorLabel } : {},
							}}
							inputProps={{
								readOnly: !props.editMode,
								style: { fontSize: 12 },
								onKeyPress: (event: any) => {
									if (event.key === 'e') {
										event.preventDefault();
									}
								}}}
							name="hideDelay"
							value={props.data.configurationGroupGeneralCamera?.configurationGroupCamera4?.hideDelay}
							onChange={(e) => {
								props.handleChange(getNumberValue(e.target.value), 'hideDelay', 'configurationGroupCamera4');
							}}
							disabled={!props.editMode}
							onKeyDown={handleKeyDown}
							onWheel={(e: any) => e.target.blur()}
						/>
						<ValidationMessage result={props.validationResult?.hideDelay} />
					</div>
					<div className="form-group">
						<Autocomplete
							filterSelectedOptions={false}
							fullWidth={true}
							className="material-autocomplete"
							options={CameraOptions}
							autoHighlight
							getOptionLabel={(option) => option.display}
							noOptionsText={TranslateText('common.noRecordFound')}
							multiple
							id="checkboxes-tags-demo"
							disableCloseOnSelect
							disabled={!props.editMode}
							value={CameraOptions.filter((option) =>
								props.data?.configurationGroupGeneralCamera.configurationGroupCamera4.overlayActiveCameras?.includes(
									ConfigurationGroupCameraBitMask[option.id as keyof typeof ConfigurationGroupCameraBitMask]
								)
							)}
							renderOption={(option, { selected }) => (
								<React.Fragment>
									<Checkbox
										icon={icon}
										checkedIcon={checkedIcon}
										style={{ marginRight: 8 }}
										checked={selected}
									/>
									{option.display}
								</React.Fragment>
							)}
							onChange={(event, newValue) => {
								const val: ConfigurationGroupCameraBitMask[] = Object.values(
									newValue as AutocompleteItem[]
								).map(
									(x) => ConfigurationGroupCameraBitMask[x.id as keyof typeof ConfigurationGroupCameraBitMask]
								);
								props.handleChange(val, 'overlayActiveCameras', 'configurationGroupCamera4');
							}}
							renderTags={(tagValue, getTagProps) =>
								tagValue.map((option, index) => (
									<Chip
										key={index}
										label={option.display}
										{...getTagProps({ index })}
										className="chip-selection"
									/>
								))
							}
							renderInput={(params) => (
								<>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
										<InputLabel
											style={{
												...inputLabelAutocompleteEditStyle(props.data?.configurationGroupGeneralCamera?.configurationGroupCamera4?.overlayActiveCameras, props.editMode),
												...(props.isForModal && verifyDifferences(props.data, 'overlayActiveCameras', 'configurationGroupCamera4', props.differences)
														? { color: props.colorLabel }
														: {}
												),
											}}
										>
											{TranslateText('fields.overlayActiveCameras')}
										</InputLabel>
										{!!props.validationResult?.overlayActiveCameras && (
											<ValidationMessage
												result={props.validationResult?.overlayActiveCameras}
												isForNewAddWizard={true}
											/>
										)}
									</div>

									<TextField name={'overlayActiveCameras'} margin={'none'} {...params} label={null} />
								</>
							)}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
export default CameraView;
