enum AuthenticationTypeEnum {
	None = 0,
	Fixed = 1,
	IButton = 2,
	MiFare = 3,
	Keypad = 4,	
	NFC = 5,
	TachoId = 6
}

export default AuthenticationTypeEnum;
