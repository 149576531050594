import '../ConfigurationGroupStyle.scss'

import { AutocompleteItem } from 'components/Common/Autocomplete/MaterialAutocomplete';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import { ConfigurationGroupCameraBitMask } from 'models/ConfigurationGroupCameraBitMask';
import { ConfigurationGroupCameraResolution } from 'models/ConfigurationGroupCameraResolution';
import React, { useMemo } from 'react';
import MaterialTextField from 'shared/components/MaterialTextField/MaterialTextField';
import { getAutocompleteEnumOptions, TranslateText } from 'utils/Translations';

import { Checkbox, Chip, FormControlLabel, InputLabel, TextField, Typography } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { Autocomplete } from '@material-ui/lab';

import { CONFIGURATIONGROUPGENERALCAMERA } from '../constants';
import { ConfigurationGroupProps } from '../types';
import {inputLabelAutocompleteStyle} from "../ConfigGroupCameraMediaView";
import {handleKeyDown} from "../../../../Common/RemoveSpinnerOptionInput";
import {getNumberValue} from "../../../../Widgets/Views/ConfigurationGroupSettingsView/Utils";

const ConfigurationGroupCamera = ({
	data,
	validationResult,
	handleChange,
	isAddNew = true,
	editMode = true,
	isForNewAddWizard = true,
}: ConfigurationGroupProps) => {
	const CameraOptions = useMemo<AutocompleteItem[]>(
		() =>
			getAutocompleteEnumOptions(ConfigurationGroupCameraBitMask, 'configurationGroups.cameraBitMaskEnum.', true),
		[]
	);

	const ResolutionOptions = useMemo<AutocompleteItem[]>(
		() =>
			getAutocompleteEnumOptions(
				ConfigurationGroupCameraResolution,
				'configurationGroups.cameraResolutionEnum.',
				true
			),
		[]
	);

	const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
	const checkedIcon = <CheckBoxIcon fontSize="small" />;

	const handleGeneralCameraChange = (value: any, key: any) => {
		handleChange(value, key, CONFIGURATIONGROUPGENERALCAMERA);
	};

	return (
		<>
			<div className="form-group">
				<FormControlLabel
					control={
						<Checkbox
							name="alwaysRecord"
							checked={data.configurationGroupGeneralCamera?.alwaysRecord ?? false}
							onChange={(event) => handleGeneralCameraChange(event.target.checked, 'alwaysRecord')}
						/>
					}
					label={
						<Typography
							style={{
								fontSize: 10,
								marginRight: 5,
								color: true ? 'black' : 'grey',
							}}
						>
							{TranslateText('fields.alwaysRecord')}
						</Typography>
					}
					disabled={!isAddNew || !editMode}
					className="m-0"
					labelPlacement="start"
				/>
			</div>
			<div className="form-group">
				<Autocomplete
					filterSelectedOptions={false}
					fullWidth={true}
					className="material-autocomplete"
					// value={configurationGroup.configurationGroupMedia.alarm}
					options={CameraOptions}
					autoHighlight
					getOptionLabel={(option) => option.display}
					noOptionsText={TranslateText('common.noRecordFound')}
					multiple
					id="checkboxes-tags-demo"
					disableCloseOnSelect
					renderOption={(option, { selected }) => (
						<React.Fragment>
							<Checkbox
								icon={icon}
								checkedIcon={checkedIcon}
								style={{ marginRight: 8 }}
								checked={selected}
							/>
							{option.display}
						</React.Fragment>
					)}
					onChange={(event, newValue) => {
						const val: ConfigurationGroupCameraBitMask[] = Object.values(
							newValue as AutocompleteItem[]
						).map(
							(x) => ConfigurationGroupCameraBitMask[x.id as keyof typeof ConfigurationGroupCameraBitMask]
						);
						handleGeneralCameraChange(val, 'camerasToRecord');
					}}
					renderTags={(tagValue, getTagProps) =>
						tagValue.map((option, index) => (
							<Chip
								key={index}
								label={option.display}
								{...getTagProps({ index })}
								className="chip-selection"
							/>
						))
					}
					renderInput={(params) => (
						<>
							<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
								<InputLabel
									style={inputLabelAutocompleteStyle(data?.configurationGroupGeneralCamera?.camerasToRecord)}
								>
									{TranslateText('fields.camerasToRecord')}
								</InputLabel>
								{!!validationResult?.camerasToRecord && (
									<ValidationMessage
										result={validationResult?.camerasToRecord}
										isForNewAddWizard={true}
									/>
								)}
							</div>

							<TextField name={'camerasToRecord'} margin={'none'} {...params} label={null} />
						</>
					)}
				/>
			</div>
			<div className="form-group">
				<Autocomplete
					filterSelectedOptions={false}
					fullWidth={true}
					className="material-autocomplete"
					// value={configurationGroup.configurationGroupMedia.alarm}
					options={CameraOptions}
					autoHighlight
					getOptionLabel={(option) => option.display}
					noOptionsText={TranslateText('common.noRecordFound')}
					multiple
					id="checkboxes-tags-demo"
					disableCloseOnSelect
					renderOption={(option, { selected }) => (
						<React.Fragment>
							<Checkbox
								icon={icon}
								checkedIcon={checkedIcon}
								style={{ marginRight: 8 }}
								checked={selected}
							/>
							{option.display}
						</React.Fragment>
					)}
					onChange={(event, newValue) => {
						const val: ConfigurationGroupCameraBitMask[] = Object.values(
							newValue as AutocompleteItem[]
						).map(
							(x) => ConfigurationGroupCameraBitMask[x.id as keyof typeof ConfigurationGroupCameraBitMask]
						);
						handleGeneralCameraChange(val, 'camerasToDisplay');
					}}
					renderTags={(tagValue, getTagProps) =>
						tagValue.map((option, index) => (
							<Chip
								key={index}
								label={option.display}
								{...getTagProps({ index })}
								className="chip-selection"
							/>
						))
					}
					renderInput={(params) => (
						<>
							<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
								<InputLabel
									style={inputLabelAutocompleteStyle(data?.configurationGroupGeneralCamera?.camerasToDisplay)}
								>
									{TranslateText('fields.camerasToDisplay')}
								</InputLabel>
								{!!validationResult?.camerasToDisplay && (
									<ValidationMessage
										result={validationResult?.camerasToDisplay}
										isForNewAddWizard={true}
									/>
								)}
							</div>

							<TextField name={'camerasToDisplay'} margin={'none'} {...params} label={null} />
						</>
					)}
				/>
			</div>
			<div className="form-group">
				<FormControlLabel
					control={
						<Checkbox
							name="enableOsd"
							checked={data.configurationGroupGeneralCamera?.enableOsd ?? false}
							onChange={(event) => handleGeneralCameraChange(event.target.checked, 'enableOsd')}
						/>
					}
					label={
						<Typography
							style={{
								fontSize: 10,
								marginRight: 5,
								color: true ? 'black' : 'grey',
							}}
						>
							{TranslateText('fields.enableOsd')}
						</Typography>
					}
					disabled={!isAddNew || !editMode}
					className="m-0"
					labelPlacement="start"
				/>
			</div>
			<div className="form-group">
				<FormControlLabel
					control={
						<Checkbox
							name="useRankingForOverlay"
							checked={data.configurationGroupGeneralCamera?.useRankingForOverlay ?? false}
							onChange={(event) =>
								handleGeneralCameraChange(event.target.checked, 'useRankingForOverlay')
							}
						/>
					}
					label={
						<Typography
							style={{
								fontSize: 10,
								marginRight: 5,
								color: true ? 'black' : 'grey',
							}}
						>
							{TranslateText('fields.useRankingForOverlay')}
						</Typography>
					}
					disabled={!isAddNew || !editMode}
					className="m-0"
					labelPlacement="start"
				/>
			</div>
			<div className="form-group">
				<FormControlLabel
					control={
						<Checkbox
							name="overlayFrontCameraAfterIgnitionOn"
							checked={data.configurationGroupGeneralCamera?.overlayFrontCameraAfterIgnitionOn ?? false}
							onChange={(event) =>
								handleGeneralCameraChange(event.target.checked, 'overlayFrontCameraAfterIgnitionOn')
							}
						/>
					}
					label={
						<Typography
							style={{
								fontSize: 10,
								marginRight: 5,
								color: true ? 'black' : 'grey',
							}}
						>
							{TranslateText('fields.overlayFrontCameraAfterIgnitionOn')}
						</Typography>
					}
					disabled={!isAddNew || !editMode}
					className="m-0"
					labelPlacement="start"
				/>
			</div>
			<div className="form-group">
				<Autocomplete
					filterSelectedOptions={false}
					fullWidth={true}
					className="material-autocomplete"
					options={ResolutionOptions}
					autoHighlight
					getOptionLabel={(option) => option.display}
					noOptionsText={TranslateText('common.noRecordFound')}
					id="checkboxes-tags-demo"
					disableCloseOnSelect
					renderOption={(option, { selected }) => (
						<React.Fragment>
							<Checkbox
								icon={icon}
								checkedIcon={checkedIcon}
								style={{ marginRight: 8 }}
								checked={selected}
							/>
							{option.display}
						</React.Fragment>
					)}
					onChange={(event, newValue, reason) => {
						if (reason === 'clear') {
							handleChange('', 'resolution', 'configurationGroupCamera1');
						} else {
							const val = newValue as AutocompleteItem;
							handleChange(val?.display, 'resolution', 'configurationGroupCamera1');
						}
					}}
					renderTags={(tagValue, getTagProps) =>
						tagValue.map((option, index) => (
							<Chip
								key={index}
								label={option.display}
								{...getTagProps({ index })}
								className="chip-selection"
							/>
						))
					}
					renderInput={(params) => (
						<>
							<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
								<InputLabel
									style={inputLabelAutocompleteStyle(data?.configurationGroupGeneralCamera?.configurationGroupCamera1?.resolution)}
								>
									{TranslateText('fields.resolution')}
								</InputLabel>
								{!!validationResult?.resolution && (
									<ValidationMessage result={validationResult?.resolution} isForNewAddWizard={true} />
								)}
							</div>

							<TextField name={'resolution'} margin={'none'} {...params} label={null} />
						</>
					)}
				/>
			</div>
			<div className="form-group">
				<FormControlLabel
					control={
						<Checkbox
							name="mirrored"
							checked={data.configurationGroupGeneralCamera?.configurationGroupCamera1?.mirrored ?? false}
							onChange={(event) =>
								handleChange(event.target.checked, 'mirrored', 'configurationGroupCamera1')
							}
						/>
					}
					label={
						<Typography
							style={{
								fontSize: 10,
								marginRight: 5,
								color: true ? 'black' : 'grey',
							}}
						>
							{TranslateText('fields.mirrored')}
						</Typography>
					}
					disabled={!isAddNew || !editMode}
					className="m-0"
					labelPlacement="start"
				/>
			</div>
			<div className="form-group">
				<FormControlLabel
					control={
						<Checkbox
							name="io"
							checked={data.configurationGroupGeneralCamera?.configurationGroupCamera1?.io ?? false}
							onChange={(event) => handleChange(event.target.checked, 'io', 'configurationGroupCamera1')}
						/>
					}
					label={
						<Typography
							style={{
								fontSize: 10,
								marginRight: 5,
								color: true ? 'black' : 'grey',
							}}
						>
							{TranslateText('fields.io')}
						</Typography>
					}
					disabled={!isAddNew || !editMode}
					className="m-0"
					labelPlacement="start"
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="rank"
					className="resize-font noSpinner"
					label={TranslateText('fields.rank')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="rank"
					value={data.configurationGroupGeneralCamera?.configurationGroupCamera1?.rank}
					handleValueChange={(value) => handleChange(getNumberValue(value), 'rank', 'configurationGroupCamera1')}
					validationResult={validationResult?.rank}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="hideDelay"
					className="resize-font noSpinner"
					label={TranslateText('fields.hideDelay')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="hideDelay"
					value={data.configurationGroupGeneralCamera?.configurationGroupCamera1?.hideDelay}
					handleValueChange={(value) => handleChange(getNumberValue(value), 'hideDelay', 'configurationGroupCamera1')}
					validationResult={validationResult?.hideDelay}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<Autocomplete
					filterSelectedOptions={false}
					fullWidth={true}
					className="material-autocomplete"
					// value={configurationGroup.configurationGroupMedia.alarm}
					options={CameraOptions}
					autoHighlight
					getOptionLabel={(option) => option.display}
					noOptionsText={TranslateText('common.noRecordFound')}
					multiple
					id="checkboxes-tags-demo"
					disableCloseOnSelect
					renderOption={(option, { selected }) => (
						<React.Fragment>
							<Checkbox
								icon={icon}
								checkedIcon={checkedIcon}
								style={{ marginRight: 8 }}
								checked={selected}
							/>
							{option.display}
						</React.Fragment>
					)}
					onChange={(event, newValue) => {
						const val: ConfigurationGroupCameraBitMask[] = Object.values(
							newValue as AutocompleteItem[]
						).map(
							(x) => ConfigurationGroupCameraBitMask[x.id as keyof typeof ConfigurationGroupCameraBitMask]
						);
						handleChange(val, 'overlayActiveCameras', 'configurationGroupCamera1');
					}}
					renderTags={(tagValue, getTagProps) =>
						tagValue.map((option, index) => (
							<Chip
								key={index}
								label={option.display}
								{...getTagProps({ index })}
								className="chip-selection"
							/>
						))
					}
					renderInput={(params) => (
						<>
							<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
								<InputLabel
									style={inputLabelAutocompleteStyle(data?.configurationGroupGeneralCamera?.configurationGroupCamera1?.overlayActiveCameras)}
								>
									{TranslateText('fields.overlayActiveCameras')}
								</InputLabel>
								{!!validationResult?.overlayActiveCameras && (
									<ValidationMessage
										result={validationResult?.overlayActiveCameras}
										isForNewAddWizard={true}
									/>
								)}
							</div>

							<TextField name={'overlayActiveCameras'} margin={'none'} {...params} label={null} />
						</>
					)}
				/>
			</div>
			<div className="form-group">
				<Autocomplete
					filterSelectedOptions={false}
					fullWidth={true}
					className="material-autocomplete"
					options={ResolutionOptions}
					autoHighlight
					getOptionLabel={(option) => option.display}
					noOptionsText={TranslateText('common.noRecordFound')}
					id="checkboxes-tags-demo"
					disableCloseOnSelect
					renderOption={(option, { selected }) => (
						<React.Fragment>
							<Checkbox
								icon={icon}
								checkedIcon={checkedIcon}
								style={{ marginRight: 8 }}
								checked={selected}
							/>
							{option.display}
						</React.Fragment>
					)}
					onChange={(event, newValue, reason) => {
						if (reason === 'clear') {
							handleChange('', 'resolution', 'configurationGroupCamera2');
						} else {
							const val = newValue as AutocompleteItem;
							handleChange(val?.display, 'resolution', 'configurationGroupCamera2');
						}
					}}
					renderTags={(tagValue, getTagProps) =>
						tagValue.map((option, index) => (
							<Chip
								key={index}
								label={option.display}
								{...getTagProps({ index })}
								className="chip-selection"
							/>
						))
					}
					renderInput={(params) => (
						<>
							<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
								<InputLabel
									style={inputLabelAutocompleteStyle(data?.configurationGroupGeneralCamera?.configurationGroupCamera2?.resolution)}
								>
									{TranslateText('fields.resolution')}
								</InputLabel>
								{!!validationResult?.resolution && (
									<ValidationMessage result={validationResult?.resolution} isForNewAddWizard={true} />
								)}
							</div>

							<TextField name={'resolution'} margin={'none'} {...params} label={null} />
						</>
					)}
				/>
			</div>
			<div className="form-group">
				<FormControlLabel
					control={
						<Checkbox
							name="mirrored"
							checked={data.configurationGroupGeneralCamera?.configurationGroupCamera2?.mirrored ?? false}
							onChange={(event) =>
								handleChange(event.target.checked, 'mirrored', 'configurationGroupCamera2')
							}
						/>
					}
					label={
						<Typography
							style={{
								fontSize: 10,
								marginRight: 5,
								color: true ? 'black' : 'grey',
							}}
						>
							{TranslateText('fields.mirrored')}
						</Typography>
					}
					disabled={!isAddNew || !editMode}
					className="m-0"
					labelPlacement="start"
				/>
			</div>
			<div className="form-group">
				<FormControlLabel
					control={
						<Checkbox
							name="io"
							checked={data.configurationGroupGeneralCamera?.configurationGroupCamera2?.io ?? false}
							onChange={(event) => handleChange(event.target.checked, 'io', 'configurationGroupCamera2')}
						/>
					}
					label={
						<Typography
							style={{
								fontSize: 10,
								marginRight: 5,
								color: true ? 'black' : 'grey',
							}}
						>
							{TranslateText('fields.io')}
						</Typography>
					}
					disabled={!isAddNew || !editMode}
					className="m-0"
					labelPlacement="start"
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="rank"
					className="resize-font noSpinner"
					label={TranslateText('fields.rank')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="rank"
					value={data.configurationGroupGeneralCamera?.configurationGroupCamera2?.rank}
					handleValueChange={(value) => handleChange(getNumberValue(value), 'rank', 'configurationGroupCamera2')}
					validationResult={validationResult?.rank}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="hideDelay"
					className="resize-font noSpinner"
					label={TranslateText('fields.hideDelay')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="hideDelay"
					value={data.configurationGroupGeneralCamera?.configurationGroupCamera2?.hideDelay}
					handleValueChange={(value) => handleChange(getNumberValue(value), 'hideDelay', 'configurationGroupCamera2')}
					validationResult={validationResult?.hideDelay}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<Autocomplete
					filterSelectedOptions={false}
					fullWidth={true}
					className="material-autocomplete"
					// value={configurationGroup.configurationGroupMedia.alarm}
					options={CameraOptions}
					autoHighlight
					getOptionLabel={(option) => option.display}
					noOptionsText={TranslateText('common.noRecordFound')}
					multiple
					id="checkboxes-tags-demo"
					disableCloseOnSelect
					renderOption={(option, { selected }) => (
						<React.Fragment>
							<Checkbox
								icon={icon}
								checkedIcon={checkedIcon}
								style={{ marginRight: 8 }}
								checked={selected}
							/>
							{option.display}
						</React.Fragment>
					)}
					onChange={(event, newValue) => {
						const val: ConfigurationGroupCameraBitMask[] = Object.values(
							newValue as AutocompleteItem[]
						).map(
							(x) => ConfigurationGroupCameraBitMask[x.id as keyof typeof ConfigurationGroupCameraBitMask]
						);
						handleChange(val, 'overlayActiveCameras', 'configurationGroupCamera2');
					}}
					renderTags={(tagValue, getTagProps) =>
						tagValue.map((option, index) => (
							<Chip
								key={index}
								label={option.display}
								{...getTagProps({ index })}
								className="chip-selection"
							/>
						))
					}
					renderInput={(params) => (
						<>
							<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
								<InputLabel
									style={inputLabelAutocompleteStyle(data?.configurationGroupGeneralCamera?.configurationGroupCamera2?.overlayActiveCameras)}
								>
									{TranslateText('fields.overlayActiveCameras')}
								</InputLabel>
								{!!validationResult?.overlayActiveCameras && (
									<ValidationMessage
										result={validationResult?.overlayActiveCameras}
										isForNewAddWizard={true}
									/>
								)}
							</div>

							<TextField name={'overlayActiveCameras'} margin={'none'} {...params} label={null} />
						</>
					)}
				/>
			</div>
			<div className="form-group">
				<Autocomplete
					filterSelectedOptions={false}
					fullWidth={true}
					className="material-autocomplete"
					options={ResolutionOptions}
					autoHighlight
					getOptionLabel={(option) => option.display}
					noOptionsText={TranslateText('common.noRecordFound')}
					id="checkboxes-tags-demo"
					disableCloseOnSelect
					renderOption={(option, { selected }) => (
						<React.Fragment>
							<Checkbox
								icon={icon}
								checkedIcon={checkedIcon}
								style={{ marginRight: 8 }}
								checked={selected}
							/>
							{option.display}
						</React.Fragment>
					)}
					onChange={(event, newValue, reason) => {
						if (reason === 'clear') {
							handleChange('', 'resolution', 'configurationGroupCamera3');
						} else {
							const val = newValue as AutocompleteItem;
							handleChange(val?.display, 'resolution', 'configurationGroupCamera3');
						}
					}}
					renderTags={(tagValue, getTagProps) =>
						tagValue.map((option, index) => (
							<Chip
								key={index}
								label={option.display}
								{...getTagProps({ index })}
								className="chip-selection"
							/>
						))
					}
					renderInput={(params) => (
						<>
							<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
								<InputLabel
									style={inputLabelAutocompleteStyle(data?.configurationGroupGeneralCamera?.configurationGroupCamera3?.resolution)}
								>
									{TranslateText('fields.resolution')}
								</InputLabel>
								{!!validationResult?.resolution && (
									<ValidationMessage result={validationResult?.resolution} isForNewAddWizard={true} />
								)}
							</div>

							<TextField name={'resolution'} margin={'none'} {...params} label={null} />
						</>
					)}
				/>
			</div>
			<div className="form-group">
				<FormControlLabel
					control={
						<Checkbox
							name="mirrored"
							checked={data.configurationGroupGeneralCamera?.configurationGroupCamera3?.mirrored ?? false}
							onChange={(event) =>
								handleChange(event.target.checked, 'mirrored', 'configurationGroupCamera3')
							}
						/>
					}
					label={
						<Typography
							style={{
								fontSize: 10,
								marginRight: 5,
								color: true ? 'black' : 'grey',
							}}
						>
							{TranslateText('fields.mirrored')}
						</Typography>
					}
					disabled={!isAddNew || !editMode}
					className="m-0"
					labelPlacement="start"
				/>
			</div>
			<div className="form-group">
				<FormControlLabel
					control={
						<Checkbox
							name="io"
							checked={data.configurationGroupGeneralCamera?.configurationGroupCamera3?.io ?? false}
							onChange={(event) => handleChange(event.target.checked, 'io', 'configurationGroupCamera3')}
						/>
					}
					label={
						<Typography
							style={{
								fontSize: 10,
								marginRight: 5,
								color: true ? 'black' : 'grey',
							}}
						>
							{TranslateText('fields.io')}
						</Typography>
					}
					disabled={!isAddNew || !editMode}
					className="m-0"
					labelPlacement="start"
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="rank"
					className="resize-font noSpinner"
					label={TranslateText('fields.rank')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="rank"
					value={data.configurationGroupGeneralCamera?.configurationGroupCamera3?.rank}
					handleValueChange={(value) => handleChange(getNumberValue(value), 'rank', 'configurationGroupCamera3')}
					validationResult={validationResult?.rank}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="hideDelay"
					className="resize-font noSpinner"
					label={TranslateText('fields.hideDelay')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="hideDelay"
					value={data.configurationGroupGeneralCamera?.configurationGroupCamera3?.hideDelay}
					handleValueChange={(value) => handleChange(getNumberValue(value), 'hideDelay', 'configurationGroupCamera3')}
					validationResult={validationResult?.hideDelay}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<Autocomplete
					filterSelectedOptions={false}
					fullWidth={true}
					className="material-autocomplete"
					// value={configurationGroup.configurationGroupMedia.alarm}
					options={CameraOptions}
					autoHighlight
					getOptionLabel={(option) => option.display}
					noOptionsText={TranslateText('common.noRecordFound')}
					multiple
					id="checkboxes-tags-demo"
					disableCloseOnSelect
					renderOption={(option, { selected }) => (
						<React.Fragment>
							<Checkbox
								icon={icon}
								checkedIcon={checkedIcon}
								style={{ marginRight: 8 }}
								checked={selected}
							/>
							{option.display}
						</React.Fragment>
					)}
					onChange={(event, newValue) => {
						const val: ConfigurationGroupCameraBitMask[] = Object.values(
							newValue as AutocompleteItem[]
						).map(
							(x) => ConfigurationGroupCameraBitMask[x.id as keyof typeof ConfigurationGroupCameraBitMask]
						);
						handleChange(val, 'overlayActiveCameras', 'configurationGroupCamera3');
					}}
					renderTags={(tagValue, getTagProps) =>
						tagValue.map((option, index) => (
							<Chip
								key={index}
								label={option.display}
								{...getTagProps({ index })}
								className="chip-selection"
							/>
						))
					}
					renderInput={(params) => (
						<>
							<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
								<InputLabel
									style={inputLabelAutocompleteStyle(data?.configurationGroupGeneralCamera?.configurationGroupCamera3?.overlayActiveCameras)}
								>
									{TranslateText('fields.overlayActiveCameras')}
								</InputLabel>
								{!!validationResult?.overlayActiveCameras && (
									<ValidationMessage
										result={validationResult?.overlayActiveCameras}
										isForNewAddWizard={true}
									/>
								)}
							</div>

							<TextField name={'overlayActiveCameras'} margin={'none'} {...params} label={null} />
						</>
					)}
				/>
			</div>
			<div className="form-group">
				<Autocomplete
					filterSelectedOptions={false}
					fullWidth={true}
					className="material-autocomplete"
					options={ResolutionOptions}
					autoHighlight
					getOptionLabel={(option) => option.display}
					noOptionsText={TranslateText('common.noRecordFound')}
					id="checkboxes-tags-demo"
					disableCloseOnSelect
					renderOption={(option, { selected }) => (
						<React.Fragment>
							<Checkbox
								icon={icon}
								checkedIcon={checkedIcon}
								style={{ marginRight: 8 }}
								checked={selected}
							/>
							{option.display}
						</React.Fragment>
					)}
					onChange={(event, newValue, reason) => {
						if (reason === 'clear') {
							handleChange('', 'resolution', 'configurationGroupCamera4');
						} else {
							const val = newValue as AutocompleteItem;
							handleChange(val?.display, 'resolution', 'configurationGroupCamera4');
						}
					}}
					renderTags={(tagValue, getTagProps) =>
						tagValue.map((option, index) => (
							<Chip
								key={index}
								label={option.display}
								{...getTagProps({ index })}
								className="chip-selection"
							/>
						))
					}
					renderInput={(params) => (
						<>
							<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
								<InputLabel
									style={inputLabelAutocompleteStyle(data?.configurationGroupGeneralCamera?.configurationGroupCamera4?.resolution)}
								>
									{TranslateText('fields.resolution')}
								</InputLabel>
								{!!validationResult?.resolution && (
									<ValidationMessage result={validationResult?.resolution} isForNewAddWizard={true} />
								)}
							</div>

							<TextField name={'resolution'} margin={'none'} {...params} label={null} />
						</>
					)}
				/>
			</div>
			<div className="form-group">
				<FormControlLabel
					control={
						<Checkbox
							name="mirrored"
							checked={data.configurationGroupGeneralCamera?.configurationGroupCamera4?.mirrored ?? false}
							onChange={(event) =>
								handleChange(event.target.checked, 'mirrored', 'configurationGroupCamera4')
							}
						/>
					}
					label={
						<Typography
							style={{
								fontSize: 10,
								marginRight: 5,
								color: true ? 'black' : 'grey',
							}}
						>
							{TranslateText('fields.mirrored')}
						</Typography>
					}
					disabled={!isAddNew || !editMode}
					className="m-0"
					labelPlacement="start"
				/>
			</div>
			<div className="form-group">
				<FormControlLabel
					control={
						<Checkbox
							name="io"
							checked={data.configurationGroupGeneralCamera?.configurationGroupCamera4?.io ?? false}
							onChange={(event) => handleChange(event.target.checked, 'io', 'configurationGroupCamera4')}
						/>
					}
					label={
						<Typography
							style={{
								fontSize: 10,
								marginRight: 5,
								color: true ? 'black' : 'grey',
							}}
						>
							{TranslateText('fields.io')}
						</Typography>
					}
					disabled={!isAddNew || !editMode}
					className="m-0"
					labelPlacement="start"
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="rank"
					className="resize-font noSpinner"
					label={TranslateText('fields.rank')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="rank"
					value={data.configurationGroupGeneralCamera?.configurationGroupCamera4?.rank}
					handleValueChange={(value) => handleChange(getNumberValue(value), 'rank', 'configurationGroupCamera4')}
					validationResult={validationResult?.rank}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="hideDelay"
					className="resize-font noSpinner"
					label={TranslateText('fields.hideDelay')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="hideDelay"
					value={data.configurationGroupGeneralCamera?.configurationGroupCamera4?.hideDelay}
					handleValueChange={(value) => handleChange(getNumberValue(value), 'hideDelay', 'configurationGroupCamera4')}
					validationResult={validationResult?.hideDelay}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<Autocomplete
					filterSelectedOptions={false}
					fullWidth={true}
					className="material-autocomplete"
					// value={configurationGroup.configurationGroupMedia.alarm}
					options={CameraOptions}
					autoHighlight
					getOptionLabel={(option) => option.display}
					noOptionsText={TranslateText('common.noRecordFound')}
					multiple
					id="checkboxes-tags-demo"
					disableCloseOnSelect
					renderOption={(option, { selected }) => (
						<React.Fragment>
							<Checkbox
								icon={icon}
								checkedIcon={checkedIcon}
								style={{ marginRight: 8 }}
								checked={selected}
							/>
							{option.display}
						</React.Fragment>
					)}
					onChange={(event, newValue) => {
						const val: ConfigurationGroupCameraBitMask[] = Object.values(
							newValue as AutocompleteItem[]
						).map(
							(x) => ConfigurationGroupCameraBitMask[x.id as keyof typeof ConfigurationGroupCameraBitMask]
						);
						handleChange(val, 'overlayActiveCameras', 'configurationGroupCamera4');
					}}
					renderTags={(tagValue, getTagProps) =>
						tagValue.map((option, index) => (
							<Chip
								key={index}
								label={option.display}
								{...getTagProps({ index })}
								className="chip-selection"
							/>
						))
					}
					renderInput={(params) => (
						<>
							<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
								<InputLabel
									style={inputLabelAutocompleteStyle(data?.configurationGroupGeneralCamera?.configurationGroupCamera4?.overlayActiveCameras)}
								>
									{TranslateText('fields.overlayActiveCameras')}
								</InputLabel>
								{!!validationResult?.overlayActiveCameras && (
									<ValidationMessage
										result={validationResult?.overlayActiveCameras}
										isForNewAddWizard={true}
									/>
								)}
							</div>

							<TextField name={'overlayActiveCameras'} margin={'none'} {...params} label={null} />
						</>
					)}
				/>
			</div>
		</>
	);
};

export default ConfigurationGroupCamera;
