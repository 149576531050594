import '../configurationGroupView.scss'

import React, { useEffect } from "react";

import {WidgetViewDefault} from "../../../Widget";
import {ConfigurationGroupSettingsEditViewProps} from "../types";
import {ConfigurationGroupSettingsEditDto} from "../ConfigurationGroupSettingsView";
import {TranslateText} from "../../../../../utils/Translations";
import {
	CONFIGURATIONGROUPPERIODICALS
} from "../../../../NewAddWizard/Views/ConfigurationGroup/constants";
import {handleKeyDown} from "../../../../Common/RemoveSpinnerOptionInput";
import {getCurrentTabValidation, getNumberValue} from "../Utils";
import TextFieldTooltip from "../../../../../shared/components/TextFieldToolTip/TextFieldTooltip";
import {verifyDifferences} from "../../ConfigurationGroupHistoryView/Utils";

type Props = WidgetViewDefault & ConfigurationGroupSettingsEditViewProps &{
	data: ConfigurationGroupSettingsEditDto;
};
const PeriodicalSettingsView = (props: Props) => {

	useEffect(() => {
		if(props.editMode) {
			if(!props.isForModal) {
				const resultValidations = getCurrentTabValidation(props.validationResult, CONFIGURATIONGROUPPERIODICALS);

				let newTabValidations = [...props.tabValidations];
				newTabValidations[5] = resultValidations;
				props.setTabValidation(newTabValidations);
			}
		}
	},[props.validationResult, props.editMode]);

	return (
		<div className="container-configuration-group-edit">
			<div className={!props.isSingleColumn ? 'container-row' : 'container-column'}
			>
				<div className={!props.isSingleColumn ? 'col-md-4' : 'col-12'}>
					<TextFieldTooltip
						id="frequencyWhileMoving"
						type="number"
						className="resize-font noSpinner"
						fullWidth
						label={TranslateText('fields.frequencyWhileMoving')}
						toolTipText={TranslateText('fields.frequencyWhileMovingDetails')}
						fontSizeLabel={12}
						labelStyle={props.isForModal &&
						verifyDifferences(
							props.data,
							'frequencyWhileMoving',
							CONFIGURATIONGROUPPERIODICALS,
							props.differences) ? {color: props.colorLabel } : {}
						}
						disableTooltipButton={!props.editMode && !props.isForModal}
						inputProps={{
							readOnly: !props.editMode,
							style: { fontSize: 12 },
							onKeyPress: (event: any) => {
								if (event.key === 'e') {
									event.preventDefault();
								}
							}}}
						name="frequencyWhileMoving"
						value={props.data.configurationGroupPeriodicals?.frequencyWhileMoving}
						onChange={(e) => {
							props.handleChange(getNumberValue(e.target.value), 'frequencyWhileMoving', CONFIGURATIONGROUPPERIODICALS);
						}}
						disabled={!props.editMode}
						onKeyDown={handleKeyDown}
						onWheel={(e: any) => e.target.blur()}
						validationResult={props.validationResult?.frequencyWhileMovingConfigurationGroupPeriodicals}
					/>
					<TextFieldTooltip
						id="frequencyWhileStandingStill"
						type="number"
						className="resize-font noSpinner"
						fullWidth
						label={TranslateText('fields.frequencyWhileStandingStill')}
						toolTipText={TranslateText('fields.frequencyWhileStandingStillDetails')}
						labelStyle={props.isForModal &&
						verifyDifferences(
							props.data,
							'frequencyWhileStandingStill',
							CONFIGURATIONGROUPPERIODICALS,
							props.differences) ? {color: props.colorLabel } : {}
						}
						disableTooltipButton={!props.editMode && !props.isForModal}
						inputProps={{
							readOnly: !props.editMode,
							style: { fontSize: 12 },
							onKeyPress: (event: any) => {
								if (event.key === 'e') {
									event.preventDefault();
								}
							}}}
						fontSizeLabel={12}
						name="frequencyWhileStandingStill"
						value={props.data.configurationGroupPeriodicals?.frequencyWhileStandingStill}
						onChange={(e) => {
							props.handleChange(getNumberValue(e.target.value), 'frequencyWhileStandingStill', CONFIGURATIONGROUPPERIODICALS);
						}}
						disabled={!props.editMode}
						onKeyDown={handleKeyDown}
						onWheel={(e: any) => e.target.blur()}
						validationResult={props.validationResult?.frequencyWhileStandingStillConfigurationGroupPeriodicals}
					/>
				</div>
			</div>
		</div>
	);
}
export default PeriodicalSettingsView;
