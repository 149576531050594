import '../configurationGroupView.scss'
import React, {useEffect} from "react";
import {TranslateText} from "../../../../../utils/Translations";
import {WidgetViewDefault} from "../../../Widget";
import {handleKeyDown} from "../../../../Common/RemoveSpinnerOptionInput";
import {ConfigurationGroupSettingsEditDto} from "../ConfigurationGroupSettingsView";
import {ConfigurationGroupSettingsEditViewProps} from "../types";
import {
	CONFIGURATIONGROUPFACTORS, CONFIGURATIONGROUPSETTINGS
} from "../../../../NewAddWizard/Views/ConfigurationGroup/constants";
import { getCurrentTabValidation, getNumberValue } from '../Utils';
import TextFieldTooltip from "../../../../../shared/components/TextFieldToolTip/TextFieldTooltip";
import {verifyDifferences} from "../../ConfigurationGroupHistoryView/Utils";

type Props = WidgetViewDefault & ConfigurationGroupSettingsEditViewProps &{
	data: ConfigurationGroupSettingsEditDto;
};
const FactorsView = (props: Props) => {

	useEffect(() => {
		if(props.editMode) {
			if(!props.isForModal) {
				const resultValidations = getCurrentTabValidation(props.validationResult, CONFIGURATIONGROUPFACTORS);

				let newTabValidations = [...props.tabValidations];
				newTabValidations[1] = resultValidations;
				props.setTabValidation(newTabValidations);
			}
		}
	},[props.validationResult, props.editMode]);

	return (
		<div className="container-configuration-group-edit">
			<div className={!props.isSingleColumn ? 'container-row' : 'container-column'}
			>
				<div className={!props.isSingleColumn ? 'col-md-4' : 'col-12'}>
					<TextFieldTooltip
						id="coast"
						type="number"
						className="resize-font noSpinner"
						fullWidth
						label={TranslateText('fields.coast')}
						toolTipText={TranslateText('fields.coastFactor')}
						fontSizeLabel={12}
						labelStyle={props.isForModal &&
						verifyDifferences(
							props.data,
							'coast',
							CONFIGURATIONGROUPFACTORS,
							props.differences) ? {color: props.colorLabel } : {}
						}
						disableTooltipButton={!props.editMode && !props.isForModal}
						inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 },
							onKeyPress: (event: any) => {
								if (event.key === '-' || event.key === 'e') {
									event.preventDefault();
								}
							}}}
						name="coast"
						value={props.data.configurationGroupFactors?.coast}
						onChange={(e) => {
							props.handleChange(getNumberValue(e.target.value), 'coast', CONFIGURATIONGROUPFACTORS);
						}}
						disabled={!props.editMode}
						onKeyDown={handleKeyDown}
						onWheel={(e: any) => e.target.blur()}
						validationResult={props.validationResult?.coastConfigurationGroupFactors}
					/>
					<TextFieldTooltip
						id="cruise"
						type="number"
						className="resize-font noSpinner"
						fullWidth
						label={TranslateText('fields.cruise')}
						toolTipText={TranslateText('fields.cruiseFactor')}
						fontSizeLabel={12}
						labelStyle={props.isForModal &&
						verifyDifferences(
							props.data,
							'cruise',
							CONFIGURATIONGROUPFACTORS,
							props.differences) ? {color: props.colorLabel } : {}
						}
						disableTooltipButton={!props.editMode && !props.isForModal}
						inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 },
							onKeyPress: (event: any) => {
								if (event.key === '-' || event.key === 'e') {
									event.preventDefault();
								}
							}}}
						name="cruise"
						value={props.data.configurationGroupFactors?.cruise}
						onChange={(e) => {
							props.handleChange(getNumberValue(e.target.value), 'cruise', CONFIGURATIONGROUPFACTORS);
						}}
						disabled={!props.editMode}
						onKeyDown={handleKeyDown}
						onWheel={(e: any) => e.target.blur()}
						validationResult={props.validationResult?.cruiseConfigurationGroupFactors}
					/>
					<TextFieldTooltip
						id="drivingBehaviour"
						type="number"
						className="resize-font noSpinner"
						fullWidth
						label={TranslateText('fields.drivingBehaviour')}
						toolTipText={TranslateText('fields.drivingBehaviourFactor')}
						fontSizeLabel={12}
						labelStyle={props.isForModal &&
						verifyDifferences(
							props.data,
							'drivingBehaviour',
							CONFIGURATIONGROUPFACTORS,
							props.differences) ? {color: props.colorLabel } : {}
						}
						disableTooltipButton={!props.editMode && !props.isForModal}
						inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 },
							onKeyPress: (event: any) => {
								if (event.key === '-' || event.key === 'e') {
									event.preventDefault();
								}
							}}}
						name="drivingBehaviour"
						value={props.data.configurationGroupFactors?.drivingBehaviour}
						onChange={(e) => {
							props.handleChange(getNumberValue(e.target.value), 'drivingBehaviour', CONFIGURATIONGROUPFACTORS);
						}}
						disabled={!props.editMode}
						onKeyDown={handleKeyDown}
						onWheel={(e: any) => e.target.blur()}
						validationResult={props.validationResult?.drivingBehaviourConfigurationGroupFactors}
					/>
				</div>
				<div className={!props.isSingleColumn ? 'col-md-4' : 'col-12'}>
					<TextFieldTooltip
						id="harshAcceleration"
						type="number"
						className="resize-font noSpinner"
						fullWidth
						label={TranslateText('fields.harshAcceleration')}
						toolTipText={TranslateText('fields.harshAccelerationFactor')}
						fontSizeLabel={12}
						labelStyle={props.isForModal &&
						verifyDifferences(
							props.data,
							'harshAcceleration',
							CONFIGURATIONGROUPFACTORS,
							props.differences) ? {color: props.colorLabel } : {}
						}
						disableTooltipButton={!props.editMode && !props.isForModal}
						inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 },
							onKeyPress: (event: any) => {
								if (event.key === '-' || event.key === 'e') {
									event.preventDefault();
								}
							}}}
						name="harshAcceleration"
						value={props.data.configurationGroupFactors?.harshAcceleration}
						onChange={(e) => {
							props.handleChange(getNumberValue(e.target.value), 'harshAcceleration', CONFIGURATIONGROUPFACTORS);
						}}
						disabled={!props.editMode}
						onKeyDown={handleKeyDown}
						onWheel={(e: any) => e.target.blur()}
						validationResult={props.validationResult?.harshAccelerationConfigurationGroupFactors}
					/>
					<TextFieldTooltip
						id="harshBrake"
						type="number"
						className="resize-font noSpinner"
						fullWidth
						label={TranslateText('fields.harshBrake')}
						toolTipText={TranslateText('fields.harshBrakeFactor')}
						fontSizeLabel={12}
						labelStyle={props.isForModal &&
						verifyDifferences(
							props.data,
							'harshBrake',
							CONFIGURATIONGROUPFACTORS,
							props.differences) ? {color: props.colorLabel } : {}
						}
						disableTooltipButton={!props.editMode && !props.isForModal}
						inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 },
							onKeyPress: (event: any) => {
								if (event.key === '-' || event.key === 'e') {
									event.preventDefault();
								}
							}}}
						name="harshBrake"
						value={props.data.configurationGroupFactors?.harshBrake}
						onChange={(e) => {
							props.handleChange(getNumberValue(e.target.value), 'harshBrake', CONFIGURATIONGROUPFACTORS);
						}}
						disabled={!props.editMode}
						onKeyDown={handleKeyDown}
						onWheel={(e: any) => e.target.blur()}
						validationResult={props.validationResult?.harshBrakeConfigurationGroupFactors}
					/>
					<TextFieldTooltip
						id="harshPedalAcceleration"
						type="number"
						className="resize-font noSpinner"
						fullWidth
						label={TranslateText('fields.harshPedalAcceleration')}
						toolTipText={TranslateText('fields.harshPedalAccelerationFactor')}
						fontSizeLabel={12}
						labelStyle={props.isForModal &&
						verifyDifferences(
							props.data,
							'harshPedalAcceleration',
							CONFIGURATIONGROUPFACTORS,
							props.differences) ? {color: props.colorLabel } : {}
						}
						disableTooltipButton={!props.editMode && !props.isForModal}
						inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 },
							onKeyPress: (event: any) => {
								if (event.key === '-' || event.key === 'e') {
									event.preventDefault();
								}
							}}}
						name="harshPedalAcceleration"
						value={props.data.configurationGroupFactors?.harshPedalAcceleration}
						onChange={(e) => {
							props.handleChange(getNumberValue(e.target.value), 'harshPedalAcceleration', CONFIGURATIONGROUPFACTORS);
						}}
						disabled={!props.editMode}
						onKeyDown={handleKeyDown}
						onWheel={(e: any) => e.target.blur()}
						validationResult={props.validationResult?.harshPedalAccelerationConfigurationGroupFactors}
					/>
				</div>
					<div className={!props.isSingleColumn ? 'col-md-4' : 'col-12'}>
					<TextFieldTooltip
						id="idle"
						type="number"
						className="resize-font noSpinner"
						fullWidth
						label={TranslateText('fields.idle')}
						toolTipText={TranslateText('fields.idleFactor')}
						fontSizeLabel={12}
						labelStyle={props.isForModal &&
						verifyDifferences(
							props.data,
							'idle',
							CONFIGURATIONGROUPFACTORS,
							props.differences) ? {color: props.colorLabel } : {}
						}
						disableTooltipButton={!props.editMode && !props.isForModal}
						inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 },
							onKeyPress: (event: any) => {
								if (event.key === '-' || event.key === 'e') {
									event.preventDefault();
								}
							}}}
						name="idle"
						value={props.data.configurationGroupFactors?.idle}
						onChange={(e) => {
							props.handleChange(getNumberValue(e.target.value), 'idle', CONFIGURATIONGROUPFACTORS);
						}}
						disabled={!props.editMode}
						onKeyDown={handleKeyDown}
						onWheel={(e: any) => e.target.blur()}
						validationResult={props.validationResult?.idleConfigurationGroupFactors}
					/>
					<TextFieldTooltip
						id="overRpm"
						type="number"
						className="resize-font noSpinner"
						fullWidth
						label={TranslateText('fields.overRpm')}
						toolTipText={TranslateText('fields.overRpmFactor')}
						fontSizeLabel={12}
						labelStyle={props.isForModal &&
						verifyDifferences(
							props.data,
							'overRpm',
							CONFIGURATIONGROUPFACTORS,
							props.differences) ? {color: props.colorLabel } : {}
						}
						disableTooltipButton={!props.editMode && !props.isForModal}
						inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 },
							onKeyPress: (event: any) => {
								if (event.key === '-' || event.key === 'e') {
									event.preventDefault();
								}
							}}}
						name="overRpm"
						value={props.data.configurationGroupFactors?.overRpm}
						onChange={(e) => {
							props.handleChange(getNumberValue(e.target.value), 'overRpm', CONFIGURATIONGROUPFACTORS);
						}}
						disabled={!props.editMode}
						onKeyDown={handleKeyDown}
						onWheel={(e: any) => e.target.blur()}
						validationResult={props.validationResult?.overRpmConfigurationGroupFactors}
					/>

					<TextFieldTooltip
						id="overSpeed"
						type="number"
						className="resize-font noSpinner"
						fullWidth
						label={TranslateText('fields.overSpeed')}
						toolTipText={TranslateText('fields.overSpeedFactor')}
						fontSizeLabel={12}
						labelStyle={props.isForModal &&
						verifyDifferences(
							props.data,
							'overSpeed',
							CONFIGURATIONGROUPFACTORS,
							props.differences) ? {color: props.colorLabel } : {}
						}
						inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 },
							onKeyPress: (event: any) => {
								if (event.key === '-' || event.key === 'e') {
									event.preventDefault();
								}
							}}}
						name="overSpeed"
						value={props.data.configurationGroupFactors?.overSpeed}
						onChange={(e) => {
							props.handleChange(getNumberValue(e.target.value), 'overSpeed', CONFIGURATIONGROUPFACTORS);
						}}
						disabled={!props.editMode}
						onKeyDown={handleKeyDown}
						onWheel={(e: any) => e.target.blur()}
						validationResult={props.validationResult?.overSpeedConfigurationGroupFactors}
					/>
					</div>
			</div>
		</div>
	);
}
export default FactorsView;
