import { EditableTripDto } from 'components/RightSidebarMenu/ExtendedMenu/Tabs/SummaryTabHistory/types';
import MileageCorrectionStateEnum from 'models/MileageCorrectionStateEnum';
import { Dispatch } from 'redux';
import {filterHistoryTrips, filterTemplates} from 'utils/HistoryUtils';
import SelectionHelper from 'utils/SelectionHelper';
import { setTripsCounting } from 'utils/TripUtils';

import { LocationCategory } from '../components/Widgets/Views/CustomerLocationCategoriesView';
import { MapOptions } from '../models/MapOptions';
import TripTypesEnum from '../models/TripTypesEnum';
import { REMOVE_CUSTOMER } from './GlobalCustomer';
import { HistoryDateFilter } from './HistoryFilter';
import {LatLng} from './LiveMap';
import {SpeedGraph} from "../components/TemplateHistoryTab/TemplateHistoryTab";
import SensorTypeEnum from "../models/SensorTypeEnum";
import {TemplateOnMap} from "../components/HistoryTemplatesSelectionOnMap/HistoryTemplatesSelectionOnMap";
import EntityTypeEnum from "../models/EntityTypeEnum";


export const SET_DETAILED_TRIPS_ON_MAP = 'SET_DETAILED_TRIPS_ON_MAP';
export const SHOW_DETAILED_TRIP_ON_MAP = 'SHOW_DETAILED_TRIP_ON_MAP';
export const SET_DETAILED_VISITS_ON_MAP = 'SET_DETAILED_VISITS_ON_MAP';
export const SHOW_DETAILED_VISIT_ON_MAP = 'SHOW_DETAILED_VISIT_ON_MAP';
export const SET_PREVIOUS_DETAILED_VISITS_ON_MAP = 'SET_PREVIOUS_DETAILED_VISITS_ON_MAP';
export const SHOW_PREVIOUS_DETAILED_VISITS_ON_MAP = 'SHOW_PREVIOUS_DETAILED_VISITS_ON_MAP';
export const SET_SELECTED_TRIPS_IN_OVERVIEW = 'SET_SELECTED_TRIPS_IN_OVERVIEW';
export const SET_HIGHLIGHTED_TRIPS = 'SET_HIGHLIGHTED_TRIPS';
export const SET_TRIP_SELECTED_IDS = 'SET_TRIP_SELECTED_IDS';
export const SET_TRIP_GROUPED_IDS = 'SET_TRIP_GROUPED_IDS';
export const SET_HISTORY_GROUPING_OPTIONS = 'SET_HISTORY_GROUPING_OPTIONS';
export const UPDATE_HISTORY_TRIPS = 'UPDATE_HISTORY_TRIPS';
export const SHOW_HISTORY_TRIP_DETAILS = 'SHOW_HISTORY_TRIP_DETAILS';
export const RELOAD_HISTORY_TRIP_DETAILS = 'RELOAD_HISTORY_TRIP_DETAILS';
export const HISTORY_SET_EXPANDED_SECTIONS = 'HISTORY_SET_EXPANDED_SECTIONS';
export const HISTORY_SET_SAVE_XLSX = 'HISTORY_SET_SAVE_XLSX';
export const SET_IS_MAP_ACTIVE = 'HISTORY_SET_IS_MAP_ACTIVE';
export const SET_TRIP_UNSAVED_DATA = 'SET_TRIP_UNSAVED_DATA';
export const SET_FOCUS_ON_CHANGE = 'SET_FOCUS_ON_CHANGE';
export const SET_HISTORY_MAP_OPTIONS = 'SET_HISTORY_MAP_OPTIONS';
export const SET_HISTORY_TRIPS_DATA = 'SET_HISTORY_TRIPS_DATA';
export const APPEND_HISTORY_TRIPS_DATA = 'APPEND_HISTORY_TRIPS_DATA';
export const CLEAR_HISTORY_TRIPS_DATA = 'CLEAR_HISTORY_TRIPS_DATA';
export const SET_DAY_TRIPS_COUNT = 'SET_DAY_TRIPS_COUNT';
export const UPDATE_NOT_VISIBLE_TRIPS = 'UPDATE_NOT_VISIBLE_TRIPS';
export const REMOVE_HISTORY_TRIPS_DATA_BY_ENTITIES = 'REMOVE_HISTORY_TRIPS_DATA_BY_ENTITIES';
export const TRIP_WITH_UPDATED_MILEAGE_CORRECTION = 'TRIP_WITH_UPDATED_MILEAGE_CORRECTION';
export const SET_HISTORY_GRID_TRIPS = 'SET_HISTORY_GRID_TRIPS';
export const APPEND_HISTORY_GRID_TRIPS = 'APPEND_HISTORY_GRID_TRIPS';
export const SET_SELECTED_LOCATION_CATEGORIES_HISTORY = 'SET_SELECTED_LOCATION_CATEGORIES_HISTORY';
export const SET_EDITED_TRIP_DRIVER = 'SET_EDITED_TRIP_DRIVER';
export const UPDATE_EDITED_TRIP = 'UPDATE_EDITED_TRIP';
export const UPDATE_TRIP_MILEAGE_CORRECTION = 'UPDATE_TRIP_MILEAGE_CORRECTION';
export const SET_SELECTED_POINT_HISTORY_TRIP_CHART = 'SET_SELECTED_POINT_HISTORY_TRIP_CHART';
export const SET_HISTORY_TEMPLATES = 'SET_HISTORY_TEMPLATES';
export const SET_SHOW_TEMPLATES = 'SET_SHOW_TEMPLATES';
export const SET_SELECTED_TEMPLATES_ON_HISTORY = 'SET_SELECTED_TEMPLATES_ON_HISTORY';
export const SET_TEMPLATES_ON_MAP = 'SET_TEMPLATES_ON_MAP';
export const SET_TRIP_EDIT_BATCH = 'SET_TRIP_EDIT_BATCH';
export const SET_HISTORY_RELOAD_FORCED = 'SET_HISTORY_RELOAD_FORCED';
export const CLEAR_TRIP_EDIT_BATCH = 'CLEAR_TRIP_EDIT_BATCH';
export const privateDataMask = '****';

export enum TripGrouping {
	tripNumber = 1,
	objectName = 2,
	driverName = 3,
	tripDate = 4,
	objectOrDriver = 5,
}

export interface HistoryGrouping {
	ascending: boolean;
	dayTripsCount: boolean;
	grouping: TripGrouping;
}

export interface HistoryGraphDto{
	tripId: string;
	tripColor: string;
	latitude: number;
	longitude: number;
}
export interface TripEditBatch {
	selectedEntity: string;
	selectedEntityName: string;
	selectedEntityType?: EntityTypeEnum;
	activeEdit: boolean;
	hasTripDevice: boolean;
}
export interface HistoryTripDto {
	id: string;
	isNonTrip: boolean;
	isPerson: boolean;
	parentId: string;
	personId: string;
	objectName: string;
	personName: string;
	private: boolean;
	startAddress: string;
	endAddress: string;
	startTrip: string;
	startTripTime: string;
	endTrip: string;
	endTripTime: string;
	tripDuration: number;
	totalMileage: number;
	visitTime: number;
	start: LatLng;
	end: LatLng;
	maxSpeed: number;
	isInProgress: boolean;
	path: LatLng[];
	index: number;
	prevConnectedTripId: string;
	prevTripId: string;
	nextConnectedTripId: string;
	nextTripId: string;
	isNextTripPrivate: boolean;
	isPreviousTripPrivate: boolean;
	tripColor: string;
	isParked: boolean;
	averageSpeed: number;
	speedGraph: SpeedGraph[];
	direction: string;
	startMileage: number;
	endMileage: number;
	endMileageCorrection: number;
	objectId: string;
	objectIcon: string;
	reference: string;
	description: string;
	tripDetourId: string;
	detourReference: string;
	detourDescription: string;
	trackType: TripTypesEnum;
	detourType: TripTypesEnum;
	detourMileage: number;
	personCode: string;
	objectCode: string;
	createdDateTime: string;
	allowMileageCorrections: boolean;
	mileageCorrectionStatus: MileageCorrectionStateEnum;
	startLocationAddress: string;
	endLocationAddress: string;
	startLocationName: string;
	startLocationId: string;
	endLocationName: string;
	endLocationId: string;
	zIndex: number;
	timeZoneMinutesOffset: number;
	timezoneId: string;
	tripHasPendingEdits: boolean;
}

export interface ProcessedTripDto extends HistoryTripDto {
	visible: boolean;
	objectNameWithTimeZoneOffset: string;
	updateTimestamp?: string;
	dayTripsIndex: number;
	idWithParent: string;
}

export interface HiddenTripDto {
	id: string;
	startTripTime: string;
	endTripTime: string;
	private: boolean;
	trackType: TripTypesEnum;
	objectId: string;
	objectIcon: string;
	personId: string;
	visitTime: number;
	isParked: boolean;
	nextTripId: string;
	prevTripId: string;
	isPerson: boolean;
	parentId: string;
	objectName: string;
	personName: string;
	endAddress: string;
	startAddress: string;
	isNextTripPrivate: boolean;
	isPrevTripPrivate: boolean;
	startLocationAddress: string;
	endLocationAddress: string;
	startLocationName: string;
	startLocationId: string;
	endLocationName: string;
	endLocationId: string;
	timeZoneMinutesOffset: number;
	objectNameWithTimeZoneOffset: string;
	timezoneId: string;
	idWithParent: string;
}

export interface HistoryTripTemplateDto {
	tripId: string;
	templates: HistoryTemplateDto[];
}

export interface HistoryTemplateDto {
	templateId: string;
	templatePath: TemplatePathDto[];
}

export interface TemplatePathDto {
	latitude: number;
	longitude: number;
	timestamp: string;
	color: string;
	sensorType: SensorTypeEnum;
	value: number;
	break: boolean;
}

export interface HistoryStoreState {
	tripSelectedIds: string[];
	tripGroupedIds: string[];
	historyTrips: ProcessedTripDto[];
	historyGridTrips: ProcessedTripDto[];
	hiddenHistoryTrips: HiddenTripDto[];
	previousHistoryTrips: ProcessedTripDto[];
	nextHistoryTrips: ProcessedTripDto[];
	notVisibleTrips: { dateFilter: HistoryDateFilter; tripsIds: string[] } | null;
	grouping: HistoryGrouping;
	tripDetails: ProcessedTripDto;
	expandedSections: { group: string; expanded: boolean }[];
	highlightedTripIds: string[];
	detailedTripsOnTheMapIds: string[];
	detailedVisitsOnTheMapIds: string[];
	detailedPreviousVisitsOnTheMapIds: { tripId: string; prevTripId?: string }[];
	selectedTripsInOverview: { selectedTripsInOverviewIds: string[]; selectedFromOverview: boolean };
	saveXlsxFile: boolean;
	historyMapActive: boolean;
	tripUnsavedData: boolean;
	focusOnChange: boolean;
	historySelectedCategories: LocationCategory[];
	historyMapOptions: MapOptions;
	tripWithUpdatedMileageCorrection: string | null;
	editedTripDriver: { id: string; parentId: string; oldDriverId: string; newDriverId: string } | null;
	selectedPointHistoryTripChart: HistoryGraphDto | null;
	templates: HistoryTripTemplateDto[];
	templatesOnMap: TemplateOnMap[];
	selectedTemplatesOnMap: TemplateOnMap[];
	tripEditBatch: TripEditBatch;
	historyReloadForced: boolean;
}

const initialState: HistoryStoreState = {
	tripSelectedIds: [] as string[],
	tripGroupedIds: [] as string[],
	grouping: {
		ascending: false,
		grouping: TripGrouping.tripDate,
		dayTripsCount: true,
	},
	historyTrips: [],
	historyGridTrips: [],
	hiddenHistoryTrips: [],
	previousHistoryTrips: [],
	nextHistoryTrips: [],
	notVisibleTrips: null,
	tripDetails: null,
	expandedSections: [],
	highlightedTripIds: [],
	detailedTripsOnTheMapIds: [],
	detailedVisitsOnTheMapIds: [],
	detailedPreviousVisitsOnTheMapIds: [],
	selectedTripsInOverview: { selectedTripsInOverviewIds: [], selectedFromOverview: false },
	saveXlsxFile: false,
	historyMapActive: true,
	tripUnsavedData: false,
	focusOnChange: false,
	historySelectedCategories: [],
	historyMapOptions: {
		showTraffic: { showTraffic: false, firstRender: true },
		mapType: 'roadmap',
		mapBounds: null,
		streetView: null,
		showCategories: false,
		showTemplates: false,
	},
	tripWithUpdatedMileageCorrection: null,
	editedTripDriver: null,
	selectedPointHistoryTripChart: null,
	templates: [],
	templatesOnMap: [],
	selectedTemplatesOnMap: null,
	tripEditBatch: {
		activeEdit: false,
		selectedEntity: null,
		selectedEntityType: null,
		selectedEntityName: null,
		hasTripDevice: false
	},
	historyReloadForced: false,
};

export enum AuditTripFieldsEnum {
	PersonId,
	TrackType,
	EndMileage,
	EndMileageCorrection,
	TripReference,
	TripDescription,
	HasDetour,
	DetourReference,
	DetourDescription,
	DetourType,
	DetourMileage,
}

export enum TripAuditChangesEnum {
	Create = 0,
	Update = 1,
	ReprocessCreate = 2,
	ReprocessUpdate = 3,
}

export const historyStoreActionCreators = {
	setEditedTripDriver: (
		editedTripDriver: { id: string; parentId: string; oldDriverId: string; newDriverId: string } | null
	) => (dispatch: Dispatch) => {
		dispatch({
			type: SET_EDITED_TRIP_DRIVER,
			payload: {
				editedTripDriver: editedTripDriver,
			},
		});
	},
	setHistoryTripsData: (
		hiddenTrips: HiddenTripDto[],
		trips: ProcessedTripDto[],
		previousTrips: ProcessedTripDto[],
		nextTrips: ProcessedTripDto[],
		highlightedTripIds: string[]
	) => (dispatch: Dispatch) => {
		dispatch({
			type: SET_HISTORY_TRIPS_DATA,
			payload: {
				hiddenHistoryTrips: hiddenTrips,
				historyTrips: trips,
				previousHistoryTrips: previousTrips,
				nextHistoryTrips: nextTrips,
				highlightedTripIds: highlightedTripIds,
			},
		});
	},
	appendHistoryTripsData: (
		hiddenTrips: HiddenTripDto[],
		trips: ProcessedTripDto[],
		previousTrips: ProcessedTripDto[],
		nextTrips: ProcessedTripDto[],
		dayTripsCount: boolean,
		templates: HistoryTripTemplateDto[]
	) => (dispatch: Dispatch) => {
		dispatch({
			type: APPEND_HISTORY_TRIPS_DATA,
			payload: {
				hiddenHistoryTrips: hiddenTrips,
				historyTrips: trips,
				previousHistoryTrips: previousTrips,
				nextHistoryTrips: nextTrips,
				dayTripsCount: dayTripsCount,
				templates: templates,
			},
		});
	},
	clearHistoryTripsData: () => (dispatch: Dispatch) => {
		dispatch({
			type: CLEAR_HISTORY_TRIPS_DATA,
			payload: null,
		});
	},
	setDayTripsCount: (dayTripsCount: boolean) => (dispatch: Dispatch) => {
		dispatch({
			type: SET_DAY_TRIPS_COUNT,
			payload: {
				dayTripsCount: dayTripsCount,
			},
		});
	},
	removeHistoryTripsDataByEntities: (entities: { id: string; type: string }[]) => (dispatch: Dispatch) => {
		dispatch({
			type: REMOVE_HISTORY_TRIPS_DATA_BY_ENTITIES,
			payload: {
				entities: entities,
			},
		});
	},
	updateNotVisibleTrips: (dateFilter: HistoryDateFilter) => (dispatch: Dispatch) => {
		dispatch({
			type: UPDATE_NOT_VISIBLE_TRIPS,
			payload: {
				dateFilter: dateFilter,
			},
		});
	},
	setSaveXlsx: (save: boolean) => (dispatch: Dispatch) => {
		dispatch({
			type: HISTORY_SET_SAVE_XLSX,
			payload: save,
		});
	},
	setShowTemplatesOnHistory: (showTemplates: boolean) => (dispatch: Dispatch) => {
		dispatch({
			type: SET_SHOW_TEMPLATES,
			payload: showTemplates
		});
	},
	setTripEditBatch: (tripEdit: TripEditBatch) => (dispatch: Dispatch) => {
		dispatch({
			type: SET_TRIP_EDIT_BATCH,
			payload: tripEdit
		});
	},
	clearTripEditBatch: () => (dispatch: Dispatch) => {
		dispatch({
			type: CLEAR_TRIP_EDIT_BATCH,
			payload: null
		});
	},
	setTripUnsavedData: (value: boolean) => (dispatch: Dispatch) => {
		dispatch({
			type: SET_TRIP_UNSAVED_DATA,
			payload: value
		});
	},
	setHistoryReloadForced: (reload: boolean) => (dispatch: Dispatch) => {
		dispatch({
			type: SET_HISTORY_RELOAD_FORCED,
			payload: reload
		});
	},
	setSelectedCategoriesHistory: (locationCategories: LocationCategory[]) => (dispatch: Dispatch) => {
		dispatch({
			type: SET_SELECTED_LOCATION_CATEGORIES_HISTORY,
			payload: locationCategories,
		});
	},
	setSelectedTemplatesOnHistory: (templates: TemplateOnMap[]) => (dispatch: Dispatch) => {
		dispatch({
			type: SET_SELECTED_TEMPLATES_ON_HISTORY,
			payload: templates,
		});
	},
	setIsMapActive: () => (dispatch: Dispatch) => {
		dispatch({
			type: SET_IS_MAP_ACTIVE,
		});
	},
	setHistoryGridTrips: (trips: ProcessedTripDto[]) => (dispatch: Dispatch) => {
		dispatch({
			type: SET_HISTORY_GRID_TRIPS,
			payload: trips,
		});
	},
	appendHistoryGridTrips: (trips: ProcessedTripDto[]) => (dispatch: Dispatch) => {
		dispatch({
			type: APPEND_HISTORY_GRID_TRIPS,
			payload: trips,
		});
	},
	setHistoryTrips: (trips: ProcessedTripDto[]) => (dispatch: Dispatch) => {
		dispatch({
			type: UPDATE_HISTORY_TRIPS,
			payload: trips,
		});
	},
	setHistoryTemplates: (templates: HistoryTripTemplateDto[]) => (dispatch: Dispatch) => {
	dispatch({
			type: SET_HISTORY_TEMPLATES,
			payload: templates
		});
	},
	setMapTemplates: (templates: TemplateOnMap[]) => (dispatch: Dispatch) => {
		dispatch({
			type: SET_TEMPLATES_ON_MAP,
			payload: templates,
		});
	},
	showDetailedTripOnMap: (tripId: string, controlPressed: boolean) => (dispatch: Dispatch) => {
		dispatch({
			type: SHOW_DETAILED_TRIP_ON_MAP,
			payload: { tripId, controlPressed },
		});
	},
	showDetailedVisitOnMap: (tripId: string, controlPressed: boolean) => (dispatch: Dispatch) => {
		dispatch({
			type: SHOW_DETAILED_VISIT_ON_MAP,
			payload: { tripId, controlPressed },
		});
	},
	showPreviousVisitOnMap: (tripId: string, controlPressed: boolean, prevTripId?: string) => (dispatch: Dispatch) => {
		dispatch({
			type: SHOW_PREVIOUS_DETAILED_VISITS_ON_MAP,
			payload: { tripId, controlPressed, prevTripId },
		});
	},
	reloadHistoryTripDetails: (idWithParent: string) => (dispatch: Dispatch) => {
		dispatch({
			type: RELOAD_HISTORY_TRIP_DETAILS,
			payload: { idWithParent },
		});
	},
	updateEditedTrip: (tripId: string, tripData: EditableTripDto, hidePrivateData = false) => (dispatch: Dispatch) => {
		dispatch({
			type: UPDATE_EDITED_TRIP,
			payload: { tripId, tripData, hidePrivateData },
		});
	},
	updateTripMileageCorrection: (
		tripId: string,
		endMileageCorrection: number,
		mileageCorrectionStatus: MileageCorrectionStateEnum
	) => (dispatch: Dispatch) => {
		dispatch({
			type: UPDATE_TRIP_MILEAGE_CORRECTION,
			payload: { tripId, endMileageCorrection, mileageCorrectionStatus },
		});
	},
};
export const historyStoreReducer = (state: HistoryStoreState, action: any) => {
	state = state || initialState;
	switch (action.type) {
		case SET_TRIP_SELECTED_IDS:
			return {
				...state,
				tripSelectedIds: action.payload,
			};
		case SET_TRIP_GROUPED_IDS:
			return {
				...state,
				tripGroupedIds: action.payload,
			};
		case SET_HISTORY_GROUPING_OPTIONS:
			return {
				...state,
				grouping: action.payload,
			};
		case SET_SELECTED_LOCATION_CATEGORIES_HISTORY:
			return {
				...state,
				historySelectedCategories: action.payload,
			};
		case SET_SELECTED_TEMPLATES_ON_HISTORY:
			return {
				...state,
				selectedTemplatesOnMap: action.payload,
			};
		case SET_TEMPLATES_ON_MAP:
			return {
				...state,
				templatesOnMap: action.payload,
			};
		case UPDATE_HISTORY_TRIPS:
			return {
				...state,
				historyTrips: action.payload,
			};
		case REMOVE_CUSTOMER:
			return {
				...initialState,
				historyMapActive: state.historyMapActive,
			};
		case HISTORY_SET_EXPANDED_SECTIONS:
			const changedGroup = action.payload as { expanded: boolean; groupName: string };

			const newSections = state.expandedSections.filter((s) => s.group !== changedGroup.groupName);
			newSections.push({
				group: changedGroup.groupName,
				expanded: changedGroup.expanded,
			});

			return {
				...state,
				expandedSections: newSections,
			};
		case SHOW_HISTORY_TRIP_DETAILS:
			return {
				...state,
				tripDetails: action.payload,
				tripUnsavedData: action.payload ? state.tripUnsavedData : false,
			};
		case RELOAD_HISTORY_TRIP_DETAILS:
			return {
				...state,
				tripDetails: state.historyTrips.find(
					(x) => x.idWithParent === action.payload.idWithParent && !x.isParked
				),
			};
		case SET_HIGHLIGHTED_TRIPS:
			return {
				...state,
				highlightedTripIds: action.payload,
			};
		case SET_HISTORY_TEMPLATES:
			return {
				...state,
				templates: action.payload
			};
		case SET_DETAILED_TRIPS_ON_MAP:
			return {
				...state,
				detailedTripsOnTheMapIds: action.payload,
				highlightedTripIds: [
					...new Set([
						...(action.payload as string[]),
						...state.detailedVisitsOnTheMapIds,
						...SelectionHelper.getPreviousTripsIds(state.detailedPreviousVisitsOnTheMapIds),
						...state.selectedTripsInOverview?.selectedTripsInOverviewIds,
					]),
				],
			};
		case SHOW_DETAILED_TRIP_ON_MAP:
			const newTripsOnTheMapIds = SelectionHelper.createNewSelection(
				state.detailedTripsOnTheMapIds,
				action.payload.tripId,
				action.payload.controlPressed
			);

			const newStateSDT = {
				...state,
				detailedTripsOnTheMapIds: newTripsOnTheMapIds,
				detailedVisitsOnTheMapIds: action.payload.controlPressed ? state.detailedVisitsOnTheMapIds : [],
				detailedPreviousVisitsOnTheMapIds: action.payload.controlPressed
					? state.detailedPreviousVisitsOnTheMapIds
					: [],
				selectedTripsInOverview: action.payload.controlPressed
					? state.selectedTripsInOverview
					: {
							selectedTripsInOverviewIds: [],
							selectedFromOverview: false,
					  },
			};

			return {
				...newStateSDT,
				highlightedTripIds: [
					...new Set([
						...newStateSDT.detailedTripsOnTheMapIds,
						...newStateSDT.detailedVisitsOnTheMapIds,
						...SelectionHelper.getPreviousTripsIds(newStateSDT.detailedPreviousVisitsOnTheMapIds),
						...newStateSDT.selectedTripsInOverview?.selectedTripsInOverviewIds,
					]),
				],
			};
		case SET_DETAILED_VISITS_ON_MAP:
			return {
				...state,
				detailedVisitsOnTheMapIds: action.payload,
				highlightedTripIds: [
					...new Set([
						...(action.payload as string[]),
						...state.detailedTripsOnTheMapIds,
						...SelectionHelper.getPreviousTripsIds(state.detailedPreviousVisitsOnTheMapIds),
						...state.selectedTripsInOverview?.selectedTripsInOverviewIds,
					]),
				],
			};
		case SHOW_DETAILED_VISIT_ON_MAP:
			const newVisitsOnTheMapIds = SelectionHelper.createNewSelection(
				state.detailedVisitsOnTheMapIds,
				action.payload.tripId,
				action.payload.controlPressed
			);

			const newStateSDV = {
				...state,
				detailedVisitsOnTheMapIds: newVisitsOnTheMapIds,
				detailedTripsOnTheMapIds: action.payload.controlPressed ? state.detailedTripsOnTheMapIds : [],
				detailedPreviousVisitsOnTheMapIds: action.payload.controlPressed
					? state.detailedPreviousVisitsOnTheMapIds
					: [],
				selectedTripsInOverview: action.payload.controlPressed
					? state.selectedTripsInOverview
					: {
							selectedTripsInOverviewIds: [],
							selectedFromOverview: false,
					  },
			};

			return {
				...newStateSDV,
				highlightedTripIds: [
					...new Set([
						...newStateSDV.detailedVisitsOnTheMapIds,
						...newStateSDV.detailedTripsOnTheMapIds,
						...SelectionHelper.getPreviousTripsIds(newStateSDV.detailedPreviousVisitsOnTheMapIds),
						...newStateSDV.selectedTripsInOverview?.selectedTripsInOverviewIds,
					]),
				],
			};
		case SET_PREVIOUS_DETAILED_VISITS_ON_MAP:
			return {
				...state,
				detailedPreviousVisitsOnTheMapIds: action.payload,
				highlightedTripIds: [
					...new Set([
						...(action.payload as string[]),
						...state.detailedTripsOnTheMapIds,
						...state.detailedVisitsOnTheMapIds,
						...state.selectedTripsInOverview?.selectedTripsInOverviewIds,
					]),
				],
			};
		case SHOW_PREVIOUS_DETAILED_VISITS_ON_MAP:
			const newPreviousVisitsOnTheMapIds = SelectionHelper.createNewPreviousSelection(
				state.detailedPreviousVisitsOnTheMapIds,
				action.payload.tripId,
				action.payload.controlPressed,
				action.payload.prevTripId
			);

			const newStateSPDV = {
				...state,
				detailedPreviousVisitsOnTheMapIds: newPreviousVisitsOnTheMapIds,
				detailedTripsOnTheMapIds: action.payload.controlPressed ? state.detailedTripsOnTheMapIds : [],
				detailedVisitsOnTheMapIds: action.payload.controlPressed ? state.detailedVisitsOnTheMapIds : [],
				selectedTripsInOverview: action.payload.controlPressed
					? state.selectedTripsInOverview
					: {
							selectedTripsInOverviewIds: [],
							selectedFromOverview: false,
					  },
			};

			return {
				...newStateSPDV,
				highlightedTripIds: [
					...new Set([
						...SelectionHelper.getPreviousTripsIds(newStateSPDV.detailedPreviousVisitsOnTheMapIds),
						...newStateSPDV.detailedTripsOnTheMapIds,
						...newStateSPDV.detailedVisitsOnTheMapIds,
						...newStateSPDV.selectedTripsInOverview?.selectedTripsInOverviewIds,
					]),
				],
			};
		case SET_SELECTED_TRIPS_IN_OVERVIEW:
			return {
				...state,
				selectedTripsInOverview: action.payload,
				highlightedTripIds: [
					...new Set([
						...(action.payload.selectedTripsInOverviewIds as string[]),
						...state.detailedTripsOnTheMapIds,
						...state.detailedVisitsOnTheMapIds,
						...SelectionHelper.getPreviousTripsIds(state.detailedPreviousVisitsOnTheMapIds),
					]),
				],
			};
		case HISTORY_SET_SAVE_XLSX:
			return {
				...state,
				saveXlsxFile: action.payload,
			};
		case SET_SHOW_TEMPLATES:
			return {
				...state,
				historyMapOptions: {...state.historyMapOptions, showTemplates: action.payload}
			};
		case SET_IS_MAP_ACTIVE:
			return {
				...state,
				historyMapActive: !state.historyMapActive,
			};
		case SET_TRIP_EDIT_BATCH:
			return {
				...state,
				tripEditBatch: action.payload
			};
		case CLEAR_TRIP_EDIT_BATCH:
			return {
				...state,
				tripEditBatch: {
					selectedEntity: null,
					selectedEntityName: null,
					selectedEntityType: null,
					activeEdit: false,
					hasTripDevice: false
				}
			};
		case SET_HISTORY_RELOAD_FORCED:
			return {
				...state,
				historyReloadForced: action.payload
			};
		case SET_TRIP_UNSAVED_DATA:
			return {
				...state,
				tripUnsavedData: action.payload,
			};
		case SET_FOCUS_ON_CHANGE:
			return {
				...state,
				focusOnChange: action.payload,
			};
		case SET_HISTORY_MAP_OPTIONS:
			return {
				...state,
				historyMapOptions: action.payload,
			};
		case SET_HISTORY_TRIPS_DATA:
			return {
				...state,
				historyTrips: action.payload.historyTrips,
				hiddenHistoryTrips: action.payload.hiddenHistoryTrips,
				previousHistoryTrips: action.payload.previousHistoryTrips,
				nextHistoryTrips: action.payload.nextHistoryTrips,
				highlightedTripIds: action.payload.highlightedTripIds,
				templates: action.payload.templates
			};
		case APPEND_HISTORY_TRIPS_DATA:
			const tempHistoryTrips: ProcessedTripDto[] = [...state.historyTrips, ...action.payload.historyTrips];
				return {
				...state,
				historyTrips: action.payload.dayTripsCount
					? tempHistoryTrips
					: setTripsCounting(tempHistoryTrips, action.payload.dayTripsCount),
				hiddenHistoryTrips: [...state.hiddenHistoryTrips, ...action.payload.hiddenHistoryTrips],
				previousHistoryTrips: [...state.previousHistoryTrips, ...action.payload.previousHistoryTrips],
				nextHistoryTrips: [...state.nextHistoryTrips, ...action.payload.nextHistoryTrips],
				templates: [...state.templates, ...action.payload.templates]
			};
		case CLEAR_HISTORY_TRIPS_DATA:
			return {
				...state,
				historyTrips: [],
				historyGridTrips: [],
				hiddenHistoryTrips: [],
				previousHistoryTrips: [],
				nextHistoryTrips: [],
				templates: [],
			};
		case SET_DAY_TRIPS_COUNT:
			//update counting on all trips
			const countUpdatedTrips = setTripsCounting(state.historyTrips, action.payload.dayTripsCount);

			//update counting on trip details
			let countUpdatedDetails = state.tripDetails;
			if (state.tripDetails) {
				const td = countUpdatedTrips.find(
					(x) => x.idWithParent === state.tripDetails.idWithParent && !x.isParked
				);
				countUpdatedDetails = { ...countUpdatedDetails, index: td ? td.index : countUpdatedDetails.index };
			}

			return {
				...state,
				historyTrips: countUpdatedTrips,
				tripDetails: countUpdatedDetails,
			};
		case REMOVE_HISTORY_TRIPS_DATA_BY_ENTITIES:
			const historyTrips = filterHistoryTrips(state.historyTrips, action.payload.entities);
			const hiddenHistoryTrips = filterHistoryTrips(state.hiddenHistoryTrips, action.payload.entities);
			const previousHistoryTrips = filterHistoryTrips(state.previousHistoryTrips, action.payload.entities);
			const nextHistoryTrips =  filterHistoryTrips(state.nextHistoryTrips, action.payload.entities);
			const historyGridTrips = filterHistoryTrips(state.historyGridTrips, action.payload.entities);
			const historyTemplates = filterTemplates(state.templates, historyTrips);

			return {
				...state,
				historyTrips: historyTrips,
				hiddenHistoryTrips: hiddenHistoryTrips,
				previousHistoryTrips: previousHistoryTrips,
				nextHistoryTrips: nextHistoryTrips,
				historyGridTrips: historyGridTrips,
				templates: historyTemplates,
			};
		case UPDATE_NOT_VISIBLE_TRIPS:
			const notVisibleTripsIds: string[] = [];
			state.historyTrips.forEach((trip: ProcessedTripDto) => {
				if (!trip.visible) {
					notVisibleTripsIds.push(trip.idWithParent);
				}
			});

			let newNotVisibleTrips: { dateFilter: HistoryDateFilter; tripsIds: string[] } | null = null;
			if (notVisibleTripsIds.length) {
				newNotVisibleTrips = {
					dateFilter: action.payload.dateFilter,
					tripsIds: notVisibleTripsIds,
				};
			}

			return {
				...state,
				notVisibleTrips: newNotVisibleTrips,
			};
		case TRIP_WITH_UPDATED_MILEAGE_CORRECTION:
			return {
				...state,
				tripWithUpdatedMileageCorrection: action.payload,
			};
		case SET_HISTORY_GRID_TRIPS:
			return {
				...state,
				historyGridTrips: action.payload,
			};
		case APPEND_HISTORY_GRID_TRIPS:
			return {
				...state,
				historyGridTrips: [...state.historyGridTrips, ...action.payload],
			};

		case SET_EDITED_TRIP_DRIVER:
			return {
				...state,
				editedTripDriver: action.payload.editedTripDriver,
			};

		case UPDATE_EDITED_TRIP:
			const editedTrip = action.payload as {
				tripId: string;
				tripData: EditableTripDto;
				hidePrivateData: boolean;
			};

			const fillEditResult = fillEditedTripData(
				editedTrip.tripId,
				editedTrip.tripData,
				editedTrip.hidePrivateData,
				state.historyTrips,
				state.historyGridTrips
			);

			return {
				...state,
				historyTrips: fillEditResult.historyOverviewTrips,
				historyGridTrips: fillEditResult.historyGridTrips,
			};
		case UPDATE_TRIP_MILEAGE_CORRECTION:
			const mileageCorrection = action.payload as {
				tripId: string;
				endMileageCorrection: number;
				mileageCorrectionStatus: MileageCorrectionStateEnum;
			};

			const fillMileageResult = fillMileageTripData(
				mileageCorrection.tripId,
				mileageCorrection.endMileageCorrection,
				mileageCorrection.mileageCorrectionStatus,
				state.historyTrips,
				state.historyGridTrips
			);

			return {
				...state,
				historyTrips: fillMileageResult.historyOverviewTrips,
				historyGridTrips: fillMileageResult.historyGridTrips,
			};
		case SET_SELECTED_POINT_HISTORY_TRIP_CHART:
			return {
				...state,
				selectedPointHistoryTripChart: action.payload,
			};

		default:
			return state;
	}
};

const getAndUpdateTripTimestamp = (id: string, historyTrips: ProcessedTripDto[]): ProcessedTripDto[] => {
	const trips = historyTrips.filter((t) => !t.isParked && t.id === id);
	trips.forEach((trip) => {
		trip.updateTimestamp = new Date().getTime().toString();
	});
	return trips;
};

const fillEditedTripData = (
	tripId: string,
	tripData: EditableTripDto,
	hidePrivateData: boolean,
	historyOverviewTrips: ProcessedTripDto[],
	historyGridTrips: ProcessedTripDto[]
) => {
	const foundTripGrids = historyGridTrips.filter((t) => !t.isParked && t.id === tripId);
	foundTripGrids.forEach((foundTripGrid) => {
		foundTripGrid.personId = tripData.driverId;
		foundTripGrid.personName = tripData.driverFirstLastName ?? '';
		foundTripGrid.trackType = tripData.tripType;
		foundTripGrid.reference = tripData.tripReference;
		foundTripGrid.description = tripData.tripDescription;
		foundTripGrid.tripDetourId = tripData.tripDetourId;
		foundTripGrid.detourReference = tripData.detourReference;
		foundTripGrid.detourDescription = tripData.detourDescription;
		foundTripGrid.detourType = tripData.detourType;
		foundTripGrid.detourMileage = tripData.detourMileage;
		foundTripGrid.private = tripData.tripType === TripTypesEnum.Private;
		foundTripGrid.tripHasPendingEdits = tripData.tripHasPendingEdits;

		if (hidePrivateData) {
			foundTripGrid.startTripTime = null;
			foundTripGrid.startAddress = privateDataMask;
			foundTripGrid.endTripTime = null;
			foundTripGrid.endAddress = privateDataMask;
			foundTripGrid.tripDuration = null;
			foundTripGrid.visitTime = null;
		}
	});

	const foundTripOverviews = getAndUpdateTripTimestamp(tripId, historyOverviewTrips);
	foundTripOverviews.forEach((foundTripOverview) => {
		foundTripOverview.personId = tripData.driverId;
		foundTripOverview.personName = tripData.driverFirstLastName ?? '';
		foundTripOverview.trackType = tripData.tripType;
		foundTripOverview.reference = tripData.tripReference;
		foundTripOverview.description = tripData.tripDescription;
		foundTripOverview.tripDetourId = tripData.tripDetourId;
		foundTripOverview.detourReference = tripData.detourReference;
		foundTripOverview.detourDescription = tripData.detourDescription;
		foundTripOverview.detourType = tripData.detourType;
		foundTripOverview.detourMileage = tripData.detourMileage;
		foundTripOverview.private = tripData.tripType === TripTypesEnum.Private;
		foundTripOverview.tripHasPendingEdits = tripData.tripHasPendingEdits;

		if (hidePrivateData) {
			foundTripOverview.startTripTime = null;
			foundTripOverview.startAddress = privateDataMask;
			foundTripOverview.endTripTime = null;
			foundTripOverview.endAddress = privateDataMask;
			foundTripOverview.tripDuration = null;
			foundTripOverview.visitTime = null;
		}
	});

	//can be 2 parked trips with same id, one for object and one for driver
	const parkedTripGridList = historyGridTrips.filter((t) => t.isParked && t.id === tripId);
	parkedTripGridList.forEach((parkedTripGrid) => {
		parkedTripGrid.personId = tripData.driverId;
		parkedTripGrid.personName = tripData.driverFirstLastName ?? '';
	});

	//can be 2 parked trips with same id, one for object and one for driver
	const parkedTripOverviewList = historyOverviewTrips.filter((t) => t.isParked && t.id === tripId);
	parkedTripOverviewList.forEach((parkedTripOverview) => {
		parkedTripOverview.updateTimestamp = new Date().getTime().toString();
		parkedTripOverview.personId = tripData.driverId;
		parkedTripOverview.personName = tripData.driverFirstLastName ?? '';
	});
	return { historyOverviewTrips: [...historyOverviewTrips], historyGridTrips: [...historyGridTrips] };
};

const fillMileageTripData = (
	tripId: string,
	endMileageCorrection: number,
	mileageCorrectionStatus: MileageCorrectionStateEnum,
	historyOverviewTrips: ProcessedTripDto[],
	historyGridTrips: ProcessedTripDto[]
) => {
	const foundTripGrids = historyGridTrips.filter((t) => !t.isParked && t.id === tripId);
	foundTripGrids.forEach((foundTripGrid) => {
		foundTripGrid.endMileageCorrection = endMileageCorrection;
		foundTripGrid.mileageCorrectionStatus = mileageCorrectionStatus;
	});

	const foundTripOverviews = getAndUpdateTripTimestamp(tripId, historyOverviewTrips);
	foundTripOverviews.forEach((foundTripOverview) => {
		foundTripOverview.endMileageCorrection = endMileageCorrection;
		foundTripOverview.mileageCorrectionStatus = mileageCorrectionStatus;
	});

	return { historyOverviewTrips: [...historyOverviewTrips], historyGridTrips: [...historyGridTrips] };
};
