import './connectionDialog.scss'
import React, { useEffect, useState } from "react";

import {Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import {ListItem} from "../DeviceTemplateWidget/DeviceTemplateWidget";
import {TranslateText, TranslateTextInterpolated} from "../../../utils/Translations";
import {DEBOUNCE_TIME} from "../../../Constants";
import {DebouncedButton} from "../../Common/DebouncedButton";
import MaterialAutocomplete from "../../Common/Autocomplete/MaterialAutocomplete";
import {ValidationMessage} from "../../ValidationMessage/ValidationMessage";
import Validator from "../../../shared/validation/Validator";
import {ValidatorFunctions} from "../../../shared/validation/ValidatorFunctions";
import {ValidationResult} from "../../../shared/validation/interfaces";

interface ConnectionDialogProps {
	onCloseCallback: () => void;
	open: boolean;
	selectedItemId: string;
	data: ListItem[]
	setItemCallback: (id: string) => void;
	onCancelCallback: () => void;
	onConnectCallback: () => void;
	hasConflicts: boolean;
}
const ConnectionDialog = (props: ConnectionDialogProps) => {

	const [disableButton, setDisableButton] = useState<boolean>(false);
	const [validationResult, setValidationResult] = useState<ValidationResult | null>(null);
	const validator: Validator = new Validator({
		fieldRules: {
			selectedItem: {
				rules: {
					required: ValidatorFunctions.required(),
					anyConflicts: {
						message: TranslateTextInterpolated('fieldsValidations.connectionConflicts', [
							TranslateText('connections.configurationGroup'),
						]),
						validationFunction: (data) => {
							return ValidatorFunctions.wrapInPromise(!props.hasConflicts);
						},
					},
				},
			},
		},
	});

	useEffect(() => {
		validator
			.validate({
				selectedItem: props.selectedItemId
			})
			.then((result) => {
				setValidationResult(result.validationResult);
				setDisableButton(!result.formResult);
			});
	},[props.selectedItemId, props.hasConflicts,])


	return (
		props.open ?
			<Dialog
				onClose={() => props.onCloseCallback}
				className="connection-dialog-configuration add-connection-dialog-configuration"
				open={props.open}
				fullWidth
				disableBackdropClick
			>
				<DialogTitle>{TranslateText('connections.objectConfigurationGroupHeader')}</DialogTitle>
				<DialogContent>
					<div className="connection-dialog-content-configuration">
						<div className="e-panel-content connection-dialog-form-configuration">
							<form noValidate={true}>
								<MaterialAutocomplete
									valueId={props.selectedItemId}
									dataSource={props.data}
									name="doConnectionProductListId"
									disabled={false}
									onChange={({ value }) => props.setItemCallback(value as string)}
									className="material-autocomplete"
									label={TranslateText('connections.selectConfigurationGroupPlaceholder')}
								/>
							</form>
						</div>
					</div>
				</DialogContent>
				<DialogActions>
					<div className="connection-actions-configuration">
						<div className="connection-validations-configuration">
							<ValidationMessage
								result={validationResult?.selectedItem}
							/>
						</div>
						<div className="connection-handle-buttons-configuration">
							<div className="left-side-buttons">
								<Button className="auto-cancel-button" onClick={() =>  props.onCancelCallback()}>
									{TranslateText('common.buttonCancel')}
								</Button>
							</div>
							<div className="right-side-buttons">
								<DebouncedButton
									className="auto-add-and-connect-button"
									disabled={disableButton}
									onClick={() => props.onConnectCallback()}
									debounceTime={DEBOUNCE_TIME}
								>
									{TranslateText('common.connect')}
								</DebouncedButton>
							</div>
						</div>
					</div>
				</DialogActions>
			</Dialog>
	: null);
}
export default ConnectionDialog;
