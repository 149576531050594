import './ConfigurationGroupStyle.scss';

import {AddWizardComponentProps} from 'components/NewAddWizard/NewAddWizard';
import ConfigurationGroupDto, {
	ConfigurationGroupFactors,
	ConfigurationGroupGeneralCamera,
	ConfigurationGroupGeneralInformation,
	ConfigurationGroupIncidents,
	ConfigurationGroupLevels,
	ConfigurationGroupMedia,
	ConfigurationGroupPeriodicals,
	ConfigurationGroupSettings,
	ConfigurationGroupThresholds,
	ConfigurationGroupTrips,
	ConfigurationGroupWeights,
} from 'models/ConfigurationGroupDto';
import React, {useEffect, useState} from 'react';
import {ValidationResult} from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import {TranslateText} from 'utils/Translations';

import ConfigurationGroupCamera from './Camera/ConfigurationGroupCamera';
import ConfigurationGroupMediaView from './Media/ConfigurationGroupMediaView';
import {handleConfigurationGroupChange} from './types';
import {ConfigurationGroupCameraMediaRules} from './Utils';

type Props = AddWizardComponentProps & {
	id: string;
	customerId: string;
	aspNetUserId: string;
	objects: string[];
	active: boolean;
	startDateUtc: string;
	endDateUtc?: string;
	configurationGroupGeneralInformation: ConfigurationGroupGeneralInformation;
	configurationGroupWeights: ConfigurationGroupWeights;
	configurationGroupFactors: ConfigurationGroupFactors;
	configurationGroupSettings: ConfigurationGroupSettings;
	configurationGroupThresholds: ConfigurationGroupThresholds;
	configurationGroupTrips: ConfigurationGroupTrips;
	configurationGroupPeriodicals: ConfigurationGroupPeriodicals;
	configurationGroupIncidents: ConfigurationGroupIncidents;
	configurationGroupMedia: ConfigurationGroupMedia;
	configurationGroupGeneralCamera: ConfigurationGroupGeneralCamera;
	configurationGroupLevels: ConfigurationGroupLevels;
};
export const inputLabelAutocompleteStyle = (data: any[] | string) => {
	return {
		color: 'black',
		fontSize: data?.length === 0 ? 12 : 10,
		marginBottom: 4,
		transition: data?.length === 0 ? 'font-size 0.25s ease-out' : 'font-size 0.25s ease-in',
	};
}
const ConfigGroupCameraMediaView = (props: Props) => {
	const [unsavedData, setUnsavedData] = useState<boolean>(false);
	const [validationResult, setValidationResult] = useState<ValidationResult | null>(null);

	const [configurationGroupDto, setConfigurationGroupDto] = useState<ConfigurationGroupDto>({
		id: props.id,
		customerId: props.customerId,
		aspNetUserId: props.aspNetUserId,
		objects: props.objects,
		active: props.active,
		startDateUtc: props.startDateUtc,
		endDateUtc: props.endDateUtc,
		configurationGroupGeneralInformation: props.configurationGroupGeneralInformation,
		configurationGroupWeights: props.configurationGroupWeights,
		configurationGroupFactors: props.configurationGroupFactors,
		configurationGroupSettings: props.configurationGroupSettings,
		configurationGroupThresholds: props.configurationGroupThresholds,
		configurationGroupTrips: props.configurationGroupTrips,
		configurationGroupPeriodicals: props.configurationGroupPeriodicals,
		configurationGroupIncidents: props.configurationGroupIncidents,
		configurationGroupMedia: props.configurationGroupMedia,
		configurationGroupGeneralCamera: props.configurationGroupGeneralCamera,
		configurationGroupLevels: props.configurationGroupLevels,
	});

	const [validator] = useState<Validator>(() => {
		return new Validator(
			{
				fieldRules: ConfigurationGroupCameraMediaRules(),
			},
			true
		);
	});

	useEffect(() => {
		if (!props.visible) return;

		setConfigurationGroupDto({
			id: props.id,
			customerId: props.customerId,
			aspNetUserId: props.aspNetUserId,
			objects: props.objects,
			active: props.active,
			startDateUtc: props.startDateUtc,
			endDateUtc: props.endDateUtc,
			configurationGroupGeneralInformation: props.configurationGroupGeneralInformation,
			configurationGroupWeights: props.configurationGroupWeights,
			configurationGroupFactors: props.configurationGroupFactors,
			configurationGroupSettings: props.configurationGroupSettings,
			configurationGroupThresholds: props.configurationGroupThresholds,
			configurationGroupTrips: props.configurationGroupTrips,
			configurationGroupPeriodicals: props.configurationGroupPeriodicals,
			configurationGroupIncidents: props.configurationGroupIncidents,
			configurationGroupMedia: props.configurationGroupMedia,
			configurationGroupGeneralCamera: props.configurationGroupGeneralCamera,
			configurationGroupLevels: props.configurationGroupLevels,
		});
	}, [props.visible]);

	useEffect(() => {
		validator.validate(configurationGroupDto).then((result) => {
			setValidationResult(result.validationResult);
			props.setValidationCallback(result.formResult);
			if (unsavedData) {
				props.onChangeCallback(configurationGroupDto);
			}
		});
	}, [configurationGroupDto]);

	const handleValueChangeMaterial = (value: any, statePropName: any, childObjName?: string) => {
		const newConfigurationGroup = handleConfigurationGroupChange<ConfigurationGroupDto>(
			configurationGroupDto,
			statePropName,
			value,
			childObjName
		);

		setUnsavedData(true);
		setConfigurationGroupDto(newConfigurationGroup);
	};

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-md-6">
					<div className="column-title">{TranslateText('common.configurationGroupMedia')}</div>
					<form id="configurationGroupMediaForm" noValidate={true}>
						<ConfigurationGroupMediaView
							data={configurationGroupDto}
							validationResult={validationResult}
							handleChange={handleValueChangeMaterial}
						/>
					</form>
				</div>
				<div className="col-md-6">
					<div className="column-title">{TranslateText('common.configurationGroupCamera')}</div>
					<form id="configurationGroupCameraForm" noValidate={true}>
						<ConfigurationGroupCamera
							data={configurationGroupDto}
							validationResult={validationResult}
							handleChange={handleValueChangeMaterial}
						/>
					</form>
				</div>
			</div>
		</div>
	);
};

export default ConfigGroupCameraMediaView;
