import './optionsView.scss'

import React from "react";

import DateTimeUtil, {DateTimeConversionUtil} from "../../../../../shared/datetime/DateTimeUtil";
import MaterialDatePicker from "../../../../Common/DateTime/MaterialDatePicker";
import {TranslateText} from "../../../../../utils/Translations";
import MaterialAutocomplete, {AutocompleteItem} from "../../../../Common/Autocomplete/MaterialAutocomplete";
import { Checkbox, CircularProgress, FormControlLabel, Typography } from "@material-ui/core";
import {TripEditWizardDto} from "../../HistoryTripEditWizard";
import TripTypesEnum from "../../../../../models/TripTypesEnum";
import moment from 'moment';
import {ValidationResult} from "../../../../../shared/validation/interfaces";

interface OptionsProps {
	data: TripEditWizardDto;
	driversDataSource: AutocompleteItem[];
	tripTypeDataSource: AutocompleteItem[];
	handleValueChangeCallback?: (value: string | boolean | Date | TripTypesEnum, statePropName: keyof TripEditWizardDto) => void;
	setIndexCallback: (value: number) => void;
	isLoading: boolean;
	hideDriver: boolean;
	validationResult: ValidationResult;
	cancelCallback: () => void;
	driverIdentification: boolean;
	trackTypeSpecification: boolean;
}
const OptionsView = (props: OptionsProps) => {
	const lastDayAllowed = moment().add(1, 'days');
	const maxDate = lastDayAllowed.endOf('day').toDate();

	return (
	<div className={'options-view'}>
		<div className={'options-container'}>
			{!props.isLoading ?
				<>
				<div className={'field-row row-padding'}>
					<span className={'row-text'} >{TranslateText('historyBatchEdit.from')}</span>
					<MaterialDatePicker
						className={'datePickerElement'}
						name="datePickerElement"
						showTime={true}
						date={props.data?.from}
						format={DateTimeConversionUtil.syncFusionToMomentDateFormat(
							DateTimeUtil.dateTimeRemoveSeconds(),
							true
						)}
						onDateChange={(date: Date) => props.handleValueChangeCallback(date, 'from')}
						fullWidth={false}
						width={220}
						hiddenCloseButton={true}
						maxDate={maxDate}
					/>
				</div>
				<div className={'field-row'}>
					<span className={'row-text'}>{TranslateText('historyBatchEdit.to')}</span>
					<MaterialDatePicker
						className={'datePickerElement'}
						name="datePickerElement"
						showTime={true}
						date={props.data?.to}
						format={DateTimeConversionUtil.syncFusionToMomentDateFormat(
							DateTimeUtil.dateTimeRemoveSeconds(),
							true
						)}
						onDateChange={(date: Date) => props.handleValueChangeCallback(date, 'to')}
						fullWidth={false}
						width={220}
						hiddenCloseButton={true}
						maxDate={maxDate}
					/>
				</div>
				{!props.hideDriver && props.driverIdentification ?
					<div className={'field-row'}  style={{height: 30, paddingTop: 45, paddingBottom: 10}}>
					<FormControlLabel
						control={
							<Checkbox
								name="activeDriver"
								color={'primary'}
								checked={props.data?.driverSelected}
								disabled={false}
							/>
						}
						onChange={() => props.handleValueChangeCallback(!props.data?.driverSelected, 'driverSelected')}
						label={
							<Typography style={{ fontSize: 12, marginRight: 5, width: 50 }}>
								{TranslateText('fleetSelection.Driver')}
							</Typography>
						}
						labelPlacement="start"
						style={{ margin: 0, marginTop: 5, paddingRight: 20  }}
					/>
					<MaterialAutocomplete
						className={`autocomplete-input wider`}
						dataSource={props?.driversDataSource}
						valueId={props.data?.selectedDriverId}
						disabled={!props.data?.driverSelected}
						onChange={({ value }) => props.handleValueChangeCallback(value as string, 'selectedDriverId')}
						placeholder={TranslateText('historyBatchEdit.name')}
						validationResult={props.validationResult?.selectedDriverId}
						isForNewAddWizard={true}
					/>
				</div>
					: null
				}
				{props.trackTypeSpecification ?
					<div className={'field-row'} style={{height: 30, paddingTop: 45}}>
					<FormControlLabel
						control={
							<Checkbox
								name="activeTripType"
								color={'primary'}
								checked={props.data?.tripTypeSelected}
								disabled={false}
							/>
						}
						onChange={() => props.handleValueChangeCallback(!props.data?.tripTypeSelected, 'tripTypeSelected')}
						label={
							<Typography style={{ fontSize: 12, marginRight: 5, width: 50 }}>
								{TranslateText('reports.parameter.tripType')}
							</Typography>
						}
						labelPlacement="start"
						style={{ margin: 0, marginTop: 5, paddingRight: 20 }}
					/>
					<MaterialAutocomplete
						className={`autocomplete-input`}
						dataSource={props.tripTypeDataSource}
						valueId={
							props.data?.selectedTripType
						}
						onChange={({ value }) => {
							props.handleValueChangeCallback(value as TripTypesEnum, 'selectedTripType')
						}}
						disabled={!props.data?.tripTypeSelected}
						placeholder={TranslateText('historyBatchEdit.type')}
						validationResult={props.validationResult?.selectedTripType}
						isForNewAddWizard={true}
					/>
				</div>
				: null}
			</> :  <CircularProgress className="loading-component" />}
		</div>
	</div>
	);
};
export default OptionsView;
