import '../configurationGroupView.scss'

import React, { useEffect } from "react";

import {TranslateText} from "../../../../../utils/Translations";
import {
	CONFIGURATIONGROUPINCIDENTS,
	CONFIGURATIONGROUPLEVELS
} from "../../../../NewAddWizard/Views/ConfigurationGroup/constants";
import {handleKeyDown} from "../../../../Common/RemoveSpinnerOptionInput";
import {WidgetViewDefault} from "../../../Widget";
import {ConfigurationGroupSettingsEditViewProps} from "../types";
import {ConfigurationGroupSettingsEditDto} from "../ConfigurationGroupSettingsView";
import {getCurrentTabValidation, getNumberValue} from "../Utils";
import TextFieldTooltip from "../../../../../shared/components/TextFieldToolTip/TextFieldTooltip";
import {verifyDifferences} from "../../ConfigurationGroupHistoryView/Utils";

type Props = WidgetViewDefault & ConfigurationGroupSettingsEditViewProps &{
	data: ConfigurationGroupSettingsEditDto;
};
const LevelsView = (props: Props) => {

	useEffect(() => {
		if(props.editMode) {
			if(!props.isForModal) {
				const resultValidations = getCurrentTabValidation(props.validationResult, CONFIGURATIONGROUPLEVELS);

				let newTabValidations = [...props.tabValidations];
				newTabValidations[9] = resultValidations;
				props.setTabValidation(newTabValidations);
			}
		}
	},[props.validationResult, props.editMode]);

	return (
		<div className="container-configuration-group-edit">
			<div className={!props.isSingleColumn ? 'container-row' : 'container-column'}
			>
				<div className={!props.isSingleColumn ? 'col-md-4' : 'col-12'}>
					<TextFieldTooltip
						id="warningLevel"
						type="number"
						className="resize-font noSpinner"
						fullWidth
						label={TranslateText('fields.warningLevel')}
						toolTipText={TranslateText('fields.warningLevelDetails')}
						fontSizeLabel={12}
						labelStyle={props.isForModal &&
						verifyDifferences(
							props.data,
							'warningLevel',
							CONFIGURATIONGROUPLEVELS,
							props.differences) ? {color: props.colorLabel } : {}
						}
						disableTooltipButton={!props.editMode && !props.isForModal}
						inputProps={{
							readOnly: !props.editMode,
							style: { fontSize: 12 },
							onKeyPress: (event: any) => {
								if (event.key === '-' || event.key === 'e') {
									event.preventDefault();
								}
							}}}
						name="warningLevel"
						value={props.data.configurationGroupLevels?.warningLevel}
						onChange={(e) => {
							props.handleChange(getNumberValue(e.target.value), 'warningLevel', CONFIGURATIONGROUPLEVELS);
						}}
						disabled={!props.editMode}
						onKeyDown={handleKeyDown}
						onWheel={(e: any) => e.target.blur()}
						validationResult={props.validationResult?.warningConfigurationGroupLevels}
					/>
					<TextFieldTooltip
						id="criticalLevel"
						type="number"
						className="resize-font noSpinner"
						fullWidth
						label={TranslateText('fields.criticalLevel')}
						toolTipText={TranslateText('fields.criticalLevelDetails')}
						fontSizeLabel={12}
						labelStyle={props.isForModal &&
						verifyDifferences(
							props.data,
							'criticalLevel',
							CONFIGURATIONGROUPLEVELS,
							props.differences) ? {color: props.colorLabel } : {}
						}
						disableTooltipButton={!props.editMode && !props.isForModal}
						inputProps={{
							readOnly: !props.editMode,
							style: { fontSize: 12 },
							onKeyPress: (event: any) => {
								if (event.key === '-' || event.key === 'e') {
									event.preventDefault();
								}
							}}}
						name="criticalLevel"
						value={props.data.configurationGroupLevels?.criticalLevel}
						onChange={(e) => {
							props.handleChange(getNumberValue(e.target.value), 'criticalLevel', CONFIGURATIONGROUPLEVELS);
						}}
						disabled={!props.editMode}
						onKeyDown={handleKeyDown}
						onWheel={(e: any) => e.target.blur()}
						validationResult={props.validationResult?.criticalConfigurationGroupLevels}
					/>
				</div>
			</div>
		</div>
	);
}
export default LevelsView;
