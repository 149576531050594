import React from 'react';
import MaterialTextField from 'shared/components/MaterialTextField/MaterialTextField';
import { TranslateText } from 'utils/Translations';

import { InputLabel } from '@material-ui/core';

import { CONFIGURATIONGROUPWEIGHTS } from '../constants';
import { ConfigurationGroupProps } from '../types';
import {handleKeyDown} from "../../../../Common/RemoveSpinnerOptionInput";
import {getNumberValue} from "../../../../Widgets/Views/ConfigurationGroupSettingsView/Utils";

type Props = ConfigurationGroupProps & {
	validTotalWeight: boolean;
};

const ConfigurationGroupWeightsView = (props: Props) => {
	const handleChangeChild = (value: unknown, statePropName: string) => {
		props.handleChange(value, statePropName, CONFIGURATIONGROUPWEIGHTS);
	};

	return (
		<>
			{!props.validTotalWeight && (
				<InputLabel className={'validation-error font-size-10'}>
					{TranslateText('fieldsValidations.totalWeight')}
				</InputLabel>
			)}
			<div className="form-group">
				<MaterialTextField
					autoFocus={true}
					isForNewAddWizard={props.isForNewAddWizard}
					id="coast"
					className="resize-font noSpinner"
					label={TranslateText('fields.coastWeight')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="coast"
					value={props.data.configurationGroupWeights?.coast}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'coast')}
					validationResult={props.validationResult?.coastConfigurationGroupWeights}
					disabled={!props.isAddNew || !props.editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={props.isForNewAddWizard}
					id="cruise"
					className="resize-font noSpinner"
					label={TranslateText('fields.cruiseWeight')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="cruise"
					value={props.data.configurationGroupWeights?.cruise}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'cruise')}
					validationResult={props.validationResult?.cruiseConfigurationGroupWeights}
					disabled={!props.isAddNew || !props.editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={props.isForNewAddWizard}
					id="drivingBehaviour"
					className="resize-font noSpinner"
					label={TranslateText('fields.drivingBehaviourWeight')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="drivingBehaviour"
					value={props.data.configurationGroupWeights?.drivingBehaviour}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'drivingBehaviour')}
					validationResult={props.validationResult?.drivingBehaviourConfigurationGroupWeights}
					disabled={!props.isAddNew || !props.editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={props.isForNewAddWizard}
					id="harshAcceleration"
					className="resize-font noSpinner"
					label={TranslateText('fields.harshAccelerationWeight')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="harshAcceleration"
					value={props.data.configurationGroupWeights?.harshAcceleration}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'harshAcceleration')}
					validationResult={props.validationResult?.harshAccelerationConfigurationGroupWeights}
					disabled={!props.isAddNew || !props.editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={props.isForNewAddWizard}
					id="harshBrake"
					className="resize-font noSpinner"
					label={TranslateText('fields.harshBrakeWeight')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="harshBrake"
					value={props.data.configurationGroupWeights?.harshBrake}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'harshBrake')}
					validationResult={props.validationResult?.harshBrakeConfigurationGroupWeights}
					disabled={!props.isAddNew || !props.editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={props.isForNewAddWizard}
					id="harshPedalAcceleration"
					className="resize-font noSpinner"
					label={TranslateText('fields.harshPedalAccelerationWeight')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="harshPedalAcceleration"
					value={props.data.configurationGroupWeights?.harshPedalAcceleration}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'harshPedalAcceleration')}
					validationResult={props.validationResult?.harshPedalAccelerationConfigurationGroupWeights}
					disabled={!props.isAddNew || !props.editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={props.isForNewAddWizard}
					id="idle"
					className="resize-font noSpinner"
					label={TranslateText('fields.idleWeight')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="idle"
					value={props.data.configurationGroupWeights?.idle}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'idle')}
					validationResult={props.validationResult?.idleConfigurationGroupWeights}
					disabled={!props.isAddNew || !props.editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={props.isForNewAddWizard}
					id="overRpm"
					className="resize-font noSpinner"
					label={TranslateText('fields.overRpmWeight')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="overRpm"
					value={props.data.configurationGroupWeights?.overRpm}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'overRpm')}
					validationResult={props.validationResult?.overRpmConfigurationGroupWeights}
					disabled={!props.isAddNew || !props.editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={props.isForNewAddWizard}
					id="overSpeed"
					className="resize-font noSpinner"
					label={TranslateText('fields.overSpeedWeight')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="overSpeed"
					value={props.data.configurationGroupWeights?.overSpeed}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'overSpeed')}
					validationResult={props.validationResult?.overSpeedConfigurationGroupWeights}
					disabled={!props.isAddNew || !props.editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
		</>
	);
};

export default ConfigurationGroupWeightsView;
