import {ConfigurationGroupSettingsEditDto} from "../ConfigurationGroupSettingsView/ConfigurationGroupSettingsView";

export const getCurrentDifferences = (version: ConfigurationGroupSettingsEditDto, previousVersion: ConfigurationGroupSettingsEditDto): string[] => {
	const properties: string[] = [];
	const compareObjects = (obj1: any, obj2: any, parentKey = '') => {
		for (const key in obj1) {
			if (Object.prototype.hasOwnProperty.call(obj1, key)) {
				const fullKey = parentKey ? `${parentKey}.${key}` : key;

				if (obj2[key] === undefined)
				{
					properties.push(fullKey);
					continue;
				}

				if (Array.isArray(obj1[key]) && Array.isArray(obj2[key]))
				{
					if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key]))
					{
						properties.push(fullKey);
					}
				} else if (typeof obj1[key] === 'object' && obj1[key] !== null && typeof obj2[key] === 'object' && obj2[key] !== null)
				{
					compareObjects(obj1[key], obj2[key], fullKey);
				} else if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key]))
				{
					properties.push(fullKey);
				}
			}
		}

		for (const key in obj2) {
			if (Object.prototype.hasOwnProperty.call(obj2, key) && !(key in obj1))
			{
				const fullKey = parentKey ? `${parentKey}.${key}` : key;
				properties.push(fullKey);
			}
		}
	};
	compareObjects(version, previousVersion);
	return properties;
};

const getPropertyPathByFieldName = (
	obj: any,
	fieldName: string,
	childObjName?: string,
	currentPath: string = ''
): string | null => {
	if (typeof obj !== 'object' || obj === null) {
		return null;
	}

	if (!childObjName) {
		if (fieldName in obj) {
			return currentPath ? `${currentPath}.${fieldName}` : fieldName;
		}
	} else {
		for (const key in obj) {
			const fullPath = currentPath ? `${currentPath}.${key}` : key;

			if (typeof obj[key] === 'object' && obj[key] !== null) {
				if (key.toLowerCase() === childObjName.toLowerCase()) {
					if (fieldName in obj[key]) {
						return `${fullPath}.${fieldName}`;
					}
					const result = getPropertyPathByFieldName(obj[key], fieldName, childObjName, fullPath);
					if (result) return result;
				}
				const result = getPropertyPathByFieldName(obj[key], fieldName, childObjName, fullPath);
				if (result) return result;
			}
		}
	}
	return null;
};

export const verifyDifferences = (
	object: any,
	keyName: string,
	childObject: string,
	array: string[]
): boolean => {
	const key = getPropertyPathByFieldName(object, keyName, childObject);
	return array?.includes(key);
}
