import React from 'react';
import MaterialTextField from 'shared/components/MaterialTextField/MaterialTextField';
import { TranslateText } from 'utils/Translations';

import { CONFIGURATIONGROUPFACTORS } from '../constants';
import { ConfigurationGroupProps } from '../types';
import {handleKeyDown} from "../../../../Common/RemoveSpinnerOptionInput";
import {getNumberValue} from "../../../../Widgets/Views/ConfigurationGroupSettingsView/Utils";

const ConfigurationGroupFactorsView = ({
	data,
	validationResult,
	handleChange,
	isAddNew = true,
	editMode = true,
	isForNewAddWizard = true,
}: ConfigurationGroupProps) => {
	const handleChangeChild = (value: unknown, statePropName: string) => {
		handleChange(value, statePropName, CONFIGURATIONGROUPFACTORS);
	};

	return (
		<>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="coast"
					className="resize-font noSpinner"
					label={TranslateText('fields.coastFactor')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="coast"
					value={data.configurationGroupFactors?.coast}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'coast')}
					validationResult={validationResult?.coastConfigurationGroupFactors}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="cruise"
					className="resize-font noSpinner"
					label={TranslateText('fields.cruiseFactor')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="cruise"
					value={data.configurationGroupFactors?.cruise}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'cruise')}
					validationResult={validationResult?.cruiseConfigurationGroupFactors}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="drivingBehaviour"
					className="resize-font noSpinner"
					label={TranslateText('fields.drivingBehaviourFactor')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="drivingBehaviour"
					value={data.configurationGroupFactors?.drivingBehaviour}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'drivingBehaviour')}
					validationResult={validationResult?.drivingBehaviourConfigurationGroupFactors}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="harshAcceleration"
					className="resize-font noSpinner"
					label={TranslateText('fields.harshAccelerationFactor')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="harshAcceleration"
					value={data.configurationGroupFactors?.harshAcceleration}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'harshAcceleration')}
					validationResult={validationResult?.harshAccelerationConfigurationGroupFactors}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="harshBrake"
					className="resize-font noSpinner"
					label={TranslateText('fields.harshBrakeFactor')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="harshBrake"
					value={data.configurationGroupFactors?.harshBrake}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'harshBrake')}
					validationResult={validationResult?.harshBrakeConfigurationGroupFactors}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="harshPedalAcceleration"
					className="resize-font noSpinner"
					label={TranslateText('fields.harshPedalAccelerationFactor')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="harshPedalAcceleration"
					value={data.configurationGroupFactors?.harshPedalAcceleration}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'harshPedalAcceleration')}
					validationResult={validationResult?.harshPedalAccelerationConfigurationGroupFactors}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="idle"
					className="resize-font noSpinner"
					label={TranslateText('fields.idleFactor')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="idle"
					value={data.configurationGroupFactors?.idle}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'idle')}
					validationResult={validationResult?.idleConfigurationGroupFactors}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="overRpm"
					className="resize-font noSpinner"
					label={TranslateText('fields.overRpmFactor')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="overRpm"
					value={data.configurationGroupFactors?.overRpm}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'overRpm')}
					validationResult={validationResult?.overRpmConfigurationGroupFactors}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="overSpeed"
					className="resize-font noSpinner"
					label={TranslateText('fields.overSpeedFactor')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="overSpeed"
					value={data.configurationGroupFactors?.overSpeed}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'overSpeed')}
					validationResult={validationResult?.overSpeedConfigurationGroupFactors}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
		</>
	);
};

export default ConfigurationGroupFactorsView;
