import './selectedItem.scss'

import React from 'react';

import { Checkbox } from '@material-ui/core';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import {TemplateOnMap} from "../HistoryTemplatesSelectionOnMap";

interface SelectedItemProps {
	item: TemplateOnMap;
	selected: boolean;
	selectCallback: (item: TemplateOnMap, select: boolean) => void;
}
const SelectedItem = (props: SelectedItemProps) => {
	return (
		<span
			className={'item-name-row'}
			onClick={() => props.selectCallback(props.item, !props.selected)}
		>
			<Checkbox
				className="item-selection-checkbox"
				color={'default'}
				checkedIcon={<CheckBoxOutlinedIcon />}
				icon={<CheckBoxOutlineBlankOutlinedIcon />}
				checked={props.selected}
			/>
			<span className="item-selection-name" title={props.item.templateName}>
				{props.item.templateName}
			</span>
		</span>
	);
};
export default SelectedItem;
