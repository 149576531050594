import { GridFilters, MatchMode, SortOrder } from '../../models/GridOverview';

export const fieldNames = {
	code: 'code',
	firstName: 'firstName',
	lastName: 'lastName',
	username: 'username',
	driver: 'driver',
	active: 'active',
	canImpersonate: 'canImpersonate',
	canResendActivationMail: 'canResendActivationMail'
};

export const initialFilters: GridFilters = {
	sortField: fieldNames.code,
	sortOrder: SortOrder.Ascendent,
	filters: {
		[fieldNames.code]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.firstName]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.lastName]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.username]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.driver]: {
			value: null,
			matchMode: MatchMode.Equals,
		},
		[fieldNames.active]: {
			value: null,
			matchMode: MatchMode.Equals,
		},
		[fieldNames.canImpersonate]: {
			value: null,
			matchMode: MatchMode.Equals,
		},
		[fieldNames.canResendActivationMail]: {
			value: null,
			matchMode: MatchMode.Equals
		}
	},
};
