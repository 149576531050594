import './MaterialAutocomplete.scss';

import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import React, { useEffect, useState } from 'react';
import { FieldValidationResult } from 'shared/validation/interfaces';
import { TranslateText } from 'utils/Translations';

import { InputLabel } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete/Autocomplete';

export interface AutocompleteItem {
	display: string;
	id: string | number | boolean;
	selected?: boolean;
	disabled?: boolean;
}

interface Props {
	valueId?: string | number | boolean;
	dataSource: AutocompleteItem[];
	name?: string;
	label?: string;
	disabled?: boolean;
	disabledItems?: boolean;
	hideDisabled?: boolean;
	fullWidth: boolean;
	errorMsgContainerId?: string;
	className?: string;
	disableClearable?: boolean;
	onChange: (autocompleteArgs: { event: React.ChangeEvent<{}>; value: string | number | boolean }) => void;
	filterSelectedOptions: boolean;
	isForNewAddWizard?: boolean;
	validationResult?: FieldValidationResult;
	error?: boolean;
	allowNewValues?: boolean;
	allowEmptyStringAsNewValue?: boolean;
	placeholder?: string;
	setNewOptionCallback?: (item: AutocompleteItem) => void;
}

const filter = createFilterOptions<AutocompleteItem>();

const MaterialAutocomplete = (props: Props) => {
	const [selectedOption, setSelectedOption] = useState<AutocompleteItem | null>(null);
	useEffect(() => {
		if (props.dataSource) {
			const option = props.dataSource.find((x) => x.id === props.valueId);
			if (option) {
				setSelectedOption(option);
				if (props.setNewOptionCallback) {
					props.setNewOptionCallback(option);
				}
			} else {
				if (props.allowNewValues && !!props.valueId) {
					setSelectedOption({
						id: props.valueId?.toString(),
						display: props.valueId?.toString(),
					});
					if (props.setNewOptionCallback) {
						props.setNewOptionCallback({
							id: props.valueId?.toString(),
							display: props.valueId?.toString(),
						});
					}
				} else {
					setSelectedOption(null);
				}
			}
		} else {
			setSelectedOption(null);
		}
	}, [props.valueId, props.dataSource]);

	return (
		<Autocomplete
			onKeyPress={(event) => {
				if (event.key === 'Enter') {
					event.preventDefault();
					return false;
				}
			}}
			filterSelectedOptions={props.filterSelectedOptions}
			fullWidth={props.fullWidth}
			className={props.className}
			value={selectedOption}
			options={props.dataSource}
			autoHighlight
			getOptionLabel={(option) => option.display}
			disabled={props.disabled}
			noOptionsText={TranslateText('common.noRecordFound')}
			getOptionSelected={(option: AutocompleteItem, value: AutocompleteItem) => {
				if (!value || !option) {
					return false;
				}
				return value.id === option.id;
			}}
			getOptionDisabled={props.disabledItems ? (option) => option.disabled : undefined}
			onChange={(event: React.ChangeEvent<{}>, value: string | AutocompleteItem) => {
				const newValue = value as AutocompleteItem;
				props.onChange({ event, value: newValue ? newValue.id : null });
			}}
			filterOptions={
				props.allowNewValues
					? (options, params) => {
							let filtered = filter(options, params);
							if (props.hideDisabled) {
								filtered = filtered.filter((x) => !x.disabled);
							}

							const { inputValue } = params;
							// Suggest the creation of a new value
							const isExisting = options.some((option) => inputValue === option.display);
							if (
								inputValue !== '' &&
								!isExisting &&
								(props.allowEmptyStringAsNewValue || inputValue.trim() !== '')
							) {
								filtered.push({
									id: props.allowEmptyStringAsNewValue ? inputValue : inputValue.trim(),
									display: `${TranslateText('common.buttonAdd')} "${inputValue}"`,
								});
							}

							return filtered;
					  }
					: props.hideDisabled
					? (options, params) => {
							return filter(options, params).filter((x) => !x.disabled);
					  }
					: undefined
			}
			selectOnFocus={props.allowNewValues ?? undefined}
			clearOnBlur={props.allowNewValues ?? undefined}
			renderInput={(params) => {
				if (props.errorMsgContainerId) {
					params.inputProps = {
						...params.inputProps,
						...{ 'data-msg-containerid': props.errorMsgContainerId },
					};
				}

				if (props.hideDisabled && selectedOption?.disabled) {
					params.inputProps = {
						...params.inputProps,
						...{ style: { color: 'rgba(0, 0, 0, 0.38)' } },
					};
				}

				return (
					<>
						{props.isForNewAddWizard && (
							<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
								<InputLabel
									style={{
										color: props.disabled ? 'rgba(0, 0, 0, 0.38)' : 'black',
										fontSize: props.valueId !== null && props.valueId !== undefined ? 10 : 12,
										marginBottom: 4,
										transition: 'font-size 0.25s ease-in-out',
									}}
								>
									{props.label}
								</InputLabel>
								{!!props.validationResult && (
									<ValidationMessage result={props.validationResult} isForNewAddWizard={true} />
								)}
							</div>
						)}
						<TextField
							error={
								props.error ||
								(props.isForNewAddWizard &&
									!!props.validationResult &&
									!!Object.keys(props.validationResult).find((r) => !props.validationResult[r].valid))
							}
							name={props.name}
							margin={props.isForNewAddWizard ? 'none' : 'normal'}
							{...params}
							label={props.isForNewAddWizard ? null : props.label}
							placeholder={props.placeholder}
						/>
					</>
				);
			}}
			disableClearable={props.disableClearable}
		/>
	);
};

MaterialAutocomplete.defaultProps = {
	className: 'material-autocomplete',
	disableClearable: false,
	fullWidth: true,
	filterSelectedOptions: false,
	allowEmptyStringAsNewValue: true,
	disabledItems: false,
};

export default React.memo(MaterialAutocomplete);
