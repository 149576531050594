import '../ExtendedMenu.scss';

import ClaimType from 'authorization/ClaimType';
import EasyTrackFeature from 'authorization/EasyTrackFeature';
import { TemplateHistoryTab } from 'components/TemplateHistoryTab';
import { User } from 'oidc-client';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NotificationPrompt from 'shared/components/UserPrompt/NotificationPrompt';
import { ApplicationState, CurrentSession } from 'store';
import { ProcessedTripDto, SHOW_HISTORY_TRIP_DETAILS } from 'store/HistoryStore';
import { SET_HISTORY_DETAILS_TABS_SELECTION } from 'store/RightSideBar';
import { TranslateText } from 'utils/Translations';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import GoogleMaterialIconOutlined from '../../../Common/Icons/GoogleMaterialIconOutlined';
import { OverviewHistoryTab } from '../Tabs/OverviewHistoryTab';
import { SummaryTabNonTrip } from '../Tabs/SummaryTabHistory/SummaryTabNonTrip';
import { SummaryTabTrip } from '../Tabs/SummaryTabHistory/SummaryTabTrip';
import { HistoryDetailsTabsHeader } from './HistoryDetailsTabsHeader';
import { HistoryDetailsTabsEnum } from './types';
import PrivacySettingsEnum from "../../../../models/PrivacySettingsEnum";
import {ClaimUtil} from "../../../../authorization/ClaimUtil";

const HistoryDetailsTabs = () => {
	const dispatch = useDispatch();
	const selectedTab: number = useSelector((s: ApplicationState) => s.rightSideBar.historyDetailsOption);
	const tripDetails: ProcessedTripDto = useSelector((s: ApplicationState) => s.historyStore.tripDetails);
	const currentSession: CurrentSession = useSelector((s: ApplicationState) => s.currentSession);
	const user: User = useSelector((s: ApplicationState) => s.oidc.user);
	const historyTrips: ProcessedTripDto[] = useSelector((state: ApplicationState) => state.historyStore.historyTrips);
	const isLoading: boolean = useSelector((state: ApplicationState) => state.liveMap.loading);
	const customerTemplateFeature = useSelector((state: ApplicationState) =>
		state.globalCustomer?.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.templates
			: state.currentSession.customer.featuresSettings.templates
	);
	const customerPrivacyFeature = useSelector((state: ApplicationState) =>
		state.globalCustomer?.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.privacySettings
			: state.currentSession.customer.featuresSettings.privacySettings
	);
	const userCanSeePrivateData = ClaimUtil.validateHasClaim(user, ClaimType.ShowPrivateTrips);

	const [availableTabs, setAvailableTabs] = useState<HistoryDetailsTabsEnum[]>([]);
	const [showNotificationPrompt, setShowNotificationPrompt] = useState<boolean>(false);
	const [showTemplatesSummary, setShowTemplatesSummary] = useState<boolean>(false);

	useEffect(() => {
		if (!tripDetails) {
			return;
		}
		const newShowTemplatesSummary = (userCanSeePrivateData ||
				!tripDetails.private ||
				(tripDetails.private && (customerPrivacyFeature !== PrivacySettingsEnum.HidePrivateLocationAndTime)) ||
				(tripDetails.personId !== null && tripDetails?.personId === currentSession?.personId));
		setShowTemplatesSummary(newShowTemplatesSummary);
		if (!newShowTemplatesSummary && selectedTab === HistoryDetailsTabsEnum.Fuel) {
			dispatch({
				type: SET_HISTORY_DETAILS_TABS_SELECTION,
				payload: HistoryDetailsTabsEnum.Overall,
			});
		}
	}, [tripDetails?.isNonTrip, tripDetails?.private]);

	const renderRightSidebarAvailableTabs = (availableTabs: HistoryDetailsTabsEnum[]) =>
		availableTabs.map((tab: HistoryDetailsTabsEnum) => {
			switch (tab) {
				case HistoryDetailsTabsEnum.Overall:
					return (
						<Tab
							value={tab}
							key={'tab-header-' + tab}
							label={<GoogleMaterialIconOutlined iconName={'widgets'} />}
						/>
					);

				case HistoryDetailsTabsEnum.Summary:
					return (
						<Tab
							value={tab}
							key={'tab-header-' + tab}
							label={<GoogleMaterialIconOutlined iconName={'query_builder'} />}
						/>
					);
				case HistoryDetailsTabsEnum.Fuel:
					return (
						<Tab
							value={tab}
							key={'tab-header-' + tab}
							label={<GoogleMaterialIconOutlined iconName={'insights'} />}
							disabled={!showTemplatesSummary}
						/>
					);
				case HistoryDetailsTabsEnum.Speed:
					return (
						<Tab
							value={tab}
							key={'tab-header-' + tab}
							label={<GoogleMaterialIconOutlined iconName={'speed'} />}
							disabled
						/>
					);
				case HistoryDetailsTabsEnum.Settings:
					return (
						<Tab
							value={tab}
							key={'tab-header-' + tab}
							label={<GoogleMaterialIconOutlined iconName={'settings'} />}
							disabled
						/>
					);
				case HistoryDetailsTabsEnum.Messages:
					return (
						<Tab
							value={tab}
							key={'tab-header-' + tab}
							label={<GoogleMaterialIconOutlined iconName={'chat'} />}
							disabled
						/>
					);
			}
		});

	const renderTabs = (availableTabs: HistoryDetailsTabsEnum[]) =>
		availableTabs.map((tab: HistoryDetailsTabsEnum) => {
			switch (tab) {
				case HistoryDetailsTabsEnum.Overall:
					return (
						<OverviewHistoryTab
							key={'tab-content-' + tab}
							tripDetails={tripDetails}
							hidden={selectedTab !== tab}
						/>
					);
				case HistoryDetailsTabsEnum.Summary:
					return tripDetails.isNonTrip ? (
						<SummaryTabNonTrip
							key={'tab-content-' + tab}
							tripDetails={tripDetails}
							hidden={selectedTab !== tab}
							isForRow={false}
						/>
					) : (
						<SummaryTabTrip
							key={'tab-content-' + tab}
							tripDetails={tripDetails}
							hidden={selectedTab !== tab}
							isForRow={false}
						/>
					);
				case HistoryDetailsTabsEnum.Fuel:
					return (
						<TemplateHistoryTab
							key={'tab-content-' + tab}
							tripDetails={tripDetails}
							hidden={selectedTab !== tab}
						/>
					);
				case HistoryDetailsTabsEnum.Speed:
				case HistoryDetailsTabsEnum.Settings:
				case HistoryDetailsTabsEnum.Messages:
					return (
						<div key={'tab-header-' + tab} hidden={selectedTab !== tab}>
							<div />
						</div>
					);
			}
		});

	useEffect(() => {
		const tabs: HistoryDetailsTabsEnum[] = [HistoryDetailsTabsEnum.Overall];
		if (
			currentSession?.customer?.featuresSettings &&
			currentSession?.customer?.featuresSettings[EasyTrackFeature.History] &&
			user?.profile[ClaimType.History]
		) {
			tabs.push(HistoryDetailsTabsEnum.Summary);
		}
		tabs.push(
			...[
				HistoryDetailsTabsEnum.Fuel,
				HistoryDetailsTabsEnum.Speed,
				HistoryDetailsTabsEnum.Settings,
				HistoryDetailsTabsEnum.Messages,
			]
		);
		setAvailableTabs(tabs);
	}, [user, currentSession]);

	if (isLoading || !tripDetails || !historyTrips.find((trip: ProcessedTripDto) => trip.id === tripDetails.id)) {
		return null;
	}

	return (
		<div className="plus-details-container">
			<NotificationPrompt
				title={TranslateText('common.titleUnsavedData')}
				message={TranslateText('notificationMessages.cancel')}
				handleUserResponse={(response: boolean) => {
					setShowNotificationPrompt(false);
					if (response) {
						dispatch({
							type: SHOW_HISTORY_TRIP_DETAILS,
							payload: null,
						});
					}
				}}
				displayDialog={showNotificationPrompt}
			/>

			<HistoryDetailsTabsHeader
				tripDetails={tripDetails}
				onClosedButtonClicked={() => setShowNotificationPrompt(true)}
			/>

			<Tabs
				className="right-sidebar-tabs"
				value={selectedTab}
				onChange={(event: React.ChangeEvent<{}>, newValue: number) => {
					dispatch({
						type: SET_HISTORY_DETAILS_TABS_SELECTION,
						payload: newValue,
					});
				}}
			>
				{renderRightSidebarAvailableTabs(availableTabs)}
			</Tabs>
			{renderTabs(availableTabs)}
		</div>
	);
};
export default HistoryDetailsTabs;
