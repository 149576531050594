import { SummaryTabNonTrip } from 'components/RightSidebarMenu/ExtendedMenu/Tabs/SummaryTabHistory/SummaryTabNonTrip';
import { SummaryTabTrip } from 'components/RightSidebarMenu/ExtendedMenu/Tabs/SummaryTabHistory/SummaryTabTrip';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactScrollIntoViewIfNeeded from 'react-scroll-into-view-if-needed';
import { ApplicationState } from 'store';

import { TableCell, TableRow } from '@material-ui/core';

import TableViewMap from '../HistoryOverview/TableViewMap';
import { HistoryTripRowDetailsProps } from './types';

const HistoryTripRowDetails = (props: HistoryTripRowDetailsProps) => {
	const showMap = useSelector((s: ApplicationState) => s.currentSession.customer.featuresSettings.map);
	const tripActiveEditBatch =  useSelector((s: ApplicationState) => s.historyStore.tripEditBatch?.activeEdit);
	const [scrollIntoView, setScrollIntoView] = useState(false);

	//we do this so that it will scroll into view after the extended menu is fully rendered
	useEffect(() => {
		setScrollIntoView(false);
		setTimeout(() => setScrollIntoView(true), 0);
	}, [props.trip.id]);

	return (
		<>
			<TableRow />
			{!tripActiveEditBatch ?
				<TableRow
					style={{
						height: 350,
						border: '1px solid rgba(0,0,0,0.4)',
						borderWidth: '1.5px 0 1.5px',
					}}
			>
				<TableCell
					style={{
						borderTop: ' 1px solid #999999',
						borderBottom: ' 1px solid #999999',
					}}
				/>
				<TableCell
					colSpan={15}
					style={{
						paddingLeft: 0,
						paddingRight: 0,
						borderTop: ' 1px solid #999999',
						borderBottom: ' 1px solid #999999',
					}}
				>
						<ReactScrollIntoViewIfNeeded
							active={scrollIntoView}
							options={{
								behavior: 'auto',
							}}
						>
						<div
							style={{
								position: 'relative',
								display: 'flex',
								width: '100%',
								minHeight: 350,
							}}
						>
							{props.trip.isNonTrip ? (
								<SummaryTabNonTrip
									tripDetails={props.trip}
									hidden={false}
									renderForTable={true}
									previousCallback={
										props.tripHistoryIndex < props.historyTripsLength - 1
											? (saveTripDetailsCallback, notifyAboutUnsavedData) =>
													props.navigateToPreviousTrip(
														saveTripDetailsCallback,
														notifyAboutUnsavedData,
														props.trip.idWithParent
													)
											: null
									}
									nextCallback={
										props.tripHistoryIndex > 0
											? (saveTripDetailsCallback, notifyAboutUnsavedData) =>
													props.navigateToNextTrip(
														saveTripDetailsCallback,
														notifyAboutUnsavedData,
														props.trip.idWithParent
													)
											: null
									}
									isForRow={true}
								/>
							) : (
								<SummaryTabTrip
									tripDetails={props.trip}
									hidden={false}
									renderForTable={true}
									previousCallback={
										props.tripHistoryIndex < props.historyTripsLength - 1
											? (saveTripDetailsCallback, notifyAboutUnsavedData) =>
													props.navigateToPreviousTrip(
														saveTripDetailsCallback,
														notifyAboutUnsavedData,
														props.trip.idWithParent
													)
											: null
									}
									nextCallback={
										props.tripHistoryIndex > 0
											? (saveTripDetailsCallback, notifyAboutUnsavedData) =>
													props.navigateToNextTrip(
														saveTripDetailsCallback,
														notifyAboutUnsavedData,
														props.trip.idWithParent
													)
											: null
									}
									unsavedDataCallback={props.setTripHasUnsavedData}
									isForRow={true}
								/>
							)}

							<div className={'tableview-map'}>{showMap && <TableViewMap trip={props.trip} />}</div>
							<div
								style={{
									flex: 1,
									paddingLeft: 90,
								}}
							>
								{/*	filler div so that the map will not cover the buttons from SummaryTabHistory */}
							</div>
						</div>
					</ReactScrollIntoViewIfNeeded>
				</TableCell>
			</TableRow>
				: null
			}
		</>
	);
};

export default HistoryTripRowDetails;
