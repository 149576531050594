import {ValidationResult} from "../../../../shared/validation/interfaces";

export const getCurrentTabValidation = (validationResult: ValidationResult, flag: string) => {
	if(validationResult !== null) {
		for (const [key, value] of Object.entries(validationResult)) {
			if (key.includes(flag)) {
				for (const [_, validation] of Object.entries(value)) {
					if (!validation.valid)
						return false;
				}
			}
		}
	}
	return true;
}
export const getNumberValue = (value: any) => {
	return value.trim() === '' ? null : Number(value);
}
