import '../ConfigurationGroupStyle.scss';

import { AutocompleteItem } from 'components/Common/Autocomplete/MaterialAutocomplete';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import { ConfigurationGroupMediaBitMask } from 'models/ConfigurationGroupMediaBitMask';
import React, { useMemo} from 'react';
import MaterialTextField from 'shared/components/MaterialTextField/MaterialTextField';
import { getAutocompleteEnumOptions, TranslateText } from 'utils/Translations';

import { Checkbox, Chip, FormControlLabel, InputLabel, TextField, Typography } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { Autocomplete } from '@material-ui/lab';

import { CONFIGURATIONGROUPMEDIA } from '../constants';
import { ConfigurationGroupProps } from '../types';
import {inputLabelAutocompleteStyle} from "../ConfigGroupCameraMediaView";
import {handleKeyDown} from "../../../../Common/RemoveSpinnerOptionInput";
import {getNumberValue} from "../../../../Widgets/Views/ConfigurationGroupSettingsView/Utils";

const ConfigurationGroupMediaView = ({
	data,
	validationResult,
	handleChange,
	isAddNew = true,
	editMode = true,
	isForNewAddWizard = true,
}: ConfigurationGroupProps) => {
	const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
	const checkedIcon = <CheckBoxIcon fontSize="small" />;

	const MediaOptions = useMemo<AutocompleteItem[]>(
		() => getAutocompleteEnumOptions(ConfigurationGroupMediaBitMask, 'configurationGroups.mediaBitMaskEnum.', true),
		[]
	);

	const handleChangeChild = (value: any, statePropName: string) => {
		handleChange(value, statePropName, CONFIGURATIONGROUPMEDIA);
	};

	return (
		<>
			<div className="form-group">
				<FormControlLabel
					control={
						<Checkbox
							name="sendMediaOverWifi"
							checked={data.configurationGroupMedia?.sendMediaOverWifi ?? false}
							onChange={(event) => handleChangeChild(event.target.checked, 'sendMediaOverWifi')}
						/>
					}
					label={
						<Typography
							style={{
								fontSize: 10,
								marginRight: 5,
								color: true ? 'black' : 'grey',
							}}
						>
							{TranslateText('fields.sendMediaOverWifi')}
						</Typography>
					}
					disabled={!isAddNew || !editMode}
					className="m-0"
					labelPlacement="start"
				/>
			</div>
			<div className="form-group">
				<Autocomplete
					filterSelectedOptions={false}
					fullWidth={true}
					className="material-autocomplete"
					// value={data.configurationGroupMedia?.alarm}
					options={MediaOptions}
					autoHighlight
					getOptionLabel={(option) => option.display}
					noOptionsText={TranslateText('common.noRecordFound')}
					multiple
					id="checkboxes-tags-demo"
					disableCloseOnSelect
					renderOption={(option, { selected }) => (
						<React.Fragment>
							<Checkbox
								icon={icon}
								checkedIcon={checkedIcon}
								style={{ marginRight: 8 }}
								checked={selected}
							/>
							{option.display}
						</React.Fragment>
					)}
					onChange={(event, newValue) => {
						const val: ConfigurationGroupMediaBitMask[] = Object.values(newValue as AutocompleteItem[]).map(
							(x) => ConfigurationGroupMediaBitMask[x.id as keyof typeof ConfigurationGroupMediaBitMask]
						);
						handleChangeChild(val, 'alarm');
					}}
					renderTags={(tagValue, getTagProps) =>
						tagValue.map((option, index) => (
							<Chip
								key={index}
								label={option.display}
								{...getTagProps({ index })}
								className="chip-selection"
							/>
						))
					}
					renderInput={(params) => (
						<>
							<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
								<InputLabel
									style={inputLabelAutocompleteStyle(data?.configurationGroupMedia?.alarm)}
								>
									{TranslateText('fields.alarm')}
								</InputLabel>
								{!!validationResult?.alarm && (
									<ValidationMessage result={validationResult?.alarm} isForNewAddWizard={true} />
								)}
							</div>

							<TextField name={'alarm'} margin={'none'} {...params} label={null} />
						</>
					)}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="videoDurationBeforeAlarm"
					className="resize-font noSpinner"
					label={TranslateText('fields.videoDurationBeforeAlarm')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="cameraAlarmMvideoDurationBeforeAlarmask"
					value={data.configurationGroupMedia?.videoDurationBeforeAlarm}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'videoDurationBeforeAlarm')}
					validationResult={validationResult?.videoDurationBeforeAlarm}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="videoDurationAfterAlarm"
					className="resize-font noSpinner"
					label={TranslateText('fields.videoDurationAfterAlarm')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="videoDurationAfterAlarm"
					value={data.configurationGroupMedia?.videoDurationAfterAlarm}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'videoDurationAfterAlarm')}
					validationResult={validationResult?.videoDurationAfterAlarm}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<Autocomplete
					filterSelectedOptions={false}
					fullWidth={true}
					className="material-autocomplete"
					// value={selectedMediaOptions}
					options={MediaOptions}
					autoHighlight
					getOptionLabel={(option) => option.display}
					noOptionsText={TranslateText('common.noRecordFound')}
					multiple
					id="checkboxes-tags-demo"
					disableCloseOnSelect
					renderOption={(option, { selected }) => (
						<React.Fragment>
							<Checkbox
								icon={icon}
								checkedIcon={checkedIcon}
								style={{ marginRight: 8 }}
								checked={selected}
							/>
							{option.display}
						</React.Fragment>
					)}
					onChange={(event, newValue) => {
						const val: ConfigurationGroupMediaBitMask[] = Object.values(newValue as AutocompleteItem[]).map(
							(x) => ConfigurationGroupMediaBitMask[x.id as keyof typeof ConfigurationGroupMediaBitMask]
						);
						handleChangeChild(val, 'emergencyBrake');
					}}
					renderTags={(tagValue, getTagProps) =>
						tagValue.map((option, index) => (
							<Chip
								key={index}
								label={option.display}
								{...getTagProps({ index })}
								className="chip-selection"
							/>
						))
					}
					renderInput={(params) => (
						<>
							<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
								<InputLabel
									style={inputLabelAutocompleteStyle(data?.configurationGroupMedia?.emergencyBrake)}
								>
									{TranslateText('fields.emergencyBrakeEvent')}
								</InputLabel>
								{!!validationResult?.emergencyBrake && (
									<ValidationMessage
										result={validationResult?.emergencyBrake}
										isForNewAddWizard={true}
									/>
								)}
							</div>

							<TextField name={'emergencyBrake'} margin={'none'} {...params} label={null} />
						</>
					)}
				/>
			</div>
			<div className="form-group">
				<Autocomplete
					filterSelectedOptions={false}
					fullWidth={true}
					className="material-autocomplete"
					// value={selectedMediaOptions}
					options={MediaOptions}
					autoHighlight
					getOptionLabel={(option) => option.display}
					noOptionsText={TranslateText('common.noRecordFound')}
					multiple
					id="checkboxes-tags-demo"
					disableCloseOnSelect
					renderOption={(option, { selected }) => (
						<React.Fragment>
							<Checkbox
								icon={icon}
								checkedIcon={checkedIcon}
								style={{ marginRight: 8 }}
								checked={selected}
							/>
							{option.display}
						</React.Fragment>
					)}
					onChange={(event, newValue) => {
						const val: ConfigurationGroupMediaBitMask[] = Object.values(newValue as AutocompleteItem[]).map(
							(x) => ConfigurationGroupMediaBitMask[x.id as keyof typeof ConfigurationGroupMediaBitMask]
						);
						handleChangeChild(val, 'harshBrake');
					}}
					renderTags={(tagValue, getTagProps) =>
						tagValue.map((option, index) => (
							<Chip
								key={index}
								label={option.display}
								{...getTagProps({ index })}
								className="chip-selection"
							/>
						))
					}
					renderInput={(params) => (
						<>
							<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
								<InputLabel
									style={inputLabelAutocompleteStyle(data?.configurationGroupMedia?.harshBrake)}
								>
									{TranslateText('fields.harshBrakeEvent')}
								</InputLabel>
								{!!validationResult?.harshBrake && (
									<ValidationMessage result={validationResult?.harshBrake} isForNewAddWizard={true} />
								)}
							</div>

							<TextField name={'harshBrake'} margin={'none'} {...params} label={null} />
						</>
					)}
				/>
			</div>
			<div className="form-group">
				<Autocomplete
					filterSelectedOptions={false}
					fullWidth={true}
					className="material-autocomplete"
					// value={selectedMediaOptions}
					options={MediaOptions}
					autoHighlight
					getOptionLabel={(option) => option.display}
					noOptionsText={TranslateText('common.noRecordFound')}
					multiple
					id="checkboxes-tags-demo"
					disableCloseOnSelect
					renderOption={(option, { selected }) => (
						<React.Fragment>
							<Checkbox
								icon={icon}
								checkedIcon={checkedIcon}
								style={{ marginRight: 8 }}
								checked={selected}
							/>
							{option.display}
						</React.Fragment>
					)}
					onChange={(event, newValue) => {
						const val: ConfigurationGroupMediaBitMask[] = Object.values(newValue as AutocompleteItem[]).map(
							(x) => ConfigurationGroupMediaBitMask[x.id as keyof typeof ConfigurationGroupMediaBitMask]
						);
						handleChangeChild(val, 'harshAcceleration');
					}}
					renderTags={(tagValue, getTagProps) =>
						tagValue.map((option, index) => (
							<Chip
								key={index}
								label={option.display}
								{...getTagProps({ index })}
								className="chip-selection"
							/>
						))
					}
					renderInput={(params) => (
						<>
							<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
								<InputLabel
									style={inputLabelAutocompleteStyle(data?.configurationGroupMedia?.harshAcceleration)}
								>
									{TranslateText('fields.harshAccelerationEvent')}
								</InputLabel>
								{!!validationResult?.harshAcceleration && (
									<ValidationMessage
										result={validationResult?.harshAcceleration}
										isForNewAddWizard={true}
									/>
								)}
							</div>

							<TextField name={'harshAcceleration'} margin={'none'} {...params} label={null} />
						</>
					)}
				/>
			</div>
			<div className="form-group">
				<Autocomplete
					filterSelectedOptions={false}
					fullWidth={true}
					className="material-autocomplete"
					// value={selectedMediaOptions}
					options={MediaOptions}
					autoHighlight
					getOptionLabel={(option) => option.display}
					noOptionsText={TranslateText('common.noRecordFound')}
					multiple
					id="checkboxes-tags-demo"
					disableCloseOnSelect
					renderOption={(option, { selected }) => (
						<React.Fragment>
							<Checkbox
								icon={icon}
								checkedIcon={checkedIcon}
								style={{ marginRight: 8 }}
								checked={selected}
							/>
							{option.display}
						</React.Fragment>
					)}
					onChange={(event, newValue) => {
						const val: ConfigurationGroupMediaBitMask[] = Object.values(newValue as AutocompleteItem[]).map(
							(x) => ConfigurationGroupMediaBitMask[x.id as keyof typeof ConfigurationGroupMediaBitMask]
						);
						handleChangeChild(val, 'overSpeedStart');
					}}
					renderTags={(tagValue, getTagProps) =>
						tagValue.map((option, index) => (
							<Chip
								key={index}
								label={option.display}
								{...getTagProps({ index })}
								className="chip-selection"
							/>
						))
					}
					renderInput={(params) => (
						<>
							<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
								<InputLabel
									style={inputLabelAutocompleteStyle(data?.configurationGroupMedia?.overSpeedStart)}
								>
									{TranslateText('fields.overSpeedStartEvent')}
								</InputLabel>
								{!!validationResult?.overSpeedStart && (
									<ValidationMessage
										result={validationResult?.overSpeedStart}
										isForNewAddWizard={true}
									/>
								)}
							</div>

							<TextField name={'overSpeedStart'} margin={'none'} {...params} label={null} />
						</>
					)}
				/>
			</div>
			<div className="form-group">
				<Autocomplete
					filterSelectedOptions={false}
					fullWidth={true}
					className="material-autocomplete"
					// value={selectedMediaOptions}
					options={MediaOptions}
					autoHighlight
					getOptionLabel={(option) => option.display}
					noOptionsText={TranslateText('common.noRecordFound')}
					multiple
					id="checkboxes-tags-demo"
					disableCloseOnSelect
					renderOption={(option, { selected }) => (
						<React.Fragment>
							<Checkbox
								icon={icon}
								checkedIcon={checkedIcon}
								style={{ marginRight: 8 }}
								checked={selected}
							/>
							{option.display}
						</React.Fragment>
					)}
					onChange={(event, newValue) => {
						const val: ConfigurationGroupMediaBitMask[] = Object.values(newValue as AutocompleteItem[]).map(
							(x) => ConfigurationGroupMediaBitMask[x.id as keyof typeof ConfigurationGroupMediaBitMask]
						);
						handleChangeChild(val, 'overRpmStart');
					}}
					renderTags={(tagValue, getTagProps) =>
						tagValue.map((option, index) => (
							<Chip
								key={index}
								label={option.display}
								{...getTagProps({ index })}
								className="chip-selection"
							/>
						))
					}
					renderInput={(params) => (
						<>
							<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
								<InputLabel
									style={inputLabelAutocompleteStyle(data?.configurationGroupMedia?.overRpmStart)}
								>
									{TranslateText('fields.overRpmStartEvent')}
								</InputLabel>
								{!!validationResult?.overRpmStart && (
									<ValidationMessage
										result={validationResult?.overRpmStart}
										isForNewAddWizard={true}
									/>
								)}
							</div>

							<TextField name={'overRpmStart'} margin={'none'} {...params} label={null} />
						</>
					)}
				/>
			</div>
			<div className="form-group">
				<Autocomplete
					filterSelectedOptions={false}
					fullWidth={true}
					className="material-autocomplete"
					// value={selectedMediaOptions}
					options={MediaOptions}
					autoHighlight
					getOptionLabel={(option) => option.display}
					noOptionsText={TranslateText('common.noRecordFound')}
					multiple
					id="checkboxes-tags-demo"
					disableCloseOnSelect
					renderOption={(option, { selected }) => (
						<React.Fragment>
							<Checkbox
								icon={icon}
								checkedIcon={checkedIcon}
								style={{ marginRight: 8 }}
								checked={selected}
							/>
							{option.display}
						</React.Fragment>
					)}
					onChange={(event, newValue) => {
						const val: ConfigurationGroupMediaBitMask[] = Object.values(newValue as AutocompleteItem[]).map(
							(x) => ConfigurationGroupMediaBitMask[x.id as keyof typeof ConfigurationGroupMediaBitMask]
						);
						handleChangeChild(val, 'idleStart');
					}}
					renderTags={(tagValue, getTagProps) =>
						tagValue.map((option, index) => (
							<Chip
								key={index}
								label={option.display}
								{...getTagProps({ index })}
								className="chip-selection"
							/>
						))
					}
					renderInput={(params) => (
						<>
							<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
								<InputLabel
									style={inputLabelAutocompleteStyle(data?.configurationGroupMedia?.idleStart)}
								>
									{TranslateText('fields.idleStartEvent')}
								</InputLabel>
								{!!validationResult?.overRpmStart && (
									<ValidationMessage
										result={validationResult?.overRpmStart}
										isForNewAddWizard={true}
									/>
								)}
							</div>

							<TextField name={'overRpmStart'} margin={'none'} {...params} label={null} />
						</>
					)}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="videoDurationBeforeEvent"
					className="resize-font noSpinner"
					label={TranslateText('fields.videoDurationBeforeEvent')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="videoDurationBeforeEvent"
					value={data.configurationGroupMedia?.videoDurationBeforeEvent}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'videoDurationBeforeEvent')}
					validationResult={validationResult?.videoDurationBeforeEvent}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="videoDurationAfterEvent"
					className="resize-font noSpinner"
					label={TranslateText('fields.videoDurationAfterEvent')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="videoDurationAfterEvent"
					value={data.configurationGroupMedia?.videoDurationAfterEvent}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'videoDurationAfterEvent')}
					validationResult={validationResult?.videoDurationAfterEvent}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
		</>
	);
};

export default ConfigurationGroupMediaView;
