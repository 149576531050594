import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import NotificationPrompt from 'shared/components/UserPrompt/NotificationPrompt';
import { filterHistoryTrips } from 'utils/HistoryUtils';
import { TranslateText } from 'utils/Translations';

import GlobalSettings from '../../GlobalSettings.json';
import MileageCorrectionStateEnum from '../../models/MileageCorrectionStateEnum';
import TripTypesEnum from '../../models/TripTypesEnum';
import { RightSideBarOptions } from '../../shared/components/RightSideBar/RightSideBarOptions';
import { ApplicationState } from '../../store';
import {
	HiddenTripDto,
	HistoryGrouping,
	historyStoreActionCreators, HistoryTripTemplateDto,
	ProcessedTripDto,
	SET_FOCUS_ON_CHANGE,
	SHOW_HISTORY_TRIP_DETAILS,
	TRIP_WITH_UPDATED_MILEAGE_CORRECTION,
	TripGrouping,
} from '../../store/HistoryStore';
import { SET_MAP_LOADING } from '../../store/LiveMap';
import { resizableEntityActionCreators, ResizableType } from '../../store/ResizableEntityState';
import { SET_HISTORY_GRID_OPTION, SET_HISTORY_MAP_OPTION } from '../../store/RightSideBar';
import ajaxUtil from '../../utils/Ajax';
import FleetSelectionArea from '../FleetSelectionArea';
import { extendObjectNameWithTimeZoneLongFormat } from '../ObjectNameWithTimeZoneOffset/Utils';
import { ITreeNode } from '../SelectionTree/TreeNode/types';
import { NumberConstants } from '../Widgets/CustomerFeaturesView';
import HistoryFilter from './HistoryFilter';
import HistoryTripContainer from './HistoryTripContainer';
import { getGrouping, getNumberDays, TripColorsHistoryMap } from './HistoryTripsUtils';
import { useHistoryTripsRefProps } from './hooks';

interface Props {
	visible: boolean;
}

export interface TripResponse {
	hiddenTrips: HiddenTripDto[];
	previousTrips: ProcessedTripDto[];
	nextTrips: ProcessedTripDto[];
	trips: ProcessedTripDto[];
	unGroupedTrips: ProcessedTripDto[];
	templates: HistoryTripTemplateDto[];
}

export interface GroupInfo {
	allVisible: boolean;
	someVisible: boolean;
	groupingType: TripGrouping;
	iconType?: 'driver' | string;
	level: number;
	tripsNumber: number;
	totalDrivingTime: number;
	totalDistance: number;
	allNonTrips: boolean;
}

export interface Group {
	name: string;
	displayName: string;
	groupInfo: GroupInfo;
	childGroups: Group[];
	trips: ProcessedTripDto[];
}

export interface Selection {
	id: string;
	type: string;
	status: 'initial' | 'loading' | 'loaded';
}

type GetTripsMutationRequest = {
	removeTripWithMileageCorrection?: boolean;
	selectedEntities: { id: string; type: string }[];
	requestTimestamp: string;
};

const getNodesItemsFlatlist = (trackedEntities: ITreeNode[], selectedTabs: string[]) => {
	//Convert to a unique flat list of nodes items
	const treeDataDictionary: { [key: string]: ITreeNode } = {};
	trackedEntities.forEach((item) => {
		if (item.type === 'Group') {
			if (item.items) {
				item.items.forEach((child) => {
					if (selectedTabs.some((tab: string) => tab === child.id)) {
						treeDataDictionary[child.id] = child;
					}
				});
			}
		} else if (selectedTabs.some((tab: string) => tab === item.id)) {
			treeDataDictionary[item.id] = item;
		}
	});
	return Object.getOwnPropertyNames(treeDataDictionary).map((id) => treeDataDictionary[id]);
};

const HistorySideBar = (props: Props) => {
	const customerId = useSelector((state: ApplicationState) => state.currentSession.customerId);
	const trackedEntities = useSelector((state: ApplicationState) => state.fleetSelection.trackedEntities);
	const dateFilter = useSelector((state: ApplicationState) => state.historyFilter.dateFilter);
	const trackType = useSelector((state: ApplicationState) => state.historyFilter.trackType);
	const selectedTabs = useSelector((state: ApplicationState) => state.historyStore.tripSelectedIds);

	const groupingOptions = useSelector((state: ApplicationState) => state.historyStore.grouping);
	const focusOnChange = useSelector((s: ApplicationState) => s.historyStore.focusOnChange);
	const isMapActive = useSelector((state: ApplicationState) => state.historyStore.historyMapActive);
	const selectedHistoryMapOption = useSelector((state: ApplicationState) => state.rightSideBar.historyMapOption);
	const selectedHistoryGridOption = useSelector((state: ApplicationState) => state.rightSideBar.historyGridOption);
	const tripDetails = useSelector((state: ApplicationState) => state.historyStore.tripDetails);
	const historyTrips = useSelector((state: ApplicationState) => state.historyStore.historyTrips);
	const historyGridTrips = useSelector((state: ApplicationState) => state.historyStore.historyGridTrips);
	const notVisibleTrips = useSelector((state: ApplicationState) => state.historyStore.notVisibleTrips);
	const editedTripDriver = useSelector((state: ApplicationState) => state.historyStore.editedTripDriver);
	const historyReloadForced = useSelector((state: ApplicationState) => state.historyStore.historyReloadForced);

	const entitySelection = useRef<Selection[]>([]);
	const requestTimestamp = useRef<string>('');

	const [groupedTrips, setGroupedTrips] = useState<Group[]>([]);
	const [ungroupedHistoryTrips, setUngroupedHistoryTrips] = useState<ProcessedTripDto[]>([]);
	const rightSideBarResizable = useSelector((state: ApplicationState) => state.resizableEntity);
	const isDriverWithNoAuthorization = useSelector(
		(state: ApplicationState) => state.currentSession.isDriverWithNoAuthorization
	);
	const tripWithUpdatedMileageCorrection = useSelector(
		(state: ApplicationState) => state.historyStore.tripWithUpdatedMileageCorrection
	);
	const customerTimezone = useSelector((s: ApplicationState) => s.currentSession.customer.timezoneId);

	const customerTemplateFeature = useSelector((state: ApplicationState) =>
		state.globalCustomer?.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.templates
			: state.currentSession.customer.featuresSettings.templates
	);
	const firstRender = useRef(true);

	const dispatch = useDispatch();

	const { mutate } = useMutation(
		(request: GetTripsMutationRequest) => {
			return ajaxUtil.post<TripResponse>(
				`${GlobalSettings.tripsApi}/get/?customerId=${customerId}`,
				{
					selectedEntities: request.selectedEntities,
					dayTripsCount: true,
					dateFilter: {
						...dateFilter,
						from: moment(dateFilter.from).format('YYYY-MM-DDTHH:mm:ss'),
						to: moment(dateFilter.to).format('YYYY-MM-DDTHH:mm:ss'),
					},
					trackType: trackType,
					templateSettings: customerTemplateFeature
				},
				{ showLoading: false }
			);
		},
		{
			onSuccess: (response: TripResponse, variables: GetTripsMutationRequest) => {
				//ignore the response if the timestamp is changed
				if (variables.requestTimestamp !== requestTimestamp.current || !response) {
					return;
				}

				const selectedAlreadyRemoved: { id: string; type: string }[] = [];
				variables.selectedEntities.forEach((se) => {
					const selection = entitySelection.current.find((x) => x.id === se.id && x.status === 'loading');
					if (selection) {
						selection.status = 'loaded';
					} else {
						selectedAlreadyRemoved.push(se);
					}
				});

				if (!response) {
					return;
				}

				let tripResponse = response;
				if (selectedAlreadyRemoved.length) {
					tripResponse = {
						...response,
						trips: filterHistoryTrips(response.trips, selectedAlreadyRemoved),
						unGroupedTrips: filterHistoryTrips(response.unGroupedTrips, selectedAlreadyRemoved),
						nextTrips: filterHistoryTrips(response.nextTrips, selectedAlreadyRemoved),
						previousTrips: filterHistoryTrips(response.previousTrips, selectedAlreadyRemoved),
						hiddenTrips: filterHistoryTrips(response.hiddenTrips, selectedAlreadyRemoved),
					};
				}

				const tripConfigs: ProcessedTripDto[] = [];
				const hiddenTripConfigs: HiddenTripDto[] = tripResponse.hiddenTrips.map((ht: HiddenTripDto) => ({
					...ht,
					idWithParent: ht.id + ht.isPerson ? ht.personId : ht.objectId,
					objectNameWithTimeZoneOffset: extendObjectNameWithTimeZoneLongFormat(
						ht.objectName,
						ht.timeZoneMinutesOffset,
						customerTimezone !== ht.timezoneId
					),
				}));

				let setColor = getNumberDays(dateFilter.from, dateFilter.to) >= 1 && selectedTabs.length === 1;
				if (!setColor) {
					const checkDate = moment(dateFilter.to).add(-1, 'day');
					setColor = tripResponse.trips.some((x) => moment.utc(x.startTrip) < checkDate);
				}

				let notVisibleTripsIds: string[] = [];
				if (
					notVisibleTrips &&
					notVisibleTrips.tripsIds?.length &&
					notVisibleTrips.dateFilter.from === dateFilter.from &&
					notVisibleTrips.dateFilter.to === dateFilter.to &&
					notVisibleTrips.dateFilter.historyTimeRange === dateFilter.historyTimeRange
				) {
					notVisibleTripsIds = notVisibleTrips.tripsIds;
				}

				for (let i = 0; i < tripResponse.trips.length; i++) {
					const trip = tripResponse.trips[i];

					if (setColor) {
						const from = moment(dateFilter.from).format('YYYY-MM-DD');
						const numberDays = getNumberDays(from, trip.startTrip, true);
						trip.tripColor = TripColorsHistoryMap[numberDays % 7];
					}

					//set id with parent id
					trip.idWithParent = trip.id + trip.parentId;

					const tripConfigsItem = {
						...trip,
						dayTripsIndex: trip.index, //save day trips index to use it later if dayTripsCount is changed
						visible:
							!notVisibleTripsIds?.length || !notVisibleTripsIds.some((x) => x === trip.idWithParent),
						objectNameWithTimeZoneOffset: extendObjectNameWithTimeZoneLongFormat(
							trip.objectName,
							trip.timeZoneMinutesOffset,
							customerTimezone !== trip.timezoneId
						),
					};

					if (tripDetails?.idWithParent === trip.idWithParent) {
						dispatch({
							type: SHOW_HISTORY_TRIP_DETAILS,
							payload: tripConfigsItem,
						});
					}

					tripConfigs.push(tripConfigsItem);
				}

				setUngroupedHistoryTrips((prev) => [
					...prev,
					...tripResponse.unGroupedTrips.map((trip) => ({
						...trip,
						idWithParent: trip.id + trip.parentId,
						objectNameWithTimeZoneOffset: extendObjectNameWithTimeZoneLongFormat(
							trip.objectName,
							trip.timeZoneMinutesOffset,
							customerTimezone !== trip.timezoneId
						),
					})),
				]);

				dispatch(
					historyStoreActionCreators.appendHistoryTripsData(
						hiddenTripConfigs,
						tripConfigs,
						tripResponse.previousTrips.map((p) => ({ ...p, idWithParent: p.id + p.parentId })),
						tripResponse.nextTrips.map((n) => ({ ...n, idWithParent: n.id + n.parentId })),
						groupingOptions.dayTripsCount,
						tripResponse.templates,
					)
				);
				dispatch(historyStoreActionCreators.appendHistoryGridTrips(tripConfigs));

				//should be dispatched after appendHistoryTripsData
				dispatch(historyStoreActionCreators.updateNotVisibleTrips(dateFilter));

				//should be placed after setting the trips
				if (!focusOnChange) {
					dispatch({
						type: SET_FOCUS_ON_CHANGE,
						payload: true,
					});
				}
			},
			onSettled: (
				tripResponse: TripResponse,
				error: GetTripsMutationRequest,
				variables: GetTripsMutationRequest
			) => {
				if (entitySelection.current.every((x) => x.status !== 'loading')) {
					dispatch({
						type: SET_MAP_LOADING,
						payload: false,
					});
				}

				if (variables.removeTripWithMileageCorrection) {
					dispatch({
						type: TRIP_WITH_UPDATED_MILEAGE_CORRECTION,
						payload: null,
					});
				}
			},
		}
	);

	const changeSelection = (value: number) => {
		if (isMapActive) {
			if (selectedHistoryMapOption === value) {
				toggleRightMenu();
			} else if (rightSideBarResizable.resizableMenuHistoryMap.isCollapsed) {
				toggleRightMenu();
				dispatch({
					type: SET_HISTORY_MAP_OPTION,
					payload: value,
				});
			} else {
				dispatch({
					type: SET_HISTORY_MAP_OPTION,
					payload: value,
				});
			}
		} else {
			toggleRightMenu();
			dispatch({
				type: SET_HISTORY_GRID_OPTION,
				payload: value,
			});
		}
	};

	useEffect(() => {
		if (tripDetails && !selectedTabs.some((se) => se === tripDetails?.parentId)) {
			dispatch({
				type: SHOW_HISTORY_TRIP_DETAILS,
				payload: null,
			});
		}
	}, [selectedTabs]);

	useEffect(() => {
		//reload history
		if(historyReloadForced) {
			requestTimestamp.current = new Date().getTime().toString();

			//clear stored trips
			setUngroupedHistoryTrips([]);
			dispatch(historyStoreActionCreators.clearHistoryTripsData());

			entitySelection.current.forEach((x) => {
				x.status = 'initial';
			});

			updateTrips();
			dispatch(historyStoreActionCreators.setHistoryReloadForced(false));
		}
	},[historyReloadForced]);

	//get server data
	useEffect(() => {
		requestTimestamp.current = new Date().getTime().toString();

		//clear stored trips
		setUngroupedHistoryTrips([]);
		dispatch(historyStoreActionCreators.clearHistoryTripsData());

		entitySelection.current.forEach((x) => {
			x.status = 'initial';
		});

		updateTrips();
	}, [dateFilter, trackType]);

	//set selection
	useEffect(() => {
		const newSelection = getNodesItemsFlatlist(trackedEntities, selectedTabs);

		if (
			newSelection.length !== entitySelection.current.length ||
			entitySelection.current.some((sn) => !newSelection.find((nsn) => nsn.id === sn.id))
		) {
			const newEntitySelection: Selection[] = newSelection
				.filter((sn) => !entitySelection.current.find((nsn) => nsn.id === sn.id))
				.map((x) => {
					return {
						id: x.id,
						type: x.type,
						status: 'initial',
					};
				});
			const removed: Selection[] = [];
			entitySelection.current.forEach((x) => {
				if (newSelection.find((nsn) => nsn.id === x.id)) {
					newEntitySelection.push(x);
				} else {
					removed.push(x);
				}
			});

			entitySelection.current = newEntitySelection;

			if (removed.length) {
				setUngroupedHistoryTrips((prev) => filterHistoryTrips(prev, removed));
				dispatch(historyStoreActionCreators.removeHistoryTripsDataByEntities(removed));
				//should be dispatched after removeHistoryTripsDataByEntities
				dispatch(historyStoreActionCreators.updateNotVisibleTrips(dateFilter));
			}

			updateTrips();
		}
	}, [trackedEntities, selectedTabs]);

	useEffect(() => {
		if (editedTripDriver) {
			//remove the trips from the list, set the status initial to retrieve the trips with updated values
			const removed: Selection[] = [];
			entitySelection.current.forEach((x) => {
				if (
					x.id === editedTripDriver.parentId ||
					x.id === editedTripDriver.oldDriverId ||
					x.id === editedTripDriver.newDriverId
				) {
					x.status = 'initial';
					removed.push(x);
				}
			});
			setUngroupedHistoryTrips((prev) => filterHistoryTrips(prev, removed));
			dispatch(historyStoreActionCreators.removeHistoryTripsDataByEntities(removed));

			updateTrips();
			dispatch(historyStoreActionCreators.setEditedTripDriver(null));
		}
	}, [editedTripDriver]);

	useEffect(() => {
		if (!firstRender.current) {
			dispatch(historyStoreActionCreators.setDayTripsCount(groupingOptions.dayTripsCount));
		}
	}, [groupingOptions.dayTripsCount]);

	useEffect(() => {
		if (!!tripWithUpdatedMileageCorrection) {
			const foundTrips = historyTrips.filter((trip) => trip.id === tripWithUpdatedMileageCorrection);
			if (foundTrips.length) {
				ajaxUtil
					.get<{ mileage: number; status: MileageCorrectionStateEnum }>(
						`${GlobalSettings.tripsApi}/getLastMileageCorrection/${foundTrips[0].id}`
					)
					.then(({ mileage, status }) => {
						if (status === MileageCorrectionStateEnum.Applied) {
							//remove the trips from the list, set the status initial to retrieve the trips with updated values
							const removed: Selection[] = [];
							entitySelection.current.forEach((x) => {
								if (foundTrips.some((t) => t.parentId === x.id)) {
									x.status = 'initial';
									removed.push(x);
								}
							});

							dispatch({
								type: SET_MAP_LOADING,
								payload: true,
							});

							setUngroupedHistoryTrips((prev) => filterHistoryTrips(prev, removed));
							dispatch(historyStoreActionCreators.removeHistoryTripsDataByEntities(removed));

							updateTrips(true);
						}
					});
			}
		}
	}, [isMapActive]);

	const updateTrips = (removeTripWithMileageCorrection?: boolean) => {
		if (trackType === TripTypesEnum.None || !customerId || !entitySelection.current.length) {
			setUngroupedHistoryTrips([]);
			dispatch(historyStoreActionCreators.clearHistoryTripsData());
			if (!focusOnChange) {
				dispatch({
					type: SET_FOCUS_ON_CHANGE,
					payload: true,
				});
			}

			return;
		}

		dispatch({
			type: SET_MAP_LOADING,
			payload: true,
		});

		const selectedEntities: { id: string; type: string }[] = [];
		entitySelection.current.forEach((x) => {
			if (x.status === 'initial') {
				selectedEntities.push(x);
				x.status = 'loading';
			}
		});

		if (selectedEntities.length) {
			mutate({ removeTripWithMileageCorrection, selectedEntities, requestTimestamp: requestTimestamp.current });
		} else if (entitySelection.current.every((x) => x.status !== 'loading')) {
			dispatch({
				type: SET_MAP_LOADING,
				payload: false,
			});
		}
	};

	const groupingOptionsRef = useRef<HistoryGrouping | null>();
	const [groupOptionsTimestamp, setGroupOptionsTimestamp] = useState<number>(0);
	useEffect(() => {
		if (!firstRender.current) {
			const newGroupedTripsData = getGrouping(
				historyTrips,
				groupingOptions.grouping,
				groupingOptions.ascending,
				entitySelection.current.length,
				dateFilter
			);

			if (groupingOptionsRef.current !== groupingOptions) {
				groupingOptionsRef.current = groupingOptions;
				setGroupOptionsTimestamp(new Date().getTime());
			}

			setGroupedTrips(newGroupedTripsData);
		}
	}, [historyTrips, groupingOptions.grouping, groupingOptions.ascending]);

	const toggleRightMenu = () => {
		dispatch(
			resizableEntityActionCreators.setCollapsed(
				isMapActive ? ResizableType.HistoryMap : ResizableType.HistoryGrid,
				isMapActive
					? rightSideBarResizable.resizableMenuHistoryMap.isCollapsed
					: rightSideBarResizable.resizableMenuHistoryGrid.isCollapsed
			)
		);
		dispatch(
			resizableEntityActionCreators.setWidth(
				isMapActive ? ResizableType.HistoryMap : ResizableType.HistoryGrid,
				isMapActive
					? rightSideBarResizable.resizableMenuHistoryMap.width
					: rightSideBarResizable.resizableMenuHistoryGrid.width
			)
		);
	};

	useEffect(() => {
		firstRender.current = false;

		if (isDriverWithNoAuthorization && selectedHistoryMapOption === 0) {
			changeSelection(1);
		}

		return () => {
			requestTimestamp.current = new Date().getTime().toString();
		};
	}, []);

	const [showNotificationPrompt, setShowNotificationPrompt] = useState<{ callback: () => void } | null>(null);
	const {
		groupVisibilityCallbackRef,
		cardVisibilityChangedRef,
		selectionCallbackRef,
		historyTripCardPropsRef,
	} = useHistoryTripsRefProps(
		historyTrips,
		historyGridTrips,
		groupingOptions,
		tripDetails,
		setShowNotificationPrompt
	);

	return !props.visible ? null : (
		<div className={'right-sidebar-menu history-view'}>
			<RightSideBarOptions
				isDriverWithNoAuthorization={isDriverWithNoAuthorization}
				selectedOption={isMapActive ? selectedHistoryMapOption : selectedHistoryGridOption}
				changeOption={changeSelection}
				isHistory={true}
			/>
			{isMapActive
				? selectedHistoryMapOption === NumberConstants.rightSideBarFleetSelectionOption && (
						<FleetSelectionArea />
				  )
				: selectedHistoryGridOption === NumberConstants.rightSideBarFleetSelectionOption && (
						<FleetSelectionArea />
				  )}
			{isMapActive &&
			(selectedHistoryMapOption === NumberConstants.rightSideBarOverviewOption ||
				selectedHistoryGridOption === NumberConstants.rightSideBarOverviewOption) ? (
				<div className={'live-data'}>
					<HistoryFilter />
					<HistoryTripContainer
						key={groupOptionsTimestamp}
						groupedTrips={groupedTrips}
						ungroupedTrips={ungroupedHistoryTrips}
						groupVisibilityCallback={groupVisibilityCallbackRef}
						cardVisibilityChanged={cardVisibilityChangedRef}
						selectionCallback={selectionCallbackRef}
						historyTripCardPropsRef={historyTripCardPropsRef}
					/>
					{showNotificationPrompt ? (
						<NotificationPrompt
							title={TranslateText('common.titleUnsavedData')}
							message={TranslateText('notificationMessages.unsavedData')}
							handleUserResponse={(response) => {
								setShowNotificationPrompt(null);
								if (response) {
									showNotificationPrompt.callback && showNotificationPrompt.callback();
								}
							}}
							displayDialog={true}
						/>
					) : null}
				</div>
			) : null}
		</div>
	);
};
export default HistorySideBar;
