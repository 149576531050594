import MaterialAutocomplete, { AutocompleteItem } from 'components/Common/Autocomplete/MaterialAutocomplete';
import FuelTypeEnum from 'models/FuelTypeEnum';
import ObjectTypeEnum from 'models/ObjectTypeEnum';
import React, { useMemo } from 'react';
import CustomerNameDisplay from 'shared/components/CustomerNameDisplay';
import MaterialTextField from 'shared/components/MaterialTextField/MaterialTextField';
import { getAutocompleteEnumOptions, TranslateText } from 'utils/Translations';

import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';

import { CONFIGURATIONGROUPGENERALINFORMATION } from '../constants';
import { ConfigurationGroupProps } from '../types';

const GeneralInformation = ({
	currentSession,
	data,
	customerId,
	validationResult,
	handleChange,
	isAddNew = true,
	editMode = true,
	isForNewAddWizard = true,
}: ConfigurationGroupProps) => {
	const objectTypeOptions = useMemo<AutocompleteItem[]>(
		() => getAutocompleteEnumOptions(ObjectTypeEnum, 'configurationGroups.objectTypeEnum.', true),
		[]
	);

	const fuelTypeOptions = useMemo<AutocompleteItem[]>(
		() => getAutocompleteEnumOptions(FuelTypeEnum, 'configurationGroups.fuelTypeEnum.'),
		[]
	);

	const handleChangeChild = (value: any, key: any) => {
		handleChange(value, key, CONFIGURATIONGROUPGENERALINFORMATION);
	};

	return (
		<>
			<CustomerNameDisplay customerId={customerId} />
			<div className="form-group">
				<MaterialTextField
					autoFocus={true}
					isForNewAddWizard={isForNewAddWizard}
					id="configurationGroupCode"
					className="resize-font"
					label={TranslateText('fields.code')}
					inputProps={{ style: { fontSize: 10 } }}
					name="code"
					value={data.configurationGroupGeneralInformation?.code}
					handleValueChange={(value) => handleChangeChild(value, 'code')}
					validationResult={validationResult?.code}
					disabled={!isAddNew || !editMode}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="configurationGroupExternalCode"
					className="resize-font"
					label={TranslateText('fields.externalCode')}
					inputProps={{ style: { fontSize: 10 } }}
					name="externalCode"
					value={data.configurationGroupGeneralInformation?.externalCode}
					handleValueChange={(value) => handleChangeChild(value, 'externalCode')}
					validationResult={validationResult?.externalCode}
					disabled={!isAddNew || !editMode}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="configurationGroupName"
					className="resize-font"
					label={TranslateText('fields.name')}
					inputProps={{ style: { fontSize: 10 } }}
					name="name"
					value={data.configurationGroupGeneralInformation?.name}
					handleValueChange={(value) => handleChangeChild(value, 'name')}
					validationResult={validationResult?.name}
					disabled={!isAddNew || !editMode}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="configurationGroupDescription"
					className="resize-font"
					label={TranslateText('fields.description')}
					inputProps={{ style: { fontSize: 10 } }}
					name="description"
					value={data.configurationGroupGeneralInformation?.description}
					handleValueChange={(value) => handleChangeChild(value, 'description')}
					validationResult={validationResult?.description}
					disabled={!isAddNew || !editMode}
				/>
			</div>
			<div className="form-group">
				<MaterialAutocomplete
					isForNewAddWizard={isForNewAddWizard}
					valueId={data.configurationGroupGeneralInformation?.objectType}
					dataSource={objectTypeOptions}
					name="objectType"
					disabled={!isAddNew || !editMode}
					label={TranslateText('configurationGroups.objectType')}
					onChange={({ value }) => handleChangeChild(value, 'objectType')}
					validationResult={validationResult?.objectType}
				/>
			</div>
			<div className="form-group">
				<MaterialAutocomplete
					isForNewAddWizard={isForNewAddWizard}
					valueId={data.configurationGroupGeneralInformation?.fuelType}
					dataSource={fuelTypeOptions}
					name="fuelType"
					disabled={!isAddNew || !editMode}
					label={TranslateText('configurationGroups.fuelType')}
					onChange={({ value }) => handleChangeChild(value, 'fuelType')}
					validationResult={validationResult?.fuelType}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					autoFocus={true}
					isForNewAddWizard={isForNewAddWizard}
					id="aspNetUserId"
					className="resize-font"
					label={TranslateText('fields.madeBy')}
					inputProps={{ style: { fontSize: 10 } }}
					name="aspNetUserId"
					hasDefaultValue={true}
					value={currentSession.userFirstAndLastName}
					handleValueChange={() => handleChange(currentSession.aspNetUserId, 'aspNetUserId')}
					validationResult={validationResult?.aspNetUserId}
					disabled={true}
				/>
			</div>
			<div className="form-group">
				<FormControlLabel
					control={
						<Checkbox
							name="active"
							checked={data?.active ?? true}
							onChange={(event) => handleChange(event.target.checked, 'active')}
						/>
					}
					label={
						<Typography
							style={{
								fontSize: 10,
								marginRight: 5,
								color: true ? 'black' : 'grey',
							}}
						>
							{TranslateText('fields.active')}
						</Typography>
					}
					disabled={false}
					className="m-0"
					labelPlacement="start"
				/>
			</div>
		</>
	);
};

export default GeneralInformation;
