import './styles.scss';

import BooleanColumn from 'components/GridOverview/Columns/BooleanColumn';
import ButtonColumn from 'components/GridOverview/Columns/ButtonColumn';
import TextColumn from 'components/GridOverview/Columns/TextColumn';
import NewGridOverview from 'components/GridOverview/NewGridOverview';
import CustomerLevelEnum from 'models/CustomerLevelEnum';
import DataExchangeTypeEnum from 'models/DataExchangeTypeEnum';
import EntityTypeEnum from 'models/EntityTypeEnum';
import { GridFilters, MatchMode, SortOrder } from 'models/GridOverview';
import IntegrationTypeEnum from 'models/IntegrationTypeEnum';
import RemoteAccessTypeEnum from 'models/RemoteAccessTypeEnum';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import NotificationPrompt from 'shared/components/UserPrompt/NotificationPrompt';
import ajaxUtil from 'utils/Ajax';
import { useToggleWithoutInit } from 'utils/hooks';

import { Button, Icon } from '@material-ui/core';

import ClaimType, { ClaimValue } from '../../authorization/ClaimType';
import { ClaimUtil } from '../../authorization/ClaimUtil';
import GlobalSettings from '../../GlobalSettings.json';
import { ApplicationState } from '../../store';
import {
	getMultiSelectionEnumColumn,
	TranslateText,
	TranslateTextInterpolated,
	TranslateWithFailText,
} from '../../utils/Translations';
import MultiSelectionColumn, {
	ColumnFlagOrdering,
	computeFlagOrderingBasedOnTranslation,
	MultiSelectionColumnOption,
} from '../GridOverview/Columns/MultiSelectionColumn';
import { IntegrationOverviewDto } from './types';

const fieldNames = {
	customerName: 'customerName',
	name: 'name',
	integrationType: 'integrationType',
	dataExchangeType: 'dataExchangeType',
	remoteAccessType: 'remoteAccessType',
	active: 'active',
};

const initialFilters: GridFilters = {
	sortField: fieldNames.name,
	sortOrder: SortOrder.Ascendent,
	filters: {
		[fieldNames.customerName]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.name]: {
			value: '',
			matchMode: MatchMode.Contains,
		},
		[fieldNames.integrationType]: {
			value: [],
			matchMode: MatchMode.In,
		},
		[fieldNames.dataExchangeType]: {
			value: [],
			matchMode: MatchMode.In,
		},
		[fieldNames.remoteAccessType]: {
			value: [],
			matchMode: MatchMode.In,
		},
		[fieldNames.active]: {
			value: null,
			matchMode: MatchMode.Equals,
		},
	},
};

export const IntegrationsOverview = () => {
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const customerLevel = useSelector((s: ApplicationState) =>
		s.globalCustomer.filteredCustomer ? s.globalCustomer.filteredCustomer.level : s.currentSession.customerLevel
	);
	const driverStyleScoresFeature = useSelector((s: ApplicationState) =>
		s.globalCustomer.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.driverStyleScores
			: s.currentSession.customer.featuresSettings.driverStyleScores
	);
	const mediaFootageFeature = useSelector((s: ApplicationState) =>
		s.globalCustomer.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.mediaFootage
			: s.currentSession.customer.featuresSettings.mediaFootage
	);
	const integrationFeature = useSelector((s: ApplicationState) =>
		s.globalCustomer.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.integration
			: s.currentSession.customer.featuresSettings.integration
	);

	//options
	const integrationTypeSelectOptions = useMemo<MultiSelectionColumnOption[]>(
		() => getMultiSelectionEnumColumn(IntegrationTypeEnum, 'integrations.integrationTypeEnum.', true),
		[]
	);
	const dataExchangeTypeOptions = useMemo<MultiSelectionColumnOption[]>(
		() => getMultiSelectionEnumColumn(DataExchangeTypeEnum, 'integrations.dataExchangeTypeEnum.'),
		[]
	);
	const remoteAccessTypeOptions = useMemo<MultiSelectionColumnOption[]>(
		() => getMultiSelectionEnumColumn(RemoteAccessTypeEnum, 'integrations.remoteAccessTypeEnum.'),
		[]
	);
	const columnFlagOrdering = useMemo<ColumnFlagOrdering>(
		() => ({
			[fieldNames.integrationType]: computeFlagOrderingBasedOnTranslation(integrationTypeSelectOptions),
			[fieldNames.dataExchangeType]: computeFlagOrderingBasedOnTranslation(dataExchangeTypeOptions),
			[fieldNames.remoteAccessType]: computeFlagOrderingBasedOnTranslation(remoteAccessTypeOptions),
		}),
		[]
	);

	//delete
	const [fetchDataTrigger, toggleFetchDataTrigger] = useToggleWithoutInit();
	const [notificationPrompt, setNotificationPrompt] = useState<{ message: string; callback: () => void } | null>();
	const deleteIntegration = (integration: IntegrationOverviewDto) => {
		setNotificationPrompt({
			message: TranslateTextInterpolated('integrations.deleteIntegrationMessage', [integration.name]),
			callback: () => {
				const url = GlobalSettings.integrationsMaintenanceApi + '/deleteIntegration/' + integration.id;
				ajaxUtil.delete<{ success: boolean }>(url).then((data) => {
					if (data.success) {
						toggleFetchDataTrigger();
					}
				});
			},
		});
	};

	//columns
	const columns = [
		TextColumn({
			fieldName: fieldNames.customerName,
			header: TranslateText('maintenanceOverview.grid.colCustomer'),
			body: (rowData: IntegrationOverviewDto) => (
				<Link title={rowData.customerName} to={`${GlobalSettings.route.customers}/${rowData.customerId}`}>
					{rowData.customerName}
				</Link>
			),
			sortable: true,
			filterable: true,
			shown: ClaimUtil.validateClaim(user, {
				claim: ClaimType.Customers,
				values: [ClaimValue.view],
			}),
		}),
		TextColumn({
			fieldName: fieldNames.name,
			header: TranslateText('maintenanceOverview.grid.colName'),
			sortable: true,
			filterable: true,
		}),
		MultiSelectionColumn({
			fieldName: fieldNames.integrationType,
			header: TranslateText('maintenanceOverview.grid.colIntegrationType'),
			sortable: true,
			filterable: true,
			body: (rowData: IntegrationOverviewDto) => {
				const enumValue = IntegrationTypeEnum[rowData.integrationType]?.toString();
				const integrationType = TranslateWithFailText(
					`integrations.integrationTypeEnum.${enumValue}`,
					enumValue
				);
				return <span title={integrationType}>{integrationType}</span>;
			},
			selectOptions: integrationTypeSelectOptions,
		}),
		MultiSelectionColumn({
			fieldName: fieldNames.dataExchangeType,
			header: TranslateText('maintenanceOverview.grid.colDataExchange'),
			sortable: true,
			filterable: true,
			body: (rowData: IntegrationOverviewDto) => {
				const enumValue = DataExchangeTypeEnum[rowData.dataExchangeType]?.toString();
				const dataExchangeTypeText = TranslateWithFailText(
					`integrations.dataExchangeTypeEnum.${enumValue}`,
					enumValue
				);
				return <span title={dataExchangeTypeText}>{dataExchangeTypeText}</span>;
			},
			selectOptions: dataExchangeTypeOptions,
		}),
		MultiSelectionColumn({
			fieldName: fieldNames.remoteAccessType,
			header: TranslateText('maintenanceOverview.grid.colRemoteAccess'),
			sortable: true,
			filterable: true,
			body: (rowData: IntegrationOverviewDto) => {
				const enumValue = RemoteAccessTypeEnum[rowData.remoteAccessType]?.toString();
				const remoteAccessTypeText = TranslateWithFailText(
					`integrations.remoteAccessTypeEnum.${enumValue}`,
					enumValue
				);
				return <span title={remoteAccessTypeText}>{remoteAccessTypeText}</span>;
			},
			selectOptions: remoteAccessTypeOptions,
		}),
		BooleanColumn({
			fieldName: fieldNames.active,
			header: TranslateText('maintenanceOverview.grid.colActive'),
			sortable: true,
			filterable: true,
		}),
		ButtonColumn({
			shown: ClaimUtil.validateClaim(user, {
				claim: ClaimType.Integration,
				values: [ClaimValue.edit],
			}),
			body: (rowData: IntegrationOverviewDto) => (
				<Button
					className="integration-grid-button"
					disableElevation
					variant="contained"
					onDoubleClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						deleteIntegration(rowData);
					}}
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						deleteIntegration(rowData);
					}}
				>
					<Icon>close</Icon>
				</Button>
			),
		}),
	];

	return (
		<>
			<NewGridOverview
				columns={columns}
				initialFilters={initialFilters}
				getGridDataUrl={GlobalSettings.integrationsMaintenanceApi}
				headerText={TranslateText('maintenanceOverview.integrations')}
				disableAddButton={
					customerLevel === CustomerLevelEnum.OEM ||
					!integrationFeature ||
					(customerLevel === CustomerLevelEnum.Reseller && !(driverStyleScoresFeature || mediaFootageFeature))
				}
				showAddConfigClaim={[
					{
						claim: ClaimType.Integration,
						values: [ClaimValue.edit],
					},
				]}
				entityType={EntityTypeEnum.Integration}
				useNewAddWizard
				addEntityUrl={GlobalSettings.integrationsMaintenanceApi + '/addIntegration'}
				columnFlagOrder={columnFlagOrdering}
				fetchDataTrigger={fetchDataTrigger}
				disableImportButton={customerLevel !== CustomerLevelEnum.Default || !integrationFeature}
				disableExportButton={customerLevel !== CustomerLevelEnum.Default || !integrationFeature}
			/>
			{notificationPrompt ? (
				<NotificationPrompt
					title={TranslateText('integrations.deleteIntegrationTitle')}
					message={notificationPrompt.message}
					handleUserResponse={(response) => {
						setNotificationPrompt(null);
						if (response && notificationPrompt.callback) {
							notificationPrompt.callback();
						}
					}}
					displayDialog={true}
				/>
			) : null}
		</>
	);
};

export default IntegrationsOverview;
