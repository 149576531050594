import './styles.scss';

import { DurationTemplates } from 'components/RightSidebarMenu/ExtendedMenu/Tabs/SummaryTabHistory/DurationTemplates';
import React, { useEffect, useState } from 'react';
import { useIsFetching } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useLocationsHistory } from 'shared/effects/useShowLastEntities';
import { ApplicationState } from 'store';
import { ProcessedTripDto, TripAuditChangesEnum } from 'store/HistoryStore';
import { FormatDate } from 'utils/DateUtils';
import { queryClientKeys } from 'utils/queryClientKeys';
import { TranslateText } from 'utils/Translations';

import {IconButton, LinearProgress, Tooltip } from '@material-ui/core';

import { useTripFullTimeZoneInfo, useTripsNavigation } from '../hooks';
import { SummaryTabFooter } from '../SummaryTabFooter';
import { TripAudit } from '../types';
import {ClaimUtil} from "../../../../../../authorization/ClaimUtil";
import ClaimType, {ClaimValue} from "../../../../../../authorization/ClaimType";
import { Add, EditOutlined } from '@material-ui/icons';
import LocationDialog from "../../../../../LocationPopup";
import {
	adjustDateForDialog,
	adjustDateToUtcForDialog,
	LocationDialogDataDto
} from "../../../../../LocationPopup/LocationDialog";
import locationDialog from "../../../../../LocationPopup";

interface Props {
	hidden: boolean;
	tripDetails: ProcessedTripDto;
	renderForTable?: boolean;
	nextCallback?: (saveTripDetailsCallback: () => void, notifyAboutUnsavedData: boolean) => void;
	previousCallback?: (saveTripDetailsCallback: () => void, notifyAboutUnsavedData: boolean) => void;
	unsavedDataCallback?: (tripHasUnsavedData: boolean) => void;
	isForRow: boolean;
}

const SummaryTabNonTrip = ({ hidden, tripDetails, renderForTable, nextCallback, previousCallback, isForRow }: Props) => {
	const fetchingDurations = useIsFetching([queryClientKeys.OBJECT_ADDRESS_DURATION_TEMPLATES, tripDetails.id]);

	const dispatch = useDispatch();

	const showHistoryLocations = useLocationsHistory();

	const { tripTimezone } = useTripFullTimeZoneInfo(tripDetails.timezoneId, tripDetails.objectNameWithTimeZoneOffset);
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const historyTrips = useSelector((s: ApplicationState) => s.historyStore.historyTrips);
	const historyGridTrips = useSelector((s: ApplicationState) => s.historyStore.historyGridTrips);
	const customerTimezone = useSelector((s: ApplicationState) => s.currentSession.customer.timezoneId);

	const [lastChangedDateTime, setLastChangedDateTime] = useState<string>('');
	const [lastChangedByPerson] = useState<{ personId: string; personName: string }>({
		personId: '',
		personName: '',
	});
	const [lastTripAuditChanges, setLastTripAuditChanges] = useState<TripAudit>(null);
	const [locationDialogData, setLocationDialogData] = useState<LocationDialogDataDto>({
		display: false,
		isForAdd: false,
		selectedLocationId: null,
		latLng: null,
		startDate: null,
	});
	const [heightSummaryInfo, setHeightSummaryInfo] = useState(Math.abs(window.innerHeight - 313));

	useEffect(() => {
		const handleResize = () => {
			setHeightSummaryInfo(window.innerHeight - 313);
		};
		if(!isForRow)
			window.addEventListener('resize', handleResize);

		return () => {
			if(!isForRow)
				window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		setLastChangedDateTime(tripDetails.startTripTime);
		setLastTripAuditChanges({
			lastChangedValues: [],
			lastChangedDateTime: null,
			lastChangedByPersonId: null,
			lastChangedAction: TripAuditChangesEnum.Create,
			lastChangedByPersonFirstLastName: null,
			lastChangedSourceIp: null,
		});
	}, [tripDetails]);

	const { previousTrip, nextTrip, navigateToTrip } = useTripsNavigation(
		tripDetails,
		historyTrips,
		historyGridTrips,
		renderForTable,
		dispatch
	);

	const navigateToPreviousTrip = async () => {
		if (!renderForTable) {
			navigateToTrip(previousTrip);
		} else if (previousCallback) {
			previousCallback(null, false);
		}
	};

	const navigateToNextTrip = async () => {
		if (!renderForTable) {
			navigateToTrip(nextTrip);
		} else if (nextCallback) {
			nextCallback(null, false);
		}
	};

	return (
		<div hidden={hidden} className={`summary-tab-nontrip ${renderForTable ? 'render-for-table' : ''}`}>
			{fetchingDurations ? <LinearProgress className="linear-progress" /> : null}

			<div className="summary-tab-content">
				{!renderForTable && (
					<div className="summary-header">
						<span className="trip-index">{`${TranslateText('tripDetailsSection.address')} ${
							tripDetails.isInProgress ? '-' : tripDetails.index + 1
						}`}</span>
					</div>
				)}
				<div className="info-area-summary" style={!isForRow ?{ height: heightSummaryInfo } : {}}>
					<div className="summary-group">
						<span className="summary-row">
							<span className="summary-field">{TranslateText('fleetSelection.Object')}</span>
							<span className="summary-value">{tripDetails.objectName}</span>
						</span>
						{customerTimezone !== tripDetails.timezoneId ? (
							<span className="summary-row">
								<span className="summary-field">
									{TranslateText('maintenanceOverview.grid.colTimeZone')}
								</span>
								<span className="summary-value">{tripTimezone}</span>
							</span>
						) : null}
					</div>

					{!renderForTable ? (
						<>
							<div className="summary-group">
								<div className="summary-row">
									<span className="summary-field">{TranslateText('tripDetailsSection.address')}</span>
									<Tooltip title={tripDetails.startAddress}>
										<div className="summary-value">{tripDetails.startAddress}</div>
									</Tooltip>
									{showHistoryLocations &&
										tripDetails?.startLocationName === null &&
										ClaimUtil.validateClaimList(user,[{
										claim: ClaimType.Locations,
										values: [ClaimValue.edit],
									}])
										? <IconButton
											className="action-icon-mui action-location-icon"
											size={'small'}
											onClick={() => {
												setLocationDialogData({
													display: true,
													isForAdd: true,
													selectedLocationId: null,
													latLng: tripDetails.start,
													startDate: tripDetails.startTrip
												});
											}}
										>
											<Add className="location-icon"/>
										</IconButton>
										: null
									}
								</div>
								{showHistoryLocations && tripDetails?.startLocationName && (
									<div className="summary-row">
										<span className="summary-field">
											{TranslateText('tripDetailsSection.location')}
										</span>
										<Tooltip title={tripDetails.startLocationName}>
											<div className="summary-value">{tripDetails.startLocationName}</div>
										</Tooltip>
										{ClaimUtil.validateClaimList(user,[{
											claim: ClaimType.Locations,
											values: [ClaimValue.edit],
										}]) && tripDetails.startLocationId !== null
											? <IconButton
												className="action-icon-mui action-location-icon"
												size={'small'}
												onClick={() => {
													setLocationDialogData({
														display: true,
														isForAdd: false,
														selectedLocationId: tripDetails.startLocationId,
														latLng: null,
														startDate: null
													});
												}}
											>
												<EditOutlined className="location-icon"/>
											</IconButton>
											: null
										}
									</div>
								)}
							</div>
							<div className="summary-group">
								<div className="summary-row">
									<span className="summary-field">
										{TranslateText('tripDetailsSection.departure')}
									</span>
									<span className="summary-value">
										{tripDetails.startTripTime
											? FormatDate(tripDetails.startTripTime, false, false, true)
											: ''}
									</span>
								</div>
								<div className="summary-row">
									<span className="summary-field">{TranslateText('tripDetailsSection.arrival')}</span>
									<span className="summary-value">
										{!tripDetails.isInProgress && tripDetails.endTripTime
											? FormatDate(tripDetails.endTripTime, false, false, true)
											: ''}
									</span>
								</div>
							</div>
						</>
					) : null}

					<DurationTemplates id={tripDetails.id} renderForTable={renderForTable} />
				</div>

				<SummaryTabFooter
					renderForTable={renderForTable}
					tripHasUnsavedData={false}
					isNonTrip={true}
					lastChangedDateTime={lastChangedDateTime}
					lastChangedByPerson={lastChangedByPerson}
					lastTripAuditChanges={lastTripAuditChanges}
					previousClick={
						(!renderForTable && !previousTrip) || (renderForTable && !previousCallback)
							? null
							: navigateToPreviousTrip
					}
					nextClick={
						(!renderForTable && !nextTrip) || (renderForTable && !nextCallback) ? null : navigateToNextTrip
					}
					useTripTimezone={true}
				/>
			</div>
			<LocationDialog
				visible={locationDialogData.display}
				closeCallback={() => {
					setLocationDialogData({
						display: false,
						isForAdd: false,
						selectedLocationId: null,
						latLng: null,
						startDate: null
					});
				}}
				selectedEntityId={locationDialogData.selectedLocationId}
				isForAdd={locationDialogData.isForAdd}
				latLngAddress={locationDialogData.latLng}
				startDateFromTrip={adjustDateForDialog(locationDialogData.startDate)}
				startDateFromTripString={adjustDateToUtcForDialog(locationDialogData.startDate, tripDetails.timeZoneMinutesOffset)}
			/>
		</div>
	);
};

export default SummaryTabNonTrip;
