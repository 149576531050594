import {StateFilterEnum} from 'models/LiveMenuUtils';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {LiveDataResultDto} from 'store/LiveData';
import {getDisplayAnalogSensorValue} from 'utils/SensorsUtils';
import {getContrastColor} from 'utils/TripUtils';

import CustomerLevelEnum from '../../models/CustomerLevelEnum';
import {LocationMapDto} from '../../models/LocationMapDto';
import LocationOnMapEnum from '../../models/LocationOnMapEnum';
import {ApplicationState} from '../../store';
import {historyStoreActionCreators} from '../../store/HistoryStore';
import {liveMapActionCreators} from '../../store/LiveMap';
import {TranslateText} from '../../utils/Translations';
import {LocationCategory} from '../Widgets/Views/CustomerLocationCategoriesView';
import {SensorTemplateEnum, SensorTemplates} from './types';
import {TemplateOnMap} from "../HistoryTemplatesSelectionOnMap/HistoryTemplatesSelectionOnMap";
import template from "../../models/Template";

const useMapCategories = (
	locations: LocationMapDto[],
	mapCategories: LocationCategory[],
	liveSelectedCategories: LocationCategory[],
	historySelectedCategories: LocationCategory[],
	customerLevel: CustomerLevelEnum
) => {
	const dispatch = useDispatch();
	useEffect(() => {
		if (customerLevel !== CustomerLevelEnum.Default || !locations?.length) {
			dispatch(liveMapActionCreators.setMapCategories([]));
			dispatch(liveMapActionCreators.setSelectedCategoriesLive([]));
			dispatch(historyStoreActionCreators.setSelectedCategoriesHistory([]));
			return;
		}

		const fetchedCategories = [
			...new Map(
				locations
					.map((l) => l.category)
					.map((lc) => [
						lc['id'],
						{
							id: lc.id,
							customerId: lc.customerId,
							name: TranslateText(`category.${lc.name}`).startsWith('category.')
								? lc.name
								: TranslateText(`category.${lc.name}`),
						} as LocationCategory, //translate location category name after fetching data
					])
			).values(),
		]; // create new list of unique location categories
		const newCategories = fetchedCategories.filter(
			(nlc) => !mapCategories.some((mp) => mp.id === nlc.id && mp.name === nlc.name)
		);
		const removedCategories = mapCategories.filter(
			(mp) => !fetchedCategories.some((fc) => mp.id === fc.id && mp.name === fc.name)
		);
		if (!mapCategories?.length) {
			dispatch(liveMapActionCreators.setMapCategories(fetchedCategories));
			dispatch(liveMapActionCreators.setSelectedCategoriesLive(fetchedCategories));
			dispatch(historyStoreActionCreators.setSelectedCategoriesHistory(fetchedCategories));
		}
		if (!newCategories?.length && !removedCategories?.length) {
			return;
		}

		let newFetchedCategories: LocationCategory[] = mapCategories;
		let newLiveSelectedCategories: LocationCategory[] = liveSelectedCategories;
		let newHistorySelectedCategories: LocationCategory[] = historySelectedCategories;
		if (newCategories.length) {
			newFetchedCategories = [...newCategories, ...mapCategories];
			newLiveSelectedCategories = [...newCategories, ...liveSelectedCategories];
			newHistorySelectedCategories = [...newCategories, ...historySelectedCategories];
		}
		if (removedCategories.length) {
			newFetchedCategories = newFetchedCategories.filter(
				(nfc) => !removedCategories.some((rc) => rc.id === nfc.id && rc.name === nfc.name)
			);
			newLiveSelectedCategories = newLiveSelectedCategories.filter(
				(lsc) => !removedCategories.some((rc) => rc.id === lsc.id && rc.name === lsc.name)
			);
			newHistorySelectedCategories = newHistorySelectedCategories.filter(
				(hsc) => !removedCategories.some((rc) => rc.id === hsc.id && rc.name === hsc.name)
			);
		}
		dispatch(liveMapActionCreators.setMapCategories(newFetchedCategories));
		dispatch(liveMapActionCreators.setSelectedCategoriesLive(newLiveSelectedCategories));
		dispatch(historyStoreActionCreators.setSelectedCategoriesHistory(newHistorySelectedCategories));
	}, [locations]);
};

const useMapTemplates = (
	templates: TemplateOnMap[],
	templatesOnMap: TemplateOnMap[],
	templatesOnMapSelected: TemplateOnMap[],
	customerLevel: CustomerLevelEnum
) => {
	const dispatch = useDispatch();
	useEffect(() => {
		if (customerLevel !== CustomerLevelEnum.Default || !templates?.length) {
			dispatch(historyStoreActionCreators.setMapTemplates([]));
			dispatch(historyStoreActionCreators.setSelectedTemplatesOnHistory(null));
			return;
		}

		const changed = templates.filter((x) => !templatesOnMap.some((s) => x.templateId === s.templateId));

		if (!templatesOnMap?.length || changed || template?.length !== templatesOnMap?.length) {
			dispatch(historyStoreActionCreators.setMapTemplates(templates));
			if(templatesOnMapSelected === null)
				dispatch(historyStoreActionCreators.setSelectedTemplatesOnHistory(templates));
		}
	}, [templates]);
};
export const useLocationsLiveMap = () => {
	return useSelector((state: ApplicationState) => {
		const locationSettings = !!state.globalCustomer.filteredCustomer
			? state.globalCustomer?.filteredCustomer?.featuresSettings?.locations
			: state.currentSession?.customer?.featuresSettings?.locations;
		let showLiveLocationsOnMap = state.currentSession?.personLiveSettings?.useLiveLocationOnMap;
		const locationsFeatureCurrentSessionCustomer = state.currentSession?.customer?.featuresSettings?.locations;
		const currentSessionLevel = state.currentSession?.customerLevel;

		if (showLiveLocationsOnMap === null || showLiveLocationsOnMap === undefined) {
			showLiveLocationsOnMap = state.currentSession?.customerLiveSettings?.useLiveLocationOnMap;
		}

		if (
			currentSessionLevel !== CustomerLevelEnum.Default &&
			!!state.globalCustomer.filteredCustomer &&
			!locationsFeatureCurrentSessionCustomer
		) {
			return false;
		}
		return showLiveLocationsOnMap !== LocationOnMapEnum.DontShow && locationSettings;
	});
};

export const useLocationsHistoryMap = () => {
	return useSelector((state: ApplicationState) => {
		const currentSessionLevel = state.currentSession?.customerLevel;
		const locationsFeatureCurrentSessionCustomer = state.currentSession?.customer?.featuresSettings?.locations;
		const locationSettings = !!state.globalCustomer.filteredCustomer
			? state.globalCustomer?.filteredCustomer?.featuresSettings?.locations
			: state.currentSession?.customer?.featuresSettings?.locations;
		let showHistoryLocationsOnMap = state.currentSession?.personHistorySettings?.useHistoryLocationOnMap;

		if (showHistoryLocationsOnMap === null || showHistoryLocationsOnMap === undefined) {
			showHistoryLocationsOnMap = state.currentSession?.customerHistorySettings?.useHistoryLocationOnMap;
		}
		if (
			currentSessionLevel !== CustomerLevelEnum.Default &&
			!!state.globalCustomer.filteredCustomer &&
			!locationsFeatureCurrentSessionCustomer
		) {
			return false;
		}
		return showHistoryLocationsOnMap !== LocationOnMapEnum.DontShow && locationSettings;
	});
};

export const useTemplatesHistoryMap = () => {
	return useSelector((state: ApplicationState) => {
		const currentSessionLevel = state.currentSession?.customerLevel;
		const templateFeatureCurrentSessionCustomer = state.currentSession?.customer?.featuresSettings?.templates;
		const templateSettings = !!state.globalCustomer.filteredCustomer
			? state.globalCustomer?.filteredCustomer?.featuresSettings?.templates
			: state.currentSession?.customer?.featuresSettings?.templates;

		let showHistoryTemplatesOnMap = state.currentSession?.personHistorySettings?.showTemplateStateOnMap;
		if(showHistoryTemplatesOnMap === null || showHistoryTemplatesOnMap === undefined)
		{
			showHistoryTemplatesOnMap = state.currentSession?.customerHistorySettings?.showTemplateStateOnMap;
		}

		if(
			currentSessionLevel !== CustomerLevelEnum.Default &&
			!!state.globalCustomer.filteredCustomer &&
			!templateFeatureCurrentSessionCustomer)
		{
			return false;
		}

		return showHistoryTemplatesOnMap !== false && templateSettings;
	});

};
export const useKeepInViewStateAndRef = (initialKeepInView: boolean, isLiveMap: boolean) => {
	const [keepInViewState, setSeepInViewState] = useState(initialKeepInView);
	const keepInViewRef = useRef(initialKeepInView);
	const setKeepInView = useCallback((value: boolean) => {
		if (isLiveMap) {
			setSeepInViewState(value);
			keepInViewRef.current = value;
		}
	}, []);

	return { keepInViewState, keepInViewRef, setKeepInView };
};

export const useSensorTemplates = (liveData: LiveDataResultDto, trackTypeSpecification: boolean) => {
	const sensorTemplates: SensorTemplates[] = [];
	let headerStyle = {};

	if (liveData.sensorDigitalTemplatesData?.length) {
		liveData.sensorDigitalTemplatesData.forEach((t) => {
			if(t.sensorObservationValue !== null) {
				sensorTemplates.push({
					...t,
					sortName: t.templateName,
					sensorTemplateType: SensorTemplateEnum.Digital
				});
			}
		});
	}

	if (liveData.sensorAnalogTemplatesData?.length) {
		liveData.sensorAnalogTemplatesData.forEach((t) => {
			sensorTemplates.push({
				...t,
				sortName: t.templateName,
				sensorTemplateType: SensorTemplateEnum.Analog,
				stateName: getDisplayAnalogSensorValue(t.sensorValue, t.unitOfMeasure),
			});
		});
	}

	if (liveData.state === StateFilterEnum.Driving && trackTypeSpecification && liveData.hasPrivateMileageInput) {
		sensorTemplates.push({
			templateId: 'privatesensor',
			sortName: liveData.privateTemplateName,
			templateName: liveData.isPrivateOff ? TranslateText('common.privateSwitch') : '',
			rank: liveData.privateTripRank,
			stateName: liveData.isPrivateOff ? TranslateText(`common.${liveData.isPrivateOff ? 'off' : 'on'}`) : '',
			labelColor: liveData.privateTripColor,
			iconName: '',
			sensorTemplateType: SensorTemplateEnum.Event,
		});
	}

	sensorTemplates.sort((a, b) => {
		if (a.rank !== b.rank) {
			if (!a.rank && a.rank !== 0) {
				return 1;
			} else if (!b.rank && b.rank !== 0) {
				return -1;
			} else {
				return a.rank - b.rank;
			}
		}
		return ('' + a.sortName).localeCompare(b.sortName);
	});

	if (sensorTemplates.length && sensorTemplates[0].labelColor) {
		headerStyle = {
			backgroundColor: sensorTemplates[0].labelColor,
			color: getContrastColor(sensorTemplates[0].labelColor),
		};
	}

	return { sensorTemplates, headerStyle };
};

export { useMapCategories };
export { useMapTemplates };
