import React from 'react';
import MaterialTextField from 'shared/components/MaterialTextField/MaterialTextField';
import { TranslateText } from 'utils/Translations';

import { CONFIGURATIONGROUPSETTINGS } from '../constants';
import { ConfigurationGroupProps } from '../types';
import {handleKeyDown} from "../../../../Common/RemoveSpinnerOptionInput";
import {getNumberValue} from "../../../../Widgets/Views/ConfigurationGroupSettingsView/Utils";

const ConfigurationGroupSettingsView = ({
	data,
	validationResult,
	handleChange,
	isAddNew = true,
	editMode = true,
	isForNewAddWizard = true,
}: ConfigurationGroupProps) => {
	const handleChangeChild = (value: unknown, statePropName: string) => {
		handleChange(value, statePropName, CONFIGURATIONGROUPSETTINGS);
	};
	return (
		<>
			<div className="form-group">
				<MaterialTextField
					autoFocus={true}
					isForNewAddWizard={isForNewAddWizard}
					id="harshAcceleration"
					className="resize-font noSpinner"
					label={TranslateText('fields.harshAccelerationSetting')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="harshAcceleration"
					value={data.configurationGroupSettings?.harshAcceleration}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'harshAcceleration')}
					validationResult={validationResult?.harshAccelerationConfigurationGroupSettings}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="harshBrake"
					className="resize-font noSpinner"
					label={TranslateText('fields.harshBrakeSetting')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="harshBrake"
					value={data.configurationGroupSettings?.harshBrake}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'harshBrake')}
					validationResult={validationResult?.harshBrakeConfigurationGroupSettings}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="rpm"
					className="resize-font noSpinner"
					label={TranslateText('fields.rpm')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="rpm"
					value={data.configurationGroupSettings?.rpm}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'rpm')}
					validationResult={validationResult?.rpmConfigurationGroupSettings}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="speed"
					className="resize-font noSpinner"
					label={TranslateText('fields.speed')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="speed"
					value={data.configurationGroupSettings?.speed}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'speed')}
					validationResult={validationResult?.speedConfigurationGroupSettings}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="torque"
					className="resize-font noSpinner"
					label={TranslateText('fields.torque')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="torque"
					value={data.configurationGroupSettings?.torque}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'torque')}
					validationResult={validationResult?.torqueConfigurationGroupSettings}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="pedal"
					className="resize-font noSpinner"
					label={TranslateText('fields.pedal')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="pedal"
					value={data.configurationGroupSettings?.pedal}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'pedal')}
					validationResult={validationResult?.pedalConfigurationGroupSettings}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="acceleration"
					className="resize-font noSpinner"
					label={TranslateText('fields.acceleration')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="acceleration"
					value={data.configurationGroupSettings?.acceleration}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'acceleration')}
					validationResult={validationResult?.accelerationConfigurationGroupSettings}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="deceleration"
					className="resize-font noSpinner"
					label={TranslateText('fields.deceleration')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="deceleration"
					value={data.configurationGroupSettings?.deceleration}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'deceleration')}
					validationResult={validationResult?.decelerationConfigurationGroupSettings}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
		</>
	);
};

export default ConfigurationGroupSettingsView;
