import {GridWidgetOverview} from "../../GridOverview/GridWidgetOverview";
import {TranslateText} from "../../../utils/Translations";
import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { Column } from "primereact/column";

export interface ConfigurationGroupObjectConflictDto {
	objectId: string;
	objectCode: string;
	objectName: string;
	configurationGroupId: string;
	configurationGroupName: string;
	configurationGroupCode: string;
}
interface ConfigurationGroupObjectConflictProps{
	showDialog: boolean;
	data: ConfigurationGroupObjectConflictDto[];
	onClickCallback: (value: boolean) => void;
}
const ConfigurationGroupObjectConflict = (props: ConfigurationGroupObjectConflictProps) => {
	const getColumns = () => {
		return [
			<Column
				key="objectCode"
				field="objectCode"
				className="widget-grid-column"
				header={TranslateText('widgets.grid.colObjectCode')}
				style={{ maxWidth: 150, whiteSpace: 'normal', wordWrap: 'break-word' }}
			/>,
			<Column
				key="objectName"
				field="objectName"
				className="widget-grid-column"
				header={TranslateText('widgets.grid.colObjectName')}
				style={{ maxWidth: 280, whiteSpace: 'normal', wordWrap: 'break-word' }}
			/>,
			<Column
				key="configurationGroupCode"
				field="configurationGroupCode"
				className="widget-grid-column"
				header={TranslateText('widgets.grid.colConfigurationGroupCode')}
				style={{ maxWidth: 170, whiteSpace: 'normal', wordWrap: 'break-word' }}
			/>,
			<Column
				key="configurationGroupName"
				field="configurationGroupName"
				className="widget-grid-column"
				header={TranslateText('widgets.grid.colConfigurationGroupName')}
				style={{ maxWidth: 300, whiteSpace: 'normal', wordWrap: 'break-word' }}
			/>,
		];
	}

	return(
		<Dialog style={{zIndex: 1400}} open={props.showDialog} maxWidth={'md'}>
				<DialogTitle>{"Configuration group - Object conflicts"}</DialogTitle>
				<DialogContent>
					<DialogContentText>{TranslateText('connections.fixableConnectionConflict')}</DialogContentText>
					<GridWidgetOverview
						className={'dynamic-height-row'}
						columns={getColumns()}
						data={props.data}
						total={props.data?.length ?? 0}
						paginator={false}
						enableScroll={true}
						scrollHeight={'200px'}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						color={'primary'}
						onClick={() => props.onClickCallback(true)}
					>
						{TranslateText('common.buttonYes')}
					</Button>
					<Button
						onClick={() => props.onClickCallback(false)}
					>
						{TranslateText('common.buttonNo')}
					</Button>
				</DialogActions>
		</Dialog>
	);
}
export default ConfigurationGroupObjectConflict;
