import MaterialAutocomplete, { AutocompleteItem } from 'components/Common/Autocomplete/MaterialAutocomplete';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import DataExchangeTypeEnum from 'models/DataExchangeTypeEnum';
import IntegrationTypeEnum from 'models/IntegrationTypeEnum';
import RemoteAccessTypeEnum from 'models/RemoteAccessTypeEnum';
import React, { useMemo } from 'react';
import CustomerNameDisplay from 'shared/components/CustomerNameDisplay';
import MaterialTextField from 'shared/components/MaterialTextField/MaterialTextField';
import { getAutocompleteEnumOptions, TranslateText } from 'utils/Translations';

import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';

import { useSelector } from 'react-redux';
import { IntegrationInformationProps } from './types';
import { ApplicationState } from '../../../../../store';
import CustomerLevelEnum from '../../../../../models/CustomerLevelEnum';

const IntegrationInformation = ({
	integration,
	customerId,
	validationResult,
	handleValueChangeMaterial,
	isAddNew = true,
	editMode = true,
	isForNewAddWizard = true,
}: IntegrationInformationProps) => {
	const customerLevel = useSelector((s: ApplicationState) =>
		s.globalCustomer.filteredCustomer ? s.globalCustomer.filteredCustomer.level : s.currentSession.customerLevel
	);
	const driverStyleScoresFeature = useSelector((s: ApplicationState) =>
		s.globalCustomer.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.driverStyleScores
			: s.currentSession.customer.featuresSettings.driverStyleScores
	);
	const mediaFootageFeature = useSelector((s: ApplicationState) =>
		s.globalCustomer.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.mediaFootage
			: s.currentSession.customer.featuresSettings.mediaFootage
	);
	const inputFontSize = useMemo(() => (isForNewAddWizard ? 10 : 12), [isForNewAddWizard]);

	const integrationTypeOptions = useMemo<AutocompleteItem[]>(() => {
		let options: AutocompleteItem[] = getAutocompleteEnumOptions(
			IntegrationTypeEnum,
			'integrations.integrationTypeEnum.',
			true
		);
		if (customerLevel === CustomerLevelEnum.Reseller && (driverStyleScoresFeature || mediaFootageFeature)) {
			options = options.filter((x) => x.id === IntegrationTypeEnum.EasyDrive);
		} else if (customerLevel === CustomerLevelEnum.Default && !(driverStyleScoresFeature || mediaFootageFeature)) {
			options = options.filter((x) => x.id !== IntegrationTypeEnum.EasyDrive);
		}
		return options;
	}, []);
	const dataExchangeTypeOptions = useMemo<AutocompleteItem[]>(
		() => getAutocompleteEnumOptions(DataExchangeTypeEnum, 'integrations.dataExchangeTypeEnum.'),
		[]
	);
	const remoteAccessTypeOptions = useMemo<AutocompleteItem[]>(
		() => getAutocompleteEnumOptions(RemoteAccessTypeEnum, 'integrations.remoteAccessTypeEnum.'),
		[]
	);

	return (
		<div className="view-section-wrapper integration-information">
			<div className="form-group">
				<MaterialTextField
					autoFocus={true}
					isForNewAddWizard={isForNewAddWizard}
					id="integration-name"
					className="resize-font"
					label={TranslateText('fields.name')}
					inputProps={{ style: { fontSize: inputFontSize } }}
					name="name"
					value={integration.name}
					handleValueChange={(value) => handleValueChangeMaterial(value, 'name')}
					validationResult={validationResult?.name}
					disabled={!editMode}
				/>
			</div>
			<CustomerNameDisplay customerId={customerId} isForNewAddWizard={isForNewAddWizard} />
			<div className="form-group">
				<MaterialAutocomplete
					isForNewAddWizard={isForNewAddWizard}
					valueId={integration.integrationType}
					dataSource={integrationTypeOptions}
					name="integrationType"
					disabled={!isAddNew || !editMode}
					label={TranslateText('integrations.integrationType')}
					onChange={({ value }) => handleValueChangeMaterial(value, 'integrationType')}
					validationResult={validationResult?.integrationType}
				/>
				{!isForNewAddWizard && (
					<ValidationMessage result={validationResult?.integrationType} isForNewAddWizard={false} />
				)}
			</div>
			<div className="form-group">
				<MaterialAutocomplete
					isForNewAddWizard={isForNewAddWizard}
					valueId={integration.dataExchangeType}
					dataSource={dataExchangeTypeOptions}
					name="dataExchangeType"
					disabled={!editMode}
					label={TranslateText('integrations.dataExchange')}
					onChange={({ value }) => handleValueChangeMaterial(value, 'dataExchangeType')}
					validationResult={validationResult?.dataExchangeType}
				/>
				{!isForNewAddWizard && (
					<ValidationMessage result={validationResult?.dataExchangeType} isForNewAddWizard={false} />
				)}
			</div>
			<div className="form-group">
				<MaterialAutocomplete
					isForNewAddWizard={isForNewAddWizard}
					valueId={integration.remoteAccessType}
					dataSource={remoteAccessTypeOptions}
					name="remoteAccessType"
					disabled={!editMode}
					label={TranslateText('integrations.remoteAccess')}
					onChange={({ value }) => handleValueChangeMaterial(value, 'remoteAccessType')}
					validationResult={validationResult?.remoteAccessType}
				/>
				{!isForNewAddWizard && (
					<ValidationMessage result={validationResult?.remoteAccessType} isForNewAddWizard={false} />
				)}
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={true}
					id="remoteAccessCredentials"
					className="resize-font"
					label={TranslateText('integrations.remoteAccessCredentials')}
					inputProps={{ style: { fontSize: inputFontSize } }}
					name="remoteAccessCredentials"
					value={integration.remoteAccessCredentials}
					handleValueChange={(value) => handleValueChangeMaterial(value, 'remoteAccessCredentials')}
					validationResult={validationResult?.remoteAccessCredentials}
					disabled={!editMode}
					multiline={true}
					rows={5}
					variant="outlined"
				/>
			</div>
			<div className="form-group">
				<FormControlLabel
					control={
						<Checkbox
							name="active"
							color={'primary'}
							checked={integration.active}
							onChange={(args) => handleValueChangeMaterial(args.target.checked, 'active')}
						/>
					}
					label={
						<Typography
							style={{
								fontSize: inputFontSize,
								marginRight: 5,
								color: editMode ? 'black' : 'grey',
							}}
						>
							{TranslateText('fields.active')}
						</Typography>
					}
					labelPlacement="start"
					style={{ margin: 0 }}
					disabled={!editMode || isAddNew}
				/>
			</div>
		</div>
	);
};

export default IntegrationInformation;
