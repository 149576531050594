import React from 'react';
import MaterialTextField from 'shared/components/MaterialTextField/MaterialTextField';
import { TranslateText } from 'utils/Translations';

import { CONFIGURATIONGROUPPERIODICALS } from '../constants';
import { ConfigurationGroupProps } from '../types';
import {handleKeyDown} from "../../../../Common/RemoveSpinnerOptionInput";
import {getNumberValue} from "../../../../Widgets/Views/ConfigurationGroupSettingsView/Utils";

const ConfigurationGroupPeriodicalsView = ({
	data,
	validationResult,
	handleChange,
	isAddNew = true,
	editMode = true,
	isForNewAddWizard = true,
}: ConfigurationGroupProps) => {
	const handleChangeChild = (value: unknown, statePropName: string) => {
		handleChange(value, statePropName, CONFIGURATIONGROUPPERIODICALS);
	};
	return (
		<>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="frequencyWhileMoving"
					className="resize-font noSpinner"
					label={TranslateText('fields.frequencyWhileMoving')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="frequencyWhileMoving"
					value={data.configurationGroupPeriodicals?.frequencyWhileMoving}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'frequencyWhileMoving')}
					validationResult={validationResult?.frequencyWhileMovingConfigurationGroupPeriodicals}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="frequencyWhileStandingStill"
					className="resize-font noSpinner"
					label={TranslateText('fields.frequencyWhileStandingStill')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="frequencyWhileStandingStill"
					value={data.configurationGroupPeriodicals?.frequencyWhileStandingStill}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'frequencyWhileStandingStill')}
					validationResult={validationResult?.frequencyWhileStandingStillConfigurationGroupPeriodicals}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
		</>
	);
};

export default ConfigurationGroupPeriodicalsView;
