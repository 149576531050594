import './entitiesView.scss';

import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import GlobalSettings from '../../../../GlobalSettings.json';
import AlertEntityTypeEnum from '../../../../models/AlertEntityTypeEnum';
import EntityTypeEnum from '../../../../models/EntityTypeEnum';
import FilterEntityType from '../../../../models/FilterEntityType';
import { ObjectFunctionFilter } from '../../../../models/ObjectFunctionFilter';
import SelectionItem from '../../../../models/SelectionItem';
import Validator from '../../../../shared/validation/Validator';
import { ApplicationState } from '../../../../store';
import ajaxUtil from '../../../../utils/Ajax';
import { TranslateText } from '../../../../utils/Translations';
import { ITreeNode } from '../../../SelectionTree/TreeNode/types';
import TreeSelectionView from '../../../TreeSelectionView';
import { EntityTypeFilter, TreeViewFilter } from '../../../TreeSelectionView/TreeSelectionView';
import { AddWizardComponentProps } from '../../NewAddWizard';
import { AlertsEntitiesData } from '../Alert/AlertView';

const EntitiesView = (props: AddWizardComponentProps) => {
	const assetTracking = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.assetTracking
			: s.currentSession.customer.featuresSettings.assetTracking
	);

	const [entityType, setEntityType] = useState<AlertEntityTypeEnum>(null);
	const [entityTypeFilters, setEntityTypeFilters] = useState([]);
	const [selectedEntities, setSelectedEntities] = useState<Object[]>([]);
	const [alertData, setAlertData] = useState(props.getDataCallback());
	const [valid, setValid] = useState(false);
	const entitiesStepRef = useRef<{ forceRefetchData: () => Promise<void> }>(null);
	const validator: Validator = new Validator({
		fieldRules: {
			selectedItems: {
				rules: {
					required: {
						message: '',
						validationFunction: async (data: any) => {
							return data !== null && data.length > 0;
						},
					},
				},
			},
		},
	});

	const getObjectsFilter = (): EntityTypeFilter[] => {
		if (assetTracking) {
			return [
				{
					filterEntityType: FilterEntityType.All,
					translationText: 'fleetSelection.all',
				},
				{
					filterEntityType: FilterEntityType.Object,
					translationText: 'fleetSelection.objects',
				},
				{
					filterEntityType: FilterEntityType.Asset,
					translationText: 'fleetSelection.assets',
				},
			];
		}

		return [
			{
				filterEntityType: FilterEntityType.Object,
				translationText: 'fleetSelection.objects',
			},
		];
	};

	useEffect(() => {
		if (!props.visible) return;
		if (selectedEntities === null || selectedEntities.length === 0) props.setValidationCallback(false);
	}, [props.visible]);

	useEffect(() => {
		const data = props.getDataCallback() as AlertsEntitiesData;
		if (data !== null && data.entityType !== entityType) {
			setEntityType(data.entityType);
			setEntityTypeFilters(getEntityFilters(data.entityType));
		}
	}, [props.getDataCallback]);

	useEffect(() => {
		validator.validate(selectedEntities).then((result) => {
			props.setValidationCallback(result.formResult && valid);
		});
	}, [selectedEntities]);

	useEffect(() => {
		props.setValidationCallback(valid);
	}, [valid]);

	useEffect(() => {
		const fetchData = async () => {
			const data = (await props.getDataCallback()) as AlertsEntitiesData;
			if (data !== null && data.eventTypeId !== undefined) {
				setAlertData(data);
				if (entitiesStepRef.current) {
					entitiesStepRef.current.forceRefetchData();
				}
			}
		};
		fetchData();
	}, [props.getDataCallback()?.eventTypeId]);
	const prepareData = (data: ITreeNode[]) => {
		if (data) {
			for (let index = 0; index < data.length; index++) {
				if (data[index].id === null && data[index].text === 'All') {
					data[index].text = TranslateText('common.all');
					break;
				}
			}
		}
		return data;
	};

	const retrieveEntitiesData = (filter: TreeViewFilter) => {
		let showPersons = filter.showPersons || filter.showDrivers;
		let showObjects = filter.showObjects || filter.showAssets;
		let objectFunctionFilter = ObjectFunctionFilter.None;

		if (entityType === AlertEntityTypeEnum.Drivers) {
			showObjects = false;
		} else if (entityType === AlertEntityTypeEnum.Objects) {
			showPersons = false;
			objectFunctionFilter = getObjectFunctionFilter(filter.showObjects, filter.showAssets);
		} else {
			objectFunctionFilter = getObjectFunctionFilter(filter.showObjects, filter.showAssets);
		}

		return ajaxUtil
			.post<ITreeNode[]>(`${GlobalSettings.listsApi}/GetAlertFleetSelectionData`, {
				customerId: filter.customerId,
				showPersons: showPersons,
				showObjects: showObjects,
				objectFunction: objectFunctionFilter,
				showInactive: filter.showInactive,
				filterText: filter.filterText.trim(),
			})
			.then((data) => prepareData(data));
	};

	const getObjectFunctionFilter = (showObjects: boolean, showAssets: boolean): ObjectFunctionFilter => {
		if (assetTracking && showObjects && showAssets) {
			return ObjectFunctionFilter.All;
		} else if (assetTracking && showAssets) {
			return ObjectFunctionFilter.Asset;
		} else if (showObjects) {
			return ObjectFunctionFilter.Full;
		}

		return ObjectFunctionFilter.None;
	};

	const addEntities = (entities: ITreeNode[]) => {
		let newData = [] as SelectionItem[];
		entities.forEach((item) => {
			const entity: SelectionItem = {
				entityId: item.id,
				entityType: item.type as EntityTypeEnum,
				id: item.id,
			};
			newData.push(entity);
		});
		setSelectedEntities([...newData]);
		if (props.visible) {
			props.onChangeCallback({ trackedEntities: [...newData] });
			const newAlertData = props.getDataCallback() as AlertsEntitiesData;
			setAlertData(newAlertData);
			props.setDataCallback({ ...newAlertData, trackedEntities: [...newData] });
		}
	};
	const handleEntitiesSelection = (entities: any[]) => {
		if (entities !== null) {
			addEntities(entities);
		}
	};
	const getEntityFilters = (entityType: AlertEntityTypeEnum): EntityTypeFilter[] => {
		switch (entityType) {
			case AlertEntityTypeEnum.Objects:
				return getObjectsFilter();
			case AlertEntityTypeEnum.Drivers:
				return [
					{
						filterEntityType: FilterEntityType.Driver,
						translationText: 'fleetSelection.drivers',
					},
				] as EntityTypeFilter[];
			case AlertEntityTypeEnum.NotEntityRelated:
				return [] as EntityTypeFilter[];
			default:
				return [] as EntityTypeFilter[];
		}
	};

	return (
		<div className={'entities-view-container'}>
			<TreeSelectionView
				ref={entitiesStepRef}
				buttonContainerVisible={entityType === AlertEntityTypeEnum.Objects && assetTracking}
				retrieveDataCallback={retrieveEntitiesData}
				filterPlaceholder={
					entityType === AlertEntityTypeEnum.Drivers
						? TranslateText('fleetSelection.searchDriverPlaceholder')
						: assetTracking
						? TranslateText('fleetSelection.searchEntitiesPlaceholder')
						: TranslateText('fleetSelection.searchObjectPlaceholder')
				}
				enableClientFilter={false}
				filters={entityTypeFilters}
				selectionChanged={handleEntitiesSelection}
				hasValidations={true}
				validator={validator}
				setDataCallBack={(isValid: boolean) => {
					setValid(isValid);
				}}
				isForNewAddWizard={true}
			/>
		</div>
	);
};
export default EntitiesView;
