import './ConfigurationGroupStyle.scss';

import { AddWizardComponentProps } from 'components/NewAddWizard/NewAddWizard';
import { ITreeNode } from 'components/SelectionTree/TreeNode/types';
import TreeSelectionView from 'components/TreeSelectionView';
import {EntityTypeFilter, TreeViewFilter} from 'components/TreeSelectionView/TreeSelectionView';
import EntityTypeEnum from 'models/EntityTypeEnum';
import { ObjectFunctionFilter } from 'models/ObjectFunctionFilter';
import SelectionItem from 'models/SelectionItem';
import React, { useEffect, useRef, useState } from 'react';
import Validator from 'shared/validation/Validator';
import ajaxUtil from 'utils/Ajax';
import { TranslateText } from 'utils/Translations';

import GlobalSettings from '../../../../GlobalSettings.json';
import {ApplicationState} from "../../../../store";
import { useSelector } from 'react-redux';
import FilterEntityType from "../../../../models/FilterEntityType";

const ObjectView = (props: AddWizardComponentProps) => {

	const assetTracking = useSelector((state: ApplicationState) =>
		state.globalCustomer?.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.assetTracking
			: state.currentSession.customer?.featuresSettings.assetTracking
	);

	const [valid, setValid] = useState(false);
	const [entityType, setEntityType] = useState<number>(null);
	const [selectedEntities, setSelectedEntities] = useState<Object[]>([]);
	const entitiesStepRef = useRef<{ forceRefetchData: () => Promise<void> }>(null);
	const [selectionButtons, setSelectionButtons] = useState([]);

	const validator: Validator = new Validator({
		fieldRules: {
			selectedItems: {
				rules: {},
			},
		},
	});

	useEffect(() => {
		const data = props.getDataCallback();
		if (data !== null && data.entityType !== entityType) {
			setEntityType(data.entityType);
			setSelectionButtons(getObjectsFilter());
		}
	}, [props.getDataCallback]);

	useEffect(() => {
		validator.validate(selectedEntities).then((result) => {
			props.setValidationCallback(result.formResult && valid);
		});
	}, [selectedEntities]);

	useEffect(() => {
		props.setValidationCallback(valid);
	}, [valid]);

	const prepareData = (data: ITreeNode[]) => {
		if (data) {
			for (let index = 0; index < data.length; index++) {
				if (data[index].id === null && data[index].text === 'All') {
					data[index].text = TranslateText('common.all');
					break;
				}
			}
		}
		return data;
	};

	const addEntities = (entities: ITreeNode[]) => {
		let newData = [] as SelectionItem[];
		entities.forEach((item) => {
			const entity: SelectionItem = {
				entityId: item.id,
				entityType: item.type as EntityTypeEnum,
				id: item.id,
			};
			newData.push(entity);
		});
		setSelectedEntities([...newData]);
		if (props.visible) {
			props.onChangeCallback({ trackedEntities: [...newData] });
		}
	};

	const retrieveEntitiesData = (filter: TreeViewFilter) => {
		let objectFunctionFilter = ObjectFunctionFilter.None;
		objectFunctionFilter = getObjectFunctionFilter(filter.showObjects, filter.showAssets);
		return ajaxUtil
			.post<ITreeNode[]>(`${GlobalSettings.listsApi}/GetConfigurationGroupObjectsSelectionData`, {
				groupConfigurationId: null,
				customerId: filter.customerId,
				objectFunction: objectFunctionFilter,
				showInactive: filter.showInactive,
				filterText: filter.filterText.trim()
			})
			.then((data) => prepareData(data));
	};

	const handleEntitiesSelection = (data: ITreeNode[]) => {
		if (data !== null) {
			addEntities(data);
		}
	};
	const getObjectFunctionFilter = (showObjects: boolean, showAssets: boolean): ObjectFunctionFilter => {
		if (assetTracking && showObjects && showAssets) {
			return ObjectFunctionFilter.All;
		} else if (assetTracking && showAssets) {
			return ObjectFunctionFilter.Asset;
		} else if (showObjects) {
			return ObjectFunctionFilter.Full;
		}

		return ObjectFunctionFilter.None;
	};
	const getObjectsFilter = (): EntityTypeFilter[] => {
		if (assetTracking) {
			return [
				{
					filterEntityType: FilterEntityType.All,
					translationText: 'fleetSelection.all',
				},
				{
					filterEntityType: FilterEntityType.Object,
					translationText: 'fleetSelection.objects',
				},
				{
					filterEntityType: FilterEntityType.Asset,
					translationText: 'fleetSelection.assets',
				},
			];
		}

		return [
			{
				filterEntityType: FilterEntityType.Object,
				translationText: 'fleetSelection.objects',
			},
		];
	};

	return (
		<div className={'entities-view-container'}>
			<TreeSelectionView
				ref={entitiesStepRef}
				retrieveDataCallback={retrieveEntitiesData}
				filterPlaceholder={assetTracking
					? TranslateText('fleetSelection.searchEntitiesPlaceholder')
					: TranslateText('fleetSelection.searchObjectPlaceholder')
				}
				enableClientFilter={false}
				selectionChanged={handleEntitiesSelection}
				hasValidations={true}
				validator={validator}
				setDataCallBack={(isValid: boolean) => {
					setValid(isValid);
				}}
				isForNewAddWizard={true}
				buttonContainerVisible={true}
				filters={selectionButtons}
			/>
		</div>
	);
};

export default ObjectView;
