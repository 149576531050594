import IntegrationType from 'components/NewAddWizard/Views/Integration/IntegrationType';
import { IntegrationMetadataValidationConfig } from 'components/NewAddWizard/Views/Integration/Utils';
import { WidgetViewDefault } from 'components/Widgets/Widget';
import GlobalSettings from 'GlobalSettings.json';
import IntegrationDto, {
	IntegrationAfasMetadata,
	IntegrationEasyDriveMetadata,
	IntegrationHereOnTrackMetadata,
} from 'models/IntegrationDto';
import IntegrationTypeEnum from 'models/IntegrationTypeEnum';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Validator from 'shared/validation/Validator';
import { ApplicationState } from 'store';
import { TranslateWithFailText } from 'utils/Translations';

import { IntegrationEasyTrackMetadata } from '../../../../models/IntegrationDto';

type Props = WidgetViewDefault & {
	data:
		| IntegrationAfasMetadata
		| IntegrationHereOnTrackMetadata
		| IntegrationEasyTrackMetadata
		| IntegrationEasyDriveMetadata;
	initialData:
		| IntegrationAfasMetadata
		| IntegrationHereOnTrackMetadata
		| IntegrationEasyTrackMetadata
		| IntegrationEasyDriveMetadata;
};

const IntegrationTypeView = (props: Props) => {
	const [integrationType, setIntegrationType] = useState<IntegrationTypeEnum | null>(null);

	const entityLoaded = useRef(false);
	const entityContextData = useSelector((state: ApplicationState) => state.loadedEntityContext?.entityContextData);

	useEffect(() => {
		// reset entityLoaded when entityDetails changes significantly
		entityLoaded.current = false;
	}, [entityContextData?.entityDetails]);

	useEffect(() => {
		if (!entityLoaded.current && entityContextData?.entityDetails) {
			const integration = entityContextData?.entityDetails as IntegrationDto;
			if (integration?.integrationType in IntegrationTypeEnum) {
				if (integration.integrationType === IntegrationTypeEnum.HereOnTrack) {
					props.setUrl(`${GlobalSettings.integrationsMaintenanceApi}/hereontrackmetadata`);
					entityLoaded.current = true;
				} else if (integration.integrationType === IntegrationTypeEnum.Afas) {
					props.setUrl(`${GlobalSettings.integrationsMaintenanceApi}/afasmetadata`);
					entityLoaded.current = true;
				} else if (integration.integrationType === IntegrationTypeEnum.EasyTrack) {
					props.setUrl(`${GlobalSettings.integrationsMaintenanceApi}/easytrackmetadata`);
					entityLoaded.current = true;
				} else if (integration.integrationType === IntegrationTypeEnum.EasyDrive) {
					props.setUrl(`${GlobalSettings.integrationsMaintenanceApi}/easydrivemetadata`);
					entityLoaded.current = true;
				}

				setIntegrationType(integration.integrationType);
				props.setDashboardTitleCallback(
					TranslateWithFailText(
						`integrations.integrationTypeEnum.${IntegrationTypeEnum[integration.integrationType]}`,
						IntegrationTypeEnum[integration.integrationType]
					)
				);
			}
		}
	}, [entityContextData]);

	useEffect(() => {
		if (integrationType in IntegrationTypeEnum) {
			props.setValidatorCallback(
				new Validator({ fieldRules: IntegrationMetadataValidationConfig(integrationType) }, true)
			);
		}
	}, [integrationType]);

	return (
		<IntegrationType
			integrationType={integrationType}
			customerId={(entityContextData?.entityDetails as IntegrationDto)?.customerId}
			integrationAfasMetadata={props.data as IntegrationAfasMetadata}
			integrationHereOnTrackMetadata={props.data as IntegrationHereOnTrackMetadata}
			integrationEasyTrackMetadata={props.data as IntegrationEasyTrackMetadata}
			integrationEasyDriveMetadata={props.data as IntegrationEasyDriveMetadata}
			validationResult={props.validationResult}
			editMode={props.editMode}
			isForNewAddWizard={false}
			handleValueChangeMaterial={(value) => {
				props.changeDataCallback({ ...value }, true);
			}}
		/>
	);
};

export default IntegrationTypeView;
