import './configurationGroupSettingsView.scss'

import React, { useEffect, useRef } from "react";
import { useState } from "react";

import { Box, Button} from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";
import {WidgetViewDefault} from "../../Widget";
import WeightsView from "./WeightsView/WeightsView";
import {
	ConfigurationGroupFactors,
	ConfigurationGroupGeneralCamera,
	ConfigurationGroupIncidents, ConfigurationGroupLevels,
	ConfigurationGroupMedia,
	ConfigurationGroupPeriodicals,
	ConfigurationGroupSettings,
	ConfigurationGroupThresholds,
	ConfigurationGroupTrips,
	ConfigurationGroupWeights
} from "../../../../models/ConfigurationGroupDto";
import {handleConfigurationGroupChange} from "../../../NewAddWizard/Views/ConfigurationGroup/types";
import Validator from "../../../../shared/validation/Validator";
import {
	ConfigurationGroupSettingAndThresholds, ConfigurationGroupTripIncidents,
	ConfigurationGroupWeightAndFactorRules
} from "../../../NewAddWizard/Views/ConfigurationGroup/Utils";
import SettingsView from "./SettingsView/SettingsView";
import TripsView
	from "./TripsView/TripsView";
import LevelsView from "./LevelsView/LevelsView";
import MediaView from "./MediaView/MediaView";
import _ from 'lodash';
import {TranslateText} from "../../../../utils/Translations";
import {ApplicationState} from "../../../../store";
import { useSelector } from 'react-redux';
import FactorsView from "./FactorsView/FactorsView";
import ThresholdsView from "./ThresholdsView/ThresholdsView";
import PeriodicalSettingsView from "./PeriodicalView/PeriodicalSettingsView";
import IncidentsView from "./IncidentsView/IncidentsView";
import CameraView from "./CameraView/CameraView";

type Props = WidgetViewDefault & {
	data: ConfigurationGroupSettingsEditDto;
};
export const inputLabelAutocompleteEditStyle = (data: any[] | string, editMode: boolean) => {
	return {
		color: editMode ? '#0000008A' : '#00000061',
		fontSize: 12,
		marginBottom: 4,
		transition: data?.length === 0 ? 'font-size 0.25s ease-out' : 'font-size 0.25s ease-in',
	};
}
export interface ConfigurationGroupSettingsEditDto {
	id: string;
	configurationGroupWeights: ConfigurationGroupWeights;
	configurationGroupFactors: ConfigurationGroupFactors;
	configurationGroupSettings: ConfigurationGroupSettings;
	configurationGroupThresholds: ConfigurationGroupThresholds;
	configurationGroupTrips: ConfigurationGroupTrips;
	configurationGroupPeriodicals: ConfigurationGroupPeriodicals;
	configurationGroupIncidents: ConfigurationGroupIncidents;
	configurationGroupMedia: ConfigurationGroupMedia;
	configurationGroupGeneralCamera: ConfigurationGroupGeneralCamera;
	configurationGroupLevels: ConfigurationGroupLevels;
}
const ConfigurationGroupSettingsView = (props: Props) => {

	const mediaFootage = useSelector((state: ApplicationState) =>
		state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.mediaFootage
			: state.currentSession.customer?.featuresSettings.mediaFootage
	);
	const [value, setValue] = useState<string>("0");
	const [tabValidations, setTabValidations] = useState(Array(10).fill(true));
	const containerRef = useRef(null);

	useEffect(() => {
		if (containerRef.current) {
			containerRef.current.scrollTop = 0;
		}
	}, [value]);

	useEffect(() => {
		props.setValidatorCallback(new Validator({
			fieldRules:
				{...ConfigurationGroupWeightAndFactorRules(),
				 ...ConfigurationGroupSettingAndThresholds(),
				 ...ConfigurationGroupTripIncidents(),
				}
		}, true));
	}, [props.entityId]);

	useEffect(() => {
		if(!props.editMode) {
		    setTabValidations([...new Array(10).fill(true)]);
		}
	},[props.editMode])
	const handleChangeTab = (newValue: string) => {
		setValue(newValue);
	};
	const handleValueChange = (value: any, statePropName: any, childObjName?: string) => {
		const clonedData = _.cloneDeep(props.data);
		const newConfigurationGroup = handleConfigurationGroupChange<ConfigurationGroupSettingsEditDto>(
			clonedData,
			statePropName,
			value,
			childObjName
		);
		props.changeDataCallback(newConfigurationGroup);
	};
	const setTabValidation = (value: boolean[]) => {
		setTabValidations([...value]);
	}

	return (
		<Box sx={{ width: '100%', overflow: 'auto'}}>
			<TabContext value={value}>
				<Box className="chip-button-list">
					<div role="group" aria-label="Settings chip buttons" className="radio-button-container">
						<Button
							variant={value === '0' ? 'contained' : 'outlined'}
							className={!tabValidations[0] ? 'chip-error' : ''}
							onClick={() => handleChangeTab('0')}
						>
							{TranslateText('configurationGroups.settingsTab.weights')}
						</Button>
						<Button
							variant={value === '1' ? 'contained' : 'outlined'}
							className={!tabValidations[1] ? 'chip-error' : ''}
							onClick={() => handleChangeTab('1')}
						>
							{TranslateText('configurationGroups.settingsTab.factors')}
						</Button>
						<Button
							variant={value === '2' ? 'contained' : 'outlined'}
							className={!tabValidations[2] ? 'chip-error' : ''}
							onClick={() => handleChangeTab('2')}
						>
							{TranslateText('configurationGroups.settingsTab.settings')}
						</Button>
						<Button
							variant={value === '3' ? 'contained' : 'outlined'}
							className={!tabValidations[3] ? 'chip-error' : ''}
							onClick={() => handleChangeTab('3')}
						>
							{TranslateText('configurationGroups.settingsTab.thresholds')}
						</Button>
						<Button
							variant={value === '4' ? 'contained' : 'outlined'}
							className={!tabValidations[4] ? 'chip-error' : ''}
							onClick={() => handleChangeTab('4')}
						>
							{TranslateText('configurationGroups.settingsTab.trips')}
						</Button>
						<Button
							variant={value === '5' ? 'contained' : 'outlined'}
							className={!tabValidations[5] ? 'chip-error' : ''}
							onClick={() => handleChangeTab('5')}
						>
							{TranslateText('configurationGroups.settingsTab.periodical')}
						</Button>
						<Button
							variant={value === '6' ? 'contained' : 'outlined'}
							className={!tabValidations[6] ? 'chip-error' : ''}
							onClick={() => handleChangeTab('6')}
						>
							{TranslateText('configurationGroups.settingsTab.incidents')}
						</Button>
						{mediaFootage && (
							<Button
								variant={value === '7' ? 'contained' : 'outlined'}
								className={!tabValidations[7] ? 'chip-error' : ''}
								onClick={() => handleChangeTab('7')}
							>
								{TranslateText('configurationGroups.settingsTab.media')}
							</Button>
						)}
						{mediaFootage && (
							<Button
								variant={value === '8' ? 'contained' : 'outlined'}
								className={!tabValidations[8] ? 'chip-error' : ''}
								onClick={() => handleChangeTab('8')}
							>
								{TranslateText('configurationGroups.settingsTab.camera')}
							</Button>
						)}
						<Button
							variant={value === '9' ? 'contained' : 'outlined'}
							className={!tabValidations[9] ? 'chip-error' : ''}
							onClick={() => handleChangeTab('9')}
						>
							{TranslateText('configurationGroups.settingsTab.levels')}
						</Button>
					</div>
				</Box>
				<div className={props.editMode ? `scrollable-container-edit` : `scrollable-container`  } ref={containerRef}>
					<TabPanel value="0" className="tab-panel">
						<WeightsView
							{...props}
							data={props.data}
							handleChange={handleValueChange}
							setTabValidation={setTabValidations}
							tabValidations={tabValidations}
							isSingleColumn={false}
							isForModal={false}
						/>
					</TabPanel>
					<TabPanel value="1" className="tab-panel">
						<FactorsView
							{...props}
							data={props.data}
							handleChange={handleValueChange}
							setTabValidation={setTabValidations}
							tabValidations={tabValidations}
							isSingleColumn={false}
							isForModal={false}
						/>
					</TabPanel>
					<TabPanel value="2" className="tab-panel">
						<SettingsView
							{...props}
							data={props.data}
							handleChange={handleValueChange}
							setTabValidation={setTabValidations}
							tabValidations={tabValidations}
							isSingleColumn={false}
							isForModal={false}
						/>
					</TabPanel>
					<TabPanel value="3" className="tab-panel">
						<ThresholdsView
							{...props}
							data={props.data}
							handleChange={handleValueChange}
							setTabValidation={setTabValidations}
							tabValidations={tabValidations}
							isSingleColumn={false}
							isForModal={false}
						/>
					</TabPanel>
					<TabPanel value="4" className="tab-panel">
						<TripsView
							{...props}
							data={props.data}
							handleChange={handleValueChange}
							setTabValidation={setTabValidations}
							tabValidations={tabValidations}
							isSingleColumn={false}
							isForModal={false}
						/>
					</TabPanel>
					<TabPanel value="5" className="tab-panel">
						<PeriodicalSettingsView
							{...props}
							data={props.data}
							handleChange={handleValueChange}
							setTabValidation={setTabValidations}
							tabValidations={tabValidations}
							isSingleColumn={false}
							isForModal={false}
						/>
					</TabPanel>
					<TabPanel value="6" className="tab-panel">
						<IncidentsView
							{...props}
							data={props.data}
							handleChange={handleValueChange}
							setTabValidation={setTabValidations}
							tabValidations={tabValidations}
							isSingleColumn={false}
							isForModal={false}
						/>
					</TabPanel>
					{mediaFootage ?
					<TabPanel value="7" className="tab-panel">
						<MediaView
							{...props}
							data={props.data}
							handleChange={handleValueChange}
							setTabValidation={setTabValidations}
							tabValidations={tabValidations}
							isSingleColumn={false}
							isForModal={false}
						/>
					</TabPanel>
					: null}
					{mediaFootage ?
					<TabPanel value="8" className="tab-panel">
						<CameraView
							{...props}
							data={props.data}
							handleChange={handleValueChange}
							setTabValidation={setTabValidations}
							tabValidations={tabValidations}
							isSingleColumn={false}
							isForModal={false}
						/>
					</TabPanel>
					: null}
					<TabPanel value="9" className="tab-panel">
						<LevelsView
							{...props}
							data={props.data}
							handleChange={handleValueChange}
							setTabValidation={setTabValidations}
							tabValidations={tabValidations}
							isSingleColumn={false}
							isForModal={false}
						/>
					</TabPanel>
				</div>
			</TabContext>
		</Box>
	);
}
export default ConfigurationGroupSettingsView;
