import '../configurationGroupView.scss'

import React, { useEffect } from "react";

import {WidgetViewDefault} from "../../../Widget";
import {ConfigurationGroupSettingsEditViewProps} from "../types";
import {ConfigurationGroupSettingsEditDto} from "../ConfigurationGroupSettingsView";
import {TranslateText} from "../../../../../utils/Translations";
import {
	CONFIGURATIONGROUPSETTINGS, CONFIGURATIONGROUPTHRESHOLDS
} from "../../../../NewAddWizard/Views/ConfigurationGroup/constants";
import {handleKeyDown} from "../../../../Common/RemoveSpinnerOptionInput";
import {ValidationMessage} from "../../../../ValidationMessage/ValidationMessage";
import { TextField } from '@material-ui/core';
import {getCurrentTabValidation, getNumberValue} from "../Utils";
import TextFieldTooltip from "../../../../../shared/components/TextFieldToolTip/TextFieldTooltip";
import {verifyDifferences} from "../../ConfigurationGroupHistoryView/Utils";

type Props = WidgetViewDefault & ConfigurationGroupSettingsEditViewProps &{
	data: ConfigurationGroupSettingsEditDto;
};
const SettingsView = (props: Props) => {

	useEffect(() => {
		if(props.editMode) {
			if(!props.isForModal) {
				const resultValidations = getCurrentTabValidation(props.validationResult, CONFIGURATIONGROUPSETTINGS);

				let newTabValidations = [...props.tabValidations];
				newTabValidations[2] = resultValidations;
				props.setTabValidation(newTabValidations);
			}
		}
	},[props.validationResult, props.editMode]);

	return (
		<div className="container-configuration-group-edit">
			<div className={!props.isSingleColumn ? 'container-row' : 'container-column'}
			>
				<div className={!props.isSingleColumn ? 'col-md-4' : 'col-12'}>
					<TextFieldTooltip
						id="harshAcceleration"
						type="number"
						className="resize-font noSpinner"
						fullWidth
						label={TranslateText('fields.harshAcceleration')}
						toolTipText={TranslateText('fields.harshAccelerationSetting')}
						fontSizeLabel={12}
						labelStyle={props.isForModal &&
						verifyDifferences(
							props.data,
							'harshAcceleration',
							CONFIGURATIONGROUPSETTINGS,
							props.differences) ? {color: props.colorLabel } : {}
						}
						disableTooltipButton={!props.editMode && !props.isForModal}
						inputProps={{
							readOnly: !props.editMode,
							style: { fontSize: 12 },
							onKeyPress: (event: any) => {
								if (event.key === '-' || event.key === 'e') {
									event.preventDefault();
								}
							}}}
						name="coast"
						value={props.data.configurationGroupSettings?.harshAcceleration}
						onChange={(e) => {
							props.handleChange(getNumberValue(e.target.value), 'harshAcceleration', CONFIGURATIONGROUPSETTINGS);
						}}
						disabled={!props.editMode}
						onKeyDown={handleKeyDown}
						onWheel={(e: any) => e.target.blur()}
						validationResult={props.validationResult?.harshAccelerationConfigurationGroupSettings}
					/>
					<TextFieldTooltip
						id="harshBrake"
						type="number"
						className="resize-font noSpinner"
						fullWidth
						label={TranslateText('fields.harshBrake')}
						toolTipText={TranslateText('fields.harshBrakeSetting')}
						fontSizeLabel={12}
						labelStyle={props.isForModal &&
						verifyDifferences(
							props.data,
							'harshBrake',
							CONFIGURATIONGROUPSETTINGS,
							props.differences) ? {color: props.colorLabel } : {}
						}
						disableTooltipButton={!props.editMode && !props.isForModal}
						inputProps={{
							readOnly: !props.editMode,
							style: { fontSize: 12 },
							onKeyPress: (event: any) => {
								if (event.key === '-' || event.key === 'e') {
									event.preventDefault();
								}
							}}}
						name="harshBrake"
						value={props.data.configurationGroupSettings?.harshBrake}
						onChange={(e) => {
							props.handleChange(getNumberValue(e.target.value), 'harshBrake', CONFIGURATIONGROUPSETTINGS);
						}}
						disabled={!props.editMode}
						onKeyDown={handleKeyDown}
						onWheel={(e: any) => e.target.blur()}
						validationResult={props.validationResult?.harshBrakeConfigurationGroupSettings}
					/>
					<div className="form-group">
						<TextField
							id="rpm"
							type="number"
							className="resize-font noSpinner"
							fullWidth
							label={TranslateText('fields.rpm')}
							InputLabelProps={{
								style:
									props.isForModal &&
									verifyDifferences(
										props.data,
										'rpm',
										CONFIGURATIONGROUPSETTINGS,
										props.differences) ? { color: props.colorLabel } : {},
							}}
							inputProps={{
								readOnly: !props.editMode,
								style: { fontSize: 12 },
								onKeyPress: (event: any) => {
									if (event.key === '-' || event.key === 'e') {
										event.preventDefault();
									}
								}}}
							name="rpm"
							value={props.data.configurationGroupSettings?.rpm}
							onChange={(e) => {
								props.handleChange(getNumberValue(e.target.value), 'rpm', CONFIGURATIONGROUPSETTINGS);
							}}
							disabled={!props.editMode}
							onKeyDown={handleKeyDown}
							onWheel={(e: any) => e.target.blur()}
						/>
						<ValidationMessage result={props.validationResult?.rpmConfigurationGroupSettings} />
					</div>
				</div>
				<div className={!props.isSingleColumn ? 'col-md-4' : 'col-12'}>
					<div className="form-group">
						<TextField
							id="speed"
							type="number"
							className="resize-font noSpinner"
							fullWidth
							label={TranslateText('fields.speed')}
							InputLabelProps={{
								style:
									props.isForModal &&
									verifyDifferences(
										props.data,
										'speed',
										CONFIGURATIONGROUPSETTINGS,
										props.differences) ? { color: props.colorLabel } : {},
							}}
							inputProps={{
								readOnly: !props.editMode,
								style: { fontSize: 12 },
								onKeyPress: (event: any) => {
									if (event.key === '-' || event.key === 'e') {
										event.preventDefault();
									}
								}}}
							name="speed"
							value={props.data.configurationGroupSettings?.speed}
							onChange={(e) => {
								props.handleChange(getNumberValue(e.target.value), 'speed', CONFIGURATIONGROUPSETTINGS);
							}}
							disabled={!props.editMode}
							onKeyDown={handleKeyDown}
							onWheel={(e: any) => e.target.blur()}
						/>
						<ValidationMessage result={props.validationResult?.speedConfigurationGroupSettings} />
					</div>
					<TextFieldTooltip
						id="torque"
						type="number"
						className="resize-font noSpinner"
						fullWidth
						label={TranslateText('fields.torque')}
						toolTipText={TranslateText('fields.torqueDetails')}
						fontSizeLabel={12}
						labelStyle={props.isForModal &&
						verifyDifferences(
							props.data,
							'torque',
							CONFIGURATIONGROUPSETTINGS,
							props.differences) ? {color: props.colorLabel } : {}
						}
						disableTooltipButton={!props.editMode && !props.isForModal}
						inputProps={{
							readOnly: !props.editMode,
							style: { fontSize: 12 },
							onKeyPress: (event: any) => {
								if (event.key === '-' || event.key === 'e') {
									event.preventDefault();
								}
							}}}
						name="torque"
						value={props.data.configurationGroupSettings?.torque}
						onChange={(e) => {
							props.handleChange(getNumberValue(e.target.value), 'torque', CONFIGURATIONGROUPSETTINGS);
						}}
						disabled={!props.editMode}
						onKeyDown={handleKeyDown}
						onWheel={(e: any) => e.target.blur()}
						validationResult={props.validationResult?.torqueConfigurationGroupSettings}
					/>
					<TextFieldTooltip
						id="pedal"
						type="number"
						className="resize-font noSpinner"
						fullWidth
						label={TranslateText('fields.pedal')}
						toolTipText={TranslateText('fields.pedalDetails')}
						fontSizeLabel={12}
						labelStyle={props.isForModal &&
						verifyDifferences(
							props.data,
							'pedal',
							CONFIGURATIONGROUPSETTINGS,
							props.differences) ? {color: props.colorLabel } : {}
						}
						disableTooltipButton={!props.editMode && !props.isForModal}
						inputProps={{
							readOnly: !props.editMode,
							style: { fontSize: 12 },
							onKeyPress: (event: any) => {
								if (event.key === '-' || event.key === 'e') {
									event.preventDefault();
								}
							}}}
						name="pedal"
						value={props.data.configurationGroupSettings?.pedal}
						onChange={(e) => {
							props.handleChange(getNumberValue(e.target.value), 'pedal', CONFIGURATIONGROUPSETTINGS);
						}}
						disabled={!props.editMode}
						onKeyDown={handleKeyDown}
						onWheel={(e: any) => e.target.blur()}
						validationResult={props.validationResult?.pedalConfigurationGroupSettings}
					/>
				</div>
				<div className={!props.isSingleColumn ? 'col-md-4' : 'col-12'}>
					<TextFieldTooltip
						id="acceleration"
						type="number"
						className="resize-font noSpinner"
						fullWidth
						label={TranslateText('fields.acceleration')}
						toolTipText={TranslateText('fields.accelerationDetails')}
						labelStyle={props.isForModal &&
						verifyDifferences(
							props.data,
							'acceleration',
							CONFIGURATIONGROUPSETTINGS,
							props.differences) ? {color: props.colorLabel } : {}
						}
						disableTooltipButton={!props.editMode && !props.isForModal}
						inputProps={{
							readOnly: !props.editMode,
							style: { fontSize: 12 },
							onKeyPress: (event: any) => {
								if (event.key === '-' || event.key === 'e') {
									event.preventDefault();
								}
							}}}
						fontSizeLabel={12}
						name="acceleration"
						value={props.data.configurationGroupSettings?.acceleration}
						onChange={(e) => {
							props.handleChange(getNumberValue(e.target.value), 'acceleration', CONFIGURATIONGROUPSETTINGS);
						}}
						disabled={!props.editMode}
						onKeyDown={handleKeyDown}
						onWheel={(e: any) => e.target.blur()}
						validationResult={props.validationResult?.accelerationConfigurationGroupSettings}
					/>
					<TextFieldTooltip
						id="deceleration"
						type="number"
						className="resize-font noSpinner"
						fullWidth
						label={TranslateText('fields.deceleration')}
						toolTipText={TranslateText('fields.decelerationDetails')}
						labelStyle={props.isForModal &&
						verifyDifferences(
							props.data,
							'deceleration',
							CONFIGURATIONGROUPSETTINGS,
							props.differences) ? {color: props.colorLabel } : {}
						}
						disableTooltipButton={!props.editMode && !props.isForModal}
						inputProps={{
							readOnly: !props.editMode,
							style: { fontSize: 12 },
							onKeyPress: (event: any) => {
								if (event.key === '-' || event.key === 'e') {
									event.preventDefault();
								}
							}}}
						fontSizeLabel={12}
						name="decelerationn"
						value={props.data.configurationGroupSettings?.deceleration}
						onChange={(e) => {
							props.handleChange(getNumberValue(e.target.value), 'deceleration', CONFIGURATIONGROUPSETTINGS);
						}}
						disabled={!props.editMode}
						onKeyDown={handleKeyDown}
						onWheel={(e: any) => e.target.blur()}
						validationResult={props.validationResult?.decelerationConfigurationGroupSettings}
					/>
				</div>
			</div>
		</div>
	);
}

export default SettingsView;
