import 'moment/min/locales';

import moment from 'moment';

import * as GlobalSettings from '../../GlobalSettings.json';
import ajaxUtil from '../../utils/Ajax';
import DateTimeUtil from '../datetime/DateTimeUtil';
import { FormValidator } from '../interfaces/FormValidator';
import { ValidationRequestData } from '../interfaces/ValidationRequestData';
import { ArrayRuleType, ValidationRule } from '../interfaces/ValidationRules';
import { Rule, ValidationData, ValidationResult } from './interfaces';

const AsyncFunction = (async () => {}).constructor;
const WARNING = 'Warning';

const ValidationUtil = {
	success: () => {
		return true;
	},
	fail: () => {
		return false;
	},
	warningRule: (ruleName: string) => `${WARNING}${ruleName}`,
	isWarningRule: (ruleName: string) => ruleName.startsWith(WARNING),

	validateChanges: async (
		validator: FormValidator,
		dataObject: { [key: string]: any },
		setInvalidKeys: (keys: string[]) => void
	): Promise<boolean> => {
		const invalidKeys = [];
		for (const key in dataObject) {
			const isValid = await ValidationUtil.validateKey(validator, key, dataObject[key]);
			if (!isValid) {
				invalidKeys.push(key);
			}
		}
		setInvalidKeys(invalidKeys);
		return invalidKeys.length === 0;
	},

	validate: async (
		validator: FormValidator,
		key: string,
		value: string,
		invalidKeys: string[],
		setInvalidKeys: (keys: string[]) => void
	) => {
		const isValid = await ValidationUtil.validateKey(validator, key, value);
		let keys = [...invalidKeys];
		if (isValid) {
			keys = invalidKeys.filter((k) => k !== key);
		} else {
			if (!keys.includes(key)) {
				keys.push(key);
			}
		}
		setInvalidKeys(keys);
	},

	validateKey: async (validator: FormValidator, key: string, value: string): Promise<boolean> => {
		if (validator && validator.rules[key]) {
			const asyncRulesNames = ValidationUtil.getAsyncRuleNames(validator, key);
			if (asyncRulesNames.length) {
				const transformedAsyncRules: ValidationRule = await ValidationUtil.transformAsyncRules(
					validator,
					key,
					value,
					asyncRulesNames
				);

				validator.removeRules(key, asyncRulesNames);
				validator.addRules(key, transformedAsyncRules);
			}
			return validator.validate(key);
		}
		return true;
	},

	validateForms: (formValidators: FormValidator[]): boolean => {
		const validationResults: boolean[] = [];
		for (const form of formValidators.filter((x) => x && x.element)) {
			validationResults.push(form.validate());
		}

		return validationResults.every((r) => r);
	},

	clearAllValidations: (
		validator: FormValidator,
		dataObject: { [key: string]: any },
		setInvalidKeys: (keys: string[]) => void
	) => {
		if (validator && dataObject) {
			for (const key in dataObject) {
				ValidationUtil.clearAsyncValidations(validator, key);
			}
			(validator as any).clearForm(); //This is need in order to clear the messages
			const warnings = validator.element.querySelectorAll('.WARNING-label');
			warnings.forEach((el) => {
				el.textContent = '';
			});
			setInvalidKeys([]);
		}
	},

	getAsyncRuleNames: (validator: FormValidator, key: string): string[] => {
		if (validator && validator.rules[key]) {
			const ruleCategory = validator.rules[key];
			const asyncRulesNames = Object.getOwnPropertyNames(ruleCategory).filter((ruleName) => {
				if (Array.isArray(ruleCategory[ruleName])) {
					const [result, message, rule] = [...(ruleCategory[ruleName] as ArrayRuleType)];
					if (rule instanceof AsyncFunction) {
						return true;
					}
				}
				return false;
			});
			return asyncRulesNames;
		}
		return [];
	},

	transformAsyncRules: async (validator: FormValidator, key: string, value: string, asyncRulesNames: string[]) => {
		const ruleConfig: ValidationRule = {};
		if (validator && validator.rules[key]) {
			await Promise.all(
				asyncRulesNames.map((name) => {
					return new Promise<void>((resolve) => {
						const rule = validator.rules[key][name] as ArrayRuleType;
						rule[2](value).then((result: boolean) => {
							// if the async rule is a WARNING the save button should be clickable and a different error should be shown
							if (ValidationUtil.isWarningRule(name)) {
								const warningContainer = document.getElementById(`${key}${WARNING}`);
								if (warningContainer) {
									if (!result) {
										warningContainer.textContent = rule[1];
										result = true;
									} else {
										warningContainer.textContent = '';
									}
								}
							}
							ruleConfig[name] = [
								result ? ValidationUtil.success : ValidationUtil.fail,
								rule[1],
								rule[2],
							];
							resolve();
						});
					});
				})
			);
		}
		return ruleConfig;
	},

	clearAsyncValidations: (validator: FormValidator, key: string): void => {
		if (validator && validator.rules[key]) {
			const ruleConfig: ValidationRule = {};
			const asyncRulesNames = ValidationUtil.getAsyncRuleNames(validator, key);
			if (asyncRulesNames.length > 0) {
				asyncRulesNames.map((name) => {
					const rule = validator.rules[key][name] as ArrayRuleType;
					ruleConfig[name] = [ValidationUtil.success, rule[1], rule[2]];
				});
				validator.removeRules(key, asyncRulesNames);
				validator.addRules(key, ruleConfig);
			}
		}
	},

	validateFieldsRequest: (validateControllerAction: string, data: ValidationRequestData): Promise<boolean> => {
		return ajaxUtil.post<boolean>(`${GlobalSettings.validateApi}/${validateControllerAction}`, data);
	},
};

export const RESERVED_KEYWORDS = ['emptyusername'];

export const ValidationRulesUtil = {
	date: (str: string | Date) => {
		if (str) {
			if (typeof str === 'string' || str instanceof Date) {
				return moment(str).isValid();
			}
			return false;
		}
		return true;
	},
	time: (str: string) => {
		return str && moment(str, DateTimeUtil.momentTimeFormat()).isValid();
	},
	dateTime: (str: string) => {
		return str && moment(str, DateTimeUtil.momentDateTimeFormat()).isValid();
	},
	numberRange: (value: number, minimum: number, maximum: number) => {
		return minimum <= value && value <= maximum;
	},
};

export const getFieldValue = (data: ValidationData, fieldName: string) => {
	if (fieldName.indexOf('.') > -1) {
		//accessing nested objects
		return fieldName.split('.').reduce((p, c) => (p && p[c]) || null, data);
	} else {
		return data[fieldName];
	}
};

export const getNestedFieldValue = (data: ValidationData, childObjName: string, fieldName: string): any => {
	if (!data) {
		return;
	}

	if (data.hasOwnProperty(fieldName)) {
		console.log('Data found: ' + data[fieldName]);
		return data[fieldName];
	}

	for (let key in data) {
		if (key.toLowerCase() === childObjName.toLowerCase()) {
			return getNestedFieldValue(data[key], childObjName, fieldName);
		}
	}
};

export const addRuleValidationToWaitList = (
	rule: Rule,
	ruleName: string,
	fieldName: string,
	waitList: Promise<boolean>[],
	result: ValidationResult,
	data: ValidationData,
	context: any
) => {
	const promise: Promise<boolean> = rule.validationFunction(data, fieldName, context);
	promise.then((success: boolean) => {
		result[fieldName][ruleName] = {
			valid: success,
			error: !rule.isWarning,
			message: typeof rule.message === 'string' ? rule.message : rule.message(),
		};
	});
	waitList.push(promise);
};

export default ValidationUtil;
