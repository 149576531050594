import ConfigurationGroupDto from 'models/ConfigurationGroupDto';
import { ValidationResult } from 'shared/validation/interfaces';
import { CurrentSession } from 'store';

import { ControlPointRounded } from '@material-ui/icons';

export type ConfigurationGroupProps = {
	currentSession?: CurrentSession | null;
	data: any;
	customerId?: string;
	validationResult: ValidationResult | null;
	handleChange: (value: any[keyof any], statePropName: keyof any, childObjName?: string) => void;
	isAddNew?: boolean;
	editMode?: boolean;
	isForNewAddWizard?: boolean;
};

export const handleConfigurationGroupChange = <TEntity>(
	rootObj: TEntity,
	statePropName: keyof TEntity,
	value: TEntity[keyof TEntity],
	childObjName?: string
): TEntity => {
	return {
		...setNestedFieldValue<TEntity>(rootObj, statePropName, value, childObjName),
	};
};

export const setNestedFieldValue = <TEntity>(
	data: any,
	fieldName: keyof TEntity,
	value: TEntity[keyof TEntity],
	childObjName?: string
): TEntity => {
	//If data is empty
	if (!data) {
		return null;
	}

	//If data has no properties
	if (Object.keys(data).length === 0) {
		return null;
	}

	//If not looking for childObj
	if (!childObjName && data.hasOwnProperty(fieldName)) {
		data[fieldName] = value;
		return data;
	}

	let result = null;

	for (const key in data) {
		//If property has no child objects
		if (typeof data[key] !== 'object') {
			continue;
		}

		if (data[key] === null) {
			continue;
		}

		//If simple object and has no properties
		if (
			Object.keys(data[key]) === undefined
				? Object.values(data[key]).length === 0
				: Object.keys(data[key])?.length === 0
		) {
			continue;
		}

		if ((childObjName === null || childObjName === undefined) && key !== fieldName) {
			continue;
		}

		if (childObjName === null || childObjName === undefined) {
			data[key] = value;
			return data;
		} else {
			if (key.toLowerCase() === childObjName.toLowerCase()) {
				//If correct child object found
				data[key][fieldName] = value;
				result = data;
			}

			//If correct child object not found
			if (key.toLowerCase() !== childObjName.toLowerCase()) {
				result = setNestedFieldValue(data[key], fieldName, value, childObjName);
			}

			if (result !== null && result !== undefined) {
				return data;
			}
		}
	}
};
