import ClaimType from 'authorization/ClaimType';
import { ClaimUtil } from 'authorization/ClaimUtil';
import Accordion from 'components/Accordion/Accordion';
import AccordionSection from 'components/Accordion/AccordionSection';
import MaterialAutocomplete, { AutocompleteItem } from 'components/Common/Autocomplete/MaterialAutocomplete';
import { CONFIGURATIONGROUPGENERALINFORMATION } from 'components/NewAddWizard/Views/ConfigurationGroup/constants';
import { handleConfigurationGroupChange } from 'components/NewAddWizard/Views/ConfigurationGroup/types';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import GlobalSettings from 'GlobalSettings.json';
import Customer from 'models/Customer';
import FuelTypeEnum from 'models/FuelTypeEnum';
import ObjectTypeEnum from 'models/ObjectTypeEnum';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomerNameDisplay from 'shared/components/CustomerNameDisplay';
import { FieldRules } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { ApplicationState } from 'store';
import ajaxUtil from 'utils/Ajax';
import { getAutocompleteEnumOptions, TranslateText } from 'utils/Translations';

import { Checkbox, FormControlLabel, TextField, Typography } from '@material-ui/core';

import { WidgetViewDefault } from '../Widget';

type ConfigurationGroupDetailsData = {
	id: string;
	configurationGroupGeneralInformation: {
		id: string;
		code: string;
		customerId: string;
		customer: Customer;
		name: string;
		description: string;
		externalCode: string;
		objectType: ObjectTypeEnum;
		fuelType: FuelTypeEnum;
	};
	aspNetUserId: string;
	aspNetUserName: string;
	active: boolean;
};

type Props = WidgetViewDefault & {
	data: ConfigurationGroupDetailsData;
	initialData: any;
	editMode: boolean;
};

const ConfigurationGroupInformationViewValidation = (): FieldRules => {
	return {
		code: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(!!data.configurationGroupGeneralInformation?.code);
					},
				},
				unique: {
					message: TranslateText('fieldsValidations.uniqueValue'),
					validationFunction: (data: any) => {
						if (!data.configurationGroupGeneralInformation?.code.trim()) {
							return ValidatorFunctions.wrapInPromise(true);
						}
						return ajaxUtil.post<boolean>(`${GlobalSettings.validateApi}/ConfigurationGroupFields`, {
							customerId: data.configurationGroupGeneralInformation?.customerId,
							value: data.configurationGroupGeneralInformation?.code,
							Field: 'code',
							editedEntityId: data.id ? data.id : null,
						});
					},
				},
			},
		},
		externalCode: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							!!data.configurationGroupGeneralInformation?.externalCode
						);
					},
				},
				unique: {
					message: TranslateText('fieldsValidations.uniqueValue'),
					validationFunction: (data: any) => {
						if (!data.configurationGroupGeneralInformation?.externalCode.trim()) {
							return ValidatorFunctions.wrapInPromise(true);
						}
						return ajaxUtil.post<boolean>(`${GlobalSettings.validateApi}/ConfigurationGroupFields`, {
							customerId: data.configurationGroupGeneralInformation?.customerId,
							value: data.configurationGroupGeneralInformation?.externalCode,
							Field: 'externalCode',
							editedEntityId: data.id ? data.id : null,
						});
					},
				},
			},
		},
		active: {
			rules: {
				required: ValidatorFunctions.required(),
			},
		},
		name: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(!!data.configurationGroupGeneralInformation?.name);
					},
				},
			},
		},
		description: {
			rules: {},
		},
		objectType: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							data.configurationGroupGeneralInformation?.objectType in ObjectTypeEnum
						);
					},
				},
			},
		},
		fuelType: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							data.configurationGroupGeneralInformation?.fuelType in FuelTypeEnum
						);
					},
				},
			},
		},
	};
};

const ConfigurationGroupInformationView = (props: Props) => {
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const [expandedSection, setExpandedSection] = useState<number>(0);

	const objectTypeOptions = useMemo<AutocompleteItem[]>(
		() => getAutocompleteEnumOptions(ObjectTypeEnum, 'configurationGroups.objectTypeEnum.', true),
		[]
	);

	const fuelTypeOptions = useMemo<AutocompleteItem[]>(
		() => getAutocompleteEnumOptions(FuelTypeEnum, 'configurationGroups.fuelTypeEnum.'),
		[]
	);

	//setup validator first time we are in edit mode
	useEffect(() => {
		props.setValidatorCallback(new Validator({ fieldRules: ConfigurationGroupInformationViewValidation() }, true));
	}, [props.entityId]);

	const events = {
		onValueChanged: async (value: any, key: any, childObjName?: string) => {
			const oldState = { ...props.data };
			const newData = handleConfigurationGroupChange<ConfigurationGroupDetailsData>(
				oldState,
				key,
				value,
				childObjName
			);
			props.changeDataCallback(newData);
		},
	};

	return (
		<>
			<Accordion
				scrollToTop={props.scrollToTop}
				expandedCallback={(index) => {
					setExpandedSection(index);
				}}
			>
				<AccordionSection expanded={expandedSection === 0} header={TranslateText('common.systemInformation')}>
					<CustomerNameDisplay customerId={props.data.configurationGroupGeneralInformation.customerId} />
					<div className="form-group">
						<TextField
							id="code"
							type="text"
							className="resize-font"
							fullWidth
							label={TranslateText('fields.code')}
							inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 } }}
							name="code"
							value={props.data.configurationGroupGeneralInformation.code}
							onChange={(e) => {
								if (e.target) {
									events.onValueChanged(e.target.value, 'code', CONFIGURATIONGROUPGENERALINFORMATION);
								}
							}}
							disabled={!props.editMode}
						/>
						<ValidationMessage result={props.validationResult?.code} />
					</div>
					<div className="form-group">
						<TextField
							id="externalCode"
							type="text"
							className="resize-font"
							fullWidth
							label={TranslateText('fields.externalCode')}
							inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 } }}
							name="externalCode"
							value={props.data.configurationGroupGeneralInformation.externalCode}
							onChange={(e) => {
								if (e.target) {
									events.onValueChanged(
										e.target.value,
										'externalCode',
										CONFIGURATIONGROUPGENERALINFORMATION
									);
								}
							}}
							disabled={!props.editMode}
						/>
						<ValidationMessage result={props.validationResult?.externalCode} />
					</div>
					<div className="form-group">
						<FormControlLabel
							control={
								<Checkbox
									name="active"
									color={'primary'}
									checked={props.data.active}
									readOnly={!props.editMode}
									onChange={(args: any) => {
										events.onValueChanged(args.target.checked, 'active');
									}}
									disabled={!props.editMode}
								/>
							}
							label={
								<Typography style={{ fontSize: 12, marginRight: 5 }}>
									{TranslateText('fields.active')}
								</Typography>
							}
							labelPlacement="start"
							style={{ margin: 0 }}
						/>
						<ValidationMessage result={props.validationResult?.active} />
					</div>
					<div className="form-group" hidden={!ClaimUtil.validateHasClaim(user, ClaimType.Persons)}>
						<div className="form-group">
							<TextField
								id="aspNetUserName"
								type="text"
								className="resize-font"
								fullWidth
								label={TranslateText('fields.madeBy')}
								inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 } }}
								name="aspNetUserName"
								value={props.data.aspNetUserName}
								onChange={(e) => {
									if (e.target) {
										events.onValueChanged(e.target.value, 'aspNetUserName');
									}
								}}
								disabled={true}
							/>
							<ValidationMessage result={props.validationResult?.aspNetUserName} />
						</div>
					</div>
				</AccordionSection>

				<AccordionSection expanded={expandedSection === 1} header={TranslateText('common.configurationGroup')}>
					<div className="form-group">
						<TextField
							id="name"
							type="text"
							className="resize-font"
							fullWidth
							label={TranslateText('fields.name')}
							inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 } }}
							name="name"
							value={props.data.configurationGroupGeneralInformation.name}
							onChange={(e) => {
								if (e.target) {
									events.onValueChanged(e.target.value, 'name', CONFIGURATIONGROUPGENERALINFORMATION);
								}
							}}
							disabled={!props.editMode}
						/>
						<ValidationMessage result={props.validationResult?.name} />
					</div>
					<div className="form-group">
						<TextField
							id="description"
							type="text"
							className="resize-font"
							fullWidth
							label={TranslateText('fields.description')}
							inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 } }}
							name="description"
							value={props.data.configurationGroupGeneralInformation.description}
							onChange={(e) => {
								if (e.target) {
									events.onValueChanged(
										e.target.value,
										'description',
										CONFIGURATIONGROUPGENERALINFORMATION
									);
								}
							}}
							disabled={!props.editMode}
						/>
						<ValidationMessage result={props.validationResult?.description} />
					</div>
					<div className="form-group">
						<MaterialAutocomplete
							valueId={props.data.configurationGroupGeneralInformation.objectType}
							dataSource={objectTypeOptions}
							name="objectType"
							disabled={!props.editMode}
							label={TranslateText('configurationGroups.objectType')}
							onChange={({ value }) =>
								events.onValueChanged(value, 'objectType', CONFIGURATIONGROUPGENERALINFORMATION)
							}
							validationResult={props.validationResult?.objectType}
						/>
						<ValidationMessage result={props.validationResult?.objectType} />
					</div>
					<div className="form-group">
						<MaterialAutocomplete
							valueId={props.data.configurationGroupGeneralInformation.fuelType}
							dataSource={fuelTypeOptions}
							name="fuelType"
							disabled={!props.editMode}
							label={TranslateText('configurationGroups.fuelType')}
							onChange={({ value }) =>
								events.onValueChanged(value, 'fuelType', CONFIGURATIONGROUPGENERALINFORMATION)
							}
							validationResult={props.validationResult?.fuelType}
						/>
						<ValidationMessage result={props.validationResult?.fuelType} />
					</div>
				</AccordionSection>
			</Accordion>
		</>
	);
};

export default ConfigurationGroupInformationView;
