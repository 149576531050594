import '../configurationGroupView.scss'

import React, {useMemo} from 'react';

import {getAutocompleteEnumOptions, TranslateText} from "../../../../../utils/Translations";
import {Checkbox, Chip, FormControlLabel, InputLabel, TextField, Typography} from '@material-ui/core';
import {WidgetViewDefault} from "../../../Widget";
import {ConfigurationGroupSettingsEditViewProps} from "../types";
import {
	ConfigurationGroupSettingsEditDto,
	inputLabelAutocompleteEditStyle
} from "../ConfigurationGroupSettingsView";
import {
	CONFIGURATIONGROUPMEDIA, CONFIGURATIONGROUPTRIPS
} from "../../../../NewAddWizard/Views/ConfigurationGroup/constants";
import {ConfigurationGroupMediaBitMask} from "../../../../../models/ConfigurationGroupMediaBitMask";
import {AutocompleteItem} from "../../../../Common/Autocomplete/MaterialAutocomplete";
import {ValidationMessage} from "../../../../ValidationMessage/ValidationMessage";
import {Autocomplete} from '@material-ui/lab';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import {handleKeyDown} from "../../../../Common/RemoveSpinnerOptionInput";
import { getNumberValue } from '../Utils';
import {verifyDifferences} from "../../ConfigurationGroupHistoryView/Utils";

type Props = WidgetViewDefault & ConfigurationGroupSettingsEditViewProps &{
	data: ConfigurationGroupSettingsEditDto;
};
const MediaView = (props: Props) => {
	const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
	const checkedIcon = <CheckBoxIcon fontSize="small" />;

	const MediaOptions = useMemo<AutocompleteItem[]>(
		() => getAutocompleteEnumOptions(ConfigurationGroupMediaBitMask, 'configurationGroups.mediaBitMaskEnum.', true),
		[]
	);

	return (
		<div className="container-configuration-group-edit">
			<div className={!props.isSingleColumn ? 'container-row' : 'container-column'}
			>
				<div className={!props.isSingleColumn ? 'col-md-6' : 'col-12'}>
					<div className="form-group">
						<FormControlLabel
							control={
								<Checkbox
									name="sendMediaOverWifi"
									checked={props.data.configurationGroupMedia?.sendMediaOverWifi ?? false}
									onChange={(event) => props.handleChange(event.target.checked, 'sendMediaOverWifi', CONFIGURATIONGROUPMEDIA)}
								/>
							}
							label={
								<Typography
									style={{
										fontSize: 12,
										marginRight: 5,
										color: props.isForModal &&
										verifyDifferences(
											props.data,
											'sendMediaOverWifi',
											CONFIGURATIONGROUPMEDIA,
											props.differences) ? props.colorLabel
											: props.editMode ? '#0000008A' : '#00000061',
									}}
								>
									{TranslateText('fields.sendMediaOverWifi')}
								</Typography>
							}
							disabled={!props.editMode}
							className="m-0"
							labelPlacement="start"
						/>
					</div>
					<div className="form-group">
						<Autocomplete
							filterSelectedOptions={false}
							fullWidth={true}
							className="material-autocomplete"
							options={MediaOptions}
							autoHighlight
							getOptionLabel={(option) => option.display}
							noOptionsText={TranslateText('common.noRecordFound')}
							multiple
							id="checkboxes-tags-demo"
							disableCloseOnSelect
							disabled={!props.editMode}
							value={MediaOptions.filter((option) =>
								props.data?.configurationGroupMedia?.alarm?.includes(
									ConfigurationGroupMediaBitMask[option.id as keyof typeof ConfigurationGroupMediaBitMask]
								)
							)}
							renderOption={(option, { selected }) => (
								<React.Fragment>
									<Checkbox
										icon={icon}
										checkedIcon={checkedIcon}
										style={{ marginRight: 8 }}
										checked={selected}
									/>
									{option.display}
								</React.Fragment>
							)}
							onChange={(event, newValue) => {
								const val: ConfigurationGroupMediaBitMask[] = Object.values(newValue as AutocompleteItem[]).map(
									(x) => ConfigurationGroupMediaBitMask[x.id as keyof typeof ConfigurationGroupMediaBitMask]
								);
								props.handleChange(val, 'alarm', CONFIGURATIONGROUPMEDIA)
							}}
							renderTags={(tagValue, getTagProps) =>
								tagValue.map((option, index) => (
									<Chip
										key={index}
										label={option.display}
										{...getTagProps({ index })}
										className="chip-selection"
									/>
								))
							}
							renderInput={(params) => (
								<>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
										<InputLabel
											style={{
												...inputLabelAutocompleteEditStyle(props.data?.configurationGroupMedia?.alarm, props.editMode),
												...(props.isForModal && verifyDifferences(props.data, 'alarm', CONFIGURATIONGROUPMEDIA, props.differences)
														? { color: props.colorLabel }
														: {}
												),
											}}
										>
											{TranslateText('fields.alarm')}
										</InputLabel>
										{!!props.validationResult?.alarm && (
											<ValidationMessage result={props.validationResult?.alarm} isForNewAddWizard={true} />
										)}
									</div>

									<TextField name={'alarm'} margin={'none'} {...params} label={null} />
								</>
							)}
						/>
					</div>
					<div className="form-group">
						<TextField
							id="videoDurationBeforeAlarm"
							type="number"
							className="resize-font noSpinner"
							fullWidth
							label={TranslateText('fields.videoDurationBeforeAlarm')}
							InputLabelProps={{
								style:
									props.isForModal &&
									verifyDifferences(
										props.data,
										'videoDurationBeforeAlarm',
										CONFIGURATIONGROUPMEDIA,
										props.differences) ? { color: props.colorLabel } : {},
							}}
							inputProps={{
								readOnly: !props.editMode,
								style: { fontSize: 12 },
								onKeyPress: (event: any) => {
									if (event.key === 'e') {
										event.preventDefault();
									}
								}}}
							name="cameraAlarmMvideoDurationBeforeAlarmask"
							value={props.data.configurationGroupMedia?.videoDurationBeforeAlarm}
							onChange={(e) => {
								props.handleChange(getNumberValue(e.target.value), 'videoDurationBeforeAlarm', CONFIGURATIONGROUPMEDIA);
							}}
							disabled={!props.editMode}
							onKeyDown={handleKeyDown}
							onWheel={(e: any) => e.target.blur()}
						/>
						<ValidationMessage result={props.validationResult?.videoDurationBeforeAlarm} />
					</div>
					<div className="form-group">
						<TextField
							id="videoDurationAfterAlarm"
							type="number"
							className="resize-font noSpinner"
							fullWidth
							label={TranslateText('fields.videoDurationAfterAlarm')}
							InputLabelProps={{
								style:
									props.isForModal &&
									verifyDifferences(
										props.data,
										'videoDurationAfterAlarm',
										CONFIGURATIONGROUPMEDIA,
										props.differences) ? { color: props.colorLabel } : {},
							}}
							inputProps={{
								readOnly: !props.editMode,
								style: { fontSize: 12 },
								onKeyPress: (event: any) => {
									if (event.key === 'e') {
										event.preventDefault();
									}
								}}}
							name="cameraAlarmMvideoDurationBeforeAlarmask"
							value={props.data.configurationGroupMedia?.videoDurationAfterAlarm}
							onChange={(e) => {
								props.handleChange(getNumberValue(e.target.value), 'videoDurationAfterAlarm', CONFIGURATIONGROUPMEDIA);
							}}
							disabled={!props.editMode}
							onKeyDown={handleKeyDown}
							onWheel={(e: any) => e.target.blur()}
						/>
						<ValidationMessage result={props.validationResult?.videoDurationAfterAlarm} />
					</div>
					<div className="form-group">
						<Autocomplete
							filterSelectedOptions={false}
							fullWidth={true}
							className="material-autocomplete"
							options={MediaOptions}
							autoHighlight
							getOptionLabel={(option) => option.display}
							noOptionsText={TranslateText('common.noRecordFound')}
							multiple
							id="checkboxes-tags-demo"
							disableCloseOnSelect
							disabled={!props.editMode}
							value={MediaOptions.filter((option) =>
								props.data?.configurationGroupMedia?.emergencyBrake?.includes(
									ConfigurationGroupMediaBitMask[option.id as keyof typeof ConfigurationGroupMediaBitMask]
								)
							)}
							renderOption={(option, { selected }) => (
								<React.Fragment>
									<Checkbox
										icon={icon}
										checkedIcon={checkedIcon}
										style={{ marginRight: 8 }}
										checked={selected}
									/>
									{option.display}
								</React.Fragment>
							)}
							onChange={(event, newValue) => {
								const val: ConfigurationGroupMediaBitMask[] = Object.values(newValue as AutocompleteItem[]).map(
									(x) => ConfigurationGroupMediaBitMask[x.id as keyof typeof ConfigurationGroupMediaBitMask]
								);
								props.handleChange(val, 'emergencyBrake', CONFIGURATIONGROUPMEDIA);
							}}
							renderTags={(tagValue, getTagProps) =>
								tagValue.map((option, index) => (
									<Chip
										key={index}
										label={option.display}
										{...getTagProps({ index })}
										className="chip-selection"
									/>
								))
							}
							renderInput={(params) => (
								<>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
										<InputLabel
											style={{
												...inputLabelAutocompleteEditStyle(props.data?.configurationGroupMedia?.emergencyBrake, props.editMode),
												...(props.isForModal && verifyDifferences(props.data, 'emergencyBrake', CONFIGURATIONGROUPMEDIA, props.differences)
														? { color: props.colorLabel }
														: {}
												),
											}}
										>
											{TranslateText('fields.emergencyBrakeEvent')}
										</InputLabel>
										{!!props.validationResult?.emergencyBrake && (
											<ValidationMessage
												result={props.validationResult?.emergencyBrake}
												isForNewAddWizard={true}
											/>
										)}
									</div>

									<TextField name={'emergencyBrake'} margin={'none'} {...params} label={null} />
								</>
							)}
						/>
					</div>
					<div className="form-group">
						<Autocomplete
							filterSelectedOptions={false}
							fullWidth={true}
							className="material-autocomplete"
							options={MediaOptions}
							autoHighlight
							getOptionLabel={(option) => option.display}
							noOptionsText={TranslateText('common.noRecordFound')}
							multiple
							id="checkboxes-tags-demo"
							disableCloseOnSelect
							disabled={!props.editMode}
							value={MediaOptions.filter((option) =>
								props.data?.configurationGroupMedia?.harshBrake?.includes(
									ConfigurationGroupMediaBitMask[option.id as keyof typeof ConfigurationGroupMediaBitMask]
								)
							)}
							renderOption={(option, { selected }) => (
								<React.Fragment>
									<Checkbox
										icon={icon}
										checkedIcon={checkedIcon}
										style={{ marginRight: 8 }}
										checked={selected}
									/>
									{option.display}
								</React.Fragment>
							)}
							onChange={(event, newValue) => {
								const val: ConfigurationGroupMediaBitMask[] = Object.values(newValue as AutocompleteItem[]).map(
									(x) => ConfigurationGroupMediaBitMask[x.id as keyof typeof ConfigurationGroupMediaBitMask]
								);
								props.handleChange(val, 'harshBrake', CONFIGURATIONGROUPMEDIA);
							}}
							renderTags={(tagValue, getTagProps) =>
								tagValue.map((option, index) => (
									<Chip
										key={index}
										label={option.display}
										{...getTagProps({ index })}
										className="chip-selection"
									/>
								))
							}
							renderInput={(params) => (
								<>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
										<InputLabel
											style={{
												...inputLabelAutocompleteEditStyle(props.data?.configurationGroupMedia?.harshBrake, props.editMode),
												...(props.isForModal && verifyDifferences(props.data, 'harshBrake', CONFIGURATIONGROUPMEDIA, props.differences)
														? { color: props.colorLabel }
														: {}
												),
											}}
										>
											{TranslateText('fields.harshBrakeEvent')}
										</InputLabel>
										{!!props.validationResult?.harshBrake && (
											<ValidationMessage result={props.validationResult?.harshBrake} isForNewAddWizard={true} />
										)}
									</div>

									<TextField name={'harshBrake'} margin={'none'} {...params} label={null} />
								</>
							)}
						/>
					</div>
				</div>
				<div className={!props.isSingleColumn ? 'col-md-6' : 'col-12'}>
					<div className="form-group">
						<Autocomplete
							filterSelectedOptions={false}
							fullWidth={true}
							className="material-autocomplete"
							options={MediaOptions}
							autoHighlight
							getOptionLabel={(option) => option.display}
							noOptionsText={TranslateText('common.noRecordFound')}
							multiple
							id="checkboxes-tags-demo"
							disableCloseOnSelect
							disabled={!props.editMode}
							value={MediaOptions.filter((option) =>
								props.data?.configurationGroupMedia?.harshAcceleration?.includes(
									ConfigurationGroupMediaBitMask[option.id as keyof typeof ConfigurationGroupMediaBitMask]
								)
							)}
							renderOption={(option, { selected }) => (
								<React.Fragment>
									<Checkbox
										icon={icon}
										checkedIcon={checkedIcon}
										style={{ marginRight: 8 }}
										checked={selected}
									/>
									{option.display}
								</React.Fragment>
							)}
							onChange={(event, newValue) => {
								const val: ConfigurationGroupMediaBitMask[] = Object.values(newValue as AutocompleteItem[]).map(
									(x) => ConfigurationGroupMediaBitMask[x.id as keyof typeof ConfigurationGroupMediaBitMask]
								);
								props.handleChange(val, 'harshAcceleration', CONFIGURATIONGROUPMEDIA);
							}}
							renderTags={(tagValue, getTagProps) =>
								tagValue.map((option, index) => (
									<Chip
										key={index}
										label={option.display}
										{...getTagProps({ index })}
										className="chip-selection"
									/>
								))
							}
							renderInput={(params) => (
								<>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
										<InputLabel
											style={{
												...inputLabelAutocompleteEditStyle(props.data?.configurationGroupMedia?.harshAcceleration, props.editMode),
												...(props.isForModal && verifyDifferences(props.data, 'harshAcceleration', CONFIGURATIONGROUPMEDIA, props.differences)
														? { color: props.colorLabel }
														: {}
												),
											}}
										>
											{TranslateText('fields.harshAccelerationEvent')}
										</InputLabel>
										{!!props.validationResult?.harshAcceleration && (
											<ValidationMessage
												result={props.validationResult?.harshAcceleration}
												isForNewAddWizard={true}
											/>
										)}
									</div>

									<TextField name={'harshAcceleration'} margin={'none'} {...params} label={null} />
								</>
							)}
						/>
					</div>
					<div className="form-group">
						<Autocomplete
							filterSelectedOptions={false}
							fullWidth={true}
							className="material-autocomplete"
							options={MediaOptions}
							autoHighlight
							getOptionLabel={(option) => option.display}
							noOptionsText={TranslateText('common.noRecordFound')}
							multiple
							id="checkboxes-tags-demo"
							disableCloseOnSelect
							disabled={!props.editMode}
							value={MediaOptions.filter((option) =>
								props.data?.configurationGroupMedia?.overSpeedStart?.includes(
									ConfigurationGroupMediaBitMask[option.id as keyof typeof ConfigurationGroupMediaBitMask]
								)
							)}
							renderOption={(option, { selected }) => (
								<React.Fragment>
									<Checkbox
										icon={icon}
										checkedIcon={checkedIcon}
										style={{ marginRight: 8 }}
										checked={selected}
									/>
									{option.display}
								</React.Fragment>
							)}
							onChange={(event, newValue) => {
								const val: ConfigurationGroupMediaBitMask[] = Object.values(newValue as AutocompleteItem[]).map(
									(x) => ConfigurationGroupMediaBitMask[x.id as keyof typeof ConfigurationGroupMediaBitMask]
								);
								props.handleChange(val, 'overSpeedStart', CONFIGURATIONGROUPMEDIA);
							}}
							renderTags={(tagValue, getTagProps) =>
								tagValue.map((option, index) => (
									<Chip
										key={index}
										label={option.display}
										{...getTagProps({ index })}
										className="chip-selection"
									/>
								))
							}
							renderInput={(params) => (
								<>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
										<InputLabel
											style={{
												...inputLabelAutocompleteEditStyle(props.data?.configurationGroupMedia?.overSpeedStart, props.editMode),
												...(props.isForModal && verifyDifferences(props.data, 'overSpeedStart', CONFIGURATIONGROUPMEDIA, props.differences)
														? { color: props.colorLabel }
														: {}
												),
											}}
										>
											{TranslateText('fields.overSpeedStartEvent')}
										</InputLabel>
										{!!props.validationResult?.overSpeedStart && (
											<ValidationMessage
												result={props.validationResult?.overSpeedStart}
												isForNewAddWizard={true}
											/>
										)}
									</div>

									<TextField name={'overSpeedStart'} margin={'none'} {...params} label={null} />
								</>
							)}
						/>
					</div>
					<div className="form-group">
						<Autocomplete
							filterSelectedOptions={false}
							fullWidth={true}
							className="material-autocomplete"
							options={MediaOptions}
							autoHighlight
							getOptionLabel={(option) => option.display}
							noOptionsText={TranslateText('common.noRecordFound')}
							multiple
							id="checkboxes-tags-demo"
							disableCloseOnSelect
							disabled={!props.editMode}
							value={MediaOptions.filter((option) =>
								props.data?.configurationGroupMedia?.overRpmStart?.includes(
									ConfigurationGroupMediaBitMask[option.id as keyof typeof ConfigurationGroupMediaBitMask]
								)
							)}
							renderOption={(option, { selected }) => (
								<React.Fragment>
									<Checkbox
										icon={icon}
										checkedIcon={checkedIcon}
										style={{ marginRight: 8 }}
										checked={selected}
									/>
									{option.display}
								</React.Fragment>
							)}
							onChange={(event, newValue) => {
								const val: ConfigurationGroupMediaBitMask[] = Object.values(newValue as AutocompleteItem[]).map(
									(x) => ConfigurationGroupMediaBitMask[x.id as keyof typeof ConfigurationGroupMediaBitMask]
								);
								props.handleChange(val, 'overRpmStart', CONFIGURATIONGROUPMEDIA);
							}}
							renderTags={(tagValue, getTagProps) =>
								tagValue.map((option, index) => (
									<Chip
										key={index}
										label={option.display}
										{...getTagProps({ index })}
										className="chip-selection"
									/>
								))
							}
							renderInput={(params) => (
								<>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
										<InputLabel
											style={{
												...inputLabelAutocompleteEditStyle(props.data?.configurationGroupMedia?.overRpmStart, props.editMode),
												...(props.isForModal && verifyDifferences(props.data, 'overRpmStart', CONFIGURATIONGROUPMEDIA, props.differences)
														? { color: props.colorLabel }
														: {}
												),
											}}
										>
											{TranslateText('fields.overRpmStartEvent')}
										</InputLabel>
										{!!props.validationResult?.overRpmStart && (
											<ValidationMessage
												result={props.validationResult?.overRpmStart}
												isForNewAddWizard={true}
											/>
										)}
									</div>

									<TextField name={'overRpmStart'} margin={'none'} {...params} label={null} />
								</>
							)}
						/>
					</div>
					<div className="form-group">
						<Autocomplete
							filterSelectedOptions={false}
							fullWidth={true}
							className="material-autocomplete"
							options={MediaOptions}
							autoHighlight
							getOptionLabel={(option) => option.display}
							noOptionsText={TranslateText('common.noRecordFound')}
							multiple
							id="checkboxes-tags-demo"
							disableCloseOnSelect
							disabled={!props.editMode}
							value={MediaOptions.filter((option) =>
								props.data?.configurationGroupMedia?.idleStart?.includes(
									ConfigurationGroupMediaBitMask[option.id as keyof typeof ConfigurationGroupMediaBitMask]
								)
							)}
							renderOption={(option, { selected }) => (
								<React.Fragment>
									<Checkbox
										icon={icon}
										checkedIcon={checkedIcon}
										style={{ marginRight: 8 }}
										checked={selected}
									/>
									{option.display}
								</React.Fragment>
							)}
							onChange={(event, newValue) => {
								const val: ConfigurationGroupMediaBitMask[] = Object.values(newValue as AutocompleteItem[]).map(
									(x) => ConfigurationGroupMediaBitMask[x.id as keyof typeof ConfigurationGroupMediaBitMask]
								);
								props.handleChange(val, 'idleStart', CONFIGURATIONGROUPMEDIA);
							}}
							renderTags={(tagValue, getTagProps) =>
								tagValue.map((option, index) => (
									<Chip
										key={index}
										label={option.display}
										{...getTagProps({ index })}
										className="chip-selection"
									/>
								))
							}
							renderInput={(params) => (
								<>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
										<InputLabel
											style={{
												...inputLabelAutocompleteEditStyle(props.data?.configurationGroupMedia?.idleStart, props.editMode),
												...(props.isForModal && verifyDifferences(props.data, 'idleStart', CONFIGURATIONGROUPMEDIA, props.differences)
														? { color: props.colorLabel }
														: {}
												),
											}}
										>
											{TranslateText('fields.idleStartEvent')}
										</InputLabel>
										{!!props.validationResult?.overRpmStart && (
											<ValidationMessage
												result={props.validationResult?.overRpmStart}
												isForNewAddWizard={true}
											/>
										)}
									</div>

									<TextField name={'overRpmStart'} margin={'none'} {...params} label={null} />
								</>
							)}
						/>
					</div>
					<div className="form-group">
						<TextField
							id="videoDurationBeforeEvent"
							type="number"
							className="resize-font noSpinner"
							fullWidth
							label={TranslateText('fields.videoDurationBeforeEvent')}
							InputLabelProps={{
								style:
									props.isForModal &&
									verifyDifferences(
										props.data,
										'videoDurationBeforeEvent',
										CONFIGURATIONGROUPMEDIA,
										props.differences) ? { color: props.colorLabel } : {},
							}}
							inputProps={{
								readOnly: !props.editMode,
								style: { fontSize: 12 },
								onKeyPress: (event: any) => {
									if (event.key === 'e') {
										event.preventDefault();
									}
								}}}
							name="videoDurationBeforeEvent"
							value={props.data.configurationGroupMedia?.videoDurationBeforeEvent}
							onChange={(e) => {
								props.handleChange(getNumberValue(e.target.value), 'videoDurationBeforeEvent', CONFIGURATIONGROUPMEDIA);
							}}
							disabled={!props.editMode}
							onKeyDown={handleKeyDown}
							onWheel={(e: any) => e.target.blur()}
						/>
						<ValidationMessage result={props.validationResult?.videoDurationBeforeEvent} />
					</div>
					<div className="form-group">
						<TextField
							id="videoDurationAfterEvent"
							type="number"
							className="resize-font noSpinner"
							fullWidth
							label={TranslateText('fields.videoDurationAfterEvent')}
							InputLabelProps={{
								style:
									props.isForModal &&
									verifyDifferences(
										props.data,
										'videoDurationAfterEvent',
										CONFIGURATIONGROUPMEDIA,
										props.differences) ? { color: props.colorLabel } : {},
							}}
							inputProps={{
								readOnly: !props.editMode,
								style: { fontSize: 12 },
								onKeyPress: (event: any) => {
									if (event.key === 'e') {
										event.preventDefault();
									}
								}}}
							name="videoDurationAfterEvent"
							value={props.data.configurationGroupMedia?.videoDurationAfterEvent}
							onChange={(e) => {
								props.handleChange(getNumberValue(e.target.value), 'videoDurationAfterEvent', CONFIGURATIONGROUPMEDIA);
							}}
							disabled={!props.editMode}
							onKeyDown={handleKeyDown}
							onWheel={(e: any) => e.target.blur()}
						/>
						<ValidationMessage result={props.validationResult?.videoDurationAfterEvent} />
					</div>
				</div>
			</div>
		</div>
	);
}
export default MediaView;
