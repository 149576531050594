import './ConfigurationGroupStyle.scss';

import { AddWizardComponentProps } from 'components/NewAddWizard/NewAddWizard';
import ConfigurationGroupDto, {
	ConfigurationGroupFactors,
	ConfigurationGroupGeneralCamera,
	ConfigurationGroupGeneralInformation,
	ConfigurationGroupIncidents,
	ConfigurationGroupLevels,
	ConfigurationGroupMedia,
	ConfigurationGroupPeriodicals,
	ConfigurationGroupSettings,
	ConfigurationGroupThresholds,
	ConfigurationGroupTrips,
	ConfigurationGroupWeights,
} from 'models/ConfigurationGroupDto';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ValidationResult } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ApplicationState } from 'store';
import { TranslateText } from 'utils/Translations';

import ConfigurationGroupSettingsView from './Settings/ConfigurationGroupSettingsView';
import ConfigurationGroupThresholdsView from './Thresholds/ConfigurationGroupThresholdsView';
import { handleConfigurationGroupChange } from './types';
import { ConfigurationGroupSettingAndThresholds } from './Utils';
import ConfigurationGroupLevelsView from "./Levels/ConfigurationGroupLevelsView";

type Props = AddWizardComponentProps & {
	id: string;
	customerId: string;
	aspNetUserId: string;
	objects: string[];
	active: boolean;
	startDateUtc: string;
	endDateUtc?: string;
	configurationGroupGeneralInformation: ConfigurationGroupGeneralInformation;
	configurationGroupWeights: ConfigurationGroupWeights;
	configurationGroupFactors: ConfigurationGroupFactors;
	configurationGroupSettings: ConfigurationGroupSettings;
	configurationGroupThresholds: ConfigurationGroupThresholds;
	configurationGroupTrips: ConfigurationGroupTrips;
	configurationGroupPeriodicals: ConfigurationGroupPeriodicals;
	configurationGroupIncidents: ConfigurationGroupIncidents;
	configurationGroupMedia: ConfigurationGroupMedia;
	configurationGroupGeneralCamera: ConfigurationGroupGeneralCamera;
	configurationGroupLevels: ConfigurationGroupLevels;
};

const ConfigGroupSettingThresholdView = (props: Props) => {
	const [unsavedData, setUnsavedData] = useState<boolean>(false);
	const [validationResult, setValidationResult] = useState<ValidationResult | null>(null);

	const [configurationGroupDto, setConfigurationGroupDto] = useState<ConfigurationGroupDto>({
		id: props.id,
		customerId: props.customerId,
		aspNetUserId: props.aspNetUserId,
		objects: props.objects,
		active: props.active,
		startDateUtc: props.startDateUtc,
		endDateUtc: props.endDateUtc,
		configurationGroupGeneralInformation: props.configurationGroupGeneralInformation,
		configurationGroupWeights: props.configurationGroupWeights,
		configurationGroupFactors: props.configurationGroupFactors,
		configurationGroupSettings: props.configurationGroupSettings,
		configurationGroupThresholds: props.configurationGroupThresholds,
		configurationGroupTrips: props.configurationGroupTrips,
		configurationGroupPeriodicals: props.configurationGroupPeriodicals,
		configurationGroupIncidents: props.configurationGroupIncidents,
		configurationGroupMedia: props.configurationGroupMedia,
		configurationGroupGeneralCamera: props.configurationGroupGeneralCamera,
		configurationGroupLevels: props.configurationGroupLevels,
	});

	const [validator] = useState<Validator>(() => {
		return new Validator(
			{
				fieldRules: ConfigurationGroupSettingAndThresholds(),
			},
			true
		);
	});

	useEffect(() => {
		if (!props.visible) return;

		setConfigurationGroupDto({
			id: props.id,
			customerId: props.customerId,
			aspNetUserId: props.aspNetUserId,
			objects: props.objects,
			active: props.active,
			startDateUtc: props.startDateUtc,
			endDateUtc: props.endDateUtc,
			configurationGroupGeneralInformation: props.configurationGroupGeneralInformation,
			configurationGroupWeights: props.configurationGroupWeights,
			configurationGroupFactors: props.configurationGroupFactors,
			configurationGroupSettings: props.configurationGroupSettings,
			configurationGroupThresholds: props.configurationGroupThresholds,
			configurationGroupTrips: props.configurationGroupTrips,
			configurationGroupPeriodicals: props.configurationGroupPeriodicals,
			configurationGroupIncidents: props.configurationGroupIncidents,
			configurationGroupMedia: props.configurationGroupMedia,
			configurationGroupGeneralCamera: props.configurationGroupGeneralCamera,
			configurationGroupLevels: props.configurationGroupLevels,
		});
	}, [props.visible]);

	useEffect(() => {
		validator.validate(configurationGroupDto).then((result) => {
			setValidationResult(result.validationResult);
			props.setValidationCallback(result.formResult);
			if (unsavedData) {
				props.onChangeCallback(configurationGroupDto);
			}
		});
	}, [configurationGroupDto]);

	const handleValueChangeMaterial = (value: any, statePropName: any, childObjName?: string) => {
		const newConfigurationGroup = handleConfigurationGroupChange<ConfigurationGroupDto>(
			configurationGroupDto,
			statePropName,
			value,
			childObjName
		);

		setUnsavedData(true);
		setConfigurationGroupDto(newConfigurationGroup);
	};

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-md-3">
					<div className="column-title">{TranslateText('common.configurationGroupSettings')}</div>
					<form id="configurationGroupSettingsForm" noValidate={true}>
						<ConfigurationGroupSettingsView
							data={configurationGroupDto}
							validationResult={validationResult}
							handleChange={handleValueChangeMaterial}
						/>
					</form>
				</div>
				<div className="col-md-6">
					<div className="column-title">{TranslateText('common.configurationGroupThresholds')}</div>
					<form id="configurationGroupThresholdsForm" noValidate={true}>
						<ConfigurationGroupThresholdsView
							data={configurationGroupDto}
							validationResult={validationResult}
							handleChange={handleValueChangeMaterial}
						/>
					</form>
				</div>
				<div className="col-md-3">
					<div className="column-title">{TranslateText('common.configurationGroupLevels')}</div>
					<form id="configurationGroupLevelsForm" noValidate={true}>
						<ConfigurationGroupLevelsView
							data={configurationGroupDto}
							validationResult={validationResult}
							handleChange={handleValueChangeMaterial}
						/>
					</form>
				</div>
			</div>
		</div>
	);
};

export default ConfigGroupSettingThresholdView;
