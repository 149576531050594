import './styles.scss';
import React, {useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import ajaxUtil from 'utils/Ajax';
import GlobalSettings from 'GlobalSettings.json';
import { ObjectTemplatesAggregation } from 'models/ObjectTemplatesAggregation';
import { TemplateSummary } from 'components/TemplateSummary';
import {CircularProgress, LinearProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { TemplateHistoryTabProps } from './types';
import {ChartHistorySummary} from "../ChartHistorySummary";
import TemplateUnitOfMeasureEnum from "../../models/TemplateUnitOfMeasureEnum";
import {TemplateStateChangeSummary} from "../TemplateStateChangesSummary";
import {isDateToday} from "../ChartHistorySummary/ChartTooltip/ChartTooltip";
import DateTimeUtil, {DateTimeConversionUtil} from "../../shared/datetime/DateTimeUtil";
import moment from 'moment';
import ChartTypeEnum from "../../models/ChartTypeEnum";
import SensorTypeEnum from "../../models/SensorTypeEnum";

export interface TemplateSelection {
	templateId: string;
	templateName: string;
	type: ChartTypeEnum;
	needToAddToTheEnd: boolean;
}

export interface TemplateGraph {
	templateId: string;
	tripId: string;
	value: number;
	latitude: number;
	longitude: number;
	timestamp: string;
	color: string;
}
export interface TemplateGraphAnalog extends TemplateGraph {
	unitOfMeasure: TemplateUnitOfMeasureEnum;
}
export interface SpeedGraph {
	id: string;
	tripId: string;
	speed: number;
	latitude: number;
	longitude: number;
	timestamp: string;
}
export interface TripTemplates {
	templates: TemplateSelection[];
	valuesRecordAnalog: Record<string, TemplateGraphAnalog[]>;
	valuesRecordDigital: Record<string, TemplateGraph[]>;
}
export interface TripStateChange {
	name: string;
	templateName: string;
	time: string;
	color: string;
	value: number;
	unitOfMeasure: TemplateUnitOfMeasureEnum;
	sensorType: SensorTypeEnum;
}
const TemplateHistoryTab = ({ hidden, tripDetails }: TemplateHistoryTabProps): JSX.Element => {
	const customerId = useSelector((state: ApplicationState) =>
		state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.id
			: state.currentSession.customerId
	);
	const customerTemplateFeature = useSelector((state: ApplicationState) =>
		state.globalCustomer?.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.templates
			: state.currentSession.customer.featuresSettings.templates
	);
	const [templatesData, setTemplatesData] = useState<TripTemplates>(null);
	const [stateChanges, setStateChanges] = useState(null);
	const [previousChartTypes, setPreviousChartTypes] = useState([]);
	const [delayForData, setDelayForData] = useState(false);
	const [showStates, setShowStates] = useState(false);
	const [showOnlyTime, setShowOnlyTime] = useState(true);
	const [containsDay, setContainsDay] = useState(false);
	const [numberOfStates, setNumberOfStates] = useState<number>(0);

	useEffect(() => {
		setDelayForData(false);
		const timer = setTimeout(() => {
			setDelayForData(true);
		}, 1000);
		return () => clearTimeout(timer);
	}, [tripDetails]);

	const { data = [], isFetching } = useQuery(
		['object-templates', tripDetails.id],
		() => {
			return ajaxUtil.get<ObjectTemplatesAggregation[]>(
				`${GlobalSettings.tripsApi}/getObjectTemplates/${tripDetails.id}/${customerId}`
			);
		},
		{
			enabled: !hidden && tripDetails.isNonTrip,
			staleTime: 60000,
			cacheTime: 60000,
		}
	);
	useEffect(() => {
		setShowStates(false);
		if(customerTemplateFeature) {
			ajaxUtil.post<TripTemplates>(
				`${GlobalSettings.tripsApi}/getTripTemplates`,
				{
					previousTripId: tripDetails.prevTripId,
					tripId: tripDetails.id,
					objectId: tripDetails.objectId,
					from: tripDetails.startTrip,
					to: tripDetails.isInProgress ? null : tripDetails.endTrip,
					isNonTrip: tripDetails.isNonTrip,
					timeZoneId: tripDetails.timezoneId,
					startLatitude: tripDetails.start?.lat,
					startLongitude: tripDetails.start?.lng,
					endLatitude: tripDetails.end?.lat,
					endLongitude: tripDetails.end?.lng
				}
			).then((data) => {
				setTemplatesData(data);
			});
		}
		else {
			setTemplatesData({
				templates: [],
				valuesRecordAnalog: {},
				valuesRecordDigital: {}
			});
		}
	},[tripDetails]);

	const setStates = (templates: string[]) => {
		setShowStates(false);
		if(templates?.length > 0) {
			ajaxUtil.post<TripStateChange[]>(
				`${GlobalSettings.tripsApi}/getStateChanges`,
				{
					objectId: tripDetails.objectId,
					templatesIds: templates,
					startDateTime: tripDetails.startTrip,
					endDateTime: tripDetails.isInProgress ? null : tripDetails.endTrip,
					timeZoneId: tripDetails.timezoneId,
				}
			).then((data) => {
				let numberOfStates = 0;
				data.forEach((state, index) => {
					numberOfStates ++;
					if(showOnlyTime && !isDateToday(state.time))
					{
						setShowOnlyTime(false);
						const date = moment(state.time).format( DateTimeConversionUtil.syncFusionToMomentDateFormat(DateTimeUtil.dateFormat(), true));
						if(/[a-zA-Z]/.test(date))
						{
							setContainsDay(true);
						}
					}
				});
				setNumberOfStates(numberOfStates);
				setStateChanges(data);
				setShowStates(true);
			});
		}
		else
		{
			setStateChanges(null);
			setShowStates(true);
		}
	};
	const renderChartHistory = () => {
		if (delayForData && templatesData !== null &&
			(!tripDetails.isNonTrip ||
				(tripDetails.isNonTrip && Object.keys(templatesData.valuesRecordAnalog).length !== 0))) {
			return (
				<ChartHistorySummary
					speedGraph={tripDetails?.speedGraph}
					color={tripDetails?.tripColor}
					templateSelection={templatesData?.templates}
					templateValuesAnalog={templatesData?.valuesRecordAnalog}
					templateValuesDigital={templatesData?.valuesRecordDigital}
					isTripInProgress={tripDetails.isInProgress}
					isNonTrip={tripDetails.isNonTrip}
					latitudeForNonTrip={tripDetails.start?.lat}
					longitudeForNonTrip={tripDetails.start?.lng}
					setStateChangesCallback={setStates}
					setPreviousChartTypesCallback={(data: any) => setPreviousChartTypes(data)}
					getPreviousChartTypesCallback={() => previousChartTypes}
					showStates={showStates}
					setShowStates={(data: boolean) => setShowStates(data)}
				/>
			);
		}
		return null;
	}
	const renderTripStateChanges = () => {
		if(showStates && delayForData && stateChanges &&  stateChanges?.length !== 0) {
			return(
				<TemplateStateChangeSummary
					states={stateChanges}
					showOnlyTime={showOnlyTime}
					containsDay={containsDay}
					numberOfStates={numberOfStates}
				/>
			);
		}
		return null;
	}

	const renderTemplateSummaries = () => {
		return data.map(d => (
			<TemplateSummary
				{...d}
				endTripTime={tripDetails.endTripTime}
				timeZoneOffset={tripDetails.timeZoneMinutesOffset}
				key={d.templateId}
			/>
		));
	}

	return (
		<div hidden={hidden} className="template-history-tab">
			{isFetching || !delayForData || templatesData === null ? (
				<LinearProgress className="linear-progress"/>
			) : (
				<div className="info-area-summary">
					{renderChartHistory()}
					{renderTripStateChanges()}
					{renderTemplateSummaries()}
				</div>
			)}
		</div>
	);
};

export default TemplateHistoryTab;
