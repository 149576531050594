import './configurationGroupHistoryModal.scss'

import React from "react";

import {TranslateText} from "../../../../../utils/Translations";
import {DebouncedButton} from "../../../../Common/DebouncedButton";
import {DEBOUNCE_TIME} from "../../../../../Constants";
import {Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import {WidgetViewDefault} from "../../../Widget";
import ConfigurationGroupHistoryModalView
	from "./ConfigurationGroupHistoryModalView/ConfigurationGroupHistoryModalView";
import {ConfigurationGroupSettingsEditDto} from "../../ConfigurationGroupSettingsView/ConfigurationGroupSettingsView";

type Props = WidgetViewDefault & {
	version: ConfigurationGroupSettingsEditDto;
	previousVersion: ConfigurationGroupSettingsEditDto;
	visible: boolean;
	closeCallback: (dataChanged: boolean) => void;
	differences: string[];
	versionTitle: number;
	previousVersionTitle: number;
};
const ConfigurationGroupHistoryModal = (props: Props) => {

	return (
	<div>
		<Dialog
			onEscapeKeyDown={() => props.closeCallback(false)}
			open={props.visible}
			className={'history-dialog'}
		>
			<DialogTitle>{TranslateText('common.configurationGroupSettings')}</DialogTitle>
			<DialogContent>
				<ConfigurationGroupHistoryModalView
					entityId={null}
					editMode={false}
					validator={null}
					validationResult={null}
					changeDataCallback={null}
					reloadDataCallback={null}
					setValidatorCallback={null}
					resetValidationsCallback={null}
					setShowSaveConfirmation={null}
					version={props.version}
					previousVersion={props.previousVersion}
					differences={props.differences}
					versionTitle={props.versionTitle}
					previousVersionTitle={props.previousVersionTitle}
				/>
			</DialogContent>
			<DialogActions className={'actions'}>
				<DebouncedButton onClick={() => {props.closeCallback(false)}} debounceTime={DEBOUNCE_TIME}>{TranslateText('common.buttonClose')}</DebouncedButton>
			</DialogActions>
		</Dialog>
	</div>
	);
}

export default ConfigurationGroupHistoryModal;
