import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import NotificationPrompt from 'shared/components/UserPrompt/NotificationPrompt';
import {TranslateText} from 'utils/Translations';

import {Tabs} from '@material-ui/core';

import * as GlobalSettings from '../../../GlobalSettings.json';
import CustomerLevelEnum from '../../../models/CustomerLevelEnum';
import {ApplicationState} from '../../../store';
import {filterUtil, FleetEntityTypeFilter, FleetSelectionDispatcher} from '../../../store/FleetSelection';
import {
	historyStoreActionCreators,
	SET_TRIP_GROUPED_IDS,
	SET_TRIP_SELECTED_IDS,
	SET_TRIP_UNSAVED_DATA
} from '../../../store/HistoryStore';
import ajaxUtil from '../../../utils/Ajax';
import {ITreeNode} from '../../SelectionTree/TreeNode/types';
import {HistoryTabHeaderWrapper} from '../HistoryTabHeaderWrapper';
import {resizableEntityActionCreators, ResizableType} from "../../../store/ResizableEntityState";
import EntityTypeEnum from "../../../models/EntityTypeEnum";
import {TripConflictResultDto} from "../HistoryTripEditWizard/EditWizardView/Conflict/ConflictView";

const setNodeVisibility = (
	treeDataDictionary: { [key: string]: ITreeNode },
	node: ITreeNode,
	filterText: string,
	filterType?: FleetEntityTypeFilter
) => {
	let hidden = false;
	if (filterText) {
		hidden = filterUtil.hiddenByText(node.text, filterText, null);
	}
	if (!hidden && filterType !== FleetEntityTypeFilter.all) {
		hidden = filterUtil.hiddenByType(node.type, filterType, null);
	}
	treeDataDictionary[node.id] = { ...node, hidden };
};

const sort = (dataCollection: ITreeNode[]) => {
	dataCollection.forEach((data: ITreeNode) => {
		if (data.items && data.items.length > 0) {
			data.items.sort((a: ITreeNode, b: ITreeNode) => a.text.localeCompare(b.text));
		}
	});

	return dataCollection.sort((a: ITreeNode, b: ITreeNode) => {
		return a.text.localeCompare(b.text);
	});
};

const HistoryTabs = () => {
	const dispatch = useDispatch();
	const tripSelectedIds = useSelector((state: ApplicationState) => state.historyStore.tripSelectedIds);
	const tripGroupedIds = useSelector((state: ApplicationState) => state.historyStore.tripGroupedIds);
	const historyTrips = useSelector((state: ApplicationState) => state.historyStore.historyTrips);
	const trackedEntities = useSelector((state: ApplicationState) => state.fleetSelection.trackedEntities);
	const customerLevel = useSelector((state: ApplicationState) => state.currentSession.customerLevel);
	const { text: filterText, entityType: filterType } = useSelector((state: ApplicationState) => state.liveDataFilter);
	const tripUnsavedData = useSelector((state: ApplicationState) => state.historyStore.tripUnsavedData);
	const tripDetailsParentId = useSelector((s: ApplicationState) => s.historyStore.tripDetails?.parentId);

	const selectedTripEditBatch = useSelector((state: ApplicationState) => state.historyStore.tripEditBatch);
	const historyMapActive = useSelector((state: ApplicationState) => state.historyStore.historyMapActive);
	const [tripsSelectedEntities, setTripsSelectedEntities] = useState<ITreeNode[]>([]);
	const [firstSelectedTabId, setFirstSelectedTabId] = useState<string>(null);
	const [showNotificationPrompt, setShowNotificationPrompt] = useState<boolean>(false);
	const [promptCallback, setPromptCallback] = useState<() => void>(null);
	const defaultValue = useSelector((state: ApplicationState) => state.currentSession.defaultEntity);

	const [localTrackedEntities, setLocalTrackedEntities] = useState<ITreeNode[]>([]);

	const initialized = useRef<boolean>(false);

	useEffect(() => {
		//from ITreeNode create dictionary with only entities, no groups, no duplicates
		const treeDataDictionary: { [key: string]: ITreeNode } = {};
		trackedEntities.forEach((item) => {
			if (item.type === 'Group') {
				if (item.items) {
					item.items.forEach((child) => {
						setNodeVisibility(treeDataDictionary, child, filterText, filterType);
					});
				}
			} else {
				setNodeVisibility(treeDataDictionary, item, filterText, filterType);
			}
		});
		//Convert to a flat list of entities
		const trackedItems = sort(Object.getOwnPropertyNames(treeDataDictionary).map((id) => treeDataDictionary[id]));

		const newGroupIds: string[] = [];
		let newTripSelectedIds: string[] = [];
		trackedItems.forEach((trackedItem) => {
			//Remove ids from grouping if they are no longer present
			if (tripGroupedIds.some((x) => x === trackedItem.id)) {
				newGroupIds.push(trackedItem.id);
			}

			//Remove ids from tab selection if they are no longer present or are hidden
			if (tripSelectedIds.some((x) => x === trackedItem.id) && !trackedItem.hidden) {
				newTripSelectedIds.push(trackedItem.id);
			}
		});
	 	if(filterText && newTripSelectedIds.length > 0 && newTripSelectedIds.every((x) => newGroupIds.includes(x))) {
				newTripSelectedIds = trackedItems
					.filter((t) => newGroupIds.includes(t.id) && !t.hidden)
					.map((t) => {
						return t.id;
					});
			}
	 	else if (!newTripSelectedIds.length || filterType !== defaultValue){
			const firstVisible = trackedItems.find((x) => !x.hidden);
			if ((firstVisible && newGroupIds.includes(firstVisible.id))) {
				newTripSelectedIds = trackedItems
					.filter((t) => newGroupIds.includes(t.id) && !t.hidden)
					.map((t) => {
						return t.id;
					});
			}
			else if (firstVisible && ((!tripSelectedIds?.length && localTrackedEntities?.length) || !newTripSelectedIds.length))
			{
				newTripSelectedIds = [firstVisible.id];
			}
		}
		else if (newTripSelectedIds.every((x) => newGroupIds.includes(x))) {
			newTripSelectedIds = [...newGroupIds];
		}

		//scroll into view only at first render
		if (!initialized.current) {
			initialized.current = true;
			if (newTripSelectedIds.length) {
				setFirstSelectedTabId(newTripSelectedIds[0]);
			}
		}

		//check and update trip grouped ids
		if (newGroupIds.length !== tripGroupedIds.length || !newGroupIds.every((g) => tripGroupedIds.includes(g))) {
			setHistoryGroupedIds(newGroupIds);
		}

		//check and update trip selected ids
		if (
			newTripSelectedIds.length !== tripSelectedIds.length ||
			!newTripSelectedIds.every((s) => tripSelectedIds.includes(s))
		) {
			setTripSelectedIds(newTripSelectedIds);
		}

		setTripsSelectedEntities(trackedItems);

		if (
			!localTrackedEntities?.length ||
			!trackedEntities?.length ||
			trackedEntities.some((te) => !localTrackedEntities.some((lte) => te.id === lte.id))
		) {
			setLocalTrackedEntities(trackedEntities);
		} //that was added in order to persist the selected entity
	}, [trackedEntities, filterText, filterType]);

	const setTripSelectedIds = (selectedTripIds: string[]) => {
		dispatch({
			type: SET_TRIP_SELECTED_IDS,
			payload: selectedTripIds,
		});
	};

	const setHistoryGroupedIds = (tripGroupedIds: string[]) => {
		dispatch({
			type: SET_TRIP_GROUPED_IDS,
			payload: tripGroupedIds,
		});
	};

	const removeTab = (entityId: string) => {
		let newTracked: ITreeNode[] = [];
		trackedEntities
			.filter((trackedEntity) => trackedEntity.id !== entityId)
			.forEach((treeNode) => {
				if (treeNode.items && treeNode.items.some((item) => item.id === entityId)) {
					newTracked = [...newTracked, ...treeNode.items.filter((item) => item.id !== entityId)];
				} else if (treeNode.id !== entityId) {
					newTracked = [...newTracked, treeNode];
				}
			});
		if (customerLevel === CustomerLevelEnum.Default) {
			const data = newTracked.map((e) => {
				delete e.items;
				return e;
			});
			ajaxUtil.post(`${GlobalSettings.fleetSelection}/SaveTracked`, data);
		}
		if(selectedTripEditBatch.activeEdit)
			dispatch(historyStoreActionCreators.clearTripEditBatch());

		FleetSelectionDispatcher.setTrackedUnfilteredEntities(dispatch, newTracked);
	};

	const checkBoxClickedCallback = useCallback(
		(id: string, checked: boolean) => {
			const newTripGroupedIds = checked
				? [...tripGroupedIds, id]
				: tripGroupedIds.filter((tripId) => tripId !== id);

			//use in selected ids only the ids from group that are visible
			const newTripSelectedIds = newTripGroupedIds.filter((id) =>
				tripsSelectedEntities.some((t) => t.id === id && !t.hidden)
			);
			setTripSelectedIds(newTripSelectedIds);
			setHistoryGroupedIds(newTripGroupedIds);
		},
		[tripGroupedIds, tripSelectedIds, tripsSelectedEntities]
	);

	const selectCallback = (id: string) => {
		if (tripGroupedIds.some((tripGroupedId) => tripGroupedId === id)) {
			setTripSelectedIds(
				tripGroupedIds.filter((id) => tripsSelectedEntities.some((t) => t.id === id && !t.hidden))
			);
		} else {
			setTripSelectedIds([id]);
		}
	};

	const validateCurrentTripData = (callbackFn: () => void) => {
		if (!tripUnsavedData) {
			callbackFn();
		} else {
			setShowNotificationPrompt(true);
			setPromptCallback(() => () => callbackFn());
		}
	};
	const getObjectHasTripDevice = async (entityId: string)  => {
		return await ajaxUtil.get<boolean>(`${GlobalSettings.tripsApi}/getObjectHasTripDevice/${entityId}`);
	}

	const editBatch = (entityId: string, entityName: string, entityType: EntityTypeEnum) => {
		if(historyMapActive)
			dispatch(historyStoreActionCreators.setIsMapActive());
		if(selectedTripEditBatch?.activeEdit) {
			dispatch(historyStoreActionCreators.clearTripEditBatch());
		}
		else {
			dispatch(
				resizableEntityActionCreators.setCollapsed(
					ResizableType.HistoryGrid,
					false
				)
			);
			if(entityType === EntityTypeEnum.Object)
			{
				ajaxUtil.get<boolean>(
					`${GlobalSettings.tripsApi}/getObjectHasTripDevice/${entityId}`
				).then((result) => {
					dispatch(historyStoreActionCreators.setTripEditBatch({
						selectedEntity: entityId,
						selectedEntityName: entityName,
						selectedEntityType: entityType,
						activeEdit: true,
						hasTripDevice: result
					}))
				}).catch((er) => {
					console.log(er);
				})
			}
			else {
				dispatch(historyStoreActionCreators.setTripEditBatch({
					selectedEntity: entityId,
					selectedEntityName: entityName,
					selectedEntityType: entityType,
					activeEdit: true,
					hasTripDevice: true
				}))
			}
		}
	}

	return (
		<div id="tabs">
			<NotificationPrompt
				title={TranslateText('common.titleUnsavedData')}
				message={TranslateText('notificationMessages.unsavedData')}
				handleUserResponse={(response) => {
					setShowNotificationPrompt(false);
					if (response) {
						promptCallback();
						setPromptCallback(null);
						dispatch({
							type: SET_TRIP_UNSAVED_DATA,
							payload: false,
						});
					}
				}}
				displayDialog={showNotificationPrompt}
			/>
			<Tabs variant={'scrollable'} scrollButtons={'on'} value={false}>
				{tripsSelectedEntities && tripsSelectedEntities.length > 0 && (
					<HistoryTabHeaderWrapper
						entities={tripsSelectedEntities}
						tripSelectedIds={tripSelectedIds}
						tripGroupedIds={tripGroupedIds}
						firstSelectedTabId={firstSelectedTabId}
						tripDetailsParentId={tripDetailsParentId}
						removeTab={removeTab}
						selectCallback={selectCallback}
						checkBoxClickedCallback={checkBoxClickedCallback}
						validateCurrentTripData={validateCurrentTripData}
						editBatch={editBatch}
					/>
				)}
			</Tabs>
		</div>
	);
};

export default HistoryTabs;
