import './configurationGroupHistoryView.scss'
import React, { useState } from "react";
import {GridWidgetOverview} from "../../../GridOverview/GridWidgetOverview";
import {TranslateText} from "../../../../utils/Translations";
import {FormatDate} from "../../../../utils/DateUtils";
import { Column } from 'primereact/column';
import {WidgetViewDefault} from "../../Widget";
import ConfigurationGroupHistoryModal from "./ConfigurationGroupHistoryModal/ConfigurationGroupHistoryModal";
import ajaxUtil from "../../../../utils/Ajax";
import GlobalSettings from "../../../../GlobalSettings.json";
import {ConfigurationGroupSettingsEditDto} from "../ConfigurationGroupSettingsView/ConfigurationGroupSettingsView";
import {getCurrentDifferences} from "./Utils";

type Props = WidgetViewDefault & {
	data: ConfigurationGroupHistoryDto[];
};
interface ConfigurationGroupHistoryDto {
	id: string;
	previousConfigurationGroupVersionId: string;
	startDate: string;
	endDate?: string;
	version: number
}

interface ConfigurationGroupHistoryVersionData {
	version: ConfigurationGroupSettingsEditDto;
	previousVersion: ConfigurationGroupSettingsEditDto;
}

const ConfigurationGroupHistoryView = (props: Props) => {
	const [selectedRow, setSelectedRow] = useState<ConfigurationGroupHistoryDto>(null);
	const [dialogVisible, setDialogVisible] = useState<boolean>(false);
	const [dataModal, setDataModal] = useState<ConfigurationGroupHistoryVersionData>(null);
	const [differences, setDifferences] = useState<string[]>([]);

	const operations = {
		getData: (id: string) => {
			setDataModal(null);
			ajaxUtil
				.get<ConfigurationGroupHistoryVersionData>(`${GlobalSettings.configurationGroupsMaintenanceApi}/historyVersion/${id}`)
				.then((data) => {
					const array = getCurrentDifferences(data.previousVersion, data.version);
					if(array) {
						setDifferences(array);
						setDataModal(data);
						setDialogVisible(true);
					}
				});
		}
	}

	const events = {
		selectRowCallback: (rowData: ConfigurationGroupHistoryDto) => {
			if(rowData.id) {
				operations.getData(rowData.id);
			}
			setSelectedRow(rowData);
		},
		closeDialog: (value: boolean) => {
			setSelectedRow(null);
			setDialogVisible(value);
		}
	}

	return (
		<div>
			<GridWidgetOverview
					columns={[
						<Column
							key={'version'}
							field={'version'}
							className="widget-grid-column"
							header={TranslateText('widgets.grid.colVersion')}
							style={{ width: 120 }}
						/>,
						<Column
							key="startDate"
							field="startDate"
							className="widget-grid-column"
							header={TranslateText('widgets.grid.colStartDate')}
							style={{ width: 240 }}
							body={(rowData: any) =>
								FormatDate(new Date(rowData.startDate))
							}
						/>,
						<Column
							key="endDate"
							field="endDate"
							className="widget-grid-column"
							header={TranslateText('widgets.grid.colEndDate')}
							style={{ width: 240 }}
							body={(rowData: any) =>
								rowData.endDate ? (
									FormatDate(new Date(rowData.endDate))
								) : null
							}
						/>,
					]}
					data={props.data}
					total={props.data?.length ?? 0}
					paginator={true}
					selectedRowCallback={(rowData) => {
						events.selectRowCallback(rowData);
					}}
					onRowClick={(rowData) => {
						events.selectRowCallback(rowData);
					}}
				/>
			<ConfigurationGroupHistoryModal
				{...props}
				version={dataModal?.version}
				previousVersion={dataModal?.previousVersion}
				versionTitle={selectedRow?.version}
				previousVersionTitle={props.data.find(x => x.id === selectedRow?.previousConfigurationGroupVersionId)?.version}
				visible={dialogVisible}
				closeCallback={events.closeDialog}
				differences={differences}
			/>
		</div>
	);
}
export default ConfigurationGroupHistoryView;
