import './styles.scss';

import CustomerLevelEnum from 'models/CustomerLevelEnum';

import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import ClaimType, { ClaimValue } from '../../authorization/ClaimType';
import GlobalSettings from '../../GlobalSettings.json';
import EntityTypeEnum from '../../models/EntityTypeEnum';
import { ApplicationState } from '../../store';
import { TranslateText } from '../../utils/Translations';
import TextColumn from '../GridOverview/Columns/TextColumn';
import BooleanColumn from '../GridOverview/Columns/BooleanColumn';
import { ImpersonationDialog, PersonOverviewDto, PersonsOverviewGridProps } from './types';
import ImpersonationButton from '../PersonsOverviewImpersonation/ImpersonationButton';
import NewGridOverview from '../GridOverview/NewGridOverview';
import { fieldNames, initialFilters } from './constants';
import ImpersonationPopUp from '../PersonsImpersonationDialog/ImpersonationPopUp';
import { IconButton, Tooltip } from '@material-ui/core';
import { Send } from '@material-ui/icons';
import {ClaimUtil} from "../../authorization/ClaimUtil";
import ResendActivationMailPopUp from "../ResendActivationMailPopUp/ResendActivationMailPopUp";

export const PersonOverview = (props: PersonsOverviewGridProps) => {
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const customerLevel = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer ? s.globalCustomer.filteredCustomer.level : s.currentSession.customerLevel
	);
	const customerId = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer ? s.globalCustomer.filteredCustomer?.id : s.currentSession.customerId
	);
	const [dialogData, setDialogData] = useState<ImpersonationDialog>(null);
	const [openResendMailDialog, setOpenResendMalDialog] = useState<boolean>(false);
	const [dataForMailDialog, setDataForMailDialog] = useState(null);

	const columns = [
		TextColumn({
			fieldName: fieldNames.code,
			header: TranslateText('maintenanceOverview.grid.colCode'),
			sortable: true,
			filterable: true,
		}),
		TextColumn({
			fieldName: fieldNames.firstName,
			header: TranslateText('maintenanceOverview.grid.colFirstName'),
			sortable: true,
			filterable: true,
		}),
		TextColumn({
			fieldName: fieldNames.lastName,
			header: TranslateText('maintenanceOverview.grid.colLastName'),
			sortable: true,
			filterable: true,
		}),
		TextColumn({
			fieldName: fieldNames.username,
			header: TranslateText('maintenanceOverview.grid.colUserName'),
			sortable: true,
			filterable: true,
			body: (rowData: PersonOverviewDto) => {
				let userName = rowData.username;
				const data = rowData?.username?.split('.');
				if (data.length > 1) {
					userName = data[1];
				}
				return <span title={userName}>{userName}</span>;
			},
		}),
		BooleanColumn({
			fieldName: fieldNames.canResendActivationMail,
			header: TranslateText('maintenanceOverview.grid.colResendActivationMail'),
			sortable: true,
			filterable: true,
			body: (rowData: PersonOverviewDto) => (
				<span key={rowData.id + 'resendMailButton'} className={'resendMail-button'}>
					<Tooltip title={TranslateText('resendActivationMail.buttonTooltip')}>
						<span>
							<IconButton
								size="small"
								disabled={!rowData.canResendActivationMail}
								onClick={(event) => {
									event.preventDefault();
									setOpenResendMalDialog(true);
									setDataForMailDialog({
										email: rowData.email,
										userId: rowData.aspNetUserId
									});
								}}
							>
								<Send />
							</IconButton>
						</span>
					</Tooltip>
       			 </span>
			),
			shown: ClaimUtil.validateHasClaim(user, ClaimType.ResendActivationMail),
		}),
		BooleanColumn({
			fieldName: fieldNames.driver,
			header: TranslateText('maintenanceOverview.grid.colDriver'),
			sortable: true,
			filterable: true,
		}),
		BooleanColumn({
			fieldName: fieldNames.active,
			header: TranslateText('maintenanceOverview.grid.colActive'),
			sortable: true,
			filterable: true,
		}),
		BooleanColumn({
			fieldName: fieldNames.canImpersonate,
			header: TranslateText('maintenanceOverview.Impersonate'),
			sortable: true,
			filterable: true,
			body: (rowData: PersonOverviewDto) => (
				<ImpersonationButton setDialogData={setDialogData} rowData={rowData} />
			),
		}),
	];

	return (
		<>
			<NewGridOverview
				columns={columns}
				initialFilters={initialFilters}
				getGridDataUrl={GlobalSettings.personsMaintenanceApi}
				headerText={TranslateText('maintenanceOverview.persons')}
				disableAddButton={false}
				showAddConfigClaim={[
					{
						claim: ClaimType.Persons,
						values: [ClaimValue.edit],
					},
				]}
				entityType={EntityTypeEnum.Person}
				disableImportButton={customerLevel !== CustomerLevelEnum.Default}
				disableExportButton={customerLevel !== CustomerLevelEnum.Default}
			/>

			{dialogData ? <ImpersonationPopUp dialogData={dialogData} setDialogData={setDialogData} /> : null}
			{<ResendActivationMailPopUp
				open={openResendMailDialog}
				email={dataForMailDialog?.email}
				userId={dataForMailDialog?.userId}
				customerId={customerId}
				onClose={() => {
					setOpenResendMalDialog(false);
					setDataForMailDialog(null);
				}}
			/>
			}
		</>
	);
};
