import React from 'react';
import MaterialTextField from 'shared/components/MaterialTextField/MaterialTextField';
import { TranslateText } from 'utils/Translations';

import { CONFIGURATIONGROUPTHRESHOLDS } from '../constants';
import { ConfigurationGroupProps } from '../types';
import {handleKeyDown} from "../../../../Common/RemoveSpinnerOptionInput";
import {getNumberValue} from "../../../../Widgets/Views/ConfigurationGroupSettingsView/Utils";

const ConfigurationGroupThresholdsView = ({
	data,
	validationResult,
	handleChange,
	isAddNew = true,
	editMode = true,
	isForNewAddWizard = true,
}: ConfigurationGroupProps) => {
	const handleChangeChild = (value: unknown, statePropName: string) => {
		handleChange(value, statePropName, CONFIGURATIONGROUPTHRESHOLDS);
	};
	return (
		<>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="coastableDistance"
					className="resize-font noSpinner"
					label={TranslateText('fields.coastableDistance')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="coastableDistance"
					value={data.configurationGroupThresholds?.coastableDistance}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'coastableDistance')}
					validationResult={validationResult?.coastableDistanceConfigurationGroupThresholds}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="cruiseTime"
					className="resize-font noSpinner"
					label={TranslateText('fields.cruiseTime')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="cruiseTime"
					value={data.configurationGroupThresholds?.cruiseTime}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'cruiseTime')}
					validationResult={validationResult?.cruiseTimeConfigurationGroupThresholds}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="idleTime"
					className="resize-font noSpinner"
					label={TranslateText('fields.idleTime')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="idleTime"
					value={data.configurationGroupThresholds?.idleTime}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'idleTime')}
					validationResult={validationResult?.idleTimeConfigurationGroupThresholds}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="overRpm"
					className="resize-font noSpinner"
					label={TranslateText('fields.overRpmThreshold')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="overRpm"
					value={data.configurationGroupThresholds?.overRpm}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'overRpm')}
					validationResult={validationResult?.overRpmConfigurationGroupThresholds}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="overSpeed"
					className="resize-font noSpinner"
					label={TranslateText('fields.overSpeedThreshold')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="overSpeed"
					value={data.configurationGroupThresholds?.overSpeed}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'overSpeed')}
					validationResult={validationResult?.overSpeedConfigurationGroupThresholds}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="harshAccelerationSpeed"
					className="resize-font noSpinner"
					label={TranslateText('fields.harshAccelerationSpeed')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="harshAccelerationSpeed"
					value={data.configurationGroupThresholds?.harshAccelerationSpeed}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'harshAccelerationSpeed')}
					validationResult={validationResult?.harshAccelerationSpeedConfigurationGroupThresholds}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="stopSpeed"
					className="resize-font noSpinner"
					label={TranslateText('fields.stopSpeed')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="stopSpeed"
					value={data.configurationGroupThresholds?.stopSpeed}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'stopSpeed')}
					validationResult={validationResult?.stopSpeedConfigurationGroupThresholds}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="brakeSpeed"
					className="resize-font noSpinner"
					label={TranslateText('fields.brakeSpeed')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="brakeSpeed"
					value={data.configurationGroupThresholds?.brakeSpeed}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'brakeSpeed')}
					validationResult={validationResult?.brakeSpeedConfigurationGroupThresholds}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="fuelLevelDelay"
					className="resize-font noSpinner"
					label={TranslateText('fields.fuelLevelDelay')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="fuelLevelDelay"
					value={data.configurationGroupThresholds?.fuelLevelDelay}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'fuelLevelDelay')}
					validationResult={validationResult?.fuelLevelDelayConfigurationGroupThresholds}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
		</>
	);
};

export default ConfigurationGroupThresholdsView;
