import { max } from 'lodash';
import FuelTypeEnum from 'models/FuelTypeEnum';
import ObjectTypeEnum from 'models/ObjectTypeEnum';
import { number } from 'prop-types';
import { FieldRules } from 'shared/validation/interfaces';
import { ValidationRegex } from 'shared/validation/ValidationRegex';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { TranslateText } from 'utils/Translations';

import GlobalSettings from '../../../../GlobalSettings.json';
import ajaxUtil from '../../../../utils/Ajax';

const expandScientificNotation = (num: number | string): string => {
	const numStr = String(num);
	if (/\d+e[+-]?\d+/i.test(numStr)) {
		return Number(numStr).toLocaleString('fullwide', { useGrouping: false });
	}
	return numStr;
}
export const ConfigurationGroupGeneralInformationRules = (): FieldRules => {
	return {
		code: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(!!data.configurationGroupGeneralInformation?.code);
					},
				},
				unique: {
					message: TranslateText('fieldsValidations.uniqueValue'),
					validationFunction: (data: any) => {
						if (!data.configurationGroupGeneralInformation?.code.trim()) {
							return ValidatorFunctions.wrapInPromise(true);
						}
						return ajaxUtil.post<boolean>(`${GlobalSettings.validateApi}/ConfigurationGroupFields`, {
							customerId: data.customerId,
							value: data.configurationGroupGeneralInformation?.code,
							Field: 'code',
							editedEntityId: data.id ? data.id : null,
						});
					},
				},
			},
		},
		externalCode: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							!!data.configurationGroupGeneralInformation?.externalCode
						);
					},
				},
				unique: {
					message: TranslateText('fieldsValidations.uniqueValue'),
					validationFunction: (data: any) => {
						if (!data.configurationGroupGeneralInformation?.externalCode.trim()) {
							return ValidatorFunctions.wrapInPromise(true);
						}
						return ajaxUtil.post<boolean>(`${GlobalSettings.validateApi}/ConfigurationGroupFields`, {
							customerId: data.customerId,
							value: data.configurationGroupGeneralInformation?.externalCode,
							Field: 'externalCode',
							editedEntityId: data.id ? data.id : null,
						});
					},
				},
			},
		},
		name: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(!!data.configurationGroupGeneralInformation?.name);
					},
				},
			},
		},
		description: {
			rules: {},
		},
		objectType: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							data.configurationGroupGeneralInformation?.objectType in ObjectTypeEnum
						);
					},
				},
			},
		},
		fuelType: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							data.configurationGroupGeneralInformation?.fuelType in FuelTypeEnum
						);
					},
				},
			},
		},
		active: {
			rules: {
				required: ValidatorFunctions.required(),
			},
		},
	};
};

export const ConfigurationGroupWeightAndFactorRules = (): FieldRules => {
	return {
		coastConfigurationGroupWeights: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupWeights?.coast !== null);
					},
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyDigits().test(expandScientificNotation(data.configurationGroupWeights?.coast))
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + '0',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupWeights?.coast >= 0);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + '100',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupWeights?.coast <= 100);
					},
				},
			},
		},
		coastConfigurationGroupFactors: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupFactors?.coast !== null);
					},
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyDigits().test(expandScientificNotation(data.configurationGroupFactors?.coast))
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + '0',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupFactors?.coast >= 0);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + '100',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupFactors?.coast <= 100);
					},
				},
			},
		},
		cruiseConfigurationGroupWeights: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupWeights?.cruise !== null);
					},
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyDigits().test(expandScientificNotation(data.configurationGroupWeights?.cruise))
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + '0',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupWeights?.cruise >= 0);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + '100',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupWeights?.cruise <= 100);
					},
				},
			},
		},
		cruiseConfigurationGroupFactors: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupFactors?.cruise !== null);
					},
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyDigits().test(expandScientificNotation(data.configurationGroupFactors?.cruise))
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + '0',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupFactors?.cruise >= 0);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + '100',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupFactors?.cruise <= 100);
					},
				},
			},
		},
		drivingBehaviourConfigurationGroupWeights: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupWeights?.drivingBehaviour !== null);
					},
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyDigits().test(expandScientificNotation(data.configurationGroupWeights?.drivingBehaviour))
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + '0',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupWeights?.drivingBehaviour >= 0);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + '100',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							data.configurationGroupWeights?.drivingBehaviour <= 100
						);
					},
				},
			},
		},
		drivingBehaviourConfigurationGroupFactors: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupFactors?.drivingBehaviour !== null);
					},
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyDigits().test(expandScientificNotation(data.configurationGroupFactors?.drivingBehaviour))
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + '0',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupFactors?.drivingBehaviour >= 0);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + '100',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							data.configurationGroupFactors?.drivingBehaviour <= 100
						);
					},
				},
			},
		},
		harshAccelerationConfigurationGroupWeights: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupWeights?.harshAcceleration !== null);
					},
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyDigits().test(expandScientificNotation(data.configurationGroupWeights?.harshAcceleration))
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + '0',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupWeights?.harshAcceleration >= 0);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + '100',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							data.configurationGroupWeights?.harshAcceleration <= 100
						);
					},
				},
			},
		},
		harshAccelerationConfigurationGroupFactors: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupFactors?.harshAcceleration !== null);
					},
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyDigits().test(expandScientificNotation(data.configurationGroupFactors?.harshAcceleration))
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + '0',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupFactors?.harshAcceleration >= 0);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + '100',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							data.configurationGroupFactors?.harshAcceleration <= 100
						);
					},
				},
			},
		},
		harshBrakeConfigurationGroupWeights: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupWeights?.harshBrake !== null);
					},
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyDigits().test(expandScientificNotation(data.configurationGroupWeights?.harshBrake))
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + '0',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupWeights?.harshBrake >= 0);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + '100',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupWeights?.harshBrake <= 100);
					},
				},
			},
		},
		harshBrakeConfigurationGroupFactors: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupFactors?.harshBrake !== null);
					},
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyDigits().test(expandScientificNotation(data.configurationGroupFactors?.harshBrake))
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + '0',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupFactors?.harshBrake >= 0);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + '100',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupFactors?.harshBrake <= 100);
					},
				},
			},
		},
		harshPedalAccelerationConfigurationGroupWeights: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							data.configurationGroupWeights?.harshPedalAcceleration !== null
						);
					},
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyDigits().test(expandScientificNotation(data.configurationGroupWeights?.harshPedalAcceleration))
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + '0',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							data.configurationGroupWeights?.harshPedalAcceleration >= 0
						);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + '100',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							data.configurationGroupWeights?.harshPedalAcceleration <= 100
						);
					},
				},
			},
		},
		harshPedalAccelerationConfigurationGroupFactors: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							data.configurationGroupFactors?.harshPedalAcceleration !== null
						);
					},
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyDigits().test(expandScientificNotation(data.configurationGroupFactors?.harshPedalAcceleration))
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + '0',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							data.configurationGroupFactors?.harshPedalAcceleration >= 0
						);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + '100',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							data.configurationGroupFactors?.harshPedalAcceleration <= 100
						);
					},
				},
			},
		},
		idleConfigurationGroupWeights: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupWeights?.idle !== null);
					},
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyDigits().test(expandScientificNotation(data.configurationGroupWeights?.idle))
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + '0',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupWeights?.idle >= 0);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + '100',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupWeights?.idle <= 100);
					},
				},
			},
		},
		idleConfigurationGroupFactors: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupFactors?.idle !== null);
					},
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyDigits().test(expandScientificNotation(data.configurationGroupFactors?.idle))
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + '0',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupFactors?.idle >= 0);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + '100',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupFactors?.idle <= 100);
					},
				},
			},
		},
		overRpmConfigurationGroupWeights: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupWeights?.overRpm !== null);
					},
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyDigits().test(expandScientificNotation(data.configurationGroupWeights?.overRpm))
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + '0',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupWeights?.overRpm >= 0);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + '100',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupWeights?.overRpm <= 100);
					},
				},
			},
		},
		overRpmConfigurationGroupFactors: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupFactors?.overRpm !== null);
					},
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyDigits().test(expandScientificNotation(data.configurationGroupFactors?.overRpm))
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + '0',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupFactors?.overRpm >= 0);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + '100',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupFactors?.overRpm <= 100);
					},
				},
			},
		},
		overSpeedConfigurationGroupWeights: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupWeights?.overSpeed !== null);
					},
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyDigits().test(expandScientificNotation(data.configurationGroupWeights?.overSpeed))
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + '0',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupWeights?.overSpeed >= 0);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + '100',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupWeights?.overSpeed <= 100);
					},
				},
			},
		},
		overSpeedConfigurationGroupFactors: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupFactors?.overSpeed !== null);
					},
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyDigits().test(expandScientificNotation(data.configurationGroupFactors?.overSpeed))
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + '0',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupFactors?.overSpeed >= 0);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + '100',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupFactors?.overSpeed <= 100);
					},
				},
			},
		},
	};
};

export const ConfigurationGroupSettingAndThresholds = (): FieldRules => {
	return {
		harshAccelerationConfigurationGroupSettings: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupSettings?.harshAcceleration !== null);
					},
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyDigits().test(expandScientificNotation(data.configurationGroupSettings?.harshAcceleration))
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + '0',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							data.configurationGroupSettings?.harshAcceleration >= 0
						);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + '10',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							data.configurationGroupSettings?.harshAcceleration <= 10
						);
					},
				},
			},
		},
		harshBrakeConfigurationGroupSettings: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupSettings?.harshBrake !== null);
					},
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyDigits().test(expandScientificNotation(data.configurationGroupSettings?.harshBrake))
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + '0',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupSettings?.harshBrake >= 0);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + '10',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupSettings?.harshBrake <= 10);
					},
				},
			},
		},
		rpmConfigurationGroupSettings: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupSettings?.rpm !== null);
					},
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyDigits().test(expandScientificNotation(data.configurationGroupSettings?.rpm))
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + 0,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupSettings?.rpm >= 0);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + 25000,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupSettings?.rpm <= 25000);
					},
				},
			},
		},
		speedConfigurationGroupSettings: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupSettings?.speed !== null);
					},
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyDigits().test(expandScientificNotation(data.configurationGroupSettings?.speed))
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + 0,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupSettings?.speed >= 0);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + 300,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupSettings?.speed <= 300);
					},
				},
			},
		},
		torqueConfigurationGroupSettings: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupSettings?.torque !== null);
					},
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyDigits().test(expandScientificNotation(data.configurationGroupSettings?.torque))
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + 0,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupSettings?.torque >= 0);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + 100,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupSettings?.torque <= 100);
					},
				},
			},
		},
		pedalConfigurationGroupSettings: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupSettings?.pedal !== null);
					},
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyDigits().test(expandScientificNotation(data.configurationGroupSettings?.pedal))
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + 0,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupSettings?.pedal >= 0);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + 100,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupSettings?.pedal <= 100);
					},
				},
			},
		},
		accelerationConfigurationGroupSettings: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupSettings?.acceleration !== null);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + 0,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupSettings?.acceleration >= 0);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + 0.1,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupSettings?.acceleration <= 0.1);
					},
				},
			},
		},
		decelerationConfigurationGroupSettings: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupSettings?.deceleration !== null);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + 0,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupSettings?.deceleration >= 0);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + 0.1,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupSettings?.deceleration <= 0.1);
					},
				},
			},
		},
		coastableDistanceConfigurationGroupThresholds: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupThresholds?.coastableDistance !== null);
					},
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyDigits().test(expandScientificNotation(data.configurationGroupThresholds?.coastableDistance))
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + 0,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							data.configurationGroupThresholds?.coastableDistance >= 0
						);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + 100000,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							data.configurationGroupThresholds?.coastableDistance <= 100000
						);
					},
				},
			},
		},
		cruiseTimeConfigurationGroupThresholds: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupThresholds?.cruiseTime !== null);
					},
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyDigits().test(expandScientificNotation(data.configurationGroupThresholds?.cruiseTime))
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + 0,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupThresholds?.cruiseTime >= 0);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + 100000,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							data.configurationGroupThresholds?.cruiseTime <= 100000
						);
					},
				},
			},
		},
		idleTimeConfigurationGroupThresholds: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupThresholds?.idleTime !== null);
					},
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyDigits().test(expandScientificNotation(data.configurationGroupThresholds?.idleTime))
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + 0,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupThresholds?.idleTime >= 0);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + 100000,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupThresholds?.idleTime <= 100000);
					},
				},
			},
		},
		overRpmConfigurationGroupThresholds: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupThresholds?.overRpm !== null);
					},
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyDigits().test(expandScientificNotation(data.configurationGroupThresholds?.overRpm))
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + 0,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupThresholds?.overRpm >= 0);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + 100000,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupThresholds?.overRpm <= 100000);
					},
				},
			},
		},
		overSpeedConfigurationGroupThresholds: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupThresholds?.overSpeed !== null);
					},
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyDigits().test(expandScientificNotation(data.configurationGroupThresholds?.overSpeed))
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + 0,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupThresholds?.overSpeed >= 0);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + 1000,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupThresholds?.overSpeed <= 1000);
					},
				},
			},
		},
		harshAccelerationSpeedConfigurationGroupThresholds: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							data.configurationGroupThresholds?.harshAccelerationSpeed !== null
						);
					},
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyDigits().test(expandScientificNotation(data.configurationGroupThresholds?.harshAccelerationSpeed))
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + 0,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							data.configurationGroupThresholds?.harshAccelerationSpeed >= 0
						);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + 100000,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							data.configurationGroupThresholds?.harshAccelerationSpeed <= 100000
						);
					},
				},
			},
		},
		stopSpeedConfigurationGroupThresholds: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupThresholds?.stopSpeed !== null);
					},
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyDigits().test(expandScientificNotation(data.configurationGroupThresholds?.stopSpeed))
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + 0,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupThresholds?.stopSpeed >= 0);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + 100000,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupThresholds?.stopSpeed <= 100000);
					},
				},
			},
		},
		brakeSpeedConfigurationGroupThresholds: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupThresholds?.brakeSpeed !== null);
					},
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyDigits().test(expandScientificNotation(data.configurationGroupThresholds?.brakeSpeed))
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + 0,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupThresholds?.brakeSpeed >= 0);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + 100000,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							data.configurationGroupThresholds?.brakeSpeed <= 100000
						);
					},
				},
			},
		},
		fuelLevelDelayConfigurationGroupThresholds: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupThresholds?.fuelLevelDelay !== null);
					},
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyDigits().test(expandScientificNotation(data.configurationGroupThresholds?.fuelLevelDelay))
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + 0,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupThresholds?.fuelLevelDelay >= 0);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + 100000,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							data.configurationGroupThresholds?.fuelLevelDelay <= 100000
						);
					},
				},
			},
		},
		warningConfigurationGroupLevels: {
			rules: {
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + 0,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupLevels?.warningLevel >= 0);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + 10,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							data.configurationGroupLevels?.warningLevel <= 10
						);
					},
				},
			},
		},
		criticalConfigurationGroupLevels: {
			rules: {
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + 0,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupLevels?.criticalLevel >= 0);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + 10,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							data.configurationGroupLevels?.criticalLevel <= 10
						);
					},
				},
			},
		},
	};
};

export const ConfigurationGroupTripIncidents = (): FieldRules => {
	return {
		ignitionTimeoutConfigurationGroupTrips: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupTrips?.ignitionTimeout !== null);
					},
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyIntegerDigits().test(expandScientificNotation(data.configurationGroupTrips?.ignitionTimeout))
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + ' -1',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupTrips?.ignitionTimeout >= -1);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + 100000,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							data.configurationGroupTrips?.ignitionTimeout <= 100000
						);
					},
				},
			},
		},
		ignitionConfigurationGroupTrips: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.oneCheckboxRequired'),
					validationFunction: (data) =>
						ValidatorFunctions.wrapInPromise(
							(!!data.configurationGroupTrips?.ignition && !data.configurationGroupTrips?.engine) ||
								(!data.configurationGroupTrips?.ignition && !!data.configurationGroupTrips?.engine)
						),
				},
			},
		},
		engineConfigurationGroupTrips: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.oneCheckboxRequired'),
					validationFunction: (data) =>
						ValidatorFunctions.wrapInPromise(
							(!!data.configurationGroupTrips?.ignition && !data.configurationGroupTrips?.engine) ||
								(!data.configurationGroupTrips?.ignition && !!data.configurationGroupTrips?.engine)
						),
				},
			},
		},
		frequencyWhileMovingConfigurationGroupPeriodicals: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) =>
						ValidatorFunctions.wrapInPromise(data.configurationGroupPeriodicals?.frequencyWhileMoving !== null),
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyIntegerDigits().test(
								expandScientificNotation(
								data.configurationGroupPeriodicals?.frequencyWhileMoving)
							)
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + ' -1',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							data.configurationGroupPeriodicals?.frequencyWhileMoving >= -1
						);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + 3600000,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							data.configurationGroupPeriodicals?.frequencyWhileMoving <= 3600000
						);
					},
				},
			},
		},
		frequencyWhileStandingStillConfigurationGroupPeriodicals: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) =>
						ValidatorFunctions.wrapInPromise(
							data.configurationGroupPeriodicals?.frequencyWhileStandingStill !== null
						),
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyIntegerDigits().test(
								expandScientificNotation(
								data.configurationGroupPeriodicals?.frequencyWhileStandingStill)
							)
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + ' -1',
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							data.configurationGroupPeriodicals?.frequencyWhileStandingStill >= -1
						);
					},
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + 3600000,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							data.configurationGroupPeriodicals?.frequencyWhileStandingStill <= 3600000
						);
					},
				},
			},
		},
		recordTimeConfigurationGroupIncidents: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) =>
						ValidatorFunctions.wrapInPromise(data.configurationGroupIncidents?.recordTime !== null),
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyDigits().test(expandScientificNotation(data.configurationGroupIncidents?.recordTime))
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + 0,
					validationFunction: (data) =>
						ValidatorFunctions.wrapInPromise(data.configurationGroupIncidents?.recordTime >= 0),
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + 3600,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupIncidents?.recordTime <= 3600);
					},
				},
			},
		},
		minimumSpeedConfigurationGroupIncidents: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) =>
						ValidatorFunctions.wrapInPromise(data.configurationGroupIncidents?.minimumSpeed !== null),
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyDigits().test(expandScientificNotation(data.configurationGroupIncidents?.minimumSpeed))
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + 0,
					validationFunction: (data) =>
						ValidatorFunctions.wrapInPromise(data.configurationGroupIncidents?.minimumSpeed >= 0),
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + 3600000,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							data.configurationGroupIncidents?.minimumSpeed <= 3600000
						);
					},
				},
			},
		},
		accelerationConfigurationGroupIncidents: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) =>
						ValidatorFunctions.wrapInPromise(data.configurationGroupIncidents?.acceleration !== null),
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyDigits().test(expandScientificNotation(data.configurationGroupIncidents?.acceleration))
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + 0,
					validationFunction: (data) =>
						ValidatorFunctions.wrapInPromise(data.configurationGroupIncidents?.acceleration >= 0),
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + 9999,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupIncidents?.acceleration <= 9999);
					},
				},
			},
		},
		brakeConfigurationGroupIncidents: {
			rules: {
				required: {
					message: TranslateText('fieldsValidations.fieldRequired'),
					validationFunction: (data) =>
						ValidatorFunctions.wrapInPromise(data.configurationGroupIncidents?.brake !== null),
				},
				number: {
					message: TranslateText('fieldsValidations.onlyDigits'),
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(
							ValidationRegex.onlyIntegerDigits().test(expandScientificNotation(data.configurationGroupIncidents?.brake))
						);
					},
				},
				minNumber: {
					message: TranslateText('fieldsValidations.minValue') + '-9999',
					validationFunction: (data) =>
						ValidatorFunctions.wrapInPromise(data.configurationGroupIncidents?.brake >= -9999),
				},
				maxNumber: {
					message: TranslateText('fieldsValidations.maxValue') + 0,
					validationFunction: (data) => {
						return ValidatorFunctions.wrapInPromise(data.configurationGroupIncidents?.brake <= 0);
					},
				},
			},
		},
	};
};

export const ConfigurationGroupCameraMediaRules = (): FieldRules => {
	return {
		alarm: {
			rules: {},
		},
	};
};
