import EntityTypeEnum from 'models/EntityTypeEnum';
import TemplateData from 'models/TemplateData';
import * as React from 'react';
import {connect, ConnectedProps} from 'react-redux';

import {Button, Checkbox, FormControlLabel, Typography} from '@material-ui/core';
import Chip from '@material-ui/core/Chip/Chip';
import {RadioButtonComponent} from '@syncfusion/ej2-react-buttons';
import {TimePickerComponent} from '@syncfusion/ej2-react-calendars';

import EntityData from '../../../models/EntityData';
import ReportDaysEnum from '../../../models/ReportDaysEnum';
import ReportFrequencyEnum from '../../../models/ReportFrequencyEnum';
import ReportGenerateTypeEnum from '../../../models/ReportGenerateTypeEnum';
import ReportRenderFormatEnum from '../../../models/ReportRenderFormatEnum';
import DateTimeUtil from '../../../shared/datetime/DateTimeUtil';
import {ApplicationState} from '../../../store';
import {TranslateText} from '../../../utils/Translations';
import MaterialAutocomplete from '../../Common/Autocomplete/MaterialAutocomplete';
import TimezoneSelection from '../../Common/CommonDataSelections/TimezoneSelection';
import ReceiversSelectionArea from './ReceiversSelectionArea';

const mapState = (state: ApplicationState) => {
	return {
		access_token: state.oidc.user?.access_token,
		customerId: state.currentSession.customerId,
	};
};

const connector = connect(mapState, null);
type PropsFromRedux = ConnectedProps<typeof connector>;
type ReportGenerateProps = PropsFromRedux & {
	filledGenerateCallback: (
		formatType: ReportRenderFormatEnum,
		separateReports: boolean,
		generateType: ReportGenerateTypeEnum,
		scheduleFrequency: ReportFrequencyEnum,
		scheduleDay: ReportDaysEnum,
		scheduleTime: Date,
		scheduleTimezoneId: string,
		sendAsMail: boolean,
		receivers: EntityData[]
	) => void;
	unsavedDataCallback: (unsavedData: boolean, suspendUnsavedData: boolean) => void;
	setGenerateType: ReportGenerateTypeEnum;
	setFormatType: ReportRenderFormatEnum;
	setSeparateReports: boolean;
	setScheduleFrequency: ReportFrequencyEnum;
	setScheduleDay: ReportDaysEnum;
	setScheduleTime: Date;
	setScheduleTimezoneId: string;
	customerTimeZoneId: string;
	setSendAsMail: boolean;
	setReceivers: EntityData[];
	showPeriodInThePastMessage: boolean;
	isEditOfFavoriteReport: boolean;
	templateEntityType: EntityTypeEnum;
	isEditOfPlannedReport: boolean;
	selectedEntities: EntityData[];
	reportCategory: string;
	templateDataType: TemplateData;
	receiversList: string[];
	groupsList: string[];
	detailed: boolean;
};
type ReportGenerateState = {
	formatType: ReportRenderFormatEnum;
	generateSeparateReports: boolean;
	generateType: ReportGenerateTypeEnum;
	scheduleFrequency: ReportFrequencyEnum;
	scheduleDay: ReportDaysEnum;
	scheduleTime: Date;
	scheduleTimezoneId: string;
	sendAsMail: boolean;
	selectedEmailReceivers: EntityData[];
	showSelectionDialog: boolean;
};

class ReportGenerate extends React.PureComponent<ReportGenerateProps, ReportGenerateState> {
	private selectedReceivers: EntityData[] = [];
	customerTimezoneId: string = null;
	timePickerRef: TimePickerComponent;
	formatTypes: { id: ReportRenderFormatEnum; display: string }[] = [];
	frequencyValues = [
		{
			id: ReportFrequencyEnum.Daily,
			display: TranslateText('reports.frequencyValue.' + ReportFrequencyEnum.Daily),
		},
		{
			id: ReportFrequencyEnum.DailyRunning,
			display: TranslateText('reports.frequencyValue.' + ReportFrequencyEnum.DailyRunning),
		},
		{
			id: ReportFrequencyEnum.Weekly,
			display: TranslateText('reports.frequencyValue.' + ReportFrequencyEnum.Weekly),
		},
		{
			id: ReportFrequencyEnum.Monthly,
			display: `${TranslateText('reports.frequencyValue.' + ReportFrequencyEnum.Monthly)} (${TranslateText(
				'reports.firstDayMonth'
			)})`,
		},
		{
			id: ReportFrequencyEnum.Quarterly,
			display: `${TranslateText('reports.frequencyValue.' + ReportFrequencyEnum.Quarterly)} (${TranslateText(
				'reports.firstDayQuarter'
			)})`,
		},
	];

	scheduleDays = [
		{
			id: ReportDaysEnum.Monday,
			display: TranslateText('reports.scheduleDay.' + ReportDaysEnum.Monday),
		},
		{
			id: ReportDaysEnum.Sunday,
			display: TranslateText('reports.scheduleDay.' + ReportDaysEnum.Sunday),
		},
	];

	roundUp(intervalMilliseconds: number, datetime: Date) {
		datetime = datetime || new Date();
		const modTicks = datetime.getTime() % intervalMilliseconds;
		let delta = modTicks === 0 ? 0 : datetime.getTime() - modTicks;
		delta += intervalMilliseconds;
		const newDate = new Date(delta);
		return newDate;
	}

	constructor(props: ReportGenerateProps) {
		super(props);
		this.state = {
			formatType: this.props.setFormatType,
			generateSeparateReports:
				this.props.templateDataType === TemplateData.ObjectRawMessage &&
				(this.props.selectedEntities.length > 1 ||
					(this.props.selectedEntities.length === 1 &&
						this.props.selectedEntities[0].entityType === EntityTypeEnum.Group))
					? true
					: false,
			generateType: ReportGenerateTypeEnum.GenerateNow,
			scheduleFrequency: ReportFrequencyEnum.Monthly,
			scheduleDay: ReportDaysEnum.Sunday,
			scheduleTime: this.defaultScheduleTime,
			scheduleTimezoneId: this.customerTimezoneId,
			sendAsMail: false,
			selectedEmailReceivers: [],
			showSelectionDialog: false,
		};
		this.filterAvailableReportRenderFormats();
	}

	filterAvailableReportRenderFormats() {
		if (this.props.templateDataType === TemplateData.DurationSummary) {
			this.formatTypes = [
				{
					id: ReportRenderFormatEnum.pdf,
					display: TranslateText('reports.formatType.' + ReportRenderFormatEnum.pdf),
				},
			];
		}
		else {
			this.formatTypes = [
				{
					id: ReportRenderFormatEnum.pdf,
					display: TranslateText('reports.formatType.' + ReportRenderFormatEnum.pdf),
				},
				{
					id: ReportRenderFormatEnum.csv,
					display: TranslateText('reports.formatType.' + ReportRenderFormatEnum.csv),
				},
				{
					id: ReportRenderFormatEnum.xlsx,
					display: TranslateText('reports.formatType.' + ReportRenderFormatEnum.xlsx),
				},
			];
		}
	}
	defaultScheduleTime = new Date(2021, 1, 1, 0, 0, 0, 0);

	setPreviouslyFilledData() {
		this.selectedReceivers = this.props.setReceivers.slice();
		const currentScheduleTime = this.props.setScheduleTime;
		const newScheduleTime =
			this.props.setScheduleTime ??
			(this.props.setScheduleFrequency === ReportFrequencyEnum.Daily
				? this.roundUp(1800 * 1000, new Date(Date.now()))
				: this.defaultScheduleTime);

		this.setState(
			{
				generateSeparateReports: this.props.setSeparateReports,
				generateType: this.props.setGenerateType,
				scheduleFrequency: this.props.setScheduleFrequency,
				scheduleDay: this.props.setScheduleDay,
				scheduleTime:
					this.props.setScheduleTime ??
					(this.props.setScheduleFrequency === ReportFrequencyEnum.Daily
						? this.roundUp(1800 * 1000, new Date(Date.now()))
						: this.defaultScheduleTime),
				scheduleTimezoneId: this.props.setScheduleTimezoneId,
				sendAsMail: this.props.setSendAsMail,
				selectedEmailReceivers: this.props.setReceivers,
			},
			() => {
				if (newScheduleTime !== currentScheduleTime) {
					this.performStateChange(newScheduleTime, 'scheduleTime');
				}

				if (this.formatTypes.map((x) => x.id).indexOf(this.state.formatType) < 0) {
					this.setState(
						{
							formatType: this.formatTypes[0].id,
						},
						() => this.updateSetValuesInReport()
					);
				}
			}
		);
	}
	componentDidMount() {
		if (this.customerTimezoneId === null) {
			this.customerTimezoneId = this.props.setScheduleTimezoneId;
		}

		this.setPreviouslyFilledData();

		if (
			this.props.templateDataType === TemplateData.ObjectRawMessage &&
			(this.props.selectedEntities.length > 1 ||
				(this.props.selectedEntities.length === 1 &&
					this.props.selectedEntities[0].entityType === EntityTypeEnum.Group))
		) {
			this.setState(
				{
					generateSeparateReports: true,
				},
				() => this.updateSetValuesInReport()
			);
		} else if (
			this.props.selectedEntities.length === 1 &&
			this.props.selectedEntities[0].entityType !== EntityTypeEnum.Group
		) {
			this.setState(
				{
					generateSeparateReports: false,
				},
				() => this.updateSetValuesInReport()
			);
		}
	}

	updateSetValuesInReport() {
		this.props.filledGenerateCallback(
			this.state.formatType,
			this.state.generateSeparateReports,
			this.state.generateType,
			this.state.scheduleFrequency,
			this.state.scheduleDay,
			this.state.scheduleTime,
			this.state.scheduleTimezoneId,
			this.state.sendAsMail,
			this.selectedReceivers
		);
	}

	handleValueChange(value: any, statePropName: string) {
		if (
			value === null &&
			(statePropName === 'scheduleFrequency' ||
				statePropName === 'scheduleDay' ||
				statePropName === 'scheduleTime' ||
				statePropName === 'scheduleTimezoneId')
		) {
			//reset to default values for schedule fields
			switch (statePropName) {
				case 'scheduleFrequency': {
					this.performStateChange(ReportFrequencyEnum.Monthly, statePropName);
					break;
				}
				case 'scheduleDay': {
					this.performStateChange(ReportDaysEnum.Sunday, statePropName);
					break;
				}
				case 'scheduleTime': {
					this.performStateChange(
						this.props.setScheduleTime ??
							(this.state.scheduleFrequency === ReportFrequencyEnum.Daily
								? this.roundUp(1800 * 1000, new Date(Date.now()))
								: this.defaultScheduleTime),
						statePropName
					);
					this.timePickerRef.value =
						this.props.setScheduleTime ??
						(this.state.scheduleFrequency === ReportFrequencyEnum.Daily
							? this.roundUp(1800 * 1000, new Date(Date.now()))
							: this.defaultScheduleTime);
					break;
				}
				case 'scheduleTimezoneId': {
					this.performStateChange(this.customerTimezoneId, statePropName);
					break;
				}
			}
		} else {
			this.performStateChange(value, statePropName);
		}
	}

	handleCheckboxChange(arg: any, statePropName: string) {
		this.performStateChange(arg.target.checked, statePropName);
	}

	handleGenerateTypeChange(type: ReportGenerateTypeEnum) {
		this.performStateChange(type, 'generateType');
	}

	performStateChange(value: any, statePropName: string) {
		if (statePropName === 'scheduleFrequency') {
			this.performStateChange(
				value === ReportFrequencyEnum.Daily
					? this.roundUp(1800 * 1000, new Date(Date.now()))
					: this.defaultScheduleTime,
				'scheduleTime'
			);
			this.timePickerRef.value =
				value === ReportFrequencyEnum.Daily
					? this.roundUp(1800 * 1000, new Date(Date.now()))
					: this.defaultScheduleTime;
		}
		this.setState(
			{
				...this.state,
				[statePropName]: value,
			},
			() => this.updateSetValuesInReport()
		);
	}

	removeSelectedReceiver(id: string) {
		const index = this.selectedReceivers.findIndex((x) => x.id === id);
		if (index > -1) {
			this.selectedReceivers.splice(index, 1);
		}
		this.setState(
			{
				selectedEmailReceivers: [...this.selectedReceivers],
			},
			() => this.updateSetValuesInReport()
		);
	}

	setSelectedEntities(data: EntityData[]) {
		this.selectedReceivers = data;
		this.setState(
			{
				showSelectionDialog: false,
				selectedEmailReceivers: this.selectedReceivers,
			},
			() => this.updateSetValuesInReport()
		);
	}

	showSelectReceiversDialog(event: any) {
		event.preventDefault();
		this.props.unsavedDataCallback(true, true);
		this.setState({
			showSelectionDialog: true,
		});
	}

	closeSelectionDialog() {
		this.props.unsavedDataCallback(true, false);
		this.setState({
			showSelectionDialog: false,
			selectedEmailReceivers: this.selectedReceivers,
		});
	}

	render() {
		return (
			<div className="categories-container">
				<div className="e-text active-step-title">{'6.' + TranslateText('reports.downloadSchedule')}</div>
				<div className="e-text">{TranslateText('reports.generateDescription')}</div>

				<form id="generateForm" noValidate={true} style={{ marginTop: 10 }}>
					<div className="form-group">
						<div className="control-pane">
							<div className="control-section">
								<div className="ddl-control-section">
									<div>
										<div
											style={{
												width: 200,
												display: 'inline-block',
											}}
										>
											{TranslateText('reports.format')}
										</div>
										<div
											style={{
												width: 300,
												display: 'inline-block',
											}}
										>
											<MaterialAutocomplete
												valueId={this.state.formatType}
												dataSource={this.formatTypes}
												name="reportFormatType"
												disabled={false}
												onChange={({ value }) => this.handleValueChange(value, 'formatType')}
												disableClearable
												className="material-autocomplete report-selection-component"
											/>
										</div>
									</div>
									{(this.props.selectedEntities.length > 1 ||
										(this.props.selectedEntities.length === 1 &&
											this.props.selectedEntities[0].entityType === EntityTypeEnum.Group) ||
										(this.props.templateDataType === TemplateData.ObjectRawMessage &&
											this.props.selectedEntities.length > 1)) && (
										<div style={{ marginTop: 10 }}>
											<FormControlLabel
												control={
													<Checkbox
														color={'primary'}
														className="selection-checkbox"
														onClick={(e: any) => {
															e.stopPropagation();
														}}
														checked={
															this.state.generateSeparateReports ||
															this.props.templateDataType ===
																TemplateData.ObjectRawMessage
														}
														onChange={(args: any) => {
															this.handleCheckboxChange(args, 'generateSeparateReports');
														}}
														disabled={
															this.props.templateDataType ===
															TemplateData.ObjectRawMessage ||
															(this.props.templateDataType === TemplateData.MileageCO2 && !this.props.detailed)
														}
														size="small"
														style={{ marginLeft: 10 }}
													/>
												}
												label={
													<Typography style={{ fontSize: 12, marginLeft: 5 }}>
														{this.props.templateEntityType === EntityTypeEnum.Object
															? TranslateText('reports.separateReportsPerObject')
															: this.props.templateEntityType ===
															  EntityTypeEnum.PersonReport
															? TranslateText('reports.separateReportsPerPerson')
															: TranslateText('reports.separateReportsPerDriver')}
													</Typography>
												}
											/>
										</div>
									)}
									<div hidden={this.props.isEditOfFavoriteReport}>
										<div hidden={this.props.isEditOfPlannedReport} style={{ marginTop: 10 }}>
											<RadioButtonComponent
												immediateRender={true}
												label={TranslateText('reports.generateNow')}
												name="generateType"
												change={() => {
													this.handleGenerateTypeChange(ReportGenerateTypeEnum.GenerateNow);
													this.performStateChange(
														this.props.customerTimeZoneId,
														'scheduleTimezoneId'
													);
												}}
												checked={this.state.generateType === ReportGenerateTypeEnum.GenerateNow}
											/>
										</div>
										<div style={{ marginTop: 6 }}>
											<RadioButtonComponent
												immediateRender={true}
												label={TranslateText('reports.schedule')}
												name="generateType"
												change={() =>
													this.handleGenerateTypeChange(ReportGenerateTypeEnum.Schedule)
												}
												checked={this.state.generateType === ReportGenerateTypeEnum.Schedule}
											/>
										</div>
										{this.state.generateType === ReportGenerateTypeEnum.Schedule ? (
											<div style={{ marginLeft: 50 }}>
												<div>
													<div
														style={{
															width: 100,
															display: 'inline-block',
														}}
													>
														{TranslateText('reports.frequency')}
													</div>
													<div
														style={{
															width: 350,
															display: 'inline-block',
														}}
													>
														<MaterialAutocomplete
															valueId={this.state.scheduleFrequency}
															dataSource={this.frequencyValues}
															name="scheduleFrequency"
															disabled={false}
															onChange={({ value }) =>
																this.handleValueChange(value, 'scheduleFrequency')
															}
															className="material-autocomplete report-selection-component"
														/>
													</div>
												</div>
												<div>
													{this.state.scheduleFrequency === ReportFrequencyEnum.Weekly && (
														<>
															<div
																style={{
																	width: 100,
																	display: 'inline-block',
																}}
															>
																{TranslateText('reports.chooseDay')}
															</div>
															<div
																style={{
																	width: 190,
																	display: 'inline-block',
																}}
															>
																<MaterialAutocomplete
																	valueId={this.state.scheduleDay}
																	dataSource={this.scheduleDays}
																	name="scheduleDay"
																	disabled={
																		this.state.scheduleFrequency !==
																		ReportFrequencyEnum.Weekly
																	}
																	onChange={({ value }) =>
																		this.handleValueChange(value, 'scheduleDay')
																	}
																	className="material-autocomplete report-selection-component"
																/>
															</div>
														</>
													)}
													<div
														style={{
															width:
																this.state.scheduleFrequency ===
																ReportFrequencyEnum.Weekly
																	? 50
																	: 100,
															display: 'inline-block',
															marginLeft:
																this.state.scheduleFrequency ===
																ReportFrequencyEnum.Weekly
																	? 10
																	: 0,
														}}
													>
														{TranslateText('reports.chooseTime')}
													</div>
													<TimePickerComponent
														immediateRender={true}
														id="scheduleTime"
														width={100}
														format={DateTimeUtil.timeFormat()}
														showClearButton={false}
														value={this.state.scheduleTime}
														ref={(input) => {
															this.timePickerRef = input;
														}}
														change={(args) => {
															this.handleValueChange(args.value, 'scheduleTime');
															this.timePickerRef.value = this.state.scheduleTime;
														}}
													/>
												</div>
												<div>
													<div
														style={{
															width: 100,
															display: 'inline-block',
														}}
													>
														{TranslateText('reports.timezone')}
													</div>
													<div
														style={{
															width: 350,
															display: 'inline-block',
														}}
													>
														<TimezoneSelection
															name="scheduleTimezoneId"
															valueId={this.state.scheduleTimezoneId}
															disabled={false}
															onChange={({ value }) =>
																this.handleValueChange(value, 'scheduleTimezoneId')
															}
															className="material-autocomplete report-selection-component"
														/>
													</div>
												</div>
												{this.props.showPeriodInThePastMessage ? (
													<div className="validation-warning" id="periodInThePastWarning">
														{TranslateText('fieldsValidations.endDateInThePast')}
													</div>
												) : null}
												<div style={{ marginTop: 20 }}>
													<FormControlLabel
														control={
															<Checkbox
																color={'primary'}
																className="selection-checkbox"
																onClick={(e: any) => {
																	e.stopPropagation();
																}}
																checked={this.state.sendAsMail}
																onChange={(args: any) =>
																	this.handleCheckboxChange(args, 'sendAsMail')
																}
																size="small"
																style={{ marginLeft: 10 }}
															/>
														}
														label={
															<Typography style={{ fontSize: 12, marginLeft: 10 }}>
																{TranslateText('reports.sendAsMail')}
															</Typography>
														}
													/>
												</div>
												{this.state.sendAsMail ? (
													<div>
														<div
															style={{
																width: 200,
																marginTop: 10,
																fontWeight: 'bold',
																fontSize: 13,
															}}
														>
															{TranslateText('reports.receivers')}
														</div>
														{this.state.selectedEmailReceivers.map((e: EntityData) => (
															<Chip
																className={e.active ? '' : 'inactive-chip'}
																key={e.id}
																size="small"
																icon={
																	<span className={`entity-icon-${e.entityType}`} />
																}
																label={e.text}
																onDelete={() => {
																	this.removeSelectedReceiver(e.id);
																}}
																color="default"
															/>
														))}
														{(!this.props.receiversList.every((id) =>
															this.state.selectedEmailReceivers.some(
																(entity) => entity.id === id
															)
														) ||
															!this.props.groupsList.every((id) =>
																this.state.selectedEmailReceivers.some(
																	(entity) => entity.id === id
																)
															)) && (
															<Button
																style={{
																	textTransform: 'none',
																}}
																className="addButtonIcon primary-button"
																onClick={this.showSelectReceiversDialog.bind(this)}
															>
																{TranslateText('reports.addReceivers')}
															</Button>
														)}

														{this.state.selectedEmailReceivers.length > 0 ? null : (
															<div className="e-error">
																{TranslateText(
																	'fieldsValidations.noMailReceiversSelected'
																)}
															</div>
														)}

														<ReceiversSelectionArea
															showDialog={this.state.showSelectionDialog}
															selectedEntities={this.state.selectedEmailReceivers}
															saveCallback={this.setSelectedEntities.bind(this)}
															closeDialogCallback={this.closeSelectionDialog.bind(this)}
														/>
													</div>
												) : null}
											</div>
										) : null}
									</div>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		);
	}
}

export default connector(ReportGenerate);
