import '../configurationGroupView.scss'

import React from "react";
import { useEffect } from "react";

import {WidgetViewDefault} from "../../../Widget";
import {ConfigurationGroupSettingsEditViewProps} from "../types";
import {ConfigurationGroupSettingsEditDto} from "../ConfigurationGroupSettingsView";
import {getCurrentTabValidation, getNumberValue} from "../Utils";
import {
	CONFIGURATIONGROUPTHRESHOLDS
} from "../../../../NewAddWizard/Views/ConfigurationGroup/constants";
import {TranslateText} from "../../../../../utils/Translations";
import TextFieldTooltip from "../../../../../shared/components/TextFieldToolTip/TextFieldTooltip";
import {handleKeyDown} from "../../../../Common/RemoveSpinnerOptionInput";
import {verifyDifferences} from "../../ConfigurationGroupHistoryView/Utils";

type Props = WidgetViewDefault & ConfigurationGroupSettingsEditViewProps &{
	data: ConfigurationGroupSettingsEditDto;
};
const ThresholdsView = (props: Props) => {

	useEffect(() => {
		if(props.editMode) {
			if(!props.isForModal) {
				const resultValidations = getCurrentTabValidation(props.validationResult, CONFIGURATIONGROUPTHRESHOLDS);

				let newTabValidations = [...props.tabValidations];
				newTabValidations[3] = resultValidations;
				props.setTabValidation(newTabValidations);
			}
		}
	},[props.validationResult, props.editMode]);

	return (
		<div className="container-configuration-group-edit">
			<div className={!props.isSingleColumn ? 'container-row' : 'container-column'}
			>
				<div className={!props.isSingleColumn ? 'col-md-4' : 'col-12'}>
					<TextFieldTooltip
						id="coastableDistance"
						type="number"
						className="resize-font noSpinner"
						fullWidth
						label={TranslateText('fields.coastableDistance')}
						toolTipText={TranslateText('fields.coastableDistanceDetails')}
						fontSizeLabel={12}
						labelStyle={props.isForModal &&
						verifyDifferences(
							props.data,
							'coastableDistance',
							CONFIGURATIONGROUPTHRESHOLDS,
							props.differences) ? { color: props.colorLabel } : {}
						}
						disableTooltipButton={!props.editMode && !props.isForModal}
						inputProps={{
							readOnly: !props.editMode,
							style: { fontSize: 12 },
							onKeyPress: (event: any) => {
								if (event.key === '-' || event.key === 'e') {
									event.preventDefault();
								}
							}}}
						name="coastableDistance"
						value={props.data.configurationGroupThresholds?.coastableDistance}
						onChange={(e) => {
							props.handleChange(getNumberValue(e.target.value), 'coastableDistance', CONFIGURATIONGROUPTHRESHOLDS);
						}}
						disabled={!props.editMode}
						onKeyDown={handleKeyDown}
						onWheel={(e: any) => e.target.blur()}
						validationResult={props.validationResult?.coastableDistanceConfigurationGroupThresholds}
					/>
					<TextFieldTooltip
						id="cruiseTime"
						type="number"
						className="resize-font noSpinner"
						fullWidth
						label={TranslateText('fields.cruiseTime')}
						toolTipText={TranslateText('fields.cruiseTimeDetails')}
						fontSizeLabel={12}
						labelStyle={props.isForModal &&
						verifyDifferences(
							props.data,
							'cruiseTime',
							CONFIGURATIONGROUPTHRESHOLDS,
							props.differences) ? { color: props.colorLabel } : {}
						}
						disableTooltipButton={!props.editMode && !props.isForModal}
						inputProps={{
							readOnly: !props.editMode,
							style: { fontSize: 12 },
							onKeyPress: (event: any) => {
								if (event.key === '-' || event.key === 'e') {
									event.preventDefault();
								}
							}}}
						name="cruiseTime"
						value={props.data.configurationGroupThresholds?.cruiseTime}
						onChange={(e) => {
							props.handleChange(getNumberValue(e.target.value), 'cruiseTime', CONFIGURATIONGROUPTHRESHOLDS);
						}}
						disabled={!props.editMode}
						onKeyDown={handleKeyDown}
						onWheel={(e: any) => e.target.blur()}
						validationResult={props.validationResult?.cruiseTimeConfigurationGroupThresholds}
					/>
					<TextFieldTooltip
						id="idleTime"
						type="number"
						className="resize-font noSpinner"
						fullWidth
						label={TranslateText('fields.idleTime')}
						toolTipText={TranslateText('fields.idleTimeDetails')}
						fontSizeLabel={12}
						labelStyle={props.isForModal &&
						verifyDifferences(
							props.data,
							'idleTime',
							CONFIGURATIONGROUPTHRESHOLDS,
							props.differences) ? { color: props.colorLabel } : {}
						}
						disableTooltipButton={!props.editMode && !props.isForModal}
						inputProps={{
							readOnly: !props.editMode,
							style: { fontSize: 12 },
							onKeyPress: (event: any) => {
								if (event.key === '-' || event.key === 'e') {
									event.preventDefault();
								}
							}}}
						name="idleTime"
						value={props.data.configurationGroupThresholds?.idleTime}
						onChange={(e) => {
							props.handleChange(getNumberValue(e.target.value), 'idleTime', CONFIGURATIONGROUPTHRESHOLDS);
						}}
						disabled={!props.editMode}
						onKeyDown={handleKeyDown}
						onWheel={(e: any) => e.target.blur()}
						validationResult={props.validationResult?.idleTimeConfigurationGroupThresholds}
					/>
				</div>
				<div className={!props.isSingleColumn ? 'col-md-4' : 'col-12'}>
					<TextFieldTooltip
						id="overRpm"
						type="number"
						className="resize-font noSpinner"
						fullWidth
						label={TranslateText('fields.overRpm')}
						toolTipText={TranslateText('fields.overRpmThreshold')}
						fontSizeLabel={12}
						labelStyle={props.isForModal &&
						verifyDifferences(
							props.data,
							'overRpm',
							CONFIGURATIONGROUPTHRESHOLDS,
							props.differences) ? { color: props.colorLabel } : {}
						}
						disableTooltipButton={!props.editMode && !props.isForModal}
						inputProps={{
							readOnly: !props.editMode,
							style: { fontSize: 12 },
							onKeyPress: (event: any) => {
								if (event.key === '-' || event.key === 'e') {
									event.preventDefault();
								}
							}}}
						name="overRpm"
						value={props.data.configurationGroupThresholds?.overRpm}
						onChange={(e) => {
							props.handleChange(getNumberValue(e.target.value), 'overRpm', CONFIGURATIONGROUPTHRESHOLDS);
						}}
						disabled={!props.editMode}
						onKeyDown={handleKeyDown}
						onWheel={(e: any) => e.target.blur()}
						validationResult={props.validationResult?.overRpmConfigurationGroupThresholds}
					/>
					<TextFieldTooltip
						id="overSpeed"
						type="number"
						className="resize-font noSpinner"
						fullWidth
						label={TranslateText('fields.overSpeed')}
						toolTipText={TranslateText('fields.overSpeedThreshold')}
						labelStyle={props.isForModal &&
						verifyDifferences(
							props.data,
							'overSpeed',
							CONFIGURATIONGROUPTHRESHOLDS,
							props.differences) ? { color: props.colorLabel } : {}
						}
						disableTooltipButton={!props.editMode && !props.isForModal}
						inputProps={{
							readOnly: !props.editMode,
							style: { fontSize: 12 },
							onKeyPress: (event: any) => {
								if (event.key === '-' || event.key === 'e') {
									event.preventDefault();
								}
							}}}
						fontSizeLabel={12}
						name="overSpeed"
						value={props.data.configurationGroupThresholds?.overSpeed}
						onChange={(e) => {
							props.handleChange(getNumberValue(e.target.value), 'overSpeed', CONFIGURATIONGROUPTHRESHOLDS);
						}}
						disabled={!props.editMode}
						onKeyDown={handleKeyDown}
						onWheel={(e: any) => e.target.blur()}
						validationResult={props.validationResult?.overSpeedConfigurationGroupThresholds}
					/>
					<TextFieldTooltip
						id="harshAccelerationSpeed"
						type="number"
						className="resize-font noSpinner"
						fullWidth
						label={TranslateText('fields.harshAccelerationSpeed')}
						toolTipText={TranslateText('fields.harshAccelerationSpeedDetails')}
						fontSizeLabel={12}
						labelStyle={props.isForModal &&
						verifyDifferences(
							props.data,
							'harshAccelerationSpeed',
							CONFIGURATIONGROUPTHRESHOLDS,
							props.differences) ? { color: props.colorLabel } : {}
						}
						disableTooltipButton={!props.editMode && !props.isForModal}
						inputProps={{
							readOnly: !props.editMode,
							style: { fontSize: 12 },
							onKeyPress: (event: any) => {
								if (event.key === '-' || event.key === 'e') {
									event.preventDefault();
								}
							}}}
						name="harshAccelerationSpeed"
						value={props.data.configurationGroupThresholds?.harshAccelerationSpeed}
						onChange={(e) => {
							props.handleChange(getNumberValue(e.target.value), 'harshAccelerationSpeed', CONFIGURATIONGROUPTHRESHOLDS);
						}}
						disabled={!props.editMode}
						onKeyDown={handleKeyDown}
						onWheel={(e: any) => e.target.blur()}
						validationResult={props.validationResult?.harshAccelerationSpeedConfigurationGroupThresholds}
					/>
				</div>
				<div className={!props.isSingleColumn ? 'col-md-4' : 'col-12'}>
					<TextFieldTooltip
						id="stopSpeed"
						type="number"
						className="resize-font noSpinner"
						fullWidth
						label={TranslateText('fields.stopSpeed')}
						toolTipText={TranslateText('fields.stopSpeedDetails')}
						fontSizeLabel={12}
						labelStyle={props.isForModal &&
						verifyDifferences(
							props.data,
							'stopSpeed',
							CONFIGURATIONGROUPTHRESHOLDS,
							props.differences) ? { color: props.colorLabel } : {}
						}
						disableTooltipButton={!props.editMode && !props.isForModal}
						inputProps={{
							readOnly: !props.editMode,
							style: { fontSize: 12 },
							onKeyPress: (event: any) => {
								if (event.key === '-' || event.key === 'e') {
									event.preventDefault();
								}
							}}}
						name="stopSpeed"
						value={props.data.configurationGroupThresholds?.stopSpeed}
						onChange={(e) => {
							props.handleChange(getNumberValue(e.target.value), 'stopSpeed', CONFIGURATIONGROUPTHRESHOLDS);
						}}
						disabled={!props.editMode}
						onKeyDown={handleKeyDown}
						onWheel={(e: any) => e.target.blur()}
						validationResult={props.validationResult?.stopSpeedConfigurationGroupThresholds}
					/>
					<TextFieldTooltip
						id="brakeSpeed"
						type="number"
						className="resize-font noSpinner"
						fullWidth
						label={TranslateText('fields.brakeSpeed')}
						toolTipText={TranslateText('fields.brakeSpeedDetails')}
						fontSizeLabel={12}
						labelStyle={props.isForModal &&
						verifyDifferences(
							props.data,
							'brakeSpeed',
							CONFIGURATIONGROUPTHRESHOLDS,
							props.differences) ? { color: props.colorLabel } : {}
						}
						disableTooltipButton={!props.editMode && !props.isForModal}
						inputProps={{
							readOnly: !props.editMode,
							style: { fontSize: 12 },
							onKeyPress: (event: any) => {
								if (event.key === '-' || event.key === 'e') {
									event.preventDefault();
								}
							}}}
						name="brakeSpeed"
						value={props.data.configurationGroupThresholds?.brakeSpeed}
						onChange={(e) => {
							props.handleChange(getNumberValue(e.target.value), 'brakeSpeed', CONFIGURATIONGROUPTHRESHOLDS);
						}}
						disabled={!props.editMode}
						onKeyDown={handleKeyDown}
						onWheel={(e: any) => e.target.blur()}
						validationResult={props.validationResult?.brakeSpeedConfigurationGroupThresholds}
					/>
					<TextFieldTooltip
						id="fuelLevelDelay"
						type="number"
						className="resize-font noSpinner"
						fullWidth
						label={TranslateText('fields.fuelLevelDelay')}
						toolTipText={TranslateText('fields.fuelLevelDelayDetails')}
						fontSizeLabel={12}
						labelStyle={props.isForModal &&
						verifyDifferences(
							props.data,
							CONFIGURATIONGROUPTHRESHOLDS,
							'fuelLevelDelay',
							props.differences) ? { color: props.colorLabel } : {}
						}
						disableTooltipButton={!props.editMode && !props.isForModal}
						inputProps={{
							readOnly: !props.editMode,
							style: { fontSize: 12 },
							onKeyPress: (event: any) => {
								if (event.key === '-' || event.key === 'e') {
									event.preventDefault();
								}
							}}}
						name="fuelLevelDelay"
						value={props.data.configurationGroupThresholds?.fuelLevelDelay}
						onChange={(e) => {
							props.handleChange(getNumberValue(e.target.value), 'fuelLevelDelay', CONFIGURATIONGROUPTHRESHOLDS);
						}}
						disabled={!props.editMode}
						onKeyDown={handleKeyDown}
						onWheel={(e: any) => e.target.blur()}
						validationResult={props.validationResult?.fuelLevelDelayConfigurationGroupThresholds}
					/>
				</div>
			</div>
		</div>
	);
}

export default ThresholdsView;
