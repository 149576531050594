import './historyTripEditWizard.scss'

import React, {useEffect, useState} from "react";
import {NumberSize, Resizable} from 're-resizable';
import {Direction} from 're-resizable/lib/resizer';
import OptionsView from "./EditWizardView/Options/OptionsView";
import {ApplicationState} from "../../../store";
import {useDispatch, useSelector} from 'react-redux';
import {AutocompleteItem} from "../../Common/Autocomplete/MaterialAutocomplete";
import ajaxUtil from "../../../utils/Ajax";
import GlobalSettings from "../../../GlobalSettings.json";
import FilterFieldTypeEnum from "../../../models/FilterFieldTypeEnum";
import FilterOperatorTypeEnum from "../../../models/FilterOperatorTypeEnum";
import TripTypesEnum from "../../../models/TripTypesEnum";
import PrivacySettingsEnum from "../../../models/PrivacySettingsEnum";
import ClaimType from "../../../authorization/ClaimType";
import {TranslateText} from "../../../utils/Translations";
import SummaryView from "./EditWizardView/Summary/SummaryView";
import moment from 'moment';
import {historyStoreActionCreators} from "../../../store/HistoryStore";
import NotificationPrompt from "../../../shared/components/UserPrompt/NotificationPrompt";
import ConflictView, {
	TripBatchOverlapped,
	TripConflictDto,
	TripConflictResultDto,
	TripsForEditDto
} from './EditWizardView/Conflict/ConflictView'
import {Button, CircularProgress} from '@material-ui/core';
import EntityTypeEnum from "../../../models/EntityTypeEnum";
import {ValidationResult} from "../../../shared/validation/interfaces";
import Validator from "../../../shared/validation/Validator";
import {ValidatorFunctions} from "../../../shared/validation/ValidatorFunctions";

interface TripEditWizardProps {
	entityId: string;
	entityName: string;
	entityType: EntityTypeEnum;
	hasTripDevice: boolean;
	resizableHeight: number;
	setResizableHeightCallback: (value: number) => void;
}
export interface TripEditWizardDto {
	from: Date;
	to: Date;
	selectedDriverId: string;
	selectedDriverName: string;
	selectedTripType: TripTypesEnum;
	driverSelected: boolean;
	tripTypeSelected: boolean;
}
const HistoryTripEditWizard = (props: TripEditWizardProps) => {

	const dispatch = useDispatch();
	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const isDriver = useSelector((state: ApplicationState) => state.currentSession.isDriver);
	const { from, to } = useSelector(
		(state: ApplicationState) => state.historyFilter.dateFilter
	);
	const customerId = useSelector((state: ApplicationState) =>
		state.globalCustomer.filteredCustomer
			? state.globalCustomer.filteredCustomer.id
			: state.currentSession.customerId
	);
	const driverIdentification = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.driverIdentification
			: s.currentSession.customer.featuresSettings.driverIdentification
	);

	const trackTypeSpecification = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.trackTypeSpecification
			: s.currentSession.customer.featuresSettings.trackTypeSpecification
	);
	const customerTripTypes = useSelector((s: ApplicationState) =>
		s.globalCustomer?.filteredCustomer
			? s.globalCustomer.filteredCustomer.featuresSettings.tripTypes
			: s.currentSession.customer.featuresSettings.tripTypes
	);

	const customerPrivacySettings = useSelector(
		(s: ApplicationState) => s.currentSession.customer.featuresSettings.privacySettings
	);
	const currentCustomerTripTypes = useSelector(
		(s: ApplicationState) => s.currentSession.customer.featuresSettings.tripTypes
	);
	const tripUnsavedData = useSelector(
		(s: ApplicationState) => s.historyStore.tripUnsavedData
	);

	const [options, setOptions] = useState<TripEditWizardDto>({
		from: null,
		to: null,
		selectedTripType: null,
		selectedDriverId: null,
		selectedDriverName: null,
		tripTypeSelected: false,
		driverSelected: false
	});
	const [driversDataSource, setDriversDataSource] = useState<AutocompleteItem[]>([]);
	const [tripTypeDataSource, setTripTypeDataSource] = useState<AutocompleteItem[]>([]);
	const [defaultTripType, setDefaultTripType] = useState<TripTypesEnum>(TripTypesEnum.None);
	const [viewIndex, setViewIndex] = useState(1);
	const [isLoading, setIsLoading] = useState(false);
	const [notifyAboutUnsavedData, setNotifyAboutUnsavedData] = useState(false);
	const [hideDriver, setHideDriver] = useState(false);
	const [objectsInConflict, setObjectInConflict] = useState<TripConflictDto[]>([]);
	const [tripsForEdit, setTripsForEdit] = useState<TripsForEditDto[]>([]);
	const [overlaps, setOverlaps] = useState<TripBatchOverlapped[]>([]);
	const [disableConfirm, setDisableConfirm] = useState<boolean>(false);
	const [validationResult, setValidationResult] = useState<ValidationResult | null>(null);
	const [formResultValidation, setFormResultValidation] = useState<boolean>(true);
	const validator: Validator = new Validator({
		fieldRules: {
			selectedDriverId: {
				rules: {
					required: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data) => {
							const newOptions = data as TripEditWizardDto;
							return ValidatorFunctions.wrapInPromise(!newOptions?.driverSelected || newOptions?.selectedDriverId !== null);
						},
					},
				},
			},
			selectedTripType: {
				rules: {
					required: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data) => {
							const newOptions = data as TripEditWizardDto;
							return ValidatorFunctions.wrapInPromise(!newOptions?.tripTypeSelected || newOptions?.selectedTripType !== null);
						},
					},
				},
			},
		},
	});

	useEffect(() => {
		validator
			.validate(options)
			.then((result) => {
				setValidationResult(result.validationResult);
				setFormResultValidation(result.formResult);
			});
	}, [options]);

	useEffect(() => {
		setIsLoading(true);
		const newOptions = {
			from: from,
			to: to,
			selectedDriverId: null,
			selectedDriverName: null,
			selectedTripType: null,
			driverSelected: false,
			tripTypeSelected: false
		} as TripEditWizardDto;

		const filter = {
			fieldType: FilterFieldTypeEnum.customerId,
			operator: FilterOperatorTypeEnum.equal,
			fieldValue: customerId,
		};
		ajaxUtil
			.get<AutocompleteItem[]>(GlobalSettings.listsApi + '/Drivers?filter=' + JSON.stringify(filter))
			.then((data) => {
				let newData = [{id: 'none', display: TranslateText('historyBatchEdit.driverNone')}, ...data];
				setDriversDataSource(newData);
				if(newData.some((x) => x.id === props.entityId)) {
					setHideDriver(true);
				}
				setIsLoading(false);
			})
			.catch((error) => {
				setIsLoading(false);
				console.log(error);
			});
		setOptions(newOptions);

		return () => {
			dispatch(historyStoreActionCreators.setTripUnsavedData(false));
		}
	},[]);

	useEffect(() => {
		let tripType: TripTypesEnum = TripTypesEnum.Business | TripTypesEnum.Commuting | TripTypesEnum.Private;

		if (trackTypeSpecification) {
			tripType = customerTripTypes;
		}

		if (
			(customerPrivacySettings !== PrivacySettingsEnum.HidePrivateData ||
				user?.profile[ClaimType.ShowPrivateTrips] ||
				isDriver) &&
			currentCustomerTripTypes & TripTypesEnum.Private
		) {
			tripType |= TripTypesEnum.Private;
		} else {
			tripType &= ~TripTypesEnum.Private;
		}
		setDefaultTripType(tripType);
	}, [trackTypeSpecification, customerTripTypes, customerPrivacySettings, currentCustomerTripTypes]);

	useEffect(() => {
		const newDataSource: AutocompleteItem[] = [];
		if (defaultTripType & TripTypesEnum.Business) {
			newDataSource.push({
				display: TranslateText('historyOverview.business'),
				id: TripTypesEnum.Business,
			});
		}
		if (defaultTripType & TripTypesEnum.Private) {
			newDataSource.push({
				display: TranslateText('historyOverview.private'),
				id: TripTypesEnum.Private,
			});
		}
		if (defaultTripType & TripTypesEnum.Commuting) {
			newDataSource.push({
				display: TranslateText('historyOverview.commuting'),
				id: TripTypesEnum.Commuting,
			});
		}
		setTripTypeDataSource(newDataSource);
	}, [defaultTripType]);
	const handleValueChange = (
		value: string | boolean | Date | TripTypesEnum,
		statePropName: keyof TripEditWizardDto
	) => {
		const tempOption = { ...options }
		switch (statePropName)
		{
			case 'selectedDriverId':
				tempOption.selectedDriverId = value as string;
				tempOption.selectedDriverName = driversDataSource.find((x) => x.id === value)?.display;
				break;
			case 'selectedTripType':
				tempOption.selectedTripType = value as TripTypesEnum;
				break;
			case 'driverSelected':
				tempOption.driverSelected = value as boolean;
				if(!value) {
					tempOption.selectedDriverId = null;
					tempOption.selectedDriverName = null;
				}
				else
				{
					tempOption.selectedDriverId = 'none';
					tempOption.selectedDriverName = TranslateText('historyBatchEdit.driverNone');
				}
				break;
			case 'tripTypeSelected':
				tempOption.tripTypeSelected = value as boolean;
				if(!value)
					tempOption.selectedTripType = null;
				break;
			case 'from':
				tempOption.from = value as Date;
				if (tempOption.to && moment(tempOption.from).isAfter(tempOption.to)) {
					const difference = moment(tempOption.from).diff(moment(tempOption.to));
					tempOption.to = moment(tempOption.to).add(difference, 'milliseconds').endOf('day').toDate();
				}
				break;
			case 'to':
				tempOption.to = value as Date;
				if (tempOption.from && moment(tempOption.from).isAfter(tempOption.to)) {
					const difference = moment(tempOption.from).diff(moment(tempOption.to));
					tempOption.to = moment(tempOption.to).add(difference, 'milliseconds').endOf('day').toDate();
				}
				break;
		}
		setOptions(tempOption);
		dispatch(historyStoreActionCreators.setTripUnsavedData(true));
	};

	const cancelEdit = () => {
		if (tripUnsavedData) {
			setNotifyAboutUnsavedData(true);
		} else {
			dispatch(historyStoreActionCreators.setTripUnsavedData(false));
			dispatch(historyStoreActionCreators.clearTripEditBatch());
		}
	}
	const handleUnsavedDataResponse = (response: boolean) => {
		if (response) {
			dispatch(historyStoreActionCreators.setTripUnsavedData(false));
			setNotifyAboutUnsavedData(false);
			dispatch(historyStoreActionCreators.clearTripEditBatch());
		}
		setNotifyAboutUnsavedData(false);
	};

	const getTripsInConflict = () => {
		setIsLoading(true);
		ajaxUtil.post<TripConflictResultDto>(
			`${GlobalSettings.tripsApi}/getTripsForConflict`,
			{
				entityId: props.entityId,
				isForObject: props.entityType === EntityTypeEnum.Object,
				driverId: options.selectedDriverId === 'none' ? null : options.selectedDriverId,
				from: moment(options.from).format('YYYY-MM-DDTHH:mm:ss'),
				to: moment(options.to).format('YYYY-MM-DDTHH:mm:ss'),
			}
		).then((data) => {
			setObjectInConflict(data.objects);
			setOverlaps(data.overlaps);
			setTripsForEdit(data.trips);
			if(data.objects?.length === 0 && data.overlaps?.length === 0)
				createEdit();
			if(data.overlaps?.length === 0)
				setDisableConfirm(false);
			setIsLoading(false);
		});
	}
	const createEdit = () => {
		setIsLoading(true);
		ajaxUtil.post<TripConflictResultDto>(
			`${GlobalSettings.tripsApi}/updateBatchTrips`,
			{
				entityId: props.entityId,
				isForObject: props.entityType === EntityTypeEnum.Object,
				driverId: options.selectedDriverId === 'none' ? null : options.selectedDriverId,
				tripType: options.selectedTripType,
				isDriverSelected: options.driverSelected,
				isTripTypeSelected: options.tripTypeSelected,
				from: moment(options.from).format('YYYY-MM-DDTHH:mm:ss'),
				to: moment(options.to).format('YYYY-MM-DDTHH:mm:ss'),
				tripsNotIncluded: tripsForEdit.filter((x) => !x.checked).map((x) => x.tripId)
			}
		).then((data) => {
			setIsLoading(false);
			dispatch(historyStoreActionCreators.setHistoryReloadForced(true));
			dispatch(historyStoreActionCreators.setTripUnsavedData(false));
			dispatch(historyStoreActionCreators.clearTripEditBatch());
		}).catch((er) => {
			console.log(er);
			setIsLoading(false);
		});
	}
	const closeForced = () => {
		dispatch(historyStoreActionCreators.setTripUnsavedData(false));
		dispatch(historyStoreActionCreators.clearTripEditBatch());
	}

	return (
		<Resizable
			size={{
				width: '100%',
				height: props.resizableHeight,
			}}
			enable={{
				top: false,
				right: false,
				bottom: true,
				left: false,
				topRight: false,
				bottomRight: false,
				bottomLeft: false,
				topLeft: false,
			}}
			minHeight={200}
			maxHeight={600}
			className={'wizard-resizable'}
			onResizeStop={(event: Event, direction: Direction, ref: HTMLElement, dimension: NumberSize) => {
				props.setResizableHeightCallback(props.resizableHeight + dimension.height);
			}}
			style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
		>
			<div className={'resizable-bar'}></div>
			<div
				className={'history-trip-wizard'}
			>
				{props.hasTripDevice ?
				<>
					<div className={'trip-wizard-title'}>
						<span style={{ paddingRight: 5 }}>{TranslateText('historyBatchEdit.editTitle')}</span>
						<span>{props?.entityName}</span>
					</div>

					<div
						className={'trip-wizard-view'}
					>
						{viewIndex === 1 ? (
							<OptionsView
								data={options}
								driversDataSource={driversDataSource}
								tripTypeDataSource={tripTypeDataSource}
								setIndexCallback={setViewIndex}
								handleValueChangeCallback={handleValueChange}
								isLoading={isLoading}
								hideDriver={hideDriver}
								cancelCallback={cancelEdit}
								validationResult={validationResult}
								driverIdentification={driverIdentification}
								trackTypeSpecification={trackTypeSpecification}
							/>
						) : viewIndex === 2 ? (
							<SummaryView
								data={options}
								setIndexCallback={setViewIndex}
								hideDriver={hideDriver}
								cancelCallback={cancelEdit}
								driverIdentification={driverIdentification}
								trackTypeSpecification={trackTypeSpecification}
							/>
						) : viewIndex === 3 && (objectsInConflict?.length > 0 || overlaps?.length > 0 )? (
							<ConflictView
								data={options}
								tripsForEdit={tripsForEdit}
								setTripsForEditCallback={(value) => setTripsForEdit(value)}
								objectsInConflict={objectsInConflict}
								overlaps={overlaps}
								setIndexCallback={setViewIndex}
								selectedEntityId={props.entityId}
								setIsLoadingCallback={(value: boolean) => setIsLoading(value)}
								isLoading={isLoading}
								cancelCallback={cancelEdit}
								setDisableConfirmCallback={(value: boolean) => setDisableConfirm(value)}
							/>
						) : <CircularProgress className="loading-component" />}
					</div>
				</> :
					<div className={'trip-wizard-view'}>
						<div className={'trip-wizard-title'}>
							<span style={{ paddingRight: 5 }}>{TranslateText('historyBatchEdit.editsAreNotPossible')} <strong>{props.entityName}</strong></span>
						</div>
					</div>}

				<div className="wizard-buttons-container">
					{props.hasTripDevice ?
					<div className={'row-buttons'}>
						<Button
							className={'cancel-button wizard-button'}
							onClick={cancelEdit}
						>
							{TranslateText('historyBatchEdit.cancel')}
						</Button>
						<div>
							<Button
								hidden={viewIndex === 1}
								disabled={isLoading ||
									(viewIndex === 3 && objectsInConflict?.length === 0 && overlaps?.length === 0)}
								className={'back-button wizard-button'}
								onClick={() => {
									setObjectInConflict([]);
									setOverlaps([]);
									setViewIndex((prev) => {
										return prev === 3 ? prev - 2 : prev - 1;
									});
								}}
							>
								{TranslateText('historyBatchEdit.back')}
							</Button>
							<Button
								disabled={!(options?.driverSelected || options?.tripTypeSelected) ||
									isLoading ||
									(disableConfirm && viewIndex === 3) ||
									!formResultValidation ||
									(viewIndex === 3 && objectsInConflict?.length === 0 && overlaps?.length === 0)}
								className={'confirm-button wizard-button'}
								onClick={() => {
									if(viewIndex === 2)
										getTripsInConflict();
									else
										if(viewIndex === 3)
											createEdit();
									setViewIndex((prev) => prev + 1)
								}}
							>
								{viewIndex == 1 ? TranslateText('historyBatchEdit.ok') : TranslateText('historyBatchEdit.confirm')}
							</Button>
						</div>
					</div> :
						<div className={'row-buttons'}>
							<Button
							disabled={false}
							className={'confirm-button wizard-button'}
							onClick={() => closeForced()}
							style={{marginLeft: 334}}
						>
							{TranslateText('historyBatchEdit.ok')}
						</Button>
						</div>
					}
				</div>
			</div>

			<NotificationPrompt
				title={TranslateText('common.titleUnsavedData')}
				message={TranslateText('notificationMessages.cancel')}
				handleUserResponse={handleUnsavedDataResponse}
				displayDialog={notifyAboutUnsavedData}
			/>
		</Resizable>
	);
}
export default HistoryTripEditWizard;
