import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import ClaimType, { ClaimValue } from 'authorization/ClaimType';
import { ClaimUtil } from 'authorization/ClaimUtil';
import ConnectionsConflictResolver from 'components/Common/ConnectionsConflictResolver';
import ConfigurationGroupInformationView from 'components/Widgets/ConfigurationGroupWidget/ConfigurationGroupInformationView';
import WidgetDashboard, { WidgetDashboardTransferableProps } from 'components/Widgets/Dashboard/WidgetDashboard';
import Widget from 'components/Widgets/Widget';
import EntityTypeEnum from 'models/EntityTypeEnum';
import { TranslateText } from 'utils/Translations';
import GlobalSettings from '../../GlobalSettings.json';
import ConfigurationGroupObjectWidget from "../Widgets/ConfigurationGroupObjectWidget";
import {ApplicationState} from "../../store";
import ProtectedContainer from "../Layout/SideBar/ProtectedContainer";
import ConfigurationGroupSettingsView from "../Widgets/Views/ConfigurationGroupSettingsView";
import ButtonWidget from "../Widgets/ButtonWidget";
import ConfigurationGroupHistoryView
	from "../Widgets/Views/ConfigurationGroupHistoryView/ConfigurationGroupHistoryView";

type MatchProps = {
	match: { params: { [key: string]: string } };
};

type Props = WidgetDashboardTransferableProps & MatchProps & {};
const ConfigurationGroupDetails = (props: Props) => {
	const user = useSelector((state: ApplicationState) => state.oidc.user);

	const [reloadInfo, setReloadInfo] = useState(false);
	const [reloadHistory, setReloadHistory] = useState(false);
	const [currentTitle, setCurrentTitle] = useState<string>('');

	const events = {
		handleConflictingConnections: (sender: ConnectionsConflictResolver, confirm: boolean) => {
			sender.handleConflictingConnections(confirm);
		},
		handleDataSaved: () => {
			setReloadHistory(true);
		},
		handleDisplayedCallback: () => {
			setReloadInfo(false);
			setReloadHistory(false);
		},
	};

	return (
		<>
			<WidgetDashboard
				goBackText={TranslateText(`detailsScreen.navigationBar.backToConfigurationGroups`)}
				history={props.history}
				currentRecordId={props.match.params.id}
				goBackToCallback={() => {
					props.history.push(GlobalSettings.route.configurationGroups, { id: props.match.params.id });
				}}
				urlPath={GlobalSettings.route.configurationGroups}
				breadcrumbText={currentTitle ? `${TranslateText('common.configurationGroup')} > ${currentTitle}` : ''}
				filterText={props.filterText}
				entityType={EntityTypeEnum.ConfigurationGroup}
			>
				<Widget
					position={{
						row: 0,
						col: 0,
						sizeX: 5,
						sizeY: 6,
					}}
					url={`${GlobalSettings.configurationGroupsMaintenanceApi}`}
					widgetTitle={TranslateText('common.information')}
					viewComponent={ConfigurationGroupInformationView}
					setDashboardTitleCallback={setCurrentTitle}
					displayedCallback={events.handleDisplayedCallback}
					forceReload={reloadInfo}
					showEditButton={ClaimUtil.validateClaim(user, {
						claim: ClaimType.ConfigurationGroup,
						values: [ClaimValue.edit],
					})}
				/>
				<ProtectedContainer
					claimConfig={[
						{ claim: ClaimType.Objects, values: [ClaimValue.view] },
						{ claim: ClaimType.ConfigurationGroup, values: [ClaimValue.view] },
					]}
				>
					<ConfigurationGroupObjectWidget
						position={{
							row: 0,
							col: 5,
							sizeX: 13,
							sizeY: 3,
						}}
						allowEditMode={true}
						entityId={props.match.params.id}
						widgetTitle={TranslateText('common.objects')}
						showEditButton={ClaimUtil.validateClaimList(user, [
							{ claim: ClaimType.Objects, values: [ClaimValue.edit] },
							{ claim: ClaimType.ConfigurationGroup, values: [ClaimValue.edit] },
						])}
						 exitEditModeOnUrlChange
					/>
				</ProtectedContainer>
				<Widget
					position={{
						row: 4,
						col: 5,
						sizeX: 13,
						sizeY: 3,
					}}
					allowEditMode={false}
					entityId={props.match.params.id}
					widgetTitle={TranslateText('common.configurationGroupHistory')}
					url={`${GlobalSettings.configurationGroupsMaintenanceApi}/history`}
					forceReload={reloadHistory}
					displayedCallback={events.handleDisplayedCallback}
					viewComponent={ConfigurationGroupHistoryView}
					showEditButton={false}
				/>
				<Widget
					position={{
						row: 6,
						col: 0,
						sizeX: 18,
						sizeY: 5,
					}}
					url={`${GlobalSettings.configurationGroupsMaintenanceApi}/settings`}
					widgetTitle={TranslateText('common.configurationGroupSettings')}
					viewComponent={ConfigurationGroupSettingsView}
					setDashboardTitleCallback={setCurrentTitle}
					displayedCallback={events.handleDisplayedCallback}
					forceReload={reloadInfo}
					hideScrollBar={true}
					savedCallback={events.handleDataSaved}
					showEditButton={ClaimUtil.validateClaim(user, {
						claim: ClaimType.ConfigurationGroup,
						values: [ClaimValue.edit],
					})}
				/>
			</WidgetDashboard>
		</>
	);
};
export default ConfigurationGroupDetails;
