import {
	ConfigurationGroupCamera,
	ConfigurationGroupFactors,
	ConfigurationGroupGeneralCamera,
	ConfigurationGroupGeneralInformation,
	ConfigurationGroupIncidents,
	ConfigurationGroupLevels,
	ConfigurationGroupMedia,
	ConfigurationGroupPeriodicals,
	ConfigurationGroupSettings,
	ConfigurationGroupThresholds,
	ConfigurationGroupTrips,
	ConfigurationGroupWeights,
} from 'models/ConfigurationGroupDto';

export const CONFIGURATIONGROUPGENERALINFORMATION = 'ConfigurationGroupGeneralInformation';
export const CONFIGURATIONGROUPWEIGHTS = 'ConfigurationGroupWeights';
export const CONFIGURATIONGROUPFACTORS = 'ConfigurationGroupFactors';
export const CONFIGURATIONGROUPSETTINGS = 'ConfigurationGroupSettings';
export const CONFIGURATIONGROUPTHRESHOLDS = 'ConfigurationGroupThresholds';
export const CONFIGURATIONGROUPINCIDENTS = 'ConfigurationGroupIncidents';
export const CONFIGURATIONGROUPPERIODICALS = 'ConfigurationGroupPeriodicals';
export const CONFIGURATIONGROUPTRIPS = 'ConfigurationGroupTrips';
export const CONFIGURATIONGROUPMEDIA = 'ConfigurationGroupMedia';
export const CONFIGURATIONGROUPGENERALCAMERA = 'ConfigurationGroupGeneralCamera';
export const CONFIGURATIONGROUPLEVELS = 'ConfigurationGroupLevels';
