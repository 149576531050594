import './objectView.scss';

import ClaimType, { ClaimValue } from 'authorization/ClaimType';
import { ClaimUtil } from 'authorization/ClaimUtil';
import { ColorPicker } from 'components/ColorPicker';
import MaterialAutocomplete from 'components/Common/Autocomplete/MaterialAutocomplete';
import TimezoneSelection from 'components/Common/CommonDataSelections/TimezoneSelection';
import { IconPicker } from 'components/IconPicker';
import { useTryGetSvgIconFromQueryCache } from 'components/IconPicker/SvgIcon/hooks';
import { AddWizardComponentProps } from 'components/NewAddWizard/NewAddWizard';
import { NL_COUNTRY_ID } from 'Constants';
import GlobalSettings from 'GlobalSettings.json';
import { RDWVehicleDataDto } from 'models/RDWVehicleDataDto';
import TrackableObject from 'models/TrackableObject';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomerNameDisplay from 'shared/components/CustomerNameDisplay';
import MaterialTextField from 'shared/components/MaterialTextField/MaterialTextField';
import FuelTypeDataSource from 'shared/staticDataSources/FuelTypeDataSource';
import { ValidationResult } from 'shared/validation/interfaces';
import Validator from 'shared/validation/Validator';
import { ValidatorFunctions } from 'shared/validation/ValidatorFunctions';
import { ApplicationState, IAvailableCustomer } from 'store';
import ajaxUtil from 'utils/Ajax';
import { GetSelectedCustomer } from 'utils/CustomerUtils';
import { TranslateText } from 'utils/Translations';

import { Checkbox, CircularProgress, FormControlLabel, InputLabel, Typography } from '@material-ui/core';

import CO2CalculationTypeEnum from '../../../../models/CO2CalculationTypeEnum';
import ObjectFunctionEnum from '../../../../models/ObjectFunctionEnum';
import ObjectTypeEnum from '../../../../models/ObjectTypeEnum';
import CO2CalculationTypeDataSource from '../../../../shared/staticDataSources/CO2CalculationTypeDataSource';
import ObjectAssetTypeDatasource from '../../../../shared/staticDataSources/ObjectAssetTypeDatasource';
import ObjectFunctionDataSource from '../../../../shared/staticDataSources/ObjectFunctionDataSource';
import ObjectTypeDataSource from '../../../../shared/staticDataSources/ObjectTypeDataSource';
import { handleKeyDown } from '../../../Common/RemoveSpinnerOptionInput';
import { ImagePicker } from '../../../ImagePicker';

const ObjectView = (props: AddWizardComponentProps) => {
	const entityDefaultsSettings = useSelector((s: ApplicationState) =>
		s.globalCustomer.filteredCustomer
			? s.globalCustomer.filteredCustomer.entityDefaultsSettings
			: s.currentSession.customer.entityDefaultsSettings
	);
	const defaultCustomerId = useSelector((s: ApplicationState) =>
		s.globalCustomer.filteredCustomer ? s.globalCustomer.filteredCustomer.id : s.currentSession.customerId
	);
	const defaultCustomerTimezone = useSelector((s: ApplicationState) =>
		s.globalCustomer.filteredCustomer
			? s.globalCustomer.filteredCustomer.timeZone
			: s.currentSession.customer.timezoneId
	);

	const entityCustomer = useSelector((s: ApplicationState) => s.loadedEntityContext?.entityContextData?.customerId);

	const user = useSelector((s: ApplicationState) => s.oidc.user);
	const availableCustomers = useSelector((s: ApplicationState) => s.availableCustomers.data);

	const [object, setObject] = useState<TrackableObject>(
		new TrackableObject(defaultCustomerId, defaultCustomerTimezone)
	);

	const [isExternalCodeFirstTouched, setIsExternalCodeFirstTouched] = useState<boolean>(false);
	const [unsavedData, setUnsavedData] = useState<boolean>(false);
	const [validationResult, setValidationResult] = useState<ValidationResult | null>(null);
	const [rdwDataFetching, setrdwDataFetching] = useState<boolean>(false);

	const { icon } = useTryGetSvgIconFromQueryCache(object.icon, 'objects/car');
	const isAsset = object?.objectFunction === ObjectFunctionEnum.Asset;
	const assetTracking = useSelector((state: ApplicationState) =>
		state.globalCustomer?.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.assetTracking
			: state.currentSession.customer?.featuresSettings.assetTracking
	);

	const GetAvailableCustomerInfo = (customerId: string): IAvailableCustomer => {
		return availableCustomers.find((x) => x.value === customerId);
	};

	const [customerCountryId, setCustomerCountryId] = useState<number>(
		GetAvailableCustomerInfo(entityCustomer ?? GetSelectedCustomer().id)?.startCountryId
	);

	const validator: Validator = new Validator({
		fieldRules: {
			code: {
				rules: {
					required: ValidatorFunctions.required(),
					maxLength: ValidatorFunctions.maxLength(24),
					noEmptySpace: ValidatorFunctions.noEmptySpace(),
					unique: {
						message: TranslateText('fieldsValidations.uniqueValue'),
						validationFunction: (data, fieldName) => {
							return ajaxUtil.post<boolean>(`${GlobalSettings.validateApi}/ObjectFields`, {
								customerId: object.customerId,
								value: data[fieldName],
								Field: fieldName,
								editedEntityId: !!object.id ? object.id : null,
							});
						},
					},
				},
			},
			externalCode: {
				rules: {
					required: ValidatorFunctions.required(),
					maxLength: ValidatorFunctions.maxLength(24),
					noEmptySpace: ValidatorFunctions.noEmptySpace(),
					unique: {
						message: TranslateText('fieldsValidations.uniqueValue'),
						validationFunction: (data, fieldName) => {
							return ajaxUtil.post<boolean>(`${GlobalSettings.validateApi}/ObjectFields`, {
								customerId: object.customerId,
								value: data[fieldName],
								Field: fieldName,
								editedEntityId: !!object.id ? object.id : null,
							});
						},
					},
				},
			},
			timezoneId: {
				rules: {
					required: ValidatorFunctions.required(),
				},
			},
			licensePlate: {
				rules: {
					required: ValidatorFunctions.required(),
					maxLength: ValidatorFunctions.maxLength(20),
					noEmptySpace: ValidatorFunctions.noEmptySpace(),
					warningUnique: {
						message: TranslateText('fieldsValidations.warningValueNotUnique'),
						isWarning: true,
						validationFunction: (data, fieldName) => {
							if (!data[fieldName]) {
								return ValidatorFunctions.wrapInPromise(true);
							}

							return ajaxUtil.post<boolean>(`${GlobalSettings.validateApi}/ObjectFields`, {
								customerId: object.customerId,
								value: data[fieldName],
								Field: 'licensePlate',
								editedEntityId: object?.id || null,
							});
						},
					},
				},
			},
			name: {
				rules: {
					required: ValidatorFunctions.required(),
					maxLength: ValidatorFunctions.maxLength(50),
					noEmptySpace: ValidatorFunctions.noEmptySpace(),
				},
			},
			vin: {
				rules: {
					maxLength: ValidatorFunctions.maxLength(30),
				},
			},
			brand: {
				rules: {
					maxLength: ValidatorFunctions.maxLength(100),
				},
			},
			model: {
				rules: {
					maxLength: ValidatorFunctions.maxLength(100),
				},
			},
			mileage: {
				rules: {
					required: ValidatorFunctions.requiredIfFieldHasValue('objectFunction', ObjectFunctionEnum.Full),
					maxLength: ValidatorFunctions.maxLength(10),
					minNumber: ValidatorFunctions.minNumber(0),
				},
			},
			toleranceTimeOutDistance: {
				rules: {
					required: ValidatorFunctions.required(),
					minLimit: {
						message: TranslateText('fieldsValidations.minValue') + '50',
						validationFunction: (data, fieldName) => {
							const value = Number(data[fieldName]);
							if (value === -1) {
								return ValidatorFunctions.wrapInPromise(true);
							} else {
								return ValidatorFunctions.wrapInPromise(
									(!value && value !== 0) || value >= 50
								);
							}
						},
					},
					maxNumber: ValidatorFunctions.maxNumber(250),
				},
			},
			timeOutEndOfTrip: {
				rules: {
					required: ValidatorFunctions.requiredIfFieldHasValue('objectFunction', ObjectFunctionEnum.Full),
					minLimit: {
						message: TranslateText('fieldsValidations.minValue') + '15',
						validationFunction: (data, fieldName) => {
							const value = Number(data[fieldName]);
							if (value === -1) {
								return ValidatorFunctions.wrapInPromise(true);
							} else {
								return ValidatorFunctions.wrapInPromise(
									(!value && value !== 0) || value >= 15
								);
							}
						},
					},
					maxNumber: ValidatorFunctions.maxNumber(300),
				},
			},
			addInGroups: {
				rules: {
					warningNotEmpty: {
						message: TranslateText('fieldsValidations.addInGroupsWarning'),
						validationFunction: (data) => {
							const objectData = data as TrackableObject;
							return ValidatorFunctions.wrapInPromise(objectData.addInGroups?.length > 0);
						},
						disabled: !ClaimUtil.validateClaim(user, {
							claim: ClaimType.Groups,
							values: [ClaimValue.edit],
						}),
						isWarning: true,
					},
				},
			},
			fuelType: {
				rules: { required: ValidatorFunctions.required() },
			},
			objectType: {
				rules: { required: ValidatorFunctions.required() },
			},
			objectFunction: {
				rules: { required: ValidatorFunctions.required() },
			},
			co2CalculationType: {
				rules: { required: ValidatorFunctions.required() },
			},
			co2Manual: {
				rules: {
					required: {
						message: TranslateText('fieldsValidations.fieldRequired'),
						validationFunction: (data) => {
							const objectData = data as TrackableObject;
							if (objectData.co2CalculationType !== CO2CalculationTypeEnum.DistanceManual)
								return ValidatorFunctions.wrapInPromise(true);
							return ValidatorFunctions.wrapInPromise(
								objectData.co2Manual !== null && objectData.co2Manual !== undefined
							);
						},
					},
					maxValue: {
						message: TranslateText('fieldsValidations.maxValue') + '9999',
						validationFunction: (data) => {
							const objectData = data as TrackableObject;
							if (objectData.co2CalculationType !== CO2CalculationTypeEnum.DistanceManual)
								return ValidatorFunctions.wrapInPromise(true);
							return ValidatorFunctions.wrapInPromise(
								objectData.co2Manual === null ||
									objectData.co2Manual === undefined ||
									objectData.co2Manual < 10000
							);
						},
					},
					minValue: {
						message: TranslateText('fieldsValidations.minValue') + '0',
						validationFunction: (data) => {
							const objectData = data as TrackableObject;
							if (objectData.co2CalculationType !== CO2CalculationTypeEnum.DistanceManual)
								return ValidatorFunctions.wrapInPromise(true);
							return ValidatorFunctions.wrapInPromise(objectData.co2Manual === null ||
								objectData.co2Manual === undefined ||
								objectData.co2Manual >= 0);
						},
					},
				},
			},
		},
	});

	useEffect(() => {
		const defaultSettings = getDefaultSettings();
		if (defaultSettings) {
			setObject({ ...object, ...defaultSettings });
		}

		validator.validate(object).then((result) => {
			setValidationResult(result.validationResult);
			props.setValidationCallback(result.formResult);
		});
	}, []);

	useEffect(() => {
		validator.validate(object).then((result) => {
			setValidationResult(result.validationResult);
			props.setValidationCallback(result.formResult);
			if (unsavedData) {
				props.onChangeCallback(object);
			}
		});
	}, [object]);

	useEffect(() => {
		setObject((oldObj) => {
			return {
				...oldObj,
				mileage: null,
				timeOutEndOfTrip: !isAsset ? entityDefaultsSettings?.timeOutEndOfTrip : -1,
				objectType: ObjectTypeEnum.Unknown,
				icon: !isAsset ? 'objects/car' : 'objects-asset/handyman',
			};
		});
	}, [object?.objectFunction]);
	useEffect(() => {
		setCustomerCountryId(GetAvailableCustomerInfo(entityCustomer ?? GetSelectedCustomer().id)?.startCountryId);
	}, [entityCustomer]);
	useEffect(() => {
		if (object?.licensePlate && customerCountryId === NL_COUNTRY_ID) {
			setrdwDataFetching(true);
			ajaxUtil
				.get<RDWVehicleDataDto>(`${GlobalSettings.objectsMaintenanceApi}/QueryRDW/${object.licensePlate}`)
				.then((data) => {
					if (data) {
						const newObject = { ...object };
						newObject.brand = data.brand;
						newObject.objectType = data.objectType;
						newObject.model = data.model;
						newObject.fuelType = data.fuelType;
						newObject.co2Nedc = data.co2Nedc;
						newObject.co2Wltp = data.co2Wltp;
						newObject.motExireDate = data.motExpireDate;
						newObject.motorInsuranceLiabilityAct = data.motorInsuranceLiabilityAct;
						newObject.tachographExpireDate = data.tachographExpireDate;
						if (newObject.co2Wltp) {
							newObject.co2CalculationType = CO2CalculationTypeEnum.DistanceWLTP;
							newObject.co2Manual = null;
							setObject(newObject);
							setUnsavedData(true);
							return;
						} else if (newObject.co2Nedc) {
							newObject.co2CalculationType = CO2CalculationTypeEnum.DistanceNEDC;
							newObject.co2Manual = null;
							setObject(newObject);
							setUnsavedData(true);
							return;
						} else {
							setCO2CalculationMethod(newObject);
						}
					} else {
						const newObject = { ...object };
						newObject.brand = '';
						newObject.model = '';
						newObject.co2Nedc = null;
						newObject.co2Wltp = null;
						newObject.motExireDate = null;
						newObject.motorInsuranceLiabilityAct = null;
						newObject.tachographExpireDate = null;
						setCO2CalculationMethod(newObject);
					}
				})
				.finally(() => {
					setrdwDataFetching(false);
				});
		}
	}, [object?.licensePlate]);

	const setCO2CalculationMethod = (newObject: TrackableObject) => {
		if (
			newObject.objectType === null ||
			[
				ObjectTypeEnum.Van,
				ObjectTypeEnum.Truck,
				ObjectTypeEnum.Car,
				ObjectTypeEnum.Crane,
				ObjectTypeEnum.Motorcycle,
				ObjectTypeEnum.Boat,
				ObjectTypeEnum.Bus,
				ObjectTypeEnum.Coach,
				ObjectTypeEnum.Moped,
				ObjectTypeEnum.Tractor,
			].indexOf(newObject.objectType) === -1
		) {
			newObject.co2CalculationType = CO2CalculationTypeEnum.NotApplicable;
			newObject.co2Manual = null;
			newObject.co2Nedc = null;
			newObject.co2Wltp = null;

			setObject(newObject);
			setUnsavedData(true);
			return;
		}
		if (newObject.objectType !== null && newObject.fuelType !== null) {
			ajaxUtil
				.get<RDWVehicleDataDto>(
					`${GlobalSettings.objectsMaintenanceApi}/QueryFuelObjectTypeValue/${newObject.customerId}/${newObject.objectType}/${newObject.fuelType}`
				)
				.then((data) => {
					if (data !== null) {
						newObject.co2CalculationType = CO2CalculationTypeEnum.DistanceTypeAndFuel;
						newObject.co2Manual = null;
					} else {
						newObject.co2CalculationType = CO2CalculationTypeEnum.DistanceManual;
					}

					setObject(newObject);
					setUnsavedData(true);
				});
		} else {
			newObject.co2CalculationType = CO2CalculationTypeEnum.NotApplicable;
			newObject.co2Wltp = null;
			newObject.co2Wltp = null;
			newObject.co2Manual = null;
			setObject(newObject);
			setUnsavedData(true);
		}
	};

	const getDefaultSettings = () => {
		let defaultSettings: {
			timeOutEndOfTrip: number;
			toleranceTimeOutDistance: number;
			co2CalculationType: CO2CalculationTypeEnum;
		} = null;
		if (!object.toleranceTimeOutDistance || !object.timeOutEndOfTrip || !object.co2CalculationType) {
			defaultSettings = {
				toleranceTimeOutDistance: !object.toleranceTimeOutDistance
					? entityDefaultsSettings.toleranceTimeOutDistance
					: object.toleranceTimeOutDistance,
				timeOutEndOfTrip: !object.timeOutEndOfTrip
					? entityDefaultsSettings.timeOutEndOfTrip
					: object.timeOutEndOfTrip,
				co2CalculationType: !object.co2CalculationType
					? entityDefaultsSettings.co2CalculationType
					: object.co2CalculationType,
			};
		}

		return defaultSettings;
	};

	const handleValueChangeMaterial = (value: any, statePropName: string) => {
		const newObject = { ...object };
		const lastValue = newObject[statePropName];
		newObject[statePropName] = value;
		if (
			!isExternalCodeFirstTouched &&
			value &&
			!object.externalCode &&
			entityDefaultsSettings.externalCodeSameCode &&
			statePropName === 'code'
		) {
			newObject.externalCode = value;
		}
		setObject(newObject);
		setUnsavedData(newObject[statePropName] !== lastValue ? true : unsavedData);
	};

	const handleValueChange = async (arg: any, statePropName: string, isSyncfusion = true) => {
		if (isSyncfusion && !arg.event) {
			return;
		}

		const newObject = { ...object };
		if (!arg.value) {
			newObject[statePropName] = arg;
		} else {
			newObject[statePropName] = arg.value;
		}

		if (statePropName === 'active') {
			newObject[statePropName] = !object.active;
		}
		setObject(newObject);
		setUnsavedData(true);
	};

	const handleCheckboxValue = (args: any) => {
		const newObject = { ...object };
		newObject.active = args.target.checked;

		setObject(newObject);
		setUnsavedData(true);
	};

	const handleIconChange = (iconName: string, content: string, iconSet: string) => {
		handleValueChangeMaterial(`${iconSet}/${iconName}`, 'icon');
	};

	return (
		<div className={'object-view-container'}>
			<div className={'object-column'}>
				<div className={'column-content'}>
					<div className={'column-title'}>{TranslateText('common.systemInformation')}</div>
					<form id="objectViewSystemInformationForm" noValidate={true}>
						<div className="view-section-wrapper">
							<div className="form-group">
								<MaterialTextField
									autoFocus={true}
									isForNewAddWizard={true}
									id="object-code"
									className="resize-font"
									label={TranslateText('fields.code')}
									inputProps={{ style: { fontSize: 10 } }}
									name="code"
									value={object.code}
									handleValueChange={(value) => handleValueChangeMaterial(value, 'code')}
									disabled={
										!ClaimUtil.validateClaim(user, {
											claim: ClaimType.Objects,
											values: [ClaimValue.edit],
										})
									}
									validationResult={validationResult?.code}
								/>
							</div>
							<div className="form-group">
								<MaterialTextField
									isForNewAddWizard={true}
									id="externalCode"
									className="resize-font"
									label={TranslateText('fields.externalCode')}
									inputProps={{ style: { fontSize: 10 } }}
									name="externalCode"
									value={object.externalCode}
									handleValueChange={(value) => handleValueChangeMaterial(value, 'externalCode')}
									handleFocus={() => {
										if (
											!isExternalCodeFirstTouched &&
											object.code &&
											!object.externalCode &&
											entityDefaultsSettings.externalCodeSameCode
										) {
											const newObject = { ...object };
											newObject.externalCode = newObject.code;
											setObject(newObject);
											setIsExternalCodeFirstTouched(true);
											setUnsavedData(true);
										}
									}}
									disabled={false}
									validationResult={validationResult?.externalCode}
								/>
							</div>
							<CustomerNameDisplay
								customerId={!!defaultCustomerId?.length ? defaultCustomerId : object.customerId}
								isForNewAddWizard={true}
							/>
							<div className="form-group">
								<TimezoneSelection
									label={TranslateText('fields.timezone')}
									name="timezoneId"
									valueId={object.timezoneId}
									disabled={
										true ||
										!ClaimUtil.validateClaim(user, {
											claim: ClaimType.Objects,
											values: [ClaimValue.edit],
										})
									}
									onChange={(args) => handleValueChange(args, 'timezoneId', false)}
									isForNewAddWizard={true}
									validationResult={validationResult?.timezoneId}
								/>
							</div>
							<div className="form-group">
								<MaterialTextField
									isForNewAddWizard={true}
									id="toleranceTimeOutDistance"
									label={TranslateText('fields.toleranceTimeOutDistance')}
									inputProps={{ style: { fontSize: 10 } }}
									name="tto"
									value={object.toleranceTimeOutDistance}
									handleValueChange={(value) =>
										handleValueChangeMaterial(value, 'toleranceTimeOutDistance')
									}
									disabled={
										!ClaimUtil.validateClaim(user, {
											claim: ClaimType.Objects,
											values: [ClaimValue.edit],
										})
									}
									validationResult={validationResult?.toleranceTimeOutDistance}
								/>
							</div>
							<div className={'form-group'}>
								<MaterialTextField
									isForNewAddWizard={true}
									id={'timeOutEndOfTrip'}
									label={TranslateText('fields.timeOutEndOfTrip')}
									inputProps={{ style: { fontSize: 10 } }}
									name={'ett'}
									value={!isAsset ? object.timeOutEndOfTrip : null}
									handleValueChange={(value) =>
										handleValueChangeMaterial(value !== '' ? value : null, 'timeOutEndOfTrip')
									}
									disabled={
										isAsset ||
										!ClaimUtil.validateClaim(user, {
											claim: ClaimType.Objects,
											values: [ClaimValue.edit],
										})
									}
									validationResult={validationResult?.timeOutEndOfTrip}
								/>
							</div>
							<div className="form-group">
								<FormControlLabel
									control={
										<Checkbox
											name="active"
											color={'primary'}
											checked={object.active}
											onChange={(args: any) => handleCheckboxValue(args)}
											disabled={true}
										/>
									}
									label={
										<Typography style={{ fontSize: 10, marginRight: 5, color: 'black' }}>
											{TranslateText('fields.active')}
										</Typography>
									}
									labelPlacement="start"
									style={{ margin: 0 }}
								/>
							</div>
						</div>
					</form>
				</div>
			</div>
			<div className={'object-column'}>
				<div className={'column-content'}>
					<div className={'column-title'}>
						{TranslateText('common.objectData')}
						{rdwDataFetching && <CircularProgress style={{ marginLeft: 5 }} size={12} color="secondary" />}
					</div>
					<form id="objectDataForm" noValidate={true}>
						<div className="view-section-wrapper">
							<div className="form-group">
								<MaterialTextField
									isForNewAddWizard={true}
									id="licensePlate"
									className="resize-font"
									label={TranslateText('fields.licensePlate')}
									inputProps={{ style: { fontSize: 10 } }}
									name="licensePlate"
									value={object.licensePlate}
									disabled={
										!ClaimUtil.validateClaim(user, {
											claim: ClaimType.Objects,
											values: [ClaimValue.edit],
										})
									}
									handleValueChange={(value) => handleValueChangeMaterial(value, 'licensePlate')}
									validationResult={validationResult?.licensePlate}
								/>
							</div>
							<div className="form-group">
								<MaterialTextField
									isForNewAddWizard={true}
									id="objectName"
									className="resize-font"
									label={TranslateText('fields.name')}
									inputProps={{ style: { fontSize: 10 } }}
									name="objectName"
									value={object.name}
									handleValueChange={(value) => handleValueChangeMaterial(value, 'name')}
									disabled={false}
									validationResult={validationResult?.name}
								/>
							</div>
							<div className="form-group">
								<MaterialTextField
									isForNewAddWizard={true}
									id="objectVin"
									className="resize-font"
									label={TranslateText('fields.vin')}
									inputProps={{ style: { fontSize: 10 } }}
									name="objectVin"
									value={object.vin}
									handleValueChange={(value) => handleValueChangeMaterial(value, 'vin')}
									disabled={false}
									validationResult={validationResult?.vin}
								/>
							</div>
							<div className="form-group">
								<MaterialTextField
									isForNewAddWizard={true}
									id="objectBrand"
									className="resize-font"
									label={TranslateText('fields.brand')}
									inputProps={{ style: { fontSize: 10 } }}
									name="objectBrand"
									value={object.brand}
									handleValueChange={(value) => handleValueChangeMaterial(value, 'brand')}
									disabled={rdwDataFetching}
									validationResult={validationResult?.brand}
								/>
							</div>
							<div className="form-group">
								<MaterialTextField
									isForNewAddWizard={true}
									id="objectModel"
									className="resize-font"
									label={TranslateText('fields.model')}
									inputProps={{ style: { fontSize: 10 } }}
									name="objectModel"
									value={object.model}
									handleValueChange={(value) => handleValueChangeMaterial(value, 'model')}
									disabled={rdwDataFetching}
									validationResult={validationResult?.model}
								/>
							</div>
							{assetTracking && (
								<div className="form-group">
									<MaterialAutocomplete
										isForNewAddWizard={true}
										valueId={object.objectFunction}
										dataSource={ObjectFunctionDataSource.dataSource()}
										name="objectFunction"
										disabled={false}
										label={TranslateText('fields.objectFunction')}
										onChange={({ value }) => {
											const newObject = { ...object };
											newObject.objectFunction = value as number;

											setObject(newObject);
											setUnsavedData(true);
										}}
										disableClearable
										validationResult={validationResult?.objectFunction}
									/>
								</div>
							)}
							<div className="form-group">
								<MaterialAutocomplete
									isForNewAddWizard={true}
									valueId={object.fuelType}
									dataSource={FuelTypeDataSource.dataSource()}
									name="fuelType"
									disabled={rdwDataFetching}
									label={TranslateText('fields.fuelType')}
									onChange={({ value }) => {
										const newObject = { ...object };
										newObject.fuelType = value as number;
										if (newObject.co2CalculationType !== CO2CalculationTypeEnum.DistanceManual) {
											setCO2CalculationMethod(newObject);
										} else {
											setObject(newObject);
											setUnsavedData(true);
										}
									}}
									validationResult={validationResult?.fuelType}
								/>
							</div>
							<div className="form-group">
								<MaterialAutocomplete
									isForNewAddWizard={true}
									valueId={object.objectType}
									dataSource={
										!isAsset
											? ObjectTypeDataSource.dataSource()
											: ObjectAssetTypeDatasource.dataSource()
									}
									name="objectType"
									disabled={rdwDataFetching}
									label={TranslateText('fields.objectType')}
									onChange={({ value }) => {
										const newObject = { ...object };
										newObject.objectType = value as number;
										if (newObject.co2CalculationType !== CO2CalculationTypeEnum.DistanceManual) {
											setCO2CalculationMethod(newObject);
										} else {
											setObject(newObject);
											setUnsavedData(true);
										}
									}}
									validationResult={validationResult?.objectType}
								/>
							</div>
							<div className="form-group">
								<MaterialAutocomplete
									isForNewAddWizard={true}
									valueId={object.co2CalculationType}
									dataSource={CO2CalculationTypeDataSource.dataSource().filter((x) => !x.disabled)}
									name="co2CalculationType"
									disabled={false}
									label={TranslateText('fields.co2CalculationType')}
									onChange={({ value }) => {
										const newObject = { ...object };
										if ((value as number) !== CO2CalculationTypeEnum.DistanceManual)
											newObject.co2Manual = null;
										newObject.co2CalculationType = value as number;
										setObject(newObject);
										setUnsavedData(true);
									}}
									validationResult={validationResult?.co2CalculationType}
								/>
							</div>
							{object.co2CalculationType === CO2CalculationTypeEnum.DistanceManual ? (
								<div className="form-group">
									<MaterialTextField
										isForNewAddWizard={true}
										id="co2Manual"
										type="number"
										fullWidth
										label={TranslateText('fields.co2manual')}
										className="auto-mileage resize-font noSpinner"
										inputProps={{
											min: 0,
											style: { fontSize: 10 },
											onKeyPress: (event: any) => {
												if (event.key === '-' || event.key === 'e') {
													event.preventDefault();
												}
											},
										}}
										name="co2manual"
										value={!isAsset ? object.co2Manual : null}
										disabled={false}
										handleValueChange={(value) =>
											handleValueChangeMaterial(value !== '' ? value : null, 'co2Manual')
										}
										validationResult={validationResult?.co2Manual}
										onKeyDown={handleKeyDown}
										onWheel={(e: any) => e.target.blur()}
									/>
								</div>
							) : null}
							<div className="form-group">
								<MaterialTextField
									isForNewAddWizard={true}
									id="co2nedc"
									type="number"
									fullWidth
									label={TranslateText('fields.co2nedc')}
									className="auto-mileage resize-font"
									inputProps={{
										min: 0,
										style: { fontSize: 10 },
									}}
									name="co2nedc"
									value={!isAsset ? object.co2Nedc : null}
									disabled={true}
									handleValueChange={(value) =>
										handleValueChangeMaterial(value !== '' ? value : null, 'co2Nedc')
									}
									validationResult={validationResult?.co2Nedc}
								/>
							</div>
							<div className="form-group">
								<MaterialTextField
									isForNewAddWizard={true}
									id="co2wltp"
									type="number"
									fullWidth
									label={TranslateText('fields.co2wltp')}
									className="auto-mileage resize-font"
									inputProps={{
										min: 0,
										style: { fontSize: 10 },
									}}
									name="co2wltp"
									value={!isAsset ? object.co2Wltp : null}
									disabled={true}
									handleValueChange={(value) =>
										handleValueChangeMaterial(value !== '' ? value : null, 'co2Wltp')
									}
									validationResult={validationResult?.co2Wltp}
								/>
							</div>
							<div className="form-group">
								<MaterialTextField
									isForNewAddWizard={true}
									id="mileage"
									type="number"
									fullWidth
									label={TranslateText('fields.mileage')}
									className="auto-mileage resize-font noSpinner"
									inputProps={{
										min: 0,
										style: { fontSize: 10 },
									}}
									name="mileage"
									value={!isAsset ? object.mileage : null}
									disabled={isAsset}
									// size="small"
									handleValueChange={(value) =>
										handleValueChangeMaterial(value !== '' ? value : null, 'mileage')
									}
									validationResult={validationResult?.mileage}
									onKeyDown={handleKeyDown}
									onWheel={(e: any) => e.target.blur()}
								/>
							</div>
						</div>
					</form>
				</div>
			</div>
			<div className={'object-column'}>
				<div className={'column-content'}>
					<div className={'column-title'}>{TranslateText('common.objectPresentation')}</div>
					<div className="form-group display-flex">
						<InputLabel style={{ color: 'black', fontSize: 10, marginBottom: 4, marginRight: 4 }}>
							{TranslateText('fields.objectIcon')}
						</InputLabel>
						<div>
							<IconPicker
								defaultIcon={icon.content}
								defaultIconName={icon.name}
								onIconSelected={handleIconChange}
								containers={!isAsset ? ['objects'] : ['objects-asset']}
								size={24}
							/>
						</div>
					</div>
					<div className="form-group display-flex">
						<InputLabel style={{ color: 'black', fontSize: 10, marginBottom: 4, marginRight: 4 }}>
							{TranslateText('fields.template.tripColor')}
						</InputLabel>
						<ColorPicker
							initialColor={object.tripColor}
							onColorChangedHandler={(color: string) => handleValueChange(color, 'tripColor', false)}
						/>
					</div>
					<form id={'objectPresentationForm'} noValidate>
						<div className="form-group display-flex" style={{ alignItems: 'start' }}>
							<InputLabel style={{ color: 'black', fontSize: 10, marginBottom: 4, marginRight: 4 }}>
								{TranslateText('fields.objectImage')}
							</InputLabel>
							<ImagePicker
								previewImage={object.base64Image}
								onImageEdit={async (base64Image: string) => {
									handleValueChangeMaterial(base64Image, 'base64Image');
								}}
								aspectRatio={1}
							/>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default ObjectView;
