import React from 'react';
import MaterialTextField from 'shared/components/MaterialTextField/MaterialTextField';
import { TranslateText } from 'utils/Translations';

import { CONFIGURATIONGROUPINCIDENTS } from '../constants';
import { ConfigurationGroupProps } from '../types';
import {handleKeyDown} from "../../../../Common/RemoveSpinnerOptionInput";
import {getNumberValue} from "../../../../Widgets/Views/ConfigurationGroupSettingsView/Utils";

const ConfigurationGroupIncidentsView = ({
	data,
	validationResult,
	handleChange,
	isAddNew = true,
	editMode = true,
	isForNewAddWizard = true,
}: ConfigurationGroupProps) => {
	const handleChangeChild = (value: unknown, statePropName: string) => {
		handleChange(value, statePropName, CONFIGURATIONGROUPINCIDENTS);
	};

	return (
		<>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="RecordTime"
					className="resize-font noSpinner"
					label={TranslateText('fields.recordTime')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="RecordTime"
					value={data.configurationGroupIncidents?.recordTime}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'recordTime')}
					validationResult={validationResult?.recordTimeConfigurationGroupIncidents}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="MinimumSpeed"
					className="resize-font noSpinner"
					label={TranslateText('fields.minimumSpeed')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="MinimumSpeed"
					value={data.configurationGroupIncidents?.minimumSpeed}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'minimumSpeed')}
					validationResult={validationResult?.minimumSpeedConfigurationGroupIncidents}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="acceleration"
					className="resize-font noSpinner"
					label={TranslateText('fields.incidentAcceleration')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="acceleration"
					value={data.configurationGroupIncidents?.acceleration}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'acceleration')}
					validationResult={validationResult?.accelerationConfigurationGroupIncidents}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
			<div className="form-group">
				<MaterialTextField
					isForNewAddWizard={isForNewAddWizard}
					id="brake"
					className="resize-font noSpinner"
					label={TranslateText('fields.brake')}
					type="number"
					inputProps={{
						style: { fontSize: 10 },
						onKeyPress: (event: any) => {
							if (event.key === 'e') {
								event.preventDefault();
							}
						},
					}}
					name="brake"
					value={data.configurationGroupIncidents?.brake}
					handleValueChange={(value) => handleChangeChild(getNumberValue(value), 'brake')}
					validationResult={validationResult?.brakeConfigurationGroupIncidents}
					disabled={!isAddNew || !editMode}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
				/>
			</div>
		</>
	);
};

export default ConfigurationGroupIncidentsView;
