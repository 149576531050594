export enum ConfigurationGroupMediaBitMask {
	// None = 0,
	PictureFront = 0,
	PictureRear = 1,
	PictureLeft = 2,
	PictureRight = 3,
	PictureDriver = 4,
	VideoFront = 5,
	VideoRear = 6,
	VideoLeft = 7,
	VideoRight = 8,
	VideoDriver = 9,
}
