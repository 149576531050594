import "./resendActivationMail.scss"

import {TranslateText, TranslateTextInterpolated} from "../../utils/Translations";
import React, {useState} from "react";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	LinearProgress
} from "@material-ui/core";
import ajaxUtil from "../../utils/Ajax";
import GlobalSettings from "../../GlobalSettings.json";
import {FetchStatus} from "../../models/FetchStatus";

export interface ResendActivationMailPopUpProps {
	open: boolean;
	email: string;
	userId: string;
	customerId: string;
	onClose: () => void;
}
const ResendActivationMailPopUp = (props: ResendActivationMailPopUpProps) => {
	const [statusSent, setStatusSent] = useState<FetchStatus>(FetchStatus.None);
	const [loading, setLoading] = useState<boolean>(false);
	const resend = () => {
		setLoading(true);
		ajaxUtil
			.post(`${GlobalSettings.personsMaintenanceApi}/resendActivationMail`,
				{
					userId: props.userId,
					customerId: props.customerId
				})
			.then((result) => {
				if(result) {
					setStatusSent(FetchStatus.Loaded);
					setLoading(false);
				}
				else {
					setStatusSent(FetchStatus.Error);
					setLoading(false);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};


	return (
	<>{props.open
		? <Dialog
			open={props.open}
			onClose={null}
			className={'resendMail-dialog resendActivation-dialog'}
		>
			<DialogTitle id="parent-dialog-title">
				{TranslateText('resendActivationMail.dialogTitle')}
			</DialogTitle>
			<DialogContent>
				<Box className={'content-dialog'}>
					{statusSent === FetchStatus.None
						? <>
							<DialogContentText>
								{TranslateText('resendActivationMail.dialogMessageForResend')}
							</DialogContentText>
							{loading &&
								<div className="loading-container">
									<LinearProgress className="linear-progress"/>
								</div>
							}
						</>
						: statusSent === FetchStatus.Loaded ?
							<DialogContentText>
								{TranslateTextInterpolated('resendActivationMail.dialogMessageSuccess', [props.email])}
							</DialogContentText>
							: <DialogContentText>
								{TranslateText('resendActivationMail.error')}
							</DialogContentText>

					}
				</Box>
			</DialogContent>
			<DialogActions className={'dialog-buttons-actions'}>
				{statusSent === FetchStatus.None
					? <>
						<Button
							disabled={loading}
							className={'left-button-custom-dialog'}
							onClick={() => props.onClose()}
							color="primary"
						>
							{TranslateText('resendActivationMail.no')}
						</Button>
						<div style={{flex: '1 0 0'}}/>
						<Button
							disabled={loading}
							className={'right-button-custom-dialog'}
							onClick={() => resend()}
							color="primary"
						>
							{TranslateText('resendActivationMail.yes')}
						</Button>
					</>
					: <>
						<Button
							disabled={false}
							className={'right-button-custom-dialog'}
							onClick={() => {
								setStatusSent(FetchStatus.None);
								props.onClose();
							}}
							color="primary"
						>
							{TranslateText('resendActivationMail.close')}
						</Button>
					</>
				}
			</DialogActions>
		</Dialog>
		: null}
	</>
	);
};

export default ResendActivationMailPopUp;
