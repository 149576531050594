import '../configurationGroupView.scss'
import React, {useEffect, useState } from "react";
import {TranslateText} from "../../../../../utils/Translations";
import {InputLabel} from '@material-ui/core';
import {WidgetViewDefault} from "../../../Widget";
import {handleKeyDown} from "../../../../Common/RemoveSpinnerOptionInput";
import {ConfigurationGroupSettingsEditDto} from "../ConfigurationGroupSettingsView";
import {ConfigurationGroupSettingsEditViewProps} from "../types";
import {
	CONFIGURATIONGROUPWEIGHTS
} from "../../../../NewAddWizard/Views/ConfigurationGroup/constants";
import {checkTotalWeight} from "../../../../NewAddWizard/Views/ConfigurationGroup/ConfigGroupWeightFactorView";
import { getCurrentTabValidation, getNumberValue } from '../Utils';
import TextFieldTooltip from "../../../../../shared/components/TextFieldToolTip/TextFieldTooltip";
import {verifyDifferences} from "../../ConfigurationGroupHistoryView/Utils";

type Props = WidgetViewDefault & ConfigurationGroupSettingsEditViewProps &{
	data: ConfigurationGroupSettingsEditDto;
};
const WeightsView = (props: Props) => {

	const [validToWeight, setValidToWeight] = useState<boolean>(false);

	useEffect(() => {
		if(!props.isForModal) {
			const validTotalWeight = checkTotalWeight(props.data.configurationGroupWeights);
			setValidToWeight(validTotalWeight);
			props.setDisableSaveButtonForced(!validTotalWeight);
		}
	},[props.data]);


	useEffect(() => {
		if(props.editMode) {
			if(!props.isForModal) {
				const validTotalWeight = checkTotalWeight(props.data.configurationGroupWeights);
				const resultValidations = getCurrentTabValidation(props.validationResult, CONFIGURATIONGROUPWEIGHTS);

				let newTabValidations = [...props.tabValidations];
				newTabValidations[0] = resultValidations && validTotalWeight;
				props.setTabValidation(newTabValidations);
			}
		}
	},[props.validationResult, props.editMode]);

	return (
		<div className="container-configuration-group-edit">
			{!validToWeight && !props.isForModal && (
				<InputLabel className={'validation-error'} style={{fontSize: 12, paddingLeft: 12}}>
					{TranslateText('fieldsValidations.totalWeight')}
				</InputLabel>
			)}
			<div className={!props.isSingleColumn ? 'container-row' : 'container-column'}>
				<div className={!props.isSingleColumn ? 'col-md-4' : 'col-12'}>
				<TextFieldTooltip
					id="coast"
					type="number"
					className="resize-font noSpinner"
					fullWidth
					label={TranslateText('fields.coast')}
					toolTipText={TranslateText('fields.coastWeight')}
					fontSizeLabel={12}
					labelStyle={props.isForModal &&
					verifyDifferences(
						props.data,
						'coast',
						CONFIGURATIONGROUPWEIGHTS,
						props.differences) ? {color: props.colorLabel } : {}
					}
					inputProps={{
						readOnly: !props.editMode,
						style: { fontSize: 12 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						}}}
					name="coast"
					value={props.data.configurationGroupWeights?.coast}
					onChange={(e) => {
						props.handleChange(getNumberValue(e.target.value), 'coast', CONFIGURATIONGROUPWEIGHTS);
					}}
					disabled={!props.editMode}
					disableTooltipButton={!props.editMode && !props.isForModal}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
					validationResult={props.validationResult?.coastConfigurationGroupWeights}
				/>
				<TextFieldTooltip
					id="cruise"
					type="number"
					className="resize-font noSpinner"
					fullWidth
					label={TranslateText('fields.cruise')}
					toolTipText={TranslateText('fields.cruiseWeight')}
					fontSizeLabel={12}
					labelStyle={props.isForModal &&
					verifyDifferences(
						props.data,
						'cruise',
						CONFIGURATIONGROUPWEIGHTS,
						props.differences) ? { color: props.colorLabel } : {}}
					inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						}}}
					name="cruise"
					value={props.data.configurationGroupWeights?.cruise}
					onChange={(e) => {
						props.handleChange(getNumberValue(e.target.value), 'cruise', CONFIGURATIONGROUPWEIGHTS);
					}}
					disabled={!props.editMode}
					disableTooltipButton={!props.editMode && !props.isForModal}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
					validationResult={props.validationResult?.cruiseConfigurationGroupWeights}
				/>
				<TextFieldTooltip
					id="drivingBehaviourWeight"
					type="number"
					className="resize-font noSpinner"
					fullWidth
					label={TranslateText('fields.drivingBehaviour')}
					toolTipText={TranslateText('fields.drivingBehaviourWeight')}
					fontSizeLabel={12}
					labelStyle={props.isForModal &&
					verifyDifferences(
						props.data,
						'drivingBehaviour',
						CONFIGURATIONGROUPWEIGHTS,
						props.differences) ? { color: props.colorLabel } : {}}
					inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						}}}
					name="drivingBehaviourWeight"
					value={props.data.configurationGroupWeights?.drivingBehaviour}
					onChange={(e) => {
						props.handleChange(getNumberValue(e.target.value), 'drivingBehaviour', CONFIGURATIONGROUPWEIGHTS);
					}}
					disabled={!props.editMode}
					disableTooltipButton={!props.editMode && !props.isForModal}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
					validationResult={props.validationResult?.drivingBehaviourConfigurationGroupWeights}
				/>
				</div>
				<div className={!props.isSingleColumn ? 'col-md-4' : 'col-12'}>
				<TextFieldTooltip
					id="harshAcceleration"
					type="number"
					className="resize-font noSpinner"
					fullWidth
					label={TranslateText('fields.harshAcceleration')}
					toolTipText={TranslateText('fields.harshAccelerationWeight')}
					fontSizeLabel={12}
					labelStyle={props.isForModal &&
					verifyDifferences(
						props.data,
						'harshAcceleration',
						CONFIGURATIONGROUPWEIGHTS,
						props.differences) ? { color: props.colorLabel } : {}}
					inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						}}}
					name="harshAcceleration"
					value={props.data.configurationGroupWeights?.harshAcceleration}
					onChange={(e) => {
						props.handleChange(getNumberValue(e.target.value), 'harshAcceleration', CONFIGURATIONGROUPWEIGHTS);
					}}
					disabled={!props.editMode}
					disableTooltipButton={!props.editMode && !props.isForModal}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
					validationResult={props.validationResult?.harshAccelerationConfigurationGroupWeights}
				/>
				<TextFieldTooltip
					id="harshBrake"
					type="number"
					className="resize-font noSpinner"
					fullWidth
					label={TranslateText('fields.harshBrake')}
					toolTipText={TranslateText('fields.harshBrakeWeight')}
					fontSizeLabel={12}
					labelStyle={props.isForModal &&
					verifyDifferences(
						props.data,
						'harshBrake',
						CONFIGURATIONGROUPWEIGHTS,
						props.differences) ? { color: props.colorLabel } : {}}
					inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						}}}
					name="harshBrake"
					value={props.data.configurationGroupWeights?.harshBrake}
					onChange={(e) => {
						props.handleChange(getNumberValue(e.target.value), 'harshBrake', CONFIGURATIONGROUPWEIGHTS);
					}}
					disabled={!props.editMode}
					disableTooltipButton={!props.editMode && !props.isForModal}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
					validationResult={props.validationResult?.harshBrakeConfigurationGroupWeights}
				/>
				<TextFieldTooltip
					id="harshPedalAcceleration"
					type="number"
					className="resize-font noSpinner"
					fullWidth
					label={TranslateText('fields.harshPedalAcceleration')}
					toolTipText={TranslateText('fields.harshPedalAccelerationWeight')}
					fontSizeLabel={12}
					labelStyle={props.isForModal &&
					verifyDifferences(
						props.data,
						'harshPedalAcceleration',
						CONFIGURATIONGROUPWEIGHTS,
						props.differences) ? { color: props.colorLabel } : {}}
					inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						}}}
					name="harshPedalAcceleration"
					value={props.data.configurationGroupWeights?.harshPedalAcceleration}
					onChange={(e) => {
						props.handleChange(getNumberValue(e.target.value), 'harshPedalAcceleration', CONFIGURATIONGROUPWEIGHTS);
					}}
					disabled={!props.editMode}
					disableTooltipButton={!props.editMode && !props.isForModal}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
					validationResult={props.validationResult?.harshPedalAccelerationConfigurationGroupWeights}
				/>
				</div>
				<div className={!props.isSingleColumn ? 'col-md-4' : 'col-12'}>
				<TextFieldTooltip
					id="idle"
					type="number"
					className="resize-font noSpinner"
					fullWidth
					label={TranslateText('fields.idle')}
					toolTipText={TranslateText('fields.idleWeight')}
					fontSizeLabel={12}
					labelStyle={props.isForModal &&
					verifyDifferences(
						props.data,
						'idle',
						CONFIGURATIONGROUPWEIGHTS,
						props.differences) ? { color: props.colorLabel } : {}}
					inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						}}}
					name="idle"
					value={props.data.configurationGroupWeights?.idle}
					onChange={(e) => {
						props.handleChange(getNumberValue(e.target.value), 'idle', CONFIGURATIONGROUPWEIGHTS);
					}}
					disabled={!props.editMode}
					disableTooltipButton={!props.editMode && !props.isForModal}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
					validationResult={props.validationResult?.idleConfigurationGroupWeights}
				/>
				<TextFieldTooltip
					id="overRpm"
					type="number"
					className="resize-font noSpinner"
					fullWidth
					label={TranslateText('fields.overRpm')}
					toolTipText={TranslateText('fields.overRpmWeight')}
					fontSizeLabel={12}
					labelStyle={props.isForModal &&
					verifyDifferences(
						props.data,
						'overRpm',
						CONFIGURATIONGROUPWEIGHTS,
						props.differences) ? { color: props.colorLabel } : {}}
					inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						}}}
					name="overRpm"
					value={props.data.configurationGroupWeights?.overRpm}
					onChange={(e) => {
						props.handleChange(getNumberValue(e.target.value), 'overRpm', CONFIGURATIONGROUPWEIGHTS);
					}}
					disabled={!props.editMode}
					disableTooltipButton={!props.editMode && !props.isForModal}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
					validationResult={props.validationResult?.overRpmConfigurationGroupWeights}
				/>
				<TextFieldTooltip
					id="overSpeed"
					type="number"
					className="resize-font noSpinner"
					fullWidth
					label={TranslateText('fields.overSpeed')}
					toolTipText={TranslateText('fields.overSpeedWeight')}
					inputProps={{ readOnly: !props.editMode, style: { fontSize: 12 },
						onKeyPress: (event: any) => {
							if (event.key === '-' || event.key === 'e') {
								event.preventDefault();
							}
						}}}
					fontSizeLabel={12}
					labelStyle={props.isForModal &&
					verifyDifferences(
						props.data,
						'overSpeed',
						CONFIGURATIONGROUPWEIGHTS,
						props.differences) ? { color: props.colorLabel } : {}}
					name="overSpeed"
					value={props.data.configurationGroupWeights?.overSpeed}
					onChange={(e) => {
						props.handleChange(getNumberValue(e.target.value), 'overSpeed', CONFIGURATIONGROUPWEIGHTS);
					}}
					disabled={!props.editMode}
					disableTooltipButton={!props.editMode && !props.isForModal}
					onKeyDown={handleKeyDown}
					onWheel={(e: any) => e.target.blur()}
					validationResult={props.validationResult?.overSpeedConfigurationGroupWeights}
				/>
				</div>
			</div>
		</div>
	);
}
export default WeightsView;
