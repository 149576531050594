import "./configurationGroupObjectWidget.scss"

import React, {useCallback, useEffect, useRef, useState} from 'react';

import {ClaimConfig} from '../../../authorization/ClaimUtil';
import {TranslateText} from '../../../utils/Translations';
import WidgetHeader from '../../BaseWidget/WidgetHeader';
import {DialogUtil} from '../../Common/NotificationDialog/NotificationDialog';
import {Box, Button, Checkbox, Typography} from "@material-ui/core";
import ObjectSelectionDialog from "./ObjectSelectionDialog";
import {ITreeNode} from "../../SelectionTree/TreeNode/types";
import EntityTypeEnum from "../../../models/EntityTypeEnum";
import ajaxUtil from "../../../utils/Ajax";
import GlobalSettings from "../../../GlobalSettings.json";
import {ApplicationState} from "../../../store";
import { useSelector } from "react-redux";
import ConfigurationGroupObjectConflict, {ConfigurationGroupObjectConflictDto} from "./ConfigurationGroupObjectConflict";
import {FormatDate} from "../../../utils/DateUtils";
import {GridWidgetOverview} from "../../GridOverview/GridWidgetOverview";
import { Column } from "primereact/column";
import { Link } from "react-router-dom";

type Props = {
	position: {
		row: number;
		col: number;
		sizeX: number;
		sizeY: number;
	};
	entityId?: string;
	url?: string;
	exitEditModeOnUrlChange: boolean;
	widgetTitle: string;
	allowEditMode?: boolean;
	editModeCallback?: (editMode: boolean) => void;
	editClaimConfig?: ClaimConfig[];
	optionalEditClaimConfig?: ClaimConfig[];
	showEditButton: boolean;
	className?: string;
	hidden?: boolean;
};

interface ConfigurationGroupObjectDto {
	id: string;
	objectId: string;
	configurationGroupId: string;
	name: string;
	code: string;
	licensePlate: string;
	startDateTime: string;
	endDateTime?: string;
	canViewObject: boolean;
}
interface ConfigurationGroupObjectResult {
	success: boolean,
	hasConflicts: boolean,
	conflicts: ConfigurationGroupObjectConflictDto[]
}
const ConfigurationGroupObjectWidget = (props: Props) => {

	const defaultCustomerId = useSelector((s: ApplicationState) =>
		s.globalCustomer.filteredCustomer ? s.globalCustomer.filteredCustomer.id : s.currentSession.customerId
	);

	const [data, setData] = useState<ConfigurationGroupObjectDto[]>([]);
	const [flags, setFlags] = useState({
		editMode: false,
		hasChanges: false,
		visible: true,
		viewEditMode: false,
	});
	const [selectedRow, setSelectedRow] = useState<ConfigurationGroupObjectDto>(null);
	const [disableDisconnect, setDisableDisconnect] = useState<boolean>(false);

	const [treeSelection, setTreeSelection] = useState([]);
	const [showSelectionDialog, setShowSelectionDialog] = useState(false);
	const [includeInactive, setIncludeInactive] = useState<boolean>(false);

	const [conflictsData, setConflictsData] = useState<ConfigurationGroupObjectConflictDto[]>([]);
	const [showConflictDialog, setShowConflictDialog] = useState<boolean>(false);
	const [showEndedConnections, setShowEndedConnections] = useState<boolean>(false);

	useEffect(() => {
		props.editModeCallback(flags.editMode);
	}, [flags.editMode]);

	useEffect(() => {
		operations.getData();
	}, [props.entityId]);

	const util = {
		setEditMode: (editMode: boolean) => {
			setFlags({ ...flags, editMode: editMode });
		},
		confirm: async () => {
			return await DialogUtil.confirm({
				title: TranslateText('common.titleUnsavedData'),
				content: TranslateText('notificationMessages.cancel'),
			});
		},
	};

	const events = {
		removePanel: async () => {
			if (!flags.hasChanges || (flags.hasChanges && (await util.confirm()))) {
				setFlags({ ...flags, visible: false });
			}
		},
		toggleEdit: async () => {
			const editMode = !flags.editMode;
			if (!editMode && flags.hasChanges) {
				if (await util.confirm()) {
					util.setEditMode(editMode);
				}
			} else {
				util.setEditMode(editMode);
			}
		},
		editModeCallback: (editMode: boolean) => {
			setFlags({
				...flags,
				viewEditMode: editMode,
			});
		},
	};

	const operations = {
		getData: () => {
			ajaxUtil
				.get<ConfigurationGroupObjectDto[]>(`${GlobalSettings.configurationGroupsMaintenanceApi}/getConfigurationGroupObjects/${props.entityId}`)
				.then((data) => {
					setData(data);
				});
		},
		addObjects: (entities: string[], bypassConflicts: boolean) => {
			ajaxUtil
				.post<ConfigurationGroupObjectResult>(`${GlobalSettings.configurationGroupsMaintenanceApi}/addConfigurationGroupObjects`,
					{
						configurationGroupId: props.entityId,
						bypassConflicts: bypassConflicts,
						customerId: defaultCustomerId,
						entities: entities
					})
				.then((data) => {
					if (!data.success) {
						if (data.hasConflicts) {
							setConflictsData(data.conflicts);
							setShowConflictDialog(true);
						}
					} else {
						setShowSelectionDialog(false);
						setTreeSelection([]);
						operations.getData();
					}
				});
		},
		terminateSelectedConnection: () => {
			setDisableDisconnect(true);
			if (selectedRow) {
				const url =
					GlobalSettings.connectionsApi +
					'/TerminateObjectConfigurationGroupConnection/' + selectedRow?.id;

				ajaxUtil.post(url, null)
					.then((data) => {
						operations.getData();
						setDisableDisconnect(false);
						setSelectedRow(null);
					});
			}
		}
	}

	const actionsSelectionDialog = {
		saveSelection: useCallback(
			(treeData: ITreeNode[]) => {
				if(treeData.length > 0) {
					const entities = treeData.map((x) => x.id);
					setTreeSelection(treeData);
					operations.addObjects(entities, false);
				}
				else {
					setShowSelectionDialog(false);
				}
			},
			[]),
		closeSelectionDialog: useCallback(() => {
			setShowSelectionDialog(false);
			setTreeSelection([]);
		}, []),
		includeInactiveCallback: useCallback((includeInactive: boolean) => {
			setIncludeInactive(includeInactive);
		},[includeInactive]),
	}

	const actionsConflictDialog = {
		onClickConflict: useCallback(
			(value: boolean) => {
				setShowConflictDialog(false);
				if(value)
				{
					const entities = treeSelection.map((x) => x.id);
					operations.addObjects(entities, true);
					setShowSelectionDialog(false);
					setTreeSelection([]);
				}
				else
				{
					const newData = treeSelection.filter(x => !conflictsData.some(c => x.id === c.objectId));
					setTreeSelection(newData)
				}
			},
			[conflictsData]
		),
	}

	return (
		<div className={`e-panel widget configuration-object-widget ${flags.visible ? '' : 'hidden'} ${props.className ? props.className : ''}`}
				data-row={props.position.row}
				data-col={props.position.col}
				data-sizex={props.position.sizeX}
				data-sizey={props.position.sizeY}
				data-minsizex={props.position.sizeX}
				data-minsizey={props.position.sizeY}
				hidden={props.hidden}
		>
			<div className="e-panel-container">
				<WidgetHeader
					caption={props.widgetTitle}
					showEditButton={props.showEditButton}
					allowEditMode={props.allowEditMode || flags.editMode}
					editClaimConfig={props.editClaimConfig}
					optionalEditClaimConfig={props.optionalEditClaimConfig}
					editMode={flags.editMode}
					removePanelCallback={events.removePanel}
					editCallback={events.toggleEdit}
				/>

				<div className={'widget-view'}>
					<GridWidgetOverview
						columns={[
							<Column
								key={'code'}
								field={'code'}
								className="widget-grid-column"
								header={TranslateText('widgets.grid.colCode')}
								style={{ width: 120 }}
								body={(rowData: any) =>
									rowData.canViewObject
										? <Link to={`${GlobalSettings.route.objects}/${rowData.objectId}`}>
											{rowData.code}
								         </Link>
										: rowData.code
								}
							/>,
							<Column
								key={'name'}
								field={'name'}
								className="widget-grid-column"
								header={TranslateText('widgets.grid.colName')}
								style={{ width: 170 }}
							/>,
							<Column
								key="licensePlate"
								field="licensePlate"
								className="widget-grid-column"
								header={TranslateText('widgets.grid.colLicensePlate')}
								style={{ width: 120 }}
							/>
							,
							<Column
								key="startDateTime"
								field="startDateTime"
								className="widget-grid-column"
								header={TranslateText('widgets.grid.colStartDate')}
								style={{ width: 240 }}
								body={(rowData: any) =>
									FormatDate(new Date(rowData.startDateTime))
								}
							/>,
							<Column
								key="endDateTime"
								field="endDateTime"
								className="widget-grid-column"
								header={
									<Box display="flex" alignItems="center">
										<span
											style={{
												color: showEndedConnections ? 'inherit' : 'gray',
												opacity: showEndedConnections ? 1 : 0.6
											}}
										>
											{TranslateText('widgets.grid.colEndDate')}
										</span>
										<Checkbox
											style={{marginLeft: 10}}
											name="active"
											color="primary"
											checked={showEndedConnections}
											readOnly={false}
											onChange={(args: any) => setShowEndedConnections(args.target.checked)}
											disabled={false}
										/>
									</Box>
								}
								style={{ width: 240 }}
								body={(rowData: any) =>
									rowData.endDateTime ? (
										FormatDate(new Date(rowData.endDateTime))
									) : null
								}
							/>,
						]}
						data={!showEndedConnections
							? data.filter(x => x.endDateTime === null)
							: data
						}
						total={data?.length ?? 0}
						paginator={true}
						selectedRowCallback={(rowData) => {
							setSelectedRow(rowData);
						}}
					/>
				</div>
			{flags.editMode ? (
				<div className={`buttons-host`}>
					<div className="left-side-buttons">
						<Button
							className="widget-button disconnect"
							disabled={(selectedRow === null) || (selectedRow.endDateTime !== null) || disableDisconnect}
							onClick={() => operations.terminateSelectedConnection()}
						>
							{TranslateText('common.disconnect')}
						</Button>
					</div>
					<div className="right-side-buttons">
						<Button
							className="widget-button link"
							disabled={false}
							onClick={() => setShowSelectionDialog(true)}
						>
							{TranslateText('common.link')}
						</Button>
					</div>
				</div>
			) : null}
			</div>
			<ObjectSelectionDialog
				configurationGroupId={props.entityId}
				selectedEntities={treeSelection}
				closeDialogCallback={actionsSelectionDialog.closeSelectionDialog}
				saveCallback={actionsSelectionDialog.saveSelection}
				showDialog={showSelectionDialog}
				templateEntityType={EntityTypeEnum.Object}
				includeInactive={includeInactive}
				includeInactiveCallback={actionsSelectionDialog.includeInactiveCallback}
			/>
			<ConfigurationGroupObjectConflict
				showDialog={showConflictDialog}
				data={conflictsData}
				onClickCallback={actionsConflictDialog.onClickConflict}
			/>
		</div>
);
}

ConfigurationGroupObjectWidget.defaultProps = {
	showEditButton: true,
};

export default ConfigurationGroupObjectWidget;
