import './historyOverview.scss';

import { History, LocationState } from 'history';
import { Resizable } from 're-resizable';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NavigationPrompt from 'shared/components/UserPrompt/NavigationPrompt';
import { availableCustomersActions } from 'store/AvailableCustomers';

import { IconButton } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';

import { ResizableConstants } from '../../shared/constants/ResizableConstants';
import { ApplicationState } from '../../store';
import { historyStoreActionCreators, SET_SELECTED_TRIPS_IN_OVERVIEW } from '../../store/HistoryStore';
import { resizableEntityActionCreators, ResizableType } from '../../store/ResizableEntityState';
import HistoryMap from '../LiveMap/HistoryMap';
import HistorySideBar from '../RightSidebarMenu/HistorySideBar';
import { HistoryOverviewGrid } from './HistoryOverviewGrid';
import HistoryTabs from './HistoryTabs/HistoryTabs';
import HistoryTimePicker from './HistoryTimePicker';
import HistoryTripTypeSelector from './HistoryTripTypeSelector';
import trackableObject from "../../models/TrackableObject";
import HistoryTripEditWizard from "./HistoryTripEditWizard/HistoryTripEditWizard";

const HistoryOverview = ({ history }: { history: History<LocationState> }) => {
	const rightSideBar = useSelector((state: ApplicationState) => state.rightSideBar);
	const historyMapActive = useSelector((state: ApplicationState) => state.historyStore.historyMapActive);
	const tripUnsavedData = useSelector((state: ApplicationState) => state.historyStore.tripUnsavedData);
	const hasMap = useSelector(
		(state: ApplicationState) =>
			state.globalCustomer?.filteredCustomer?.featuresSettings?.map ||
			state.currentSession.customer?.featuresSettings?.map
	);
	const dispatch = useDispatch();
	const selectedTrips = useSelector(
		(s: ApplicationState) => s.historyStore.selectedTripsInOverview?.selectedTripsInOverviewIds
	);
	const selectedTripEditBatch = useSelector((state: ApplicationState) => state.historyStore.tripEditBatch);
	const [resizableMenuExpandedHistoryMap, setResizableExpandedHistoryMap] = useState(true);
	const [resizableMenuExpandedHistoryGrid, setResizableExpandedHistoryGrid] = useState(false);
	const resizableMenuHistoryMap = useRef<Resizable>(null);
	const resizableMenuHistoryGrid = useRef<Resizable>(null);
	const resizableMenuHistory = useSelector((s: ApplicationState) => s.resizableEntity);
	const [isCollapsed, setIsCollapsed] = useState(
		historyMapActive
			? resizableMenuHistory.resizableMenuHistoryMap.isCollapsed
			: resizableMenuHistory.resizableMenuHistoryGrid.isCollapsed
	);
	const [resizableHeightHistoryEditTrip, setResizableHeightHistoryEditTrip] = useState<number>(400);
	const calculateExpandedHistoryGridView = useCallback((isExpanded: boolean) => {
		if (resizableMenuHistoryGrid.current) {
			if (
				(resizableMenuHistoryGrid.current.getParentSize().width / 2 - ResizableConstants.defaultWidth) / 2 +
					ResizableConstants.defaultWidth <
				resizableMenuHistoryGrid.current.size.width
			) {
				if (!isExpanded) {
					setResizableExpandedHistoryGrid(true);
					dispatch(resizableEntityActionCreators.setExpanded(ResizableType.HistoryGrid, true));
				}
			} else if (isExpanded) {
				setResizableExpandedHistoryGrid(false);
				dispatch(resizableEntityActionCreators.setExpanded(ResizableType.HistoryGrid, false));
			}
		}
	}, []);
	const calculateExpandedHistoryMapView = useCallback((isExpanded: boolean) => {
		if (resizableMenuHistoryMap.current) {
			if (
				(resizableMenuHistoryMap.current.getParentSize().width / 2 - ResizableConstants.defaultWidth) / 2 +
					ResizableConstants.defaultWidth <
				resizableMenuHistoryMap.current.size.width
			) {
				if (!isExpanded) {
					setResizableExpandedHistoryMap(true);
					dispatch(resizableEntityActionCreators.setExpanded(ResizableType.HistoryMap, true));
				}
			} else if (isExpanded) {
				setResizableExpandedHistoryMap(false);
				dispatch(resizableEntityActionCreators.setExpanded(ResizableType.HistoryMap, false));
			}
		}
	}, []);
	useEffect(() => {
		if (historyMapActive) {
			calculateExpandedHistoryMapView(resizableMenuExpandedHistoryMap);
		} else {
			calculateExpandedHistoryGridView(resizableMenuExpandedHistoryGrid);
		}
	}, [historyMapActive, isCollapsed]);

	useEffect(() => {
		if (!hasMap && historyMapActive) {
			dispatch(historyStoreActionCreators.setIsMapActive());
		}
		dispatch(availableCustomersActions.toggleSelection(true));
		if(selectedTripEditBatch.activeEdit) {
			dispatch(historyStoreActionCreators.clearTripEditBatch());
			dispatch(historyStoreActionCreators.setTripUnsavedData(false));
		}
	}, []);
	useEffect(() => {
		historyMapActive
			? setIsCollapsed(resizableMenuHistory.resizableMenuHistoryMap.isCollapsed)
			: setIsCollapsed(resizableMenuHistory.resizableMenuHistoryGrid.isCollapsed);
	}, [resizableMenuHistory, historyMapActive]);

	useEffect(() => {
		dispatch({
			type: SET_SELECTED_TRIPS_IN_OVERVIEW,
			payload: {
				selectedTripsInOverviewIds: selectedTrips,
				selectedFromOverview: false,
			},
		});

		return () => {
			dispatch(historyStoreActionCreators.clearTripEditBatch());
			dispatch(historyStoreActionCreators.setTripUnsavedData(false));
		};
	}, []);

	return (
		<>
			<NavigationPrompt unsavedData={tripUnsavedData} history={history} />
			<div className={'history-overview'}>
				<div className={'history-map-container'}>
					<div className={'history-filter-container'}>
						<HistoryTimePicker />
						<HistoryTripTypeSelector />
						{!historyMapActive && (
							<IconButton
								onClick={() => {
									dispatch(historyStoreActionCreators.setSaveXlsx(true));
								}}
							>
								<GetApp />
							</IconButton>
						)}
					</div>
					<HistoryTabs />
					<div className={'map-container'}>
						{hasMap && historyMapActive ? (
							<div className={'live-map-container'}>
								<HistoryMap />
							</div>
						) : (
							<>
							{selectedTripEditBatch?.activeEdit
								? <HistoryTripEditWizard
									entityId={selectedTripEditBatch?.selectedEntity}
									entityName={selectedTripEditBatch?.selectedEntityName}
									entityType={selectedTripEditBatch?.selectedEntityType}
									hasTripDevice={selectedTripEditBatch?.hasTripDevice}
									resizableHeight={resizableHeightHistoryEditTrip}
									setResizableHeightCallback={(value: number) => setResizableHeightHistoryEditTrip(value)}
								/>
								: null
							}
							<HistoryOverviewGrid resizableHeightHistoryEditTrip={resizableHeightHistoryEditTrip} />
							</>
						)}
					</div>
				</div>
				<Resizable
					ref={historyMapActive ? resizableMenuHistoryMap : resizableMenuHistoryGrid}
					size={{
						width: historyMapActive
							? isCollapsed
								? ResizableConstants.minWidth
								: resizableMenuHistory.resizableMenuHistoryMap.width
							: isCollapsed
							? ResizableConstants.minWidth
							: resizableMenuHistory.resizableMenuHistoryGrid.width,
						height: ResizableConstants.maxHeight,
					}}
					enable={{
						top: false,
						right: false,
						bottom: false,
						left: !isCollapsed,
						topRight: false,
						bottomRight: false,
						bottomLeft: false,
						topLeft: false,
					}}
					minWidth={isCollapsed ? ResizableConstants.minWidth : ResizableConstants.defaultWidth}
					maxWidth={ResizableConstants.maxWidth}
					style={{ display: 'flex' }}
					className={`${!rightSideBar.showRightBar ? 'hide-resizable-component' : ''} ${
						resizableMenuExpandedHistoryMap || resizableMenuExpandedHistoryGrid ? 'history-sidebar-max' : ''
					}`}
					onResize={() => {
						historyMapActive
							? calculateExpandedHistoryMapView(resizableMenuExpandedHistoryMap)
							: calculateExpandedHistoryGridView(resizableMenuExpandedHistoryGrid);
					}}
					onResizeStop={(event, direction, ref, dimension) => {
						historyMapActive
							? dispatch(
									resizableEntityActionCreators.setWidth(
										ResizableType.HistoryMap,
										resizableMenuHistory.resizableMenuHistoryMap.width + dimension.width
									)
							  )
							: dispatch(
									resizableEntityActionCreators.setWidth(
										ResizableType.HistoryGrid,
										resizableMenuHistory.resizableMenuHistoryGrid.width + dimension.width
									)
							  );
					}}
				>
					<HistorySideBar visible={rightSideBar.showRightBar} />
				</Resizable>
			</div>
		</>
	);
};

export default HistoryOverview;
