import './conflictView.scss'

import React, {useEffect, useRef, useState } from "react";

import {TripEditWizardDto} from "../../HistoryTripEditWizard";
import {Box, Button, Checkbox, CircularProgress, Collapse, Fade, FormControlLabel, IconButton,
	Paper, Popper, PopperPlacementType, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import {KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import {FormatDate} from "../../../../../utils/DateUtils";
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import {TranslateText, TranslateTextInterpolated} from "../../../../../utils/Translations";

interface ConflictProps {
	data: TripEditWizardDto;
	tripsForEdit: TripsForEditDto[];
	objectsInConflict: TripConflictDto[];
	overlaps: TripBatchOverlapped[];
	setTripsForEditCallback: (value: TripsForEditDto[]) => void;
	setIndexCallback: (value: number) => void;
	selectedEntityId: string;
	setIsLoadingCallback: (value: boolean) => void;
	isLoading: boolean;
	cancelCallback: () => void;
	setDisableConfirmCallback: (value: boolean) => void;
}

export interface TripConflictDto {
	objectId: string;
	objectName: string;
	tripsCount: number;
	tripsInConflict: string[];
}

export interface TripsForEditDto {
	tripId: string;
	startDateTime: string;
	endDateTime?: string;
	isInProgress: string;
	checked: boolean;
}
export interface TripConflictResultDto {
	trips: TripsForEditDto[];
	objects:TripConflictDto[];
	overlaps: TripBatchOverlapped[];
}

export interface TripBatchOverlapped {
	id: string;
	startDateTimeUtc: string;
	endDateTimeUtc: string;
	editDateTimeUtc: string;
}

const ConflictView = (props: ConflictProps) => {

	const [openRows, setOpenRows] = useState<{ [key: string]: boolean }>({});
	const [isExpanded, setIsExpanded] = useState(false);
	const [openBypassAgreement, setOpenBypassAgreement] = useState(false);
	const [agreement, setAgreement] = useState(false);
	const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);
	const [placement, setPlacement] = React.useState<PopperPlacementType>();
	const [consent, setConsent] = useState<boolean>(false);
	const checkboxRef = useRef(null);

	useEffect(() => {
		props.setDisableConfirmCallback(!agreement);
	},[]);
	const handleRowClick = (id: any) => {
		setOpenRows((prev: any) => ({
			...prev,
			[id]: !prev[id],
		}));
	}
	const toggleExpand = () => {
		setIsExpanded((prev) =>  !prev);
	};

	const handleBypassConsentClick = (agreement: boolean) => {
		setAgreement(agreement);
		setOpenBypassAgreement(false);
		setConsent(false);
		props.setDisableConfirmCallback(!agreement);
	};
	const handleCheckboxClick = () => {
		if(agreement) {
			setAgreement(false);
			props.setDisableConfirmCallback(true);
		}
		else {
			setOpenBypassAgreement((prev) => !prev);
		}
	};

	return (
		<div className={'conflict-view'}>
			<div className={'conflict-container'}>
				{!props.isLoading
					?
					<>
					{props.overlaps?.length > 0
						? <>
							<div className={'field-row row-padding'} style={{ minWidth: 800, position: 'relative', overflow: 'hidden' }}>
								<span className={'conflict-title'}>{TranslateText('historyBatchEdit.overlaps')}</span>
								<span onClick={handleCheckboxClick} ref={checkboxRef}>
									<Checkbox
										className="category-selection-checkbox"
										color={'default'}
										checkedIcon={<CheckBoxOutlinedIcon />}
										icon={<CheckBoxOutlineBlankOutlinedIcon />}
										checked={agreement}
									/>
								  </span>
								<Popper
									style={{ maxWidth: 295, zIndex: 1000 }}
									open={openBypassAgreement}
									anchorEl={checkboxRef.current}
									placement="bottom"
									modifiers={[
										{
											name: 'flip',
											enabled: true,
											options: {
												altBoundary: true,
												rootBoundary: 'document',
												padding: 8,
											},
										},
										{
											name: 'arrow',
											enabled: true,
											options: {
												element: checkboxRef.current,
											},
										},
									]}
									transition
								>
									{({ TransitionProps }) => (
										<Fade {...TransitionProps} timeout={350}>
											<Paper elevation={24}>
												<Typography
													style={{
														fontSize: 12,
														color: 'grey',
														marginLeft: 10,
													}}
												>
													{TranslateText('historyBatchEdit.bypassOverlapConsentMessage')}
												</Typography>
												<FormControlLabel
													control={
														<Checkbox
															name="active"
															color={'primary'}
															checked={consent}
															onChange={(event) => setConsent(event.target.checked)}
														/>
													}
													label={
														<Typography
															style={{
																fontSize: 12,
																marginRight: 5,
																color: 'grey',
																marginLeft: 10,
															}}
														>
															{TranslateText('historyBatchEdit.bypassOverlapConsent')}
														</Typography>
													}
													labelPlacement="start"
													style={{ margin: 0 }}
													disabled={false}
												/>
												<div>
													<Button
														style={{
															fontSize: 12,
															textTransform: 'none',
														}}
														onClick={() => handleBypassConsentClick(false)}
													>
														{TranslateText('common.buttonCancel')}
													</Button>
													<Button
														disabled={!consent}
														style={{
															fontSize: 12,
															textTransform: 'none',
														}}
														onClick={() => handleBypassConsentClick(true)}
													>
														{TranslateText('common.buttonAgree')}
													</Button>
												</div>
											</Paper>
										</Fade>
									)}
								</Popper>
							</div>
							<div className={"field-row row-padding"} style={{minWidth: 800, paddingBottom: isExpanded ? 0 : 20}}>
								<span className={'row-weight'}>
									{TranslateText('historyBatchEdit.overlapInformation')}
								</span>
								<IconButton
									aria-label={'expand row'}
									size="small"
									onClick={() => toggleExpand()}
									style={{ padding: 0, height: '30px' }}
								>
									{isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
								</IconButton>
							</div>
							{isExpanded && (
								<div style={{ marginLeft: '20px', fontSize: 12, paddingBottom: 10 }}>
									{props.overlaps.map((overlap, idx) => (
										<div
											key={overlap.id}
											className={'field-row row-padding'}
											style={{
												minWidth: 750,
												padding: '8px 16px',
												color: '#666',
												backgroundColor: idx % 2 === 0 ? '#ffffff' : '#f5f5f5',
												borderBottom: idx < props.overlaps.length - 1 ? '1px solid #ddd' : 'none',
											}}
										>
											<span> {TranslateText('historyBatchEdit.batchEditOverlapFrom')} <strong>{FormatDate(overlap.startDateTimeUtc, false, false, false)}</strong> {TranslateText('historyBatchEdit.tripConflictTo')} <strong>{FormatDate(overlap.endDateTimeUtc, false, false, false)}</strong> {TranslateText('historyBatchEdit.batchEditOverlapCreatedAt')} <strong>{FormatDate(overlap.editDateTimeUtc, false, false, false)}</strong></span>
										</div>
									))}
								</div>
							)}
						</>
						: null
					}
					{props.objectsInConflict?.length > 0
						? <>
							<div className ={'field-row row-padding'}>
								<span className ={ 'conflict-title'}>{TranslateText('historyBatchEdit.conflict')}</ span >
							</div>
							<div className ={'field-row row-padding'}
								  style ={ { width: '100%', justifyContent: 'flex-start'} }>
								<span className ={ 'row-weight'}
									   style ={ { paddingRight: 5} }>
									{ props.data.selectedDriverName}
								</span>
								<span style ={ { paddingRight: 5} }>{TranslateText('historyBatchEdit.alreadyRegistered')}</ span >
							</div>
						</>
						: null
					}
					<Table>
					<TableBody>
						{props.objectsInConflict.map((row, index) => (
							<React.Fragment key={row.objectId}>
								<TableRow
									style={{
										backgroundColor: index % 2 === 0 ? '#ffffff' : '#f5f5f5',
										height: '30px',
									}}
								>
									<TableCell
										style={{
											fontSize: 14,
											height: '30px',
											padding: '0 16px',
											lineHeight: '30px',
											fontWeight: 'bold',
											overflow: 'hidden',
											whiteSpace: 'nowrap',
											textOverflow: 'ellipsis',
										}}
									>
										{TranslateTextInterpolated('historyBatchEdit.conflictInformation', [row.objectName, row.tripsCount.toString()])}
									</TableCell>
									<TableCell
										align="right"
										style={{
											height: '30px',
											padding: '0 16px',
											lineHeight: '30px',
										}}
									>

										< Checkbox
											className="category-selection-checkbox"
											color={'default'}
											checkedIcon={<CheckBoxOutlinedIcon />}
											icon={<CheckBoxOutlineBlankOutlinedIcon />}
											style={{marginRight: 24}}
											checked={!props.tripsForEdit.some((x) => row.tripsInConflict.some((t) => t === x.tripId) && !x.checked)}
											onClick={(event: any) => {
												const isChecked = event.target.checked;
												const newTripsForEdit = [...props.tripsForEdit];

												newTripsForEdit.forEach((t) => {
													if (row.tripsInConflict.some((x) => t.tripId === x)) {
														t.checked = isChecked;
													}
												});

												props.setTripsForEditCallback(newTripsForEdit);
											}}
										/>

										<IconButton
											aria-label="expand row"
											size="small"
											onClick={() => handleRowClick(row.objectId)}
											style={{ padding: 0, height: '30px' }}
										>
											{openRows[row.objectId] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
										</IconButton>
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
										<Collapse in={openRows[row.objectId]} timeout="auto" unmountOnExit>
											<Box margin={1}>
												{props.tripsForEdit.filter((x) => row.tripsInConflict.some((t) => t === x.tripId))
													.map((content, idx) => (
													<TableRow key={idx}
															  style={{
																  maxWidth: '100%',
																  backgroundColor: idx % 2 === 0 ? '#f9f9f9' : '#ffffff',
															  }}
													>
														<TableCell
															colSpan={2}
															style={{
																fontSize: 12,
																padding: '8px 16px',
																color: '#666',
																borderBottom: idx < row.tripsInConflict.length - 1 ? '1px solid #ddd' : 'none',
															}}
														>
															{<div className={'field-row'} style={{width: '100%'}}>
																<span> {TranslateText('historyBatchEdit.tripConflict')} <strong>{FormatDate(content.startDateTime, false, false, true)}</strong> {TranslateText('historyBatchEdit.tripConflictTo')} <strong>{!content.isInProgress ? FormatDate(content.endDateTime, false, false, true) : '-'}</strong></span>
																<span
																	onClick ={
																		() => {
																			const newTripsForEdit = [...props.tripsForEdit];
																			newTripsForEdit.forEach((t) => {
																				if (t.tripId === content.tripId)
																					t.checked = !t.checked;
																			});
																			props.setTripsForEditCallback(newTripsForEdit);
																		}}
																>
																< Checkbox
																	className = "category-selection-checkbox"
																	color ={ 'default'}
																	checkedIcon ={< CheckBoxOutlinedIcon />}
																	icon ={< CheckBoxOutlineBlankOutlinedIcon />}
																	checked={content.checked}
																/>
															</span>
															</div>
															}
														</TableCell>
													</TableRow>
												))}
											</Box>
										</Collapse>
									</TableCell>
								</TableRow>
							</React.Fragment>
						))}
					</TableBody>
				</Table>
				</>
					: <CircularProgress className="loading-component" />
				}
			</div>
		</div>
	);
};

export  default ConflictView;
